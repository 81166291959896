import React, { useState } from "react";
import { useDispatch } from "react-redux";

/*Import react components */
import { AttributeTable } from "./AttributeTable";

/*Import redux actions */
import { productStatusAlert } from "../../../redux/actions/sellerActions";

/*Import material ui */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { IconButton, capitalize } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import InfoIcon from "@material-ui/icons/Info";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#d5f9ef",
    color: "#3c3c3c",
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: 13,
    fontWeight: "bold",
    letterSpacing: 1,
  },
  body: {
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#2d2b2b",
    fontWeight: 500,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#eeeeee",
    },
  },
}))(TableRow);

/* matrial ui css */
const useStyles = makeStyles((theme) => ({
  actionButtonDiv: {
    display: "flex",
    justifyContent: 'center'
  },
  deleteButton: {
    border: "1px solid #53E7BC",
    background: "transparent",
    color: "#53E7BC",
    borderRadius: "0.2rem",
  },
  editButton: {
    border: "1px solid #53E7BC",
    background: "#53E7BC",
    color: "#FFFFFF",
    borderRadius: "0.2rem",
  },
  detailsButton: {
    border: "1px solid #53E7BC",
    background: "transparent",
    color: "#53E7BC",
    borderRadius: "0.2rem",
  },
  addAttribute: {
    border: "1px solid #53E7BC",
    background: "#53E7BC",
    color: "#FFFFFF",
    borderRadius: "0.2rem",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    padding: theme.spacing(1),
    margin: "4px",
    "&:hover": {
      background: "#53E7BC",
      color: "#FFFFFF",
    },
  },
  noFound: {
    color: "#030104",
    textAlign: "center",
  },
  colorbox: {
    width: "20px",
    height: "20px",
    border: "2px solid #383838",
    borderRadius: "50%",
  },
}));

 const ProductsTable = (props) => {
  const classes = useStyles();
  const {
    product,
    setConfirmOpen,
    setProductId,
    handleClickEditOpen,
    handleClickDetailsOpen,
    handleClickAddAttribute,
    setActiveConfirm,
    setStatusObject,
  } = props;

  const dispatch = useDispatch();

  const [openAttributeList, setOpenAttributeList] = useState(false);

  /* function to handle open delete confirmation window and set product id */
  const handleClickOpen = (id) => {
    setConfirmOpen(true);
    setProductId(id);
  };

  /* function to handle open product active confirm dialog */
  const handleStatusOpen = (id, status, title) => {
    setActiveConfirm(true);
    setStatusObject((prevState) => ({
      ...prevState,
      status: status,
      id: id,
      title: title,
    }));
  };

  const handleProductActivation = () => {
    dispatch(
      productStatusAlert("Add attribute to Activate the product status.")
    );
  };

  return (
    <React.Fragment key={product.id}>
      <StyledTableRow>
        <StyledTableCell component="th" align="center" scope="row">
          {product.id}
        </StyledTableCell>

        <StyledTableCell align="center">
          {capitalize(product.description.name)}
        </StyledTableCell>

        <StyledTableCell align="center">
          {product.categories ? capitalize(product.categories[0]["code"]) : "-"}
        </StyledTableCell>

        <StyledTableCell align="center">{product.quantity}</StyledTableCell>

        <StyledTableCell align="center">
          {product.visible ? (
            <Button
              variant="contained"
              onClick={() => handleStatusOpen(product.id, false, "Inactive")}
              size="small"
              color="primary"
            >
              Active
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={
                product.attributes?.length > 0
                  ? () => handleStatusOpen(product.id, true, "Active")
                  : () => handleProductActivation()
              }
              size="small"
              style={{ backgroundColor: "#dc004e", color: "#fff" }}
            >
              Inactive
            </Button>
          )}
        </StyledTableCell>

        <StyledTableCell align="center">
          <div className={classes.actionButtonDiv}>
            <Tooltip title="Delete Product" placement="top" arrow>
              <IconButton
                aria-label="delete"
                onClick={() => handleClickOpen(product.id)}
                className={classes.deleteButton}
                style={{
                  outline: "none",
                  height: "30px",
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Edit Product" placement="top" arrow>
              <IconButton
                aria-label="edit"
                onClick={() => handleClickEditOpen(product)}
                className={classes.editButton}
                style={{
                  outline: "none",
                  height: "30px",
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="View Product" placement="top" arrow>
              <IconButton
                aria-label="details"
                onClick={() => handleClickDetailsOpen(product)}
                className={classes.detailsButton}
                style={{
                  outline: "none",
                  height: "30px",
                }}
              >
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </StyledTableCell>

        <StyledTableCell align="center">
          <IconButton
            aria-label="add-attribute"
            className={classes.addAttribute}
            onClick={() => handleClickAddAttribute(product)}
            style={{
              outline: "none",
              height: "30px",
            }}
          >
            <AddIcon fontSize="small" />
            Add
          </IconButton>

          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenAttributeList(!openAttributeList)}
            className={classes.addAttribute}
            style={{
              outline: "none",
              height: "30px",
            }}
          >
            {openAttributeList ? (
              <KeyboardArrowUpIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
            List
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>

      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={7}
        >
          <Collapse in={openAttributeList} timeout="auto" unmountOnExit>
            <AttributeTable product={product} />
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};

export default React.memo(ProductsTable)
