import React from "react";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiListItemText-primary": {
      fontSize: 12,
      fontFamily: "'Fira Sans', sans-serif",
    },
    "& .MuiListItemSecondaryAction-root": {
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 600,
      fontSize: 12,
    },
  },
  profit: {
    "&>span": {
      fontWeight: 600,
    },
  },
}));

export const SalesCommission = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { open, handleClose, commission, order } = props;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={classes.dialog}
    >
      {Object.keys(commission).length > 0 && (
        <>
          <DialogTitle
            id="responsive-dialog-title"
            style={{ textAlign: "center" }}
          >
            {"Sales Commision"}
          </DialogTitle>
          <DialogContent>
            <List>
              <ListItem style={{ padding: 0, minWidth: 300 }}>
                <ListItemText>Subtotal</ListItemText>
                <ListItemSecondaryAction>
                  {order.orderSubTotal}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              <ListItem style={{ padding: 0 }}>
                <ListItemText>GST Total</ListItemText>
                <ListItemSecondaryAction>
                  {order.orderTaxTotal}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              <ListItem style={{ padding: 0 }}>
                <ListItemText>Sales Commission (inc. service tax 18%)</ListItemText>
                <ListItemSecondaryAction>
                  - Rs.{commission.stockXBidFinderPrice}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              <ListItem style={{ padding: 0 }}>
                <ListItemText>OutXtock Fixed Charge</ListItemText>
                <ListItemSecondaryAction>
                  - Rs.{commission.stockXBidFixedPrice}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              <ListItem style={{ padding: 0 }}>
                <ListItemText className={classes.profit}>
                  Sale's Profit
                </ListItemText>
                <ListItemSecondaryAction>
                  Rs.{commission.sellerProfit}
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
