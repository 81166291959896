import React from "react";

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#f0e0d4",
  },
  pagetitle: {
    fontSize: 28,
    fontWeight: 600,
    color: "#F24046",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
    fontStretch: "normal",
    margin: "90px 0 10px 0",
    textAlign: "center",
  },

  text: {
    fontSize: 16,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 300,
    margin: "auto",
    lineHeight: "30px",
    color: "#5c6166",
    marginBottom: 15,
  },
  darkText: {
    fontSize: 16,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 300,
    margin: "auto",
    lineHeight: "30px",
    color: "#5c6166",
  },
  heading: {
    textAlign: "start",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    color: "#F24046",
    marginBottom: 15,
  },
  subheading: {
    fontSize: 16,
    fontFamily: "'Fira Sans', sans-serif",
    margin: "15px 0 15px 0",
    fontWeight: 600,
  },
}));

export const PrivacyPolicyContent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container fixed style={{ marginBottom: 20 }}>
        <Grid item xs={12}>
          <Typography component="h6" className={classes.pagetitle}>
            PRIVACY POLICY
          </Typography>

          {/* <div>
              {parse(getDocument(TERMS_OF_USE_DOC))}
          </div> */}

          <Typography component="p" className={classes.text}>
            The website{" "}
            <a href="https://outxtock.com" target="_blank">
              www.outxtock.com
            </a>{" "}
            and its mobile application (each a <b>“Platform”</b>) enables
            sellers to list and offer to sell their products to various buyers
            without any kind of intervention from Outxtock. Outxtock recognizes
            the importance of privacy as well as the importance of maintaining
            the confidentiality of personal information. This Privacy Policy
            <b> “Privacy Policy”</b> applies to all products and services
            provided by Outxtock and sets out how Outxtock may collect, use and
            disclose information in relation to User(s) of the Platform.
          </Typography>

          <Typography component="p" className={classes.text}>
            User(s) may use Outxtock's services and products via a mobile device
            either through mobile applications or mobile optimized websites.
            This Privacy Policy also applies to such use of Outxtock’s services
            and products.
          </Typography>

          <Typography component="p" className={classes.text}>
            All capitalized terms not defined in this document shall have the
            meanings ascribed to them in the Terms of Use of the Platform, which
            can be found here. Contracting entity shall be Stockxbid
            Technologies Private Limited (herein after referred to as{" "}
            <b>‘Outxtock’</b> or <b>‘us’</b> or <b>‘our’</b>).
          </Typography>

          <Typography component="h6" className={classes.heading}>
            1. COLLECTION OF INFORMATION
          </Typography>

          <Typography component="h6" className={classes.text}>
            1. User(s) privacy is important to Outxtock and Outxtock have taken
            steps to ensure that Outxtock do not collect more information from
            User than is necessary for Outxtock to provide User(s) with
            Outxtock’s services and to protect User(s) account.
          </Typography>
          <Typography component="h6" className={classes.text}>
            2. Information including, but not limited to, User(s) name, address,
            phone number, fax number, email address, gender, date and/or year of
            birth and user preferences ("Registration Information") may be
            collected at the time of User registration on the Platform.
          </Typography>
          <Typography component="h6" className={classes.text}>
            3. In connection with any communication or transaction and payment
            services or any other services that you may avail using the
            Platform, information, including but not limited to, bank account
            numbers, billing and delivery information, credit/debit card numbers
            and expiration dates and tracking information from cheques or money
            orders ("Account Information") may be collected, among other things,
            to facilitate the sale and purchase as well as the settlement of
            purchase price of the products or services transacted on or procured
            through the Platform, if applicable.
          </Typography>
          <Typography component="h6" className={classes.text}>
            4. Outxtock records and retains details of Users’ activities on the
            Platform. Information relating to communication or transactions
            including, but not limited to, the types and specifications of the
            goods, pricing and delivery information, any dispute records and any
            information disclosed in any communication forum provided by us
            and/or other affiliated companies of Outxtock (“Activities
            Information”) may be collected as and when the communication and /
            or transactions are conducted through the Platform.
          </Typography>
          <Typography component="h6" className={classes.text}>
            5. Outxtock records and retains records of Users’ browsing or buying
            activities on Platform including but not limited to IP addresses,
            browsing patterns and User behavioural patterns. In addition, we
            gather statistical information about the Platform and visitors to
            the Platform including, but not limited to, IP addresses, browser
            software, operating system, software and hardware attributes, pages
            viewed, number of sessions and unique visitors (together "Browsing
            Information").
          </Typography>
          <Typography component="h6" className={classes.text}>
            6. Registration Information, Account Information, Activities
            Information, and Browsing Information are collectively referred to
            as User Data.
          </Typography>
          <Typography component="h6" className={classes.text}>
            7. It is mandatory for Users of the Platform to provide certain
            categories of User Data (as specified at the time of collection). In
            the event that Users do not provide any or sufficient User Data
            marked as mandatory, Outxtock may not be able to complete the
            registration process or provide such Users with Outxtock’s products
            or services.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            2. USE OF USER DATA
          </Typography>

          <Typography component="p" className={classes.text}>
            If you provide any User Data to Outxtock, you are deemed to have
            authorized Outxtock to collect, retain and use that User Data for
            the following purposes:
          </Typography>

          <Typography component="h6" className={classes.text}>
            1. Verification of User’s identity.
          </Typography>
          <Typography component="h6" className={classes.text}>
            2. Processing User’s registration as a user, providing User(s) with
            a log-in ID for the Platform and maintaining and managing User’s
            registration.
          </Typography>
          <Typography component="h6" className={classes.text}>
            3. Providing User(s) with customer service and responding to User(s)
            queries, feedback, claims or disputes;
          </Typography>
          <Typography component="h6" className={classes.text}>
            4. To enable communication between Users on the Platform and / or
            processing Users transactions on the Platform.
          </Typography>
          <Typography component="h6" className={classes.text}>
            5. Performing research or statistical analysis in order to improve
            the content and layout of the Platform, to improve Outxtock’s
            product offerings and services and for marketing and promotional
            purposes.
          </Typography>
          <Typography component="h6" className={classes.text}>
            6. Subject to applicable laws, Outxtock (including our affiliated
            companies and their designated Service Providers may use User’s
            name, phone number, residential address, email address, fax number
            and other data ("Marketing Data") to provide notices, surveys,
            product alerts, communications and other marketing materials to
            User(s) relating to products and services offered by Outxtock or
            Outxtock’s affiliated companies.
          </Typography>
          <Typography component="h6" className={classes.text}>
            7. If User(s) voluntarily submit any User(s) information or other
            information to the Platform for publication on the Platform through
            the publishing tools, then User(s) are deemed to have given consent
            to the publication of such information on the Platform; and
          </Typography>
          <Typography component="h6" className={classes.text}>
            8. making such disclosures as may be required for any of the above
            purposes or as required by law, regulations, and guidelines or in
            respect of any investigations, claims or potential claims brought on
            or against us or against third parties.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            3. DISCLOSURE OF USER DATA
          </Typography>

          <Typography component="h6" className={classes.text}>
            1. User(s) further agrees that Outxtock may disclose and transfer
            User(s) Data to third party service providers (including but not
            limited to data entry, database management, promotions, products and
            services alerts, delivery services, payment extension services,
            authentication and verification services and logistics services)
            ("Service Providers"). These Service Providers are under a duty of
            confidentiality to Outxtock and are only permitted to use User(s)
            Data in connection with the purposes specified in clause 2 herein
            above.
          </Typography>
          <Typography component="h6" className={classes.text}>
            2. User(s) agree that Outxtock may disclose and transfer User(s)
            Data to Outxtock’s affiliated companies and/or their designated
            Service Providers.
          </Typography>
          <Typography component="h6" className={classes.text}>
            3. When necessary Outxtock may also disclose and transfer User(s)
            Data to our professional advisers, law enforcement agencies,
            insurers, government and regulatory and other organizations.
          </Typography>
          <Typography component="h6" className={classes.text}>
            4. Any User(s) Data supplied by User(s) will be retained by Outxtock
            and will be accessible by our employees, any Service Providers
            engaged by Outxtock and third parties referred to in clause 3
            herein, for or in relation to any of the purposes stated in Clause 2
            herein above.
          </Typography>
          <Typography component="h6" className={classes.text}>
            5. All voluntary information provided/uploaded such as products,
            images, remarks, feedbacks etc. by User(s) on the Platform may be
            made publicly available on the Platform and therefore accessible by
            any internet user. Any voluntary information that User disclose to
            Outxtock becomes public information and User relinquishes any
            proprietary rights in such information. User(s) should exercise
            caution when deciding to include personal or proprietary information
            in the voluntary information that User(s) submits to Outxtock or
            uploads on the Platform.
          </Typography>
          <Typography component="h6" className={classes.text}>
            6. Outxtock may share User Data with third parties, including
            without limitation, banks, financial institutions, credit agencies,
            or vendors to enable such third parties to offer their products or
            services to such Users. While Outxtock shall endeavour to have in
            place internal procedures to keep User Data secure from intruders,
            there is no guarantee that such measures/procedures can eliminate
            all of the risks of theft, loss or misuse.
          </Typography>
          <Typography component="h6" className={classes.text}>
            7. Outxtock may establish relationships with other parties and
            websites to offer User the benefit of products and services which
            Outxtock does not offer. Outxtock may offer you access to these
            other parties and/or their websites. This Privacy Policy does not
            apply to the products and services enabled or facilitated by such
            third parties. The privacy policies of those other parties may
            differ from Outxtock, and Outxtock has no control over the
            information that User may submit to those third parties. User should
            read the relevant privacy policy for those third parties before
            responding to and availing any offers, products or services
            advertised or provided by those third parties.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            4. RIGHT TO UPDATE USER DATA
          </Typography>

          <Typography component="p" className={classes.text}>
            Under the applicable laws, User(s) have the right of access to
            personal information held by Outxtock and to request updating /
            correcting the information.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            5. COOKIES
          </Typography>
          <Typography component="p" className={classes.text}>
            Outxtock uses "cookies" to store specific information about User(s)
            and track User(s) visits to the Sites. A "cookie" is a small amount
            of data that is sent to User’s browser and stored on User’s device.
            If User does not deactivate or erase the cookie, each time User uses
            the same device to access the Platform, our services will be
            notified of User visit to the Platform and in turn Outxtock may have
            knowledge of User(s) visit and the pattern of User’s usage.
          </Typography>
          <Typography component="p" className={classes.text}>
            Generally, Outxtock use cookies to identify User(s) and enable
            Outxtock to
          </Typography>

          <Typography component="h6" className={classes.text}>
            i) access User’s Registration Information or Account Information so
            User(s) do not have to re-enter it;
          </Typography>
          <Typography component="h6" className={classes.text}>
            ii) gather statistical information about usage by Users(s);
          </Typography>
          <Typography component="h6" className={classes.text}>
            iii) research visiting patterns and help target advertisements based
            on User(s) interests;
          </Typography>
          <Typography component="h6" className={classes.text}>
            iv) assist Outxtock’s partners to track User(s) visits to the
            Platform and process orders; and
          </Typography>
          <Typography component="h6" className={classes.text}>
            v) track progress and participation on the Platform.
          </Typography>

          <Typography component="p" className={classes.text}>
            User(s) can determine if and how a cookie will be accepted by
            configuring the browser which is installed in User’s device. If
            User(s) chooses, User(s) can change those configurations. If User(s)
            reject all cookies by choosing the cookie-disabling function, User
            may be required to re-enter information on the Platform more often
            and certain features of the Platform may be unavailable.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            6. MINORS
          </Typography>

          <Typography component="p" className={classes.text}>
            The Platform and its contents are not targeted to minors (those
            under the age of 18). However, Outxtock has no way of distinguishing
            the age of individuals who access our Platform. If a minor has
            provided Outxtock with personal information without parental or
            guardian consent, the parent or guardian should contact Outxtock’s
            Legal Department at the address set out in clause 11 below to remove
            the information.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            7. SECURITY MEASURES
          </Typography>

          <Typography component="p" className={classes.text}>
            Outxtock employs commercially reasonable security methods to prevent
            unauthorized access to the Platform, to maintain data accuracy and
            to ensure the correct use of the information Outxtock holds. No data
            transmission over the internet or any wireless network can be
            guaranteed to be perfectly secure. As a result, while Outxtock tries
            to protect the information Outxtock holds, Outxtock cannot guarantee
            the security of any information the User transmits to Outxtock and
            User(s) do so at their own risk.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            8. DATA RETENTING
          </Typography>

          <Typography component="p" className={classes.text}>
            Outxtock takes every reasonable step to ensure that User’s personal
            information is processed ‘as is’. You may access, correct, and
            update your personal information directly through the
            functionalities provided on the Platform.
          </Typography>

          <Typography component="p" className={classes.text}>
            You have an option to withdraw your consent that you have already
            provided by writing to us at the contact information provided below.
            Please mention “for withdrawal of consent” in the subject line of
            your communication. Outxtock will verify such requests before acting
            upon your request. Please note, however, that withdrawal of consent
            will not be retroactive and will be in accordance with the terms of
            this Privacy Policy, related terms of use, and applicable laws. In
            the event you withdraw the consent given to us under this Privacy
            Policy, such withdrawal may hamper your access to the Platform or
            restrict the provision of our services to you for which Outxtock
            consider that information to be necessary.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            9. USER RIGHTS
          </Typography>

          <Typography component="p" className={classes.text}>
            Outxtock retains your personal information in accordance with
            applicable laws, for a period no longer than is required for the
            purpose for which it was collected or as required under any
            applicable law. However, Outxtock may retain data related to you if
            it believes it may be necessary to prevent fraud or future abuse, to
            enable Outxtock to exercise its legal rights and/or defend against
            legal claims or if required by law or for other legitimate purposes.
            Outxtock may continue to retain your data in anonymised form for
            analytical and research purposes.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            10. CHANGES TO THIS PRIVACY POLICY
          </Typography>

          <Typography component="p" className={classes.text}>
            Any changes to this Privacy Policy will be communicated by us
            posting an amended and restated Privacy Policy on the Platform. Once
            posted on the Platform the new Privacy Policy will be effective
            immediately. Your continued use of the Platform shall be deemed to
            be your acceptance to the provisions of the Privacy Policy. User(s)
            agrees that any information Outxtock hold about User (as described
            in this Privacy Policy and whether or not collected prior to or
            after the new Privacy Policy became effective) will be governed by
            the latest version of the Privacy Policy.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            GRIEVANCE OFFICER
          </Typography>
          <Typography component="p" className={classes.text}>
            In accordance with Information Technology Act 2000 and rules made
            there under, the name and contact details of the Grievance Officer
            are provided below:
          </Typography>
          <Typography component="p" className={classes.darkText}>
            <b>Rohan Dhiman</b>
          </Typography>
          <Typography component="p" className={classes.darkText}>
            <b>Grievance Officer,</b>
          </Typography>
          <Typography component="p" className={classes.darkText}>
            <b>
              Address: 29/1, First Floor, 6th Cross Rd, Sangeetha Apts, Swimming
              Pool Extension, Malleshwaram 11th Cross, Bengaluru, Karnataka
              560003
            </b>
          </Typography>
          <Typography component="p" className={classes.darkText}>
            <b>Ph: +91 9380272287.</b>
          </Typography>
          <Typography component="p" className={classes.darkText}>
            <b>Email: <a href="mailto:rohan.dhiman@stockxbid.com "> rohan.dhiman@stockxbid.com </a></b>
          </Typography>
          <Typography component="p" className={classes.darkText}>
            <b>Time: Mon - Sat (10:00 AM - 07:00 PM).</b>
          </Typography>
        </Grid>
      </Container>
    </div>
  );
};
