import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  loading: false,
  serverError: false,
  error: null,
  orders: [],
  bargains: [],
};

export default function (state = initialState, action) {   
  switch (action.type) {
    case ActionTypes.SELLINGS_DETAILS_BARGAINS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.SELLINGS_DETAILS_BARGAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        bargains: action.payload.bargains,
      };
    case ActionTypes.SELLINGS_DETAILS_BARGAINS_ERROR:
      return {
        ...state,
        loading: false,
        bargains: action.payload.bargains,
        error: action.payload.error,
      };

    case ActionTypes.SELLINGS_DETAILS_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.SELLINGS_DETAILS_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders : action.payload.orders,
      };
    case ActionTypes.SELLINGS_DETAILS_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
        orders : action.payload.orders,
        error: action.payload.error,
      };

    case ActionTypes.SELLINGS_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
        serverError: false,
      };
    case ActionTypes.SET_BARGAINS:
      return {
        ...state,
        loading : false,
        bargains: state.bargains.filter((item) => item._id !== action.payload),
      };
    case ActionTypes.SELLINGS_BARGAINS_RESPONSE_REQUEST:
      return {
        ...state,
        loading : true
      };
    case ActionTypes.SELLINGS_BARGAIN_RESPONSE_ERROR:
      return {
        ...state,
        loading : false
      };
    

    case ActionTypes.SELLER_CANCEL_REFRESH: 
    return {
        ...state,
        orders : action.payload.orders,
    };
    
    
       
    default:
      return state;
  }
}
