// import React, { Fragment, useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import axios from "axios";

/* import redux actions */
import { paymentSuccess } from "../../../redux/actions/checkoutActions";

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import PaymentIcon from "@material-ui/icons/Payment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ActionTypes } from "../../../redux/constants/actionTypes";
import { getBargainUpdateRequest } from "../../../util/componentUtil/CheckOutUtiils";
import { useState } from "react";
// import { getHashValue } from "../../../util/commonUtil/HashUtil";
import Spinner from "../../../util/spinner/spinner";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(0, 2),
    "& .MuiListItemText-primary": {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
    },
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  titleblock: {
    backgroundColor: "#53E7BC",
    border: "1px solid #d6d6d6",
    borderRadius: 4,
  },
  summarytitle: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const baseUrl = process.env.REACT_APP_BASE_URL; /* react base url */

export const Payment = (props) => {
  const classes = useStyles();
  const { handleBack } = props;
  const { cartItem } = useSelector((state) => state.shoppingCart);
  const { account } = useSelector((state) => state.auth);
  const { paymentLoader } = useSelector((state) => state.checkout);

  const dispatch = useDispatch();
  const history = useHistory();

  /* ============= payu money with hidden form values */
  const [values, setValues] = useState(false);
  const [key, setKey] = useState("");
  const [txnid, setTxnid] = useState("");
  const [productinfo, setProductinfo] = useState("");
  const [amount, setAmount] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [hash, setHash] = useState("");
  const [surl, setSurl] = useState("");
  const [furl, setFurl] = useState("");
  //dev
  const [salt, setSalt] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [boltUrl, setBoltUrl] = useState("");
  const [paymentWindow, setPaymentWindow] = useState("");

  // staging
  // const [salt, setSalt] = useState("YkYxUhAtYuLqggyreJB6i1rSSDxuEtMR");

  // prod
  // const [salt, setSalt] = useState("47WId8vIU7");

  const fetchPaymentDetails = async () => {
    if (account.delivery) {
      const details = {
        email: account.emailAddress,
        firstName: account.firstName,
        lastName: account.lastName,
        phone: account.billing.phone,
        productInfo: "test",
        amount: cartItem.total,
        code: cartItem.code,
        id: account.id,
        userType: account.userType,
      };

      const { data } = await axios.post(
        `${baseUrl}/payupayment/details`,
        details
      );

      // console.log("payment data : ", JSON.stringify(data));
      // setKey(process.env.REACT_APP_MERCHANT_KEY);
      setKey(data.key);
      setTxnid(data.txnId);
      setProductinfo(data.productInfo);
      setAmount(data.amount);
      setEmail(data.email);
      setFirstname(data.firstName);
      setLastname(data.lastName);
      setPhone(data.phone);
      setHash(data.hash);
      setSurl(data.sUrl);
      setFurl(data.fUrl);
      setSalt(data.salt);
      setValues(true);
      setPaymentWindow(data.paymentWindow);
      setBoltUrl(data.boltUrl);
      setLinkUrl(data.linkUrl);
    }
  };

  useEffect(() => {
    fetchPaymentDetails();
  }, []);
  /* eslint-disable-line react-hooks/exhaustive-deps */

  /* ============ end ================ */

  useEffect(() => {
    dispatch({ type: ActionTypes.CLEAR_PAYMENT_LOAD });
  }, []);

  const handlePayUmoney = async () => {
    if (account.delivery) {
      // const details = {
      //   email: account.emailAddress,
      //   firstName: account.firstName,
      //   lastName: account.lastName,
      //   phone: account.billing.phone,
      //   productInfo: "test",
      //   amount: cartItem.total,
      //   code: cartItem.code,
      //   id: account.id,
      //   userType : account.userType
      // };

      // const { data } = await axios.post(
      //   `${baseUrl}/payupayment/details`,
      //   details
      // );

      const RequestData = {
        key: key,
        txnid: txnid,
        hash: hash,
        amount: amount,
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone: phone,
        productinfo: productinfo,
        surl: surl,
        furl: furl,
        udf1: cartItem.code,
        udf2: account.id,
        udf3: account.userType,
      };

      // console.log("bolt call : ", JSON.stringify(RequestData));

      window.bolt.launch(RequestData, {
        responseHandler: (response) => {
          // console.log("payu response : " + JSON.stringify(response));

          if (response.response.txnStatus === "CANCEL") {
            dispatch({ type: ActionTypes.CLEAR_PAYMENT_LOAD });
            return;
          } else {
            const successPayment = {
              currency: "INR",
              payment: {
                amount: response.response.amount,
                payUMoneyPaymentResponse: {
                  amount: response.response.amount,
                  createdOn: response.response.createdOn,
                  merchId: response.response.meCode,
                  merchanId: "merchChanId",
                  mihpayId: response.response.mihpayid,
                  mode: response.response.mode,
                  paidCustomerName: response.response.field4,
                  paidCustomerPhoneNumber: response.response.phone,
                  paymentId: response.response.payuMoneyId,
                  txnId: response.response.txnid,
                  txnMessage: response.response.txnMessage,
                  txnStatus: response.response.txnStatus,
                },
                paymentModule: "payu",
                paymentType: "PAYUMONEY",
                transactionType: "AUTHORIZECAPTURE",
              },
            };

            let updateQuery = getBargainUpdateRequest(
              cartItem.products,
              cartItem.customer,
              "PAID"
            );

            dispatch(
              paymentSuccess(
                successPayment,
                cartItem.code,
                history,
                updateQuery
              )
            );
          }
        },
        catchException: (error) => {
          dispatch({ type: ActionTypes.CLEAR_PAYMENT_LOAD });
          // console.log(error);
          history.push("/payupayment/failure/response");
        },
      });
    }
  };

  return (
    <Fragment>
      {paymentWindow && paymentWindow !== "" && paymentWindow === "bolt" && (
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
          />

          <script id="bolt" src={boltUrl} bolt-color="e34524"></script>
        </Helmet>
      )}
      {/* bolt-logo="" */}

      <Backdrop className={classes.backdrop} open={paymentLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.titleblock}>
            <Typography gutterBottom className={classes.summarytitle}>
              Payment method
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            {!values ? (
              <Spinner />
            ) : (
              values &&
              paymentWindow &&
              paymentWindow !== "" &&
              paymentWindow === "link" && (
                <form action={linkUrl} method="post">
                  <input type="hidden" name="key" value={key} />
                  <input type="hidden" name="txnid" value={txnid} />
                  <input type="hidden" name="productinfo" value={productinfo} />
                  <input type="hidden" name="amount" value={amount} />
                  <input type="hidden" name="email" value={email} />
                  <input type="hidden" name="firstname" value={firstname} />
                  <input type="hidden" name="lastname" value={lastname} />
                  <input type="hidden" name="surl" value={surl} />
                  <input type="hidden" name="furl" value={furl} />
                  <input type="hidden" name="phone" value={phone} />
                  <input type="hidden" name="hash" value={hash} />
                  <input type="hidden" name="salt" value={salt} />
                  <input type="hidden" name="udf1" value={cartItem.code} />
                  <input type="hidden" name="udf2" value={account.id} />
                  <input type="hidden" name="udf3" value={account.userType} />

                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="add"
                    className={classes.margin}
                    type="submit"
                  >
                    <PaymentIcon className={classes.extendedIcon} />
                    Pay
                  </Fab>
                </form>
              )
            )}

            {paymentWindow &&
              paymentWindow !== "" &&
              paymentWindow === "bolt" && (
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="add"
                  className={classes.margin}
                  onClick={handlePayUmoney}
                >
                  <PaymentIcon className={classes.extendedIcon} />
                  Pay Prod
                </Fab>
              )}
          </Grid>
        </Grid>
      </Container>

      <div className={classes.buttons}>
        <Button onClick={handleBack} className={classes.button}>
          Back
        </Button>
      </div>
    </Fragment>
  );
};
