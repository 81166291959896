import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ButtonBase from "@material-ui/core/ButtonBase";
// import { isArrayNotEmpty } from "../../../util/commonUtil/commonUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
  dilogAppbar: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#53E7BC",
    border: "none",
    boxShadow: "0 1px 10px rgba(0, 0, 0, 0.1)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#FFFFFF",
  },
  modalTitle: {
    fontSize: "1rem",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#FFFFFF",
  },
  table: {
    minWidth: 500,
    "& .MuiTableCell-head": {
      fontWeight: 800,
      fontSize: "14px",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#363636",
    },
    "& .MuiTableCell-body": {
      fontWeight: 500,
      fontSize: "14px",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#363636",
    },
  },
  tableHead: {
    background: "#d5f9ef",
  },
  image: {
    width: 60,
    height: 60,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  colorbox: {
    width: "20px",
    height: "20px",
    border: "2px solid #383838",
    borderRadius: "50%",
    margin: "auto",
  },
}));

const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

export const ViewOrderDialog = (props) => {
  const classes = useStyles();
  const { open, order, handleCloseViewOrders } = props;

  return (
    <Dialog
      onClose={handleCloseViewOrders}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={classes.root}
      maxWidth={"md"}
      fullWidth={true}
    >
      <DialogTitle id="customized-dialog-title" className={classes.dilogAppbar}>
        <Typography className={classes.modalTitle}>Ordered Product</Typography>

        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCloseViewOrders}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">Product ID</TableCell>
                <TableCell align="center">Category</TableCell>
                <TableCell align="center">Image</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Size</TableCell>
                <TableCell align="center">Color</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="center">Price</TableCell>
                <TableCell align="center">Order Total</TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={classes.tableBody}>
              {Object.keys(order).length > 0 && order.product != null ? (
                <TableRow>
                  <TableCell component="th" align="center" scope="row">
                    {order.product.id}
                  </TableCell>
                  <TableCell component="th" align="center" scope="row">
                    {order.product.categories[0].code}
                  </TableCell>
                  <TableCell component="th" align="center" scope="row">
                    <ButtonBase className={classes.image}>
                      <img
                        className={classes.img}
                        alt="complex"
                        src={`${imageBaseUrl}${order.image}`}
                      />
                    </ButtonBase>
                  </TableCell>
                  <TableCell component="th" align="center" scope="row">
                    {order.productName}
                  </TableCell>
                  <TableCell component="th" align="center" scope="row">
                    {order.attributes.length > 0
                      ? order.attributes[0].attributeName
                      : "-"}
                  </TableCell>
                  <TableCell component="th" align="center" scope="row">
                    {order.attributes.length > 0 ? (
                      <p
                        className={classes.colorbox}
                        style={{
                          backgroundColor: `${order.attributes[0].attributeValue}`,
                        }}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell component="th" align="center" scope="row">
                    {order.orderedQuantity}
                  </TableCell>
                  <TableCell component="th" align="center" scope="row">
                    {order.orderPriceSummary.basePrice}
                  </TableCell>
                  <TableCell component="th" align="center" scope="row">
                    {order.orderPriceSummary.subTotal}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>Product not found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};
