import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux actions
import { changePassword } from "../../../redux/actions/customerActions";
import { SnackBar } from "../../SnackBar";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "6px",
    padding: theme.spacing(2),
    "&:hover": {
      boxShadow:
        "0 2px 4px rgba(27, 27, 27, 0.1), 0 20px 40px rgba(27, 27, 27, 0.2)",
    },
  },
  formroot: {
    textAlign: "center",
    "& .MuiFormControl-root": {
      width: "80%",
      marginTop: "1.2rem",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "16px",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#495057",
      textAlign: "initial",
      background: "#F7F7F7",
    },
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    color: "#666666",
  },
  button: {
    background: "#53E7BC",
    color: "#FFFFFF",
    margin: "1rem",
    fontSize: 14,
    borderRadius: "0.375rem",
    padding: theme.spacing(1),
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  errorBlock: {
    fontSize: "12px",
    color: "red",
    textAlign: "center",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    textTransform: "lowercase",
  },
}));

export const ChangePassword = () => {
  const classes = useStyles();
  const { account } = useSelector((state) => state.auth);
  const { error } = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const inputsValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (!oldPassword) {
      inputsValid = false;
      formErrors["oldPassword"] = "Please enter your old password.";
    }

    if (!newPassword) {
      inputsValid = false;
      formErrors["newPassword"] = "Please enter your new password.";
    }

    if (!confirmPassword) {
      inputsValid = false;
      formErrors["confirmPassword"] = "Please enter your confirm new password.";
    }

    if (oldPassword == newPassword || oldPassword == confirmPassword) {
      inputsValid = false;
      formErrors["newPassword"] = "Old password and new password cannot be same";

    }

    if (
      typeof newPassword !== "undefined" &&
      typeof confirmPassword !== "undefined"
    ) {
      if (newPassword !== confirmPassword) {
        inputsValid = false;
        formErrors["newPassword"] = "Passwords don't match.";
      }
    }

    setFormErrors(formErrors);
    return inputsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (inputsValidation()) {
      const newPasswordData = {
        emailAddress: account.emailAddress,
        newPassword: newPassword,
        oldPassword: oldPassword,
      };

      dispatch(changePassword(newPasswordData));
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  return (
    <Grid
      container
      justify="center"
      direction="column"
      alignItems="center"
      spacing={3}
    >
      <Grid item lg={6} md={8} xs={12}>
        <Grid container justify="center">
          <Paper className={classes.paper}>
            <form className={classes.formroot} onSubmit={handleSubmit}>
              <TextField
                id="oldPassword"
                name="oldPassword"
                label="Old Password"
                type="password"
                variant="outlined"
                size="small"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                helperText={
                  formErrors.oldPassword ? formErrors.oldPassword : ""
                }
              />

              <TextField
                id="newPassword"
                name="newPassword"
                label="New Password"
                variant="outlined"
                size="small"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                helperText={
                  formErrors.newPassword ? formErrors.newPassword : ""
                }
              />

              <TextField
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm New Password"
                variant="outlined"
                size="small"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                helperText={
                  formErrors.confirmPassword ? formErrors.confirmPassword : ""
                }
              />

              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  size="small"
                  // disabled={loading}
                >
                  Submit
                </Button>
              </div>
            </form>

            {error && <Box className={classes.errorBlock}>{error}</Box>}
          </Paper>
        </Grid>
      </Grid>

      <SnackBar />
    </Grid>
  );
};
