import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// react components
import Spinner from "../../../util/spinner/spinner";
import { EditBankDetails } from "./EditBankDetails";

//redux actions
import { getSellerProfileById } from "../../../redux/actions/sellerActions";

//material ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  rootdiv: {
    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
  paper: {
    maxWidth: "981px",
    backgroundColor: "#ffffff",
    padding: theme.spacing(2),
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.16)",
    border: "1px solid #53e7bc",
    margin: "auto",
  },
  headingblock: {
    maxWidth: "981px",
    backgroundColor: "#53e7bc",
    padding: theme.spacing(2),
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.16)",
    border: "1px solid #53e7bc",
    margin: "auto",
  },
  heading: {
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#fff",
  },
}));

export const BankDetails = () => {
  const classes = useStyles();
  const { account } = useSelector((state) => state.auth);
  const { loading, sellerProfile } = useSelector((state) => state.seller);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSellerProfileById(account.id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.rootdiv}>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <Paper variant="outlined" square className={classes.headingblock}>
            <Grid item xs={12}>
              <Typography className={classes.heading}>Bank Details</Typography>
            </Grid>
          </Paper>

          <Paper variant="outlined" square className={classes.paper}>
            {Object.keys(sellerProfile).length > 0 &&
              sellerProfile.bankDetails.detailsExist && (
                <EditBankDetails
                  sellerProfile={sellerProfile}
                  userId={account.id}
                />
              )}
          </Paper>
        </Fragment>
      )}
    </div>
  );
};
