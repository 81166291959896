import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { SnackBar } from "../components/SnackBar";

/* import redux actions */
import { forgetPassword } from "../redux/actions/authActions";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { ActionTypes } from "../redux/constants/actionTypes";
import Loading from "../util/spinner/Loading";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '100%',
    minHeight: '100%',
    backgroundColor: "#F7F7F7",
    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
  paper: {
    height: "auto",
    width: "25rem",
    borderRadius: "6px",
    "&:hover": {
      boxShadow:
        "0 2px 4px rgba(27, 27, 27, 0.1), 0 20px 40px rgba(27, 27, 27, 0.2)",
    },
  },
  titlediv: {
    textAlign: "center",
  },
  heading: {
    fontSize: "30px",
    fontWeight: 600,
    color: "#4A4A4A",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.2,
  },
  lable: {
    fontSize: "12px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    color: "#212529",
    lineHeight: 1.5,
    padding: "8px",
    width: "65%",
    margin: "auto",
  },
  formroot: {
    textAlign: "center",
    "& .MuiFormControl-root": {
      width: "80%",
      marginTop: "1.2rem",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "16px",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#495057",
      textAlign: "initial",
      background: "#F7F7F7",
    },
  },
  requestButton: {
    background: "#53E7BC",
    color: "#FFFFFF",
    margin: "1rem",
    fontSize: "13px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "none",
    borderRadius: "0.375rem",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  buttongroup: {
    textAlign: "center",
  },
  cancelButton: {
    border: "1px solid #53E7BC",
    background: "transparent",
    color: "#53E7BC",
    margin: "1rem",
    fontSize: "13px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "none",
    borderRadius: "0.375rem",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    color: "#666666",
  },
  errorBlock: {
    fontSize: "12px",
    color: "red",
    textAlign: "center",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    textTransform: "lowercase",
  },
  menuitem: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#495057",
    "& em": {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 13,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#495057",
    },
  },
  dropdownStyle: {
    marginTop: 48,
  },
}));

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

export const ForgotPassword = (props) => {
  const classes = useStyles();
  const { error, loading } = useSelector((state) => state.UI);
  const history = useHistory();
  const dispatch = useDispatch();

  const [userType, setUserType] = useState(
    props.location.state === undefined
      ? ""
      : props.location.state.prevPath === "/seller-login"
      ? "user"
      : props.location.state.prevPath === "/customer-login"
      ? "customer"
      : ""
  );
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const handleClickCancel = () => {
    if(props.location.state === undefined){
      history.push("/");
    }else{
      history.push(props.location.state.prevPath)
    }
    
  };

  const inputValidation = () => {
    let errors = {};
    let inputsValid = true;

    if (props.location.state === undefined) {
      if (!userType) {
        inputsValid = false;
        errors["userType"] = "Please select user type.";
      }
    }

    if (!emailRegex.test(email)) {
      inputsValid = false;
      errors["email"] = "Invalid e-mail address";
    }

    if (!email) {
      inputsValid = false;
      errors["email"] = "Please enter your e-mail";
    }

    setErrors(errors);
    return inputsValid;
  };
  useEffect(() => {
    return () => {
      dispatch({ type: ActionTypes.CLEAR_ERRORS });
    }
  }, [])

  const handleRequestLink = (event) => {
    event.preventDefault();

    if (inputValidation()) {
      const passwordVerifier = {
        emailAddress: email,
        userType: userType,
      };

      dispatch(forgetPassword(passwordVerifier));
      setEmail("");
    }
  };

  return (
    <div className={classes.root}>
      {loading && <Loading />}
      <Grid
        container
        justify="center"
        direction="column"
        alignItems="center"
        style={{ minHeight: "100vh", maxWidth: "100%" }}
        spacing={3}
      >
        <Grid item xs={8}>
          <Grid container justify="center">
            <div className={classes.titlediv}>
              <Typography className={classes.heading}>
                Reset Your Password
              </Typography>
              <Typography className={classes.lable}>
                Enter your email address you're using for your account below and
                we will send you a password reset link
              </Typography>
              <Typography className={classes.lable} style = {{ color: "green" }}>
                Mail Missing ? Consider checking your spam folder.
              </Typography>
            </div>

            <Paper className={classes.paper}>
              <form
                autoComplete="off"
                onSubmit={handleRequestLink}
                className={classes.formroot}
              >
                {props.location.state === undefined && (
                  <FormControl variant="outlined" size="small">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={userType}
                      onChange={(e) => setUserType(e.target.value)}
                      MenuProps={{
                        classes: { paper: classes.dropdownStyle },
                      }}
                    >
                      <MenuItem value="customer" className={classes.menuitem}>
                        Customer
                      </MenuItem>
                      <MenuItem value="user" className={classes.menuitem}>
                        Seller
                      </MenuItem>
                    </Select>

                    <FormHelperText>
                      {errors.userType ? errors.userType : ""}
                    </FormHelperText>
                  </FormControl>
                )}

                <TextField
                  id="email"
                  name="email"
                  label="Your Email"
                  variant="outlined"
                  size="small"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={errors.email ? errors.email : ""}
                />

                <div className={classes.buttongroup}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.requestButton}
                    size="small"
                    disabled={loading}
                  >
                    Request Reset link
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.cancelButton}
                    onClick={handleClickCancel}
                    size="small"
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </div>
              </form>

              {error && <Box className={classes.errorBlock}>{error}</Box>}
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <SnackBar />
    </div>
  );
};
