import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import proImage from "../../../assets/images/mpd-circle@2x.jpg";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import InfoIcon from "@material-ui/icons/Info";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "981px",
    backgroundColor: "#ffffff",
    padding: theme.spacing(2),
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.16)",
    border: "1px solid #53e7bc",
    margin: "auto",
  },
  image: {
    maxWidth: "86px",
    height: "86px",
    objectFit: "contain",
  },
  sellerName: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: 1.2,
    color: "#000000",
  },
  sellerType: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.19,
    color: "#000000",
    width: "59px",
    height: "19px",
  },
  editButton: {
    maxWidth: "120px",
    maxHeight: "32px",
    borderRadius: "20px",
    backgroundColor: "#53e7bc",
    position: "relative",
    float: "right",
    marginTop: "22px",
    "&:hover": {
      backgroundColor: "#53e7bc",
    },
  },
  contactInfo: {
    display: "flex",
    marginTop: "1rem",
  },
  contactInfoTitle: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    color: "#030104",
    lineHeight: 1.5,
    textAlign: "left",
    marginLeft: "10px",
  },
  emailSection: {
    display: "flex",
    marginTop: "2rem",
  },
  phoneSection: {
    display: "flex",
    marginTop: "2rem",
  },
  gstSection: {
    marginTop: "2rem",
  },
  gstTitle: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: 1.19,
    color: "#747474",
    marginBottom: theme.spacing(1),
  },
  gstNumber: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: 1.22,
    color: "#030104",
  },
  emailId: {
    display: "flex",
  },
  phoneNumber: {
    display: "flex",
  },
  addressSection: {
    marginTop: "2rem",
  },
  addressTitle: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    color: "#747474",
    lineHeight: 1.19,
    textAlign: "left",
    marginBottom: theme.spacing(1),
  },
  address: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    color: "#030104",
    lineHeight: 1.22,
    textAlign: "left",
    maxWidth: "349px",
    height: "44px",
  },
}));

export const CompanyDetails = () => {
  const classes = useStyles();
  const { sellerProfile } = useSelector((state) => state.seller);

  return (
    <Fragment>
      {Object.keys(sellerProfile).length > 0 && (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item>
                <Icon>
                  <img
                    src={proImage}
                    alt={"profile"}
                    className={classes.image}
                  />
                </Icon>
              </Grid>

              <Grid item xs={12} sm container style={{ margin: "auto" }}>
                <Box variant="div">
                  <Typography className={classes.sellerName}>
                    {sellerProfile.supplierInfoRes.supplierDetailInfo.name}
                  </Typography>

                  <Typography className={classes.sellerType}>
                    {sellerProfile.userType}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid item xs>
              <div className={classes.contactInfo}>
                <InfoIcon fontSize="small" />

                <Typography className={classes.contactInfoTitle}>
                  Contact info
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm container>
              <Grid item xs={6}>
                <div className={classes.emailSection}>
                  <Box component="div" className={classes.emailId}>
                    <MailOutlineIcon fontSize="small" />

                    <Typography className={classes.contactInfoTitle}>
                      {sellerProfile.supplierInfoRes.supplierDetailInfo.email}
                    </Typography>
                  </Box>
                </div>
              </Grid>

              <Grid>
                <div className={classes.phoneSection}>
                  <Box component="div" className={classes.phoneNumber}>
                    <PhoneInTalkIcon fontSize="small" />

                    <Typography className={classes.contactInfoTitle}>
                      +91{" "}
                      {
                        sellerProfile.supplierInfoRes.supplierDetailInfo
                          .phoneNumber
                      }
                    </Typography>
                  </Box>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm container>
              <Grid item xs={6}>
                <div className={classes.gstSection}>
                  <Typography className={classes.gstTitle}>GST</Typography>

                  <Typography className={classes.gstNumber}>
                    {sellerProfile.supplierInfoRes.supplierDetailInfo.gstNumber}
                  </Typography>
                </div>
              </Grid>

              <Grid>
                <div className={classes.phoneSection}>
                  <Box component="div" >
                    <Typography className={classes.gstTitle}>Ownership Type</Typography>

                    <Typography className={classes.gstNumber}>
                      {
                        sellerProfile.supplierInfoRes.supplierDetailInfo
                          .typeOfOnwerShip
                      }
                    </Typography>
                  </Box>
                </div>
              </Grid>
            </Grid>

            <Grid item xs>
              <div className={classes.addressSection}>
                <Typography className={classes.addressTitle}>
                  ADDRESS
                </Typography>

                <Typography className={classes.address}>
                  {
                    sellerProfile.supplierInfoRes.supplierDetailInfo.address
                      .addressLine1
                  }{" "}
                  {
                    sellerProfile.supplierInfoRes.supplierDetailInfo.address
                      .addressLine2
                  }{" "}
                  {
                    sellerProfile.supplierInfoRes.supplierDetailInfo.address
                      .city
                  }{" "}
                  -{" "}
                  {
                    sellerProfile.supplierInfoRes.supplierDetailInfo.address
                      .pincode
                  }{" "}
                  {
                    sellerProfile.supplierInfoRes.supplierDetailInfo.address
                      .state
                  }
                </Typography>
              </div>
            </Grid>
          </Paper>
        </div>
      )}
    </Fragment>
  );
};
