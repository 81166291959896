import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { bargainResponse } from "../../redux/actions/sellerActions";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
// import { snackBarAlert } from "../../redux/actions/deliveryActions";
import { SnackBar } from "../SnackBar";
import { Card, CardContent } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .sk-circle": {
      height: "50px",
      width: "50px",
    },
  },
  contentSection: {
    maxHeight: "200px",
    minWidth: "400px",
  },
  dialogBar: {
    minWidth: 600,
    background: "#53E7BC",
    padding: "3px",
  },
  dialogTitle: {
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: "1.25rem",
    lineHeight: 2.3,
  },
  closeIcon: {
    color: "#212529",
    float: "right",
    position: "relative",
  },
  table: {
    minWidth: 700,
    "& .MuiTableCell-head": {
      fontWeight: 800,
      fontSize: "14px",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#363636",
    },
    "& .MuiTableCell-body": {
      fontWeight: 500,
      fontSize: "14px",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#363636",
      padding: theme.spacing(1),
      textAlign: "center",
    },
  },
  tableHead: {
    background: "#d5f9ef",
  },
  buttonsDiv: {
    "& > *": {
      margin: theme.spacing(1),
      textTransform: "none",
      borderRadius: "4px",
    },
  },
  actionButton: {
    backgroundColor: "#53E7BC",
    fontSize: "13px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#363636",
    "&:hover": {
      backgroundColor: "#53E7BC",
    },
  },
  notfound: {
    textAlign: "center",
    padding: "25px",
    border: "1px solid #53E7BC",
},
notfoundtext: {
    fontSize: "18px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#383838",
},
}));

export const Notification = (props) => {
  const classes = useStyles();
  const { bargains , loading} = useSelector((state) => state.sales);
  const { open, onClose } = props;

  const dispatch = useDispatch();

  const handleBargainResponse = (item, status) => {
    const responseData = {
      docId: item._id,
      ACTIVE_STATUS: true,
      ATTRIBUTE_ID: item._source.attribute_id,
      ATTRIBUTE_COLOR: item._source.attribute_color,
      ATTRIBUTE_COLOR_CODE: item._source.attribute_color_code,
      ATTRIBUTE_PRICE: item._source.attribute_price,
      ATTRIBUTE_QUANTITY: item._source.attribute_quantity,
      ATTRIBUTE_SIZE: item._source.attribute_size,
      ATTRIBUTE_SKU: item._source.attribute_sku,
      BRAND: item._source.brand,
      BARGAIN_START_DATE: item._source.bargain_start_date,
      BARGAIN_END_DATE: item._source.bargin_end_date,
      BARGAIN_PRICE: item._source.bargin_price,
      BARGAIN_QUANTITY: item._source.bargin_quantity,
      BARGAIN_TITLE: status,
      CATEGORY: item._source.category,
      CUST_ID: item._source.customer_ID,
      CUST_NAME: item._source.customer_Name,
      CUST_MOBILE: item._source.customer_number,
      event_saga_store_id: "",
      FASHION_GROUP: item._source.fashion_group,
      IDEAL_FOR: item._source.ideal_for,
      LOWEST_PRICE: item._source.lowest_price,
      MANUFACTURER: item._source.manufacture,
      MERCHANT_STORE_ID: "",
      MERCHANT: item._source.merchant,
      MANUFACTURER_ID: item._source.manufacture_ID,
      MERCHANT_ID: item._source.merchant_ID,
      OFFERING_PRICE: item._source.offering_PRICE,
      PARENT_SKU_ID: item._source.parent_sku_id,
      PRODUCT_ID: item._source.product_ID,
      PRODUCT_BUYABLE: item._source.product_buyable,
      PRODUCT_IMAGE_URL: item._source.product_image_url,
      PRODUCT_HSN: item._source.product_hsn,
      PRODUCT_TOTAL_QUANTITY: item._source.product_total_quantity,
      STATUS: status,
      SKU_DESC: "",
      SUB_CATEGORY: "",
      SKU_NAME: item._source.sku_name,
      SELLER_NUMBER: item._source.seller_number,
      uid: "",
      UPC: "",
      VNDR_ID: item._source.vendor_ID,
      VENDOR: item._source.vendor,
      YWC_ID: "",
    };

    let msg = "";
    if (status === 'ACCEPT') {
      msg = "Bargain Request is Accepted.";
    } else if (status === 'REJECT') {
      msg = "Bargain Request is Rejected.";
    }

    dispatch(bargainResponse(responseData, item._id , msg));
  
  };

  return (
    <div>

    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className={classes.root}
      maxWidth="xl"
    >
      <DialogTitle id="scroll-dialog-title" className={classes.dialogBar}>
        <Typography className={classes.dialogTitle}>
          Vendor Notification
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            className={classes.closeIcon}
          >
            <HighlightOffRoundedIcon />
          </IconButton>
        </Typography>
      </DialogTitle>
      {bargains && bargains.length > 0 ? (<DialogContent dividers>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>Customer Name</TableCell>
                <TableCell align="center">Product Name</TableCell>
                <TableCell align="right">Offering Price</TableCell>

                <TableCell align="right">Ask Price</TableCell>
                <TableCell align="right">Difference</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {bargains && bargains.length > 0 ? (
                bargains.map((bargain) => (
                  <TableRow key={bargain._id}>
                    <TableCell component="th" scope="row">
                      {bargain._source.customer_Name}
                    </TableCell>
                    <TableCell align="right">
                      {bargain._source.sku_name}
                    </TableCell>
                    <TableCell align="right">
                    ₹ {bargain._source.attribute_price}/-
                    </TableCell>
                    <TableCell align="right">
                    ₹ {bargain._source.bargin_price}/-
                    </TableCell>

                    {/* <TableCell align="right">
                    ₹ {bargain._source.bargin_price} /-
                    </TableCell> */}

                    {Number(bargain._source.bargin_price) -
                          Number(bargain._source.attribute_price)>= 0 ?  <TableCell align="right" style={{color: 'green'}}>
                          {(Number(bargain._source.bargin_price) -
                            Number(bargain._source.attribute_price)).toFixed(2)}{""}/-
                        </TableCell> :  <TableCell align="right" style={{color: 'red'}}>
                        {(Number(bargain._source.bargin_price) -
                          Number(bargain._source.attribute_price)).toFixed(2)}{""}/-
                      </TableCell>}

                    <TableCell align="center">
                      <Box component="div" className={classes.buttonsDiv}>
                        <Button
                          variant="contained"
                          disabled={loading}
                          onClick={() =>
                            handleBargainResponse(bargain, "ACCEPT")
                          }
                          className={classes.actionButton}
                        >
                          Accept
                        </Button>

                        <Button
                          variant="contained"
                          disabled={loading}
                          onClick={() =>
                            handleBargainResponse(bargain, "REJECT")
                          }
                          className={classes.actionButton}
                        >
                          Reject
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    Bids not found for this product.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>) : (
        <Card>
                                
        <CardContent className={classes.notfound}>
            <Typography
                component="p"
                variant="h6"
                className={classes.notfoundtext}
            >
                Notifications not found.
            </Typography>
        </CardContent>
    </Card>
      )} 
      
    </Dialog>
   <SnackBar /></div>
  );
};
