import React, { Fragment } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { saveRecentSerachQueries } from "../redux/actions/customerActions";

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000000",
  },
  inputRoot: {
    color: "inherit",
    fontFamily: "'Fira Sans', sans-serif",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
  option: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#383838",
    "&:hover": {
      backgroundColor: "cyan !important",
    },
  },
  noOptions: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#383838",
  },
}));

const baseUrl = process.env.REACT_APP_BASE_URL;

export const SearchComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { searchQueries } = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  // const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const onAutocomplete = async (e) => {
    try {
      if (e.target.value && e.target.value !== "") {
        if (e.target.value && e.target.value.length > 1) {
          const searchRequest = {
            query: e.target.value,
          };
          const { data } = await axios.post(
            `${baseUrl}/search/autocomplete`,
            searchRequest
          );
          setOptions(data.values);
        }
      } else {
        setOptions(searchQueries);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setOptions(searchQueries);
      } else {
        setOptions(searchQueries);
      }
    }
  };

  const onSearch = (newValue) => {
    setInputValue(newValue);
    if (newValue && newValue !== "") {
      dispatch(saveRecentSerachQueries(newValue));
      history.push(`/search?query=${newValue}`);
    }
  };

  return (
    <Fragment>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>

      <Autocomplete
        id="google-map-demo"
        classes={{
          option: classes.option,
          noOptions: classes.noOptions,
        }}
        options={options}
        getOptionLabel={(option) => option}
        getOptionSelected={(option, value) => option.value === value.value}
        onChange={(event, newValue) => {
          onSearch(newValue);
        }}
        style={{ width: "100%" }}
        value={inputValue}
        renderInput={(params) => (
          <InputBase
            placeholder="Search…"
            ref={params.InputProps.ref}
            onChange={onAutocomplete}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={params.inputProps}
          />
        )}
      />
    </Fragment>
  );
};
