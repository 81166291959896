import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/* react components */
import { AppBars } from "../../components/AppBar";
// import { CategoryList } from "../../components/CategoryList";
import { Footer } from "../../components/Footer";
import Spinner from "../../util/spinner/spinner";
import { DemoItem } from "../../components/DemoItem";

/* redux actions */
import { deleteProductFromWishlist } from "../../redux/actions/customerWishlistActions";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MenuHeader from "../../components/MenuHeader";
import Sitemap from "../Sitemap/sitemap";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  banner: {
    backgroundImage: `url(${"/stockxbid/images/wishlist.jpg"})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    backgroundColor: "#fff",
    padding: "4.6rem 0 5rem",
    justifyContent: "center",
    fontSize: 32,
    fontFamily: "'Fira Sans', sans-serif",
  },
  button: {
    marginBottom: "10px",
    fontSize: 12,
    fontFamily: "'Fira Sans', sans-serif",
    "&:hover": {
      backgroundColor: "#c96",
      color: "#fff",
    },
  },
  notfound: {
    padding: "10px",
    fontSize: "16px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#140101",
    opacity: 0.6,
  },
}));

export const WishList = () => {
  const classes = useStyles();
  const { wishlist, loading } = useSelector((state) => state.Wishlist);
  const dispatch = useDispatch();
  const [showSiteMap6, setShowSiteMap6] = useState(false);

  /* function to remove wishlisted product by id */
  const removeWishlistProduct = (id) => {
    dispatch(deleteProductFromWishlist(id));
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <CssBaseline />
      <AppBars position="absolute" color="default" className={classes.appBar} />
      {/* <CategoryList /> */}
      {!showSiteMap6 ? (
        <>
          <MenuHeader />
          <div className={classes.banner}>Wishlist</div>

          <Container>
            <Grid container justifyContent="center" spacing={2}>
              {loading ? (
                <Spinner />
              ) : wishlist?.length > 0 ? (
                wishlist.map((product, index) => (
                  <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                    <DemoItem product={product} />
                    <div style={{ textAlign: "center" }}>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        color="primary"
                        onClick={() => removeWishlistProduct(product.id)}
                      >
                        Remove
                      </Button>
                    </div>
                  </Grid>
                ))
              ) : (
                <Grid item>
                  <Typography className={classes.notfound}>
                    Your wishlist Empty.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Container>
        </>
      ) : (
        <Sitemap />
      )}

      <Footer showSiteMap={showSiteMap6} setShowSiteMap={setShowSiteMap6} />
    </div>
  );
};
