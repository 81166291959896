import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/* Material ui */
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import DateRangeIcon from "@material-ui/icons/DateRange";

/* Apply css */
const useStyles = makeStyles((theme) => ({
  dateFilte: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    backgroundColor: "#53E7BC",
    color: "#363636",
    fontSize: "13px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    borderRadius: "4px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  filterLable: {
    marginTop: "0.5rem",
    fontSize: "14px",
    fontWeight: 500,
    color: "#666666",
    fontFamily: "'Fira Sans', sans-serif",
    paddingLeft: "2rem",
  },
  search: {
    position: "relative",
    float: "right",
    "& .MuiOutlinedInput-inputAdornedStart": {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Avenir",
      color: "#7c7c7c",
      opacity: 0.54,
    },
  },
  searchIcon: {
    color: "#7c7c7c",
  },
  dateDiv: {
    display: "flex",
    border: "1px solid #d6d6d6",
    // width: "12ch",
    width: "100%",
    color: "#212529",
    borderRadius: "4px",
    maxHeight: "40px",
    padding: "6px",
    backgroundColor: "#fefefe",
  },
  date: {
    border: "none",
    width: "10ch",
    outline: "none",
    fontSize: "13px",
    fontFamily: "Avenir",
    opacity: 0.54,
  },
  root: {
    "& > *": {
      margin: theme.spacing(0, 1),
    },
  },
}));

export const Filter = (props) => {
  const classes = useStyles();

  const {
    fromDate,
    handleFromDate,
    search,
    handleDateRangeFilter,
    handleClearDateFilter,
    handleSearch,
    toDate,
    handleToDate,
    placeholder,
  } = props;

  return (
    <>
      <Grid item xs={12} sm={8}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl>
                  <Typography className={classes.filterLable}>
                    Filter By:
                  </Typography>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined">
                  <Box className={classes.dateDiv}>
                    <DateRangeIcon />

                    <DatePicker
                    maxDate= {new Date()}
                      placeholderText="From"
                      selected={fromDate}
                      onChange={handleFromDate}
                      className={classes.date}
                    />
                  </Box>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined">
                  <Box className={classes.dateDiv}>
                    <DateRangeIcon />

                    <DatePicker
                    maxDate= {new Date()}
                      placeholderText="To"
                      selected={toDate}
                      onChange={handleToDate}
                      className={classes.date}
                    />
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={classes.root}>
              <Button
                onClick={handleDateRangeFilter}
                variant="contained"
                className={classes.submitButton}
                size="small"
              >
                Submit
              </Button>

              <Button
                onClick={handleClearDateFilter}
                variant="contained"
                className={classes.submitButton}
                size="small"
              >
                Clear
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          className={classes.search}
          id="input-with-icon-textfield"
          size="small"
          placeholder={placeholder}
          variant="outlined"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </>
  );
};
