import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, capitalize } from '@material-ui/core';

import React from 'react';



const  RefundStatus = (props) => {
    const { open, handleClose, refundStatus} = props;

  return (
    <div> 
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Refund Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {refundStatus &&   capitalize(refundStatus.transactionDetails.status)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default  React.memo(RefundStatus)