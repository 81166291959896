import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  Typography,
  createTheme,
} from "@material-ui/core";
import {CloseOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { customerLogin } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {},
  themeProvider: {
    display: "flex",
    '@media(max-Width: 400px)' : {
      display: "flex",
      flexDirection: "column"
    }
  },
  gridContainer: {
    width: "45%",
    background: "rgb(83, 231, 188)",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: "#fff",
    '@media(max-Width: 400px)' : {
      width: "100%",
      padding: "5px",
      display: "flex",
      flexDirection: "column"
    }
  },

  gridContent: {
    fontSize: "18px",
    fontWeight: 600,
  },

  boxContainer: {
    padding: "15px 20px",
    display: "flex",
    justifyContent: "space-between",
    '@media(max-Width: 400px)' : {
      display: "none",
    }
  },
  boxContainer2: {
    padding: "15px 20px",
    display: "none",
    justifyContent: "space-between",
    '@media(max-Width: 400px)' : {
      display: "flex", 
      background: "rgb(83, 231, 188)",
    }
  },

  boxForm: {
    padding: "10px 30px",
    display: "flex",
    flexDirection: "column",
  },
  buttonCategory: {
    marginRight: "10px",
    marginBottom: "10px",
    backgroundColor: "rgb(83, 231, 188)",
  },
  closeOutlined: {
    cursor: "pointer",
    "&:hover": {
      padding: "5px",
      backgroundColor: "rgb(83, 231, 188)",
    },
  },
}));

const theme = createTheme();

const AuthenticationAlertPop = (props) => {
  const history = useHistory();
  const { open, handleCloseAlert } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const { loading, error, serverError } = useSelector((state) => state.UI);

  const handleInputsValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (!email) {
      inputsValid = false;
      formErrors["email"] = "this field is required";
    }

    if (!password) {
      inputsValid = false;
      formErrors["password"] = "this field is required";
    }

    setFormErrors(formErrors);
    return inputsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (handleInputsValidation()) {
      const customerData = {
        username: email,
        password: password,
      };

      dispatch(customerLogin(customerData, history));
    }
  };

  const handleCategoryProductsList = (categoryPath) => {
    history.push(`/${categoryPath}`);
  };

  return (
    <div>
      <Dialog
        open={open}
        xs={12}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className={classes.paper}
      >
        <Grid item className={classes.themeProvider} theme={theme}>
        <Box className={classes.boxContainer2}>
              <Typography component="h1" variant="h5">
                Login
              </Typography>
              <CloseOutlined
                onClick={handleCloseAlert}
                className={classes.closeOutlined}
              />
            </Box>
          <Grid item  className={classes.gridContainer}>
            
            <img
              alt="logo"
              src={
                "https://storage.googleapis.com/stockxbid_gc_bng_misc/logo_beta_v/outxtock.svg"
              }
              style={{
                maxHeight: "60px",
                cursor: "pointer",
              }}
            />
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.gridContent}
            >
              Get Access to your Orders, Wishlist and Recommendations
            </Typography>

            <Typography
              variant="subtitle1"
              component="p"
              className={classes.gridContent}
              style={{ marginTop: "100px" }}
            >
              Good time for shopping
            </Typography>

            <img
              alt="logo"
              src={
                "https://storage.googleapis.com/stockxbid_gc_bng_misc/login_box_dsply/outxtock_login_pop_box.webp"
              }
              style={{
                maxHeight: "120px",
                cursor: "pointer",
              }}
            />
          </Grid>

          <Grid
            item
            
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className={classes.boxContainer}>
              <Typography component="h1" variant="h5">
                Login
              </Typography>
              <CloseOutlined
                onClick={handleCloseAlert}
                className={classes.closeOutlined}
              />
            </Box>
            <Box
              component="form"
              className={classes.boxForm}
              onSubmit={handleSubmit}
            >
              <TextField
                margin="normal"
                required
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value.trim())}
                autoFocus
                helperText={formErrors.email ? formErrors.email : ""}
              />
              <TextField
                margin="normal"
                required
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value.trim())}
                helperText={formErrors.password ? formErrors.password : ""}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                size="small"
                variant="contained"
                disabled={loading}
                className={classes.buttonCategory}
              >
                Log In
              </Button>
              {error && (
                <Box
                  style={{
                    fontSize: "10px",
                    color: "red",
                    fontFamily: "'Fira Sans', sans-serif",
                    margin: 10,
                  }}
                >
                  {error}
                </Box>
              )}
              {serverError && (
                <Box
                  style={{
                    fontSize: "12px",
                    color: "red",
                    fontFamily: "'Fira Sans', sans-serif",
                    margin: 10,
                  }}
                >
                  {serverError}
                </Box>
              )}
              <Grid item>
                <Grid item style={{ textAlign: "center" }}>
                  <Link
                    variant="body2"
                    onClick={(e) =>
                      handleCategoryProductsList("forgot-password")
                    }
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.boxContainer}
            >
              Categories
            </Typography>
            <Grid item style={{ textAlign: "center" }}>
              <Button
                className={classes.buttonCategory}
                type="submit"
                variant="contained"
                onClick={(e) => handleCategoryProductsList("sneakers/products")}
              >
                Sneakers
              </Button>
              <Button
                className={classes.buttonCategory}
                type="submit"
                variant="contained"
                onClick={(e) =>
                  handleCategoryProductsList("streetwears/products")
                }
              >
                Streetwears
              </Button>
              <Button
                className={classes.buttonCategory}
                type="submit"
                variant="contained"
                onClick={(e) => handleCategoryProductsList("watches/products")}
              >
                Watches
              </Button>
              <Button
                className={classes.buttonCategory}
                type="submit"
                variant="contained"
                onClick={(e) => handleCategoryProductsList("bags/products")}
              >
                Bags
              </Button>
              <Button
                className={classes.buttonCategory}
                type="submit"
                variant="contained"
                onClick={(e) => handleCategoryProductsList("toys/products")}
              >
                Toys
              </Button>
            </Grid>

            <Typography
              component="p"
              style={{ textAlign: "center", margin: "10px 0" }}
            >
              New to OutXtock?{" "}
              <span item xs>
                <Link
                  variant="body2"
                  onClick={(e) => handleCategoryProductsList("customer-signup")}
                >
                  Create an account
                </Link>
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default React.memo(AuthenticationAlertPop);
