import React, { Fragment, useEffect, useState } from "react";

/* import react components */
import { AppBars } from "../components/AppBar";
// import { CategoryList } from "../components/CategoryList";
import { Footer } from "../components/Footer";
import ProductFilter from "../components/ProductFilter";
import ProductList from "../components/ProductList";

/* import material ui */
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid } from "@material-ui/core";
import { useRef } from "react";
import MenuHeader from "../components/MenuHeader";
import Sitemap from "./Sitemap/sitemap";
import { useParams } from "react-router-dom/cjs/react-router-dom";

export const CategoryProductsList = (props) => {
  const categoryRef = useRef();
  const [selectedPriceSort, setSelectedPriceSort] = useState("all");
  const [showSiteMap2, setShowSiteMap2] = useState(false);
  const { category } = useParams();

  const categoryClickedFromCategoryTab = (categoryName) => {
    categoryRef.current.categoryClickedFromTab(categoryName);
  };

  
  useEffect(() => {
    var metaDescriptionTag = document.querySelector('meta[name="description"]');

    // Check if the meta tag is found
    if (metaDescriptionTag) {
      // Update the content attribute with a new description
      metaDescriptionTag.setAttribute('content', `${category}`)
    }
    if(category==="sneakers"){
      document.title = `Shoe Stores - Outxtock`
    }
    else if(category==="streetwears"){
      document.title = `MensWear near me - Outxtock`
    }
    else if(category==="watches"){
      document.title = `Watch Store - Outxtock`
    }
    else if(category==="bags"){
      document.title = `Bag Shop - Outxtock`
    }
    else if(category==="toys"){
      document.title = `Toy Store - Outxtock`
    }
    else{
      document.title = category
    }
  },[category])

  return (
    <Fragment>
      <CssBaseline />
      <AppBars />
      {/* <CategoryList categoryClicked={categoryClickedFromCategoryTab} fromProductOrSearchPage={true}/> */}
      {!showSiteMap2 ? (
        <>
          <MenuHeader
            categoryClicked={categoryClickedFromCategoryTab}
            fromProductOrSearchPage={true}
          />
          {/* <Banners type={params.category} /> */}

          <section style={{ marginTop: 10, backgroundColor: "#fff" }}>
            <Grid container style={{ marginBottom: 10 }}>
              <ProductFilter
                ref={categoryRef}
                setSelectedPriceSort={setSelectedPriceSort}
              />
              <ProductList
                selectedPriceSort={selectedPriceSort}
                setSelectedPriceSort={setSelectedPriceSort}
              />
            </Grid>
          </section>
        </>
      ) : (
        <Sitemap />
      )}
      <Footer showSiteMap={showSiteMap2} setShowSiteMap={setShowSiteMap2} />
    </Fragment>
  );
};
