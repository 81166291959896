import React from "react";
import { useHistory } from "react-router";

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100vh",
  },
  NotFoundCode: {
    fontSize: "60px",
    left: "50%",
  },
  subTitle: {
    marginTop: theme.spacing(2),
  },
  homeButton: {
    color: "#FFFFFF",
    background: "#53E7BC",
    marginTop: theme.spacing(2),
    fontSize: "13px",
    fontWeight: 600,
    borderRadius: "0.375rem",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
}));

export const WorkProgress = () => {
  const classes = useStyles();
  const history = useHistory();

  const goBack = () => {
    history.go(-1);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.NotFoundCode}>
        WORK IN PROGRESS.......
      </Typography>

      <Button
        variant="contained"
        size="small"
        className={classes.homeButton}
        onClick={goBack}
      >
        Go Back
      </Button>
    </div>
  );
};
