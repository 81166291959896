import React,{useState} from "react";

/* import react components */
import { AppBars } from "../../components/AppBar";
import { Footer } from "../../components/Footer";
// import { CategoryList } from "../../components/CategoryList";

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
// import { PrivacyPolicyDocFooter } from './../../util/documents/PrivacyPolicyDocFooter';
// import parse from "html-react-parser";
import MenuHeader from "../../components/MenuHeader";
import { PrivacyPolicyFooterNewContent } from "../../util/documents/PrivacyPolicyFooterNewContent";
import Sitemap from "../Sitemap/sitemap";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#f0e0d4",
  },
  pagetitle: {
    fontSize: 28,
    fontWeight: 600,
    color: "#F24046",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
    fontStretch: "normal",
    margin: "90px 0 10px 0",
    textAlign: "center",
  },
  subheading: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    margin: "15px 0 0 8px",
    fontWeight: 600,
  },
  text: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 300,
    padding: 8,
    margin: "auto",
    lineHeight: "30px",
    color: "#5c6166",
  },
}));

export const PrivacyPolicyFooterNew = () => {
  const classes = useStyles();
  const [showSiteMap13, setShowSiteMap13] = useState(false);

  // const PRIVACY_POLICY_DOC = "PRIVACY_POLICY";

  // const getDocument = (documentStr) => {
  //   if( PRIVACY_POLICY_DOC  === documentStr ) {
  //     return PrivacyPolicyDocFooter;
  //   }
  //   return "";
  // };

  return (
    <div className={classes.root}>
      <AppBars />
      {/* <CategoryList /> */}
      {!showSiteMap13 ? (
        <>
          <MenuHeader />
          <Container fixed style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              {/* <div>
              {parse(getDocument(PRIVACY_POLICY_DOC))}
          </div> */}
              <PrivacyPolicyFooterNewContent />
            </Grid>
          </Container>
        </>
      ) : (
        <Sitemap />
      )}
      <Footer showSiteMap={showSiteMap13} setShowSiteMap={setShowSiteMap13} />
    </div>
  );
};
