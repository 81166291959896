import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  loading: false,
  cartItem: {},
  error: null,
  serverError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CART_PAGE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CART_PAGE_LOADED:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.ADD_PRODUCT_TO_CART:
      return {
        ...state,
        loading: false,
        cartItem: action.payload,
      };
    case ActionTypes.GET_CART_ITEM:
      const updatedProducts = action.payload.products.map((item) => {
        // Check if 'visible' is true
        if (item.visible === true) {
          return item;
        } else {
          return null;
        }
      });
      const filteredProducts = updatedProducts.filter(
        (product) => product !== null
      );
      return {
        ...state,
        loading: false,
        cartItem: {
          ...action.payload,
          products: filteredProducts,
        },
      };
    case ActionTypes.CART_PAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.CART_PAGE_SERVER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
        serverError: action.payload,
      };
    case ActionTypes.CART_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
        serverError: false,
      };
    case ActionTypes.INCREASE_QUANTITY:
      return {
        ...state,
        cartItem:
          state.cartItem.id === action.payload.id ? { ...action.payload } : {},
      };
    case ActionTypes.DECREASE_QUANTITY:
      return {
        ...state,
        cartItem:
          state.cartItem.id === action.payload.id ? { ...action.payload } : {},
      };
    case ActionTypes.DELETE_CART_ITEM:
      return {
        ...state,
        loading: false,
        cartItem:
          state.cartItem.id === action.payload.id ? { ...action.payload } : {},
      };
    default:
      return state;
  }
}
