import React,{useState,useEffect} from "react";

/* import react components */
import { AppBars } from "../../components/AppBar";
import { Footer } from "../../components/Footer";

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { ShippingPolicyDocFooter } from './../../util/documents/ShippingPolicyDocFooter';
import MenuHeader from "../../components/MenuHeader";
import { ShippingPolicyFooterContent } from "../../util/documents/ShippingPolicyFooterContent";
import Sitemap from "../Sitemap/sitemap";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#f0e0d4",
  },
  pagetitle: {
    fontSize: 28,
    fontWeight: 600,
    color: "#F24046",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
    fontStretch: "normal",
    margin: "90px 0 10px 0",
    textAlign: "center",
  },
  subheading: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    margin: "15px 0 0 8px",
    fontWeight: 600,
  },
  text: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 300,
    padding: 8,
    margin: "auto",
    lineHeight: "30px",
    color: "#5c6166",
  },
}));

export const ShippingPolicy = () => {
  const classes = useStyles();

  const [showSiteMap15, setShowSiteMap15] = useState(false);
  const SHIPPING_POLICY_DOC = "shipping-policy";

  // const getDocument = (documentStr) => {
  //   if(SHIPPING_POLICY_DOC === documentStr) {
  //       return ShippingPolicyDocFooter;
  //   }
  // };

  useEffect(() => {
    var metaDescriptionTag = document.querySelector('meta[name="description"]');

    // Check if the meta tag is found
    if (metaDescriptionTag) {
      // Update the content attribute with a new description
      metaDescriptionTag.setAttribute('content', `OutStock Delivery and Shipping Policy`)
    }
    document.title = 'Delivery and Shipping Policy'
  },[])
   

  return (
    <div className={classes.root}>
      <AppBars />
      {/* <CategoryList /> */}
      {!showSiteMap15 ? (<>
      <MenuHeader />
      <Container fixed style={{ marginBottom: 20 }}>
        <Grid item xs={12}>

          {/* <div>
              {parse(getDocument(SHIPPING_POLICY_DOC))}
          </div> */}

          <ShippingPolicyFooterContent />


         
        </Grid>
        </Container>
        </>):(<Sitemap />)}
      <Footer showSiteMap={showSiteMap15} setShowSiteMap={setShowSiteMap15} />
    </div>
  );
};
