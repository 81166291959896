import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../util/spinner/spinner";

import { getCategoryList } from "../../../redux/actions/sellerActions";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Container, capitalize } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Table, TableHead, TableRow } from "@material-ui/core";
import { TableContainer } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiTableCell-head": {
      backgroundColor: "#d5f9ef",
      color: "#3c3c3c",
      fontFamily: "'Fira Sans', sans-serif",
      fontSize: 13,
      fontWeight: "bold",
      letterSpacing: 1,
    },
    "& .MuiTableCell-body": {
      fontSize: 13,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#2d2b2b",
      fontWeight: 500,
    },
  },
}));

const productInventory = process.env.REACT_APP_BASE_URL;

export const DownloadTemplate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { categorylist, loading, serverError, error } = useSelector(
    (state) => state.seller
  );

  const [openAlert, setOpenAlert] = React.useState(false);
  const [category, setCategory] = React.useState("");

  /* function to close confiram alert */
  const handleAlertClose = () => {
    setOpenAlert(false);
    setCategory("");
  };

  /* function to download template */
  const handleDownloadTemplate = (category) => {
    setCategory(category);
    setOpenAlert(true);
  };

  /* function to download xlxs based on category */
  const handleDownloadConfirm = (categoryCode) => {
    setOpenAlert(false);
    window.location.href = `${productInventory}/product/bulk/upload/${categoryCode}/template/download`;
  };

  useEffect(() => {
    dispatch(getCategoryList());
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : serverError ? (
        <p>{serverError}</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Container maxWidth="md">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Category Name</TableCell>
                  <TableCell align="right">Download Template</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {categorylist ? (
                  categorylist.length > 0 ? (
                    categorylist.filter(cat => cat.visible === true).map((category) => (
                      <StyledTableRow key={category.id}>
                        <TableCell align="center">{capitalize(category.code)}</TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<GetAppIcon />}
                            onClick={() =>
                              handleDownloadTemplate(category.code)
                            }
                            style={{ minWidth: "30%" }}
                          >
                            {category.code}
                          </Button>
                        </TableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <TableRow key={1}>
                      <TableCell component="th" scope="row">
                        Category not found.
                      </TableCell>
                    </TableRow>
                  )
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog
            open={openAlert}
            onClose={handleAlertClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
            <DialogContent>
              <DialogContentText
                style={{ fontFamily: "'Fira Sans', sans-serif" }}
              >
                Are you sure you want download {category} template.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleAlertClose}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => handleDownloadConfirm(category)}
              >
                Download
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      )}
    </Fragment>
  );
};
