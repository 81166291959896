import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import { CategoryTab } from "./InventoryTabs/CategoryTab";
import { ProductsTab } from "./InventoryTabs/ProductsTab";

const useStyles = makeStyles((theme) => ({
  tabSection: {
    borderBottom: "1px solid #E9E9E9",
  },
  appBar: {
    color: "inherit",
    background: "transparent",
    boxShadow: "none",
    borderBottom: "1px solid #E9E9E9",
    "& .Mui-selected": {
      color: "#27B23E",
    },
    "& .MuiTab-wrapper": {
      fontSize: 13,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 600,
      letterSpacing: "0.19px",
      lineHeight: 1.38,
      textTransform: "capitalize",
    },
  },
  indicator: {
    backgroundColor: "#27B23E",
  },
}));

export const Collections = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("1");

  const selectTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className={classes.tabSection}>
      <TabContext value={activeTab}>
        <AppBar position="static" className={classes.appBar}>
          <TabList
            onChange={selectTab}
            aria-label="simple tabs example"
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab label="Category" value="2" style={{ outline: "none" }} />
            <Tab label="Products" value="1" style={{ outline: "none" }} />
          </TabList>
        </AppBar>

        <TabPanel value="2">
          <CategoryTab />
        </TabPanel>

        <TabPanel value="1">
          <ProductsTab />
        </TabPanel>
      </TabContext>
    </div>
  );
};
