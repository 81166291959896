import React from "react";

/* material ui */
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Typography from "@material-ui/core/Typography";

/* material ui css */
const useStyles = makeStyles((theme) =>
  createStyles({
    rootdialog: {
      "& .MuiButtonBase-root": {
        outline: "none",
      },
      "& .MuiDialogContentText-root": {
        fontSize: 14,
        fontFamily: "'Fira Sans', sans-serif",
        fontWeight: 500,
        color: "#000000",
      },
    },
    title: {
      "& h2": {
        fontSize: 24,
        fontFamily: "'Fira Sans', sans-serif",
        fontWeight: 500,
        color: "#008000",
        textAlign: "center",
      },
    },
  })
);

export const SignupAlert = (props) => {
  const classes = useStyles();
  const { open, handleClose } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      className={classes.rootdialog}
    >
      <DialogTitle id="form-dialog-title" className={classes.title}>
        <CheckCircleOutlineIcon fontSize="large" />
        <Typography>Welcome to OutXtock</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your account has been successfully created. Your Account is being
          processed by our administration team.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Home
        </Button>
        <Button onClick={handleClose} color="primary">
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
};
