import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux actions
import {
  getCities,
  getPinCodes,
  getStates,
} from "../../../redux/actions/otherActions";

// material ui
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PublicIcon from "@material-ui/icons/Public";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import NotListedLocationOutlinedIcon from "@material-ui/icons/NotListedLocationOutlined";
import FiberPinIcon from "@material-ui/icons/FiberPin";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { isEmptyObject } from "jquery";

const useStyles = makeStyles((theme) =>
  createStyles({
    addressroot: {
      "& .MuiFormControlLabel-label": {
        fontSize: 13,
        fontFamily: "'Fira Sans', sans-serif",
        color: "#4A4A4A",
      },
      "& .MuiOutlinedInput-inputAdornedStart": {
        paddingLeft: 4,
        backgroundColor: "#fff",
        borderLeft: "1px solid pink",
        color: "#000000",
      },
      "& .MuiOutlinedInput-inputAdornedEnd": {
        backgroundColor: "#fff",
        borderRight: "1px solid pink",
      },
      "& .MuiFormHelperText-root": {
        color: "red",
        fontFamily: "'Fira Sans', sans-serif",
        textAlign: "center",
      },
      "& .MuiInputAdornment-positionStart": {
        color: "#000000",
      },
    },
    title: {
      fontWeight: "bold",
      color: "#53e7bc",
      textDecoration: "underline",
    },
    lable: {
      textAlign: "start",
      margin: "0px 10px",
      fontSize: 12,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#000000",
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    backbutton: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      "&:hover": {
        backgroundColor: "#53e7bc",
        color: "#fff",
      },
    },
    labelRoot: {
      fontSize: "14px",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#666666",
    },
    option: {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#383838",
      "&:hover": {
        backgroundColor: "cyan !important",
      },
    },
    noOptions: {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#383838",
    },
  })
);

export const Address = (props) => {
  const classes = useStyles();
  const {
    handleBack,
    initialValues,
    handleInputChange,
    handleChangeState,
    handleChangeCity,
    handlePincode,
  } = props;
  const { pincodes, cities, states } = useSelector((state) => state.Others);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});


  const inputValidation = () => {
    let errors = {};
    let inputsValid = true;

    if (!initialValues.addressOne) {
      inputsValid = false;
      errors["addressOne"] = "Please enter your address.";
    }

    if (!initialValues.pinCode) {
      inputsValid = false;
      errors["pinCode"] = "Please select your pincode.";
    }

    if (!initialValues.state) {
      inputsValid = false;
      errors["state"] = "Please select your state.";
    }

    if (!initialValues.city) {
      inputsValid = false;
      errors["city"] = "Please select your city.";
    }

    setErrors(errors);
    return inputsValid;
  };


  const handleNext = () => {
    if (inputValidation()) {
      props.handleNext();
    }
  };

  const handleClickState = (state) => {
    if(!isEmptyObject(state)){
      initialValues.city = ''
      initialValues.pinCode = ''
          handleChangeState(state)
          dispatch(getCities(state.hubState));
    }
  }

  const handleClickCity = (city) => {
    initialValues.pinCode = ''
    if(!isEmptyObject(city)){
      handleChangeCity(city)
      initialValues.pinCode = ''
      dispatch(getPinCodes(city.hubCity));
    }
    
  }
  const  handleClickPinCode = (pinCode)=>{
    if(!isEmptyObject(pinCode)){
      handlePincode(pinCode);
    }
   
  }
  

  useEffect(() => {
    dispatch(getStates());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className={classes.addressroot}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>
            Registered Office Address
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                Address 1{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>
              <TextField
                id="addressOne"
                name="addressOne"
                variant="outlined"
                size="small"
                value={initialValues.addressOne}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                helperText={errors.addressOne ? errors.addressOne : ""}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <InputLabel className={classes.lable}>Address 2</InputLabel>
              <TextField
                id="addressTwo"
                name="addressTwo"
                variant="outlined"
                size="small"
                value={initialValues.addressTwo}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                Country{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>
              <TextField
                id="country"
                name="country"
                disabled
                variant="outlined"
                size="small"
                value={initialValues.country}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PublicIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                State{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>
              <Autocomplete
                id="asynchronous-demo"
                classes={{
                  option: classes.option,
                  noOptions: classes.noOptions,
                }}
                options={states}
                value={initialValues.state}
                getOptionSelected={(option, value) =>
                  
                  option.hubState === value
                }
                getOptionLabel={(option) =>
                  option.hubState
                    ? option.hubState ==="HubState" ? "" : option.hubState
                    : initialValues.state
                }
                onChange={(event, newValue) => {
                  
                  handleClickState(newValue)
                }}
                
                
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: "new-password",
                      startAdornment: (
                        <InputAdornment position="start">
                          <NotListedLocationOutlinedIcon />
                        </InputAdornment>
                      ),
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={errors.state ? errors.state : ""}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                City{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>
              <Autocomplete
                id="asynchronous-demo"
                classes={{
                  option: classes.option,
                  noOptions: classes.noOptions,
                }}
                options={cities}
                value={initialValues.city}
                getOptionSelected={(option, value) =>
                  option.hubCity === value
                }
                getOptionLabel={(option) =>
                  option.hubCity
                    ? option.hubCity
                    : initialValues.city
                }
                onChange={(event, newValue) => {
                  handleClickCity(newValue)
                }}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: "new-password",
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationCityIcon />
                        </InputAdornment>
                      ),
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={errors.city ? errors.city : ""}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                Pincode{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>

              <Autocomplete
                id="asynchronous-demo"
                classes={{
                  option: classes.option,
                  noOptions: classes.noOptions,
                }}
                options={pincodes}
                value={initialValues.pinCode}
                getOptionSelected={(option, value) =>
                  option.pincode === value
                }
                getOptionLabel={(option) =>
                  option.pincode
                    ? option.pincode
                    : initialValues.pinCode
                }
                onChange={(event, newValue) => {
                  handleClickPinCode(newValue);
                }}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: "new-password",
                      startAdornment: (
                        <InputAdornment position="start">
                          <FiberPinIcon />
                        </InputAdornment>
                      ),
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={errors.pinCode ? errors.pinCode : ""}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className={classes.buttons}>
        <Button onClick={handleBack} className={classes.button}>
          Back
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleNext}
          className={classes.backbutton}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
