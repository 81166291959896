import * as React from 'react';

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {closePolicyModal} from "../../../redux/actions/PolicyModalAction";
import parse from 'html-react-parser';

import { useDispatch, useSelector } from "react-redux";
import {PrivacyPolicyDoc} from "../../../util/documents/PrivacyPolicyDoc";
import {TermsOfUse} from "../../../util/documents/TermsOfUse";


export const  PoliciesModal = () => {

  const { open  , scrollType , titleContent} = useSelector((state) => state.policyModalReducer );
  const dispatch = useDispatch();

  const TERMS_OF_USE_DOC = "Terms of Use";
  const PRIVACY_POLICY_DOC = "Privacy Policy- Website";

  const handleClose = () => {
    dispatch(closePolicyModal());
  };

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  const getDocument = () => {
    if( TERMS_OF_USE_DOC  === titleContent ) {
      return TermsOfUse;
    } else if(PRIVACY_POLICY_DOC === titleContent) {
      return PrivacyPolicyDoc;
    }
    return "";
  };

  return (
    <div>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scrollType}
        fullWidth={true}
        maxWidth={"lg"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{titleContent}</DialogTitle>
        <DialogContent dividers={scrollType === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div>
              {parse(getDocument())}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>Close</Button>

        </DialogActions>
      </Dialog>

    </div>
  );
}
