import React, { Fragment } from "react";
import "../../../../node_modules/draft-js/dist/Draft.css";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";

const useStyles = makeStyles((theme) => ({
  buttons: {
    border: "1px solid silver",
    minHeight: "2em",
    cursor: "text",
    borderRadius: "4px",
    "& .MuiSvgIcon-root": {
      width: 16,
      height: 16,
    },
  },
  button: {
    border: "1px solid silver",
    borderRadius: "4px",
    margin: "8px 14px",
  },
  editorBlock: {
    border: "1px solid silver",
    minHeight: "14em",
    cursor: "text",
    borderRadius: "4px",
    margin: "4px 0px",
    padding: "12px",
    backgroundColor: "#F7F7F7",
    "& .DraftEditor-editorContainer": {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#495057",
    },
  },
}));

export const MyEditor = (props) => {
  const classes = useStyles();
  const { Editor, RichUtils, editorState, setEditorState } = props;

  const editor = React.useRef(null);
  function focusEditor() {
    editor.current.focus();
  }

  const boldText = () => {
    const nextState = RichUtils.toggleInlineStyle(editorState, "BOLD");

    setEditorState(nextState);
  };

  const onUnderlineClick = () => {
    const nextState = RichUtils.toggleInlineStyle(editorState, "UNDERLINE");
    setEditorState(nextState);
  };

  const onItalicClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };

  const onOrderListClick = () => {
    setEditorState(RichUtils.toggleBlockType(editorState, "ordered-list-item"));
  };

  const onUnorderListClick = () => {
    setEditorState(
      RichUtils.toggleBlockType(editorState, "unordered-list-item")
    );
  };

  return (
    <Fragment>
      <div className={classes.buttons}>
        <IconButton onClick={boldText} size="small" className={classes.button}>
          <FormatBoldIcon size="small" />
        </IconButton>

        <IconButton
          onClick={onUnderlineClick}
          size="small"
          className={classes.button}
        >
          <FormatUnderlinedIcon />
        </IconButton>

        <IconButton
          onClick={onItalicClick}
          size="small"
          className={classes.button}
        >
          <FormatItalicIcon />
        </IconButton>

        <IconButton
          onClick={onOrderListClick}
          size="small"
          className={classes.button}
        >
          <FormatListNumberedIcon />
        </IconButton>

        <IconButton
          onClick={onUnorderListClick}
          size="small"
          className={classes.button}
        >
          <FormatListBulletedIcon />
        </IconButton>
      </div>
      <div className={classes.editorBlock} onClick={focusEditor}>
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={setEditorState}
          placeholder="Item Description"
        />
      </div>
    </Fragment>
  );
};
