import { ActionTypes } from "../constants/actionTypes";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const csvValidationUrl = process.env.REACT_APP_CSV_VALIDATION;

/* file validation befor upload  */
export const uploadedFileValidation = (file, fileName) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const { data } = await axios.post(
      `${csvValidationUrl}/validate-file/validateCategory?type=${fileName}`,
      formData
    );
    if (data.errorMessage != null && data.errorMessage.length > 0) {
      dispatch({
        type: ActionTypes.BULK_UPLOAD_VALIDATION,
        payload: data.errorMessage,
      });
    } else {
      dispatch({
        type: ActionTypes.SET_SNACKBAR,
        payload: {
          alert: "success",
          message: "Validation is successfull, proceed to upload.",
        },
      });
      dispatch({ type: ActionTypes.CLEAR_INVENTORY_ERROR });
    }
  } catch (error) {
    // console.log(error);
  }
};

/* get all unused images by seller */
export const getUnusedImages = () => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.INVENTORY_LOADING });
    const { data } = await axios.get(`${baseUrl}/private/bulkupload/images`);
    dispatch({ type: ActionTypes.UN_USED_IMAGES_BY_SELLER, payload: data });
  } catch (error) {
    // console.log(error);
  }
};

/* delete specific unused image from list */
export const deleteUnusedImageByName = (imageName) => async (dispatch) => {
  try {
    await axios.delete(`${baseUrl}/private/bulkupload/image/${imageName}`);
    dispatch({
      type: ActionTypes.DELETE_UNUSED_IMAGE_BY_NAME,
      payload: imageName,
    });
    dispatch(inventorySnackBar("success", `Image deleted.`));
  } catch (error) {
    // console.log(error);
  }
};

/* Remove All Un used Images */
export const removeAllUnUsedImages = (sellerId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.INVENTORY_LOADING });
    await axios.delete(
      `${baseUrl}/private/bulkupload/seller/${sellerId}/images`
    );
    dispatch({ type: ActionTypes.UN_USED_IMAGES_BY_SELLER, payload: [] });
    dispatch(inventorySnackBar("success", `All Images deleted.`));
  } catch (error) {
    // console.log(error);
  }
};

/* snackbar for inventory */
export const inventorySnackBar = (alert, message) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SNACKBAR,
    payload: {
      alert: alert,
      message: message,
    },
  });
};

export const clearInventoryError = () => (dispatch) => {
  dispatch({ type: ActionTypes.CLEAR_INVENTORY_ERROR });
};
