import { ActionTypes } from "../constants/actionTypes";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const otpService = process.env.REACT_APP_NOTIFICATION_SERVICE;
const gstService = process.env.REACT_APP_HSN_VALIDATION;
const address = process.env.REACT_APP_SHIPPING_URL;

export const getOTP = (phoneNumber) => async (dispatch) => {
  try {
    await axios.post(`${otpService}/generate/otp/${phoneNumber}`);
  } catch (error) {
    // console.log(error);
  }
};

export const verifyOTP = (phoneNumber, otpData) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.CLEAR_OTP_MESSAGE });
    await axios.post(`${otpService}/verify/otp/${phoneNumber}`, otpData);
    dispatch({ type: ActionTypes.VALID_OTP, payload: "Verified" });
  } catch (error) {
    if (error.response && error.response.status === 500) {
      dispatch({
        type: ActionTypes.INVALID_OTP,
        payload: error.response.data.errorMesssage,
      });
    } else if (error.response && error.response.status === 400) {
      dispatch({
        type: ActionTypes.INVALID_OTP,
        payload: error.response.data,
      });
    } else {
      // console.log(error);
    }
  }
};

export const changeMobileNumber = () => (dispatch) => {
  try {
    dispatch({ type: ActionTypes.CLEAR_OTP_MESSAGE });
  } catch (error) {
    // console.log(error);
  }
};

export const getStates = () => async (dispatch) => {
 
  try {
    let config = {
      headers: {
        "X-API-VERSION": "v1",
      },
    };

    const { data } = await axios.get(`${address}/api/v1/searchByAllState`, config);
    dispatch({ type: ActionTypes.GET_ALL_STATE, payload: data });

  } catch (error) {
    // console.log(error);
  }
};

export const getCities = (state) => async (dispatch) => {
  try {
    let config = {
      headers: {
        "X-API-VERSION": "v1",
      },
    };

    const { data } = await axios.get(`${address}/api/v1/searchByHubState/${state}`, config);
    // data:  contains dublicate values based on pincodes
    const uniqueCities = data?.filter((item, index, self) => {
      return self.findIndex(fitem => item['hubCity']?.toLowerCase() == fitem['hubCity']?.toLowerCase()) === index
    });
    dispatch({ type: ActionTypes.CITY_BY_STATE, payload: uniqueCities });
  } catch (error) {
    // console.log(error);
  }
};

export const getPinCodes = (city) => async (dispatch) => {
  try {
    let config = {
      headers: {
        "X-API-VERSION": "v1",
      },
    };

    const { data } = await axios.get(`${address}/api/v1/searchByHub/${city}`, config);
    dispatch({ type: ActionTypes.PINCODES_BY_CITY, payload: data });
  } catch (error) {
    // console.log(error);
  }
};

export const getTermsCondition = (userType) => async (dispatch) => {
  try {
    // const { data } = await axios.get(`${baseUrl}/user/policies/${userType}`);
    const { data } = await axios.get(`${baseUrl}/user/policies/`);

    let policyList = data.map(ele => ele.policyHeading);
    let clickedPolicy = {} ;

    policyList.forEach(ele => {
      clickedPolicy[ele] = false;
    });

    dispatch({ type: ActionTypes.USER_TERMS_CONDITIONS,
      payload: {
        termsConditions: data,
        clickedPolicy: clickedPolicy,
      }});
  } catch (error) {
    // console.log(error);
  }
};

export const getBussinessCategory = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseUrl}/business/category`);
    dispatch({ type: ActionTypes.BUSSINESS_CATEGORIES, payload: data });
  } catch (error) {
    // console.log(error);
  }
};

export const getSubCategory = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseUrl}/business/subcategory`);
    dispatch({ type: ActionTypes.BUSSINESS_SUB_CATEGORIES, payload: data });
  } catch (error) {
    // console.log(error);
  }
};

// selected categories in product category
export const selectBussinessCategories = (obj) => (dispatch) => {
  dispatch({ type: ActionTypes.SELECTED_PRODUCT_CATEGORIES, payload: obj });
};

// remove single category from product category
export const removeSelectedCategory = (index) => (dispatch) => {
  dispatch({ type: ActionTypes.REMOVE_SELECTED_CATEGORY, payload: index });
};

// clear all selected categories after successfull vendor sign up
export const clearSelectedCategories = () => (dispatch) => {
  dispatch({ type: ActionTypes.CLEAR_SELECTED_CATERORIES });
};

export const closeSignupAlert = (history) => (dispatch) => {
  dispatch({ type: ActionTypes.CLOSE_USER_SIGNUP_ALERT });
  history.push("/seller-login");
};

export const gstVerification = (gstIn) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.CLEAR_GST_MESSAGE });
    const { data } = await axios.get(
      `${gstService}/api/v1/validateGst/${gstIn}`
    );
    

      if(data.GSTIN_Status === "GSTIN found") {
        dispatch({ type: ActionTypes.VALID_GST, payload: data.GSTIN_Status });
      } else {
        dispatch({ type: ActionTypes.INVALID_GST, payload: "GST not found" });
      }

  } catch (error) {
    if (error.response && error.response.status === 500) {
      dispatch({ type: ActionTypes.INVALID_GST, payload: "GST not found" });
    } 
    else if (error.response && error.response.status === 404) {
      dispatch({ type: ActionTypes.INVALID_GST, payload: "GST not found" });
    } else if (error.response && error.response.status === 417) {
      dispatch({ type: ActionTypes.INVALID_GST, payload: "GST not found" });
    } else {
      // console.log(error);
    }
  }
};

export const changeGstNumber = () => (dispatch) => {
  try {
    dispatch({ type: ActionTypes.CLEAR_GST_MESSAGE });
  } catch (error) {
    // console.log(error);
  }
};

export const clearAllValidations = () => (dispatch) => {
  try {
    dispatch({ type: ActionTypes.CLEAR_GST_MESSAGE });
  } catch (error) {
    // console.log(error);
  }
};
