import axios from "axios";
import { ActionTypes } from "../constants/actionTypes";


const baseUrl = process.env.REACT_APP_BASE_URL;

export const signupUser = (newUserData, history) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_UI });
    const {data } = await axios.post(`${baseUrl}/customer/register`, newUserData);
    
    dispatch({ type: ActionTypes.SIGNUP_SUCCESS });
    dispatch({ type: ActionTypes.CLEAR_ERRORS });
    history.push("/customer-login");
  } catch (error) {
    if (error.response.status === 500) {
      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: error.response.data.message,
      });
    } else {
      dispatch({
        type: ActionTypes.SERVER_ERROR,
        payload: "Server error , try again later !!",
      });
    }
  }
};

export const customerLogin = (customerData, history) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_UI });
    const { data } = await axios.post(
      `${baseUrl}/customer/login`,
      customerData
    );
    const jwt = `Bearer ${data.token}`;
    localStorage.setItem("jwt", jwt);
    localStorage.setItem("cust", JSON.stringify(data));
    axios.defaults.headers.common["Authorization"] = jwt;
    dispatch(getUserData());
    dispatch({ type: ActionTypes.CLEAR_ERRORS });

    if (history.location.pathname === "/customer-login") {
      history.push("/");
    } else {
      history.push(history.location.pathname);
      dispatch({ type: ActionTypes.AUTH_ALERT });
    }
  } catch (error) { 
    if (error?.response) {
      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: error?.response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: "In-Valid Username/Password, Please try again!!",
      });
    }
  }
};

export const getUserData = () => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_USER });
    const { data } = await axios.get(`${baseUrl}/auth/customer/profile`);
    dispatch({
      type: ActionTypes.SET_USER,
      payload: data,
    });
  } catch (error) {
    // console.log(error);
    dispatch({type : ActionTypes.SET_UNAUTHENTICATED});
  }
};

export const logoutAction = (history) => (dispatch) => {
  localStorage.removeItem("jwt");
  if (localStorage.sell) localStorage.removeItem("sell");
  if (localStorage.cust) localStorage.removeItem("cust");
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: ActionTypes.SET_UNAUTHENTICATED });
  if (history) history.push("/");
};

export const sellerLogin = (sellerData, history) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_UI });
    const { data } = await axios.post(`${baseUrl}/private/login`, sellerData);
    const jwt = `Bearer ${data.token}`;
    localStorage.setItem("jwt", jwt);
    localStorage.setItem("sell", JSON.stringify(data));
    axios.defaults.headers.common["Authorization"] = jwt;
    dispatch(getSellerData(history));
    dispatch({ type: ActionTypes.CLEAR_ERRORS });
    // history.push("/seller-dashboard");
  } catch (error) {
    
    if (error?.response) {
      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: error?.response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: "The password is incorrect, try again!!",
      });
    }
  }
};

export const getSellerData = (history) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_USER });
    const { data } = await axios.get(`${baseUrl}/private/user/profile`);
    dispatch({
      type: ActionTypes.SET_USER,
      payload: data,
    });
    history.push("/seller-dashboard");
  } catch (error) {
    dispatch({
      type: ActionTypes.SET_ERROR,
      payload: "User Account not active!!",
    });
  }
};

export const signupSupplier = (userObject) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_UI });
    await axios.post(`${baseUrl}/user/create`, userObject);
    dispatch({ type: ActionTypes.CLEAR_ERRORS });
    dispatch({ type: ActionTypes.SHOW_USER_SIGNUP_ALERT });
  } catch (error) {
    // console.log(error)
    if (error.response.status === 500) {
      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: error.response.data.message,
      });
    } else {
      dispatch({
        type: ActionTypes.SERVER_ERROR,
        payload: "Server error , try again later !!",
      });
    }
  }
};

export const checkSellerAccountStatus = (statusData) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_UI });
    const { data } = await axios.post(
      `${baseUrl}/user/statusCheck`,
      statusData
    );
    dispatch({
      type: ActionTypes.CHECK_SELLER_ACCOUNT_STATUS,
      payload: data.message,
    });
    dispatch({ type: ActionTypes.CLEAR_ERRORS });
  } catch (error) {
    dispatch({
      type: ActionTypes.SET_ERROR,
      payload: "User account not found.",
    });
  }
};

export const clearAllMessageState = () => (dispatch) => {
  dispatch({ type: ActionTypes.CLEAR_FORM_MESSAGES });
};

export const forgetPassword = (passwordVerifier) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_UI });
    const { data } = await axios.post(
      `${baseUrl}/user/forgot-password`,
      passwordVerifier
    );
    dispatch({ type: ActionTypes.CLEAR_ERRORS });
    dispatch({
      type: ActionTypes.SET_SNACKBAR,
      payload: {
        alert: "success",
        message: data,
      },
    });
  } catch (error) {
    if (error?.response?.status === 400) {
      dispatch({ type: ActionTypes.SET_ERROR, payload: error.response.data });
    } else {
      // console.log("server error");
    }
  }
};

export const verifyToken = (tokenParam, history) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_UI });
    const token = {
      token: tokenParam,
    };

    const { data } = await axios.post(`${baseUrl}/user/confirm-token`, token);
    dispatch({ type: ActionTypes.CLEAR_ERRORS });
    history.push({
      pathname: "/ywc/reset-password",
      state: { email: data.emailAddress, type: data.userType },
    });
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({ type: ActionTypes.SET_ERROR, payload: error.response.data });
    } else {
      // console.log(error);
    }
  }
};

export const resetPassword = (userPasswordDto, history) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_UI });
    const data = await axios.patch(`${baseUrl}/user/reset-password`, userPasswordDto);
    dispatch({ type: ActionTypes.CLEAR_ERRORS });
    if (userPasswordDto.userType === "customer") {
      history.push("/customer-login");
    } else {
      history.push("/seller-login");
    }
  } catch (error) {
    dispatch({ type: ActionTypes.SET_ERROR, payload: error.response.data });
    // console.log(error);
  }
};

export const clearErrorMsg = () => (dispatch) => {
  dispatch({ type: ActionTypes.CLEAR_ERRORS });
};

export const authAlert = () => (dispatch) => {
  dispatch({ type: ActionTypes.AUTH_ALERT });
};

// verify OTP and Login customer
export const verifyOtp = (phoneNumber, otpDto, history) => async (dispatch) => {
  try {
    if (!otpDto.otp || otpDto.otp.trim() === "") {
      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: "Please enter your OTP",
      });
      return;
    }
    dispatch({ type: ActionTypes.LOADING_UI });
    const { data } = await axios.post(
      `${baseUrl}/customer/login/phoneNumber/${phoneNumber}/otp/verify`,
      otpDto
    );

    const jwt = `Bearer ${data.token}`;
    localStorage.setItem("jwt", jwt);
    localStorage.setItem("cust", JSON.stringify(data));
    axios.defaults.headers.common["Authorization"] = jwt;
    dispatch(getUserData());
    dispatch({ type: ActionTypes.CLEAR_ERRORS });

    if (history.location.pathname === "/customer-login") {
      history.push("/");
    } else {
      history.push(history.location.pathname);
      dispatch({ type: ActionTypes.AUTH_ALERT });
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: "Invalid OTP",
      });
    } else {
      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: error.response?.data,
      });
    }
  }
};

// check duplicate mobile number of customer while signup
export const verifyCustomerDuplicateMobileNumber =
  (verifierDto) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/verify/customer/detail`,
        verifierDto
      );

      dispatch({
        type: ActionTypes.CHECK_DUPLICATE_MOBILENUMBER,
        payload: data,
      });
    } catch (error) {
      // console.log(error.response);
    }
  };

// check duplicate email of customer while signup
export const verifyCustomerDuplicateEmail =
  (verifierDto) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/verify/customer/detail`,
        verifierDto
      );

      dispatch({
        type: ActionTypes.CHECK_CUST_DUPLICATE_EMAIL,
        payload: data,
      });
    } catch (error) {
      // console.log(error);
    }
  };

//verify OTP and Login seller
export const verifySellerOtp =
  (phoneNumber, otpDto, history) => async (dispatch) => {
    try {
      if (!otpDto.otp || otpDto.otp.trim() === "") {
        dispatch({
          type: ActionTypes.SET_ERROR,
          payload: "Please enter your OTP",
        });
        return;
      }
      dispatch({ type: ActionTypes.LOADING_UI });
      const { data } = await axios.post(
        `${baseUrl}/user/login/phoneNumber/${phoneNumber}/otp/verify`,
        otpDto
      );
      const jwt = `Bearer ${data.token}`;
      localStorage.setItem("jwt", jwt);
      localStorage.setItem("sell", JSON.stringify(data));
      axios.defaults.headers.common["Authorization"] = jwt;
      dispatch(getSellerData(history));
      dispatch({ type: ActionTypes.CLEAR_ERRORS });
      // history.push("/seller-dashboard");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        dispatch({
          type: ActionTypes.SET_ERROR,
          payload: "Invalid OTP",
        });
      } else {
        dispatch({
          type: ActionTypes.SET_ERROR,
          payload: error.response?.data,
        });
      }
    }
  };
