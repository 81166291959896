import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

/* redux imports*/
import {
  getAllFilters,
  getProductsByGroupWithFilter,
} from "../redux/actions/homepageActions";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Link } from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { isStringNullorEmpty } from "../util/commonUtil/commonUtil";

const useStyles = makeStyles((theme) => ({
  rootDiv: {
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiGrid-root :hover": {
      textDecoration: "none",
    },
    "& .MuiCardMedia-img": {
      objectFit: "contain",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#383838",
      fontFamily: "'Fira Sans', sans-serif",
      textAlign: "center",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "15px",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#383838",
    },
    "& .MuiSelect-select": {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#140101",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  menuItem: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 2.13,
    color: "#140101",
  },
  selectSortBy: {
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.09,
    color: "#140101",
  },
  hidden: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  visible: {
    display:'block'
  },
  sort: {
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 2.13,
    color: "#140101",
  },
  dropdownStyle: {
    marginTop: 48,
  },
  showMore: {
    color: "#0000FF",
    textTransform: "uppercase",
    fontSize: 14,
  },
  SizeBox: {
    display: "grid",
    columnGap: 3,
    rowGap: 1,
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));

const ProductFilter = forwardRef((props, ref) => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  var queryParam = props?.queryParam || "";

  const { setSelectedPriceSort } = props;

  const { filters, type, page, sortby } = useSelector((state) => state.home);

  const dispatch = useDispatch();

  const [available, setAvailable] = useState(0);
  const [brand, setBrand] = useState("");
  const [size, setSize] = useState([]);
  const [gender, setGender] = useState("");
  const [moreBrands, setMoreBrands] = useState(4);
  const [moreSize, setMoreSize] = useState(4);
  // const [checked, setChecked] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const itemsPerPage = 12;
  const categoryCode = params.category || "";
  const groupName = params.groupName || "";
  const defaultGroup = ["New_Uploads", "Top_Rated", "Lowest_Price"];

  const resetPriceSort = (sortBy) => {
    setSelectedPriceSort(sortBy);
  };

  const handleAvailable = (value) => {
    resetPriceSort(sortby);
    if (available === value) {
      setAvailable(0);
    } else {
      setAvailable(value);
    }
  };

  /* handle function to select Gender */
  const handleGender = (value) => {
    resetPriceSort(sortby);
    if (gender === value) {
      setGender("");
    } else {
      setGender(value);
    }
  };

  /* handle function to select Size */
  const HandleChange = (category) => {
    const clickedCategory = size.indexOf(category);
    const all = [...size];

    if (clickedCategory === -1) {
      all.push(category);
    } else {
      all.splice(clickedCategory, 1);
    }
    // let string = all;
    // let finalSize = string.toString()
    // console.log(finalSize)
    // let sizeList = finalSize.replaceAll(',', '%');
    // console.log(sizeList)

    setSize(all);
  };

  // const handleSelectSize = (value) => {
  //     resetPriceSort("all");
  //     if (size === value) {
  //         setSize("");
  //     } else {
  //         setSize(value);
  //     }
  // };

  const handleSelectBrand = (value) => {
    resetPriceSort(sortby);
    if (brand === value) {
      setBrand("");
    } else {
      setBrand(value);
    }
  };

  useEffect(() => {
    dispatch(getAllFilters());
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  useImperativeHandle(ref, () => ({
    categoryClickedFromTab(categoryValue) {
      if (!isStringNullorEmpty(categoryValue)) {
        categoryClicked(categoryValue);
      }
    },
  }));

  // use effect only for filter values
  useEffect(() => {
    // console.log("params :  " +  params.groupName + "  ------ " + params.default);
    getCategoryProductsList();
  }, [
    params.category,
    available,
    gender,
    size,
    brand,
    page,
    type,
    queryParam,
    sortby,
  ]);

  const getCategoryProductsList = () => {
    const data = {
      category: categoryCode,
      group: groupName,
      brand: brand,
      available: available,
      type: type || "",
      sortby: sortby || "all",
      pageNumber: page || 1,
      pageSize: itemsPerPage,
      defaultGroup: defaultGroup.includes(groupName),
      gender: gender,
      size: size,
      queryParam: queryParam || "",
    };

    dispatch(getProductsByGroupWithFilter(data));
  };

  const categoryClicked = (value) => {
    resetNonRelevantStates();

    // if(!isStringNullorEmpty(groupName)) {
    //     history.push(
    //         `/${value}/${groupName}/${
    //             params.default ? 1 : 0
    //         }/products`
    //     );
    // } else {
    history.push(`/${value}/products`);
    // }
  };

  const resetNonRelevantStates = () => {
    // setType("");
    // setPage(1);
    setAvailable(0);
    setBrand("");
    setSize("");
    setGender("");
    // setSortby("all");
    setMoreBrands(4);
    setMoreSize(4);
    queryParam = "";
  };

  return (
    <Grid item md={3} lg={2}>
      <Grid>
        <Grid
          container
          alignItems="center"
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            fontWeight: "bold",
            fontSize: "1.2rem",
            zIndex: 3,
            paddingTop: "1rem",
            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <Box
            component={Grid}
            style={{
              paddingLeft: "1.5rem",
              fontFamily: "'Fira Sans', sans-serif",
              display: 'flex',
              justifyContent: 'space-between',
              width: '100vw',
              paddingRight:"1rem"
            }}
          >
            <h6> FILTERS </h6>
            {showFilter ? (
              <KeyboardArrowUpIcon
                onClick={() => setShowFilter(false)}
                sx={{ display: { lg: "none", sm: "flex", md: "none" } }}
                fontSize="medium"
              />
            ) : (
              <KeyboardArrowDownIcon
                onClick={() => setShowFilter(true)}
                sx={{ display: { lg: "none", sm: "flex", md: "none" } }}
                fontSize="medium"
              />
            )}
          </Box>

          <Grid
            item
            style={{ height: "1px", width: "100%", paddingTop: "1.1rem" }}
          >
            <Divider />
          </Grid>

          {Object.keys(filters).length > 0 &&  (
            <>
              <Grid
                container
                direction="column"
                className={showFilter ? classes.visible : classes.hidden}
                style={{
                  paddingLeft: "1.5rem",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <Grid item style={{ padding: "1rem 0" }}>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "1rem",
                      fontWeight: 600,
                      fontFamily: "'Fira Sans', sans-serif",
                    }}
                  >
                    Category
                  </Typography>
                </Grid>

                <Grid item style={{ marginLeft: "0.5rem" }}>
                  <FormControl
                    component="fieldset"
                    style={{ fontSize: "1rem", fontWeight: 600 }}
                  >
                    <FormGroup>
                      {filters.category?.length > 0 &&
                        filters.category.map((category, index) => (
                          <React.Fragment key={index}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    categoryCode === category ? true : false
                                  }
                                  name={category}
                                  size="small"
                                />
                              }
                              label={category}
                              onClick={() => {
                                categoryClicked(category);
                              }}
                            />
                          </React.Fragment>
                        ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <div style={{ paddingTop: "0.5rem" }}>
                <Divider />
              </div>

              <Grid
                container
                direction="column"
                className={showFilter ? classes.visible : classes.hidden}
                style={{
                  paddingLeft: "1.5rem",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <Grid item style={{ padding: "1rem 0" }}>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "1rem",
                      fontWeight: 600,
                      fontFamily: "'Fira Sans', sans-serif",
                    }}
                  >
                    Gender
                  </Typography>
                </Grid>

                <Grid item style={{ marginLeft: "0.5rem" }}>
                  <FormControl
                    component="fieldset"
                    style={{ fontSize: "1rem", fontWeight: 600 }}
                  >
                    <FormGroup>
                      {filters.gender?.length > 0 &&
                        filters.gender.map((gen, index) => (
                          <React.Fragment key={index}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={gender === gen ? true : false}
                                  name={gen}
                                  size="small"
                                />
                              }
                              label={gen}
                              onChange={() => {
                                handleGender(gen);
                              }}
                            />
                          </React.Fragment>
                        ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <div style={{ paddingTop: "0.5rem" }}>
                <Divider />
              </div>

              <Grid
                container
                direction="column"
                className={showFilter ? classes.visible : classes.hidden}
                style={{
                  paddingLeft: "1.5rem",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                {Object.entries(filters.brand)
                  .filter((key, value) => key[0] === categoryCode)
                  .map(
                    ([key, value]) =>
                      value.length > 0 && (
                        <React.Fragment key={key}>
                          <Grid item style={{ padding: "1rem 0" }}>
                            <Typography
                              component="p"
                              style={{
                                fontSize: "1rem",
                                fontWeight: 600,
                                fontFamily: "'Fira Sans', sans-serif",
                              }}
                            >
                              Brands
                            </Typography>
                          </Grid>

                          <Grid item style={{ marginLeft: "0.5rem" }}>
                            <FormGroup>
                              {value.slice(0, moreBrands).map((item, index) => (
                                <React.Fragment key={index}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          brand && brand === item ? true : false
                                        }
                                        name={item}
                                        size="small"
                                      />
                                    }
                                    label={item}
                                    onChange={() => {
                                      handleSelectBrand(item);
                                    }}
                                  />
                                </React.Fragment>
                              ))}
                            </FormGroup>

                            {value.length >= 4 &&
                              (moreBrands === 4 ? (
                                <Link
                                  component="button"
                                  variant="caption"
                                  underline="none"
                                  onClick={() => setMoreBrands(value.length)}
                                  className={classes.showMore}
                                >
                                  {value.length - moreBrands} more
                                </Link>
                              ) : (
                                <Link
                                  component="button"
                                  variant="caption"
                                  underline="none"
                                  onClick={() => setMoreBrands(4)}
                                  className={classes.showMore}
                                >
                                  show less
                                </Link>
                              ))}
                          </Grid>
                        </React.Fragment>
                      )
                  )}
              </Grid>

              <Divider />

              <Grid
                container
                direction="column"
                className={showFilter ? classes.visible : classes.hidden}
                style={{
                  paddingLeft: "1.5rem",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                {Object.entries(filters.sizes)
                  .filter((key, value) => key[0] === categoryCode)
                  .map(
                    ([key, value]) =>
                      value.length > 0 && (
                        <React.Fragment key={key}>
                          <Grid item style={{ padding: "1rem 0" }}>
                            <Typography
                              component="p"
                              style={{
                                fontSize: "1rem",
                                fontWeight: 600,
                                fontFamily: "'Fira Sans', sans-serif",
                              }}
                            >
                              Size
                            </Typography>
                          </Grid>

                          <Grid item style={{ marginLeft: "0.5rem" }}>
                            <Box className={classes.SizeBox}>
                              {value.slice(0, moreSize).map((item, index) => (
                                <React.Fragment key={index}>
                                  <FormControlLabel
                                    control={
                                      <input
                                        type="checkbox"
                                        name={item}
                                        size="small"

                                        // selected={selectedCheckboxes.includes(checkbox.id)}
                                      />
                                      // <Checkbox
                                      //     checked={size && size === item  ? true : false}
                                      //     name={item}
                                      //     size="small"
                                      // />
                                    }
                                    label={item}
                                    onChange={() => HandleChange(item)}
                                    // onChange={(e) => {
                                    //     handleSelectSize(item);
                                    // }}
                                  />
                                </React.Fragment>
                              ))}
                            </Box>

                            {value.length >= 4 &&
                              (moreSize === 4 ? (
                                <Link
                                  component="button"
                                  variant="caption"
                                  underline="none"
                                  onClick={() => setMoreSize(value.length)}
                                  className={classes.showMore}
                                >
                                  {value.length - moreSize} more
                                </Link>
                              ) : (
                                <Link
                                  component="button"
                                  variant="caption"
                                  underline="none"
                                  onClick={() => setMoreSize(4)}
                                  className={classes.showMore}
                                >
                                  show less
                                </Link>
                              ))}
                          </Grid>
                        </React.Fragment>
                      )
                  )}
              </Grid>

              <div style={{ paddingTop: "0.5rem" }}>
                <Divider />
              </div>

              <Grid
                container
                direction="column"
                className={showFilter ? classes.visible : classes.hidden}
                style={{ paddingLeft: "1.5rem" }}
              >
                <Grid item style={{ padding: "1rem 0" }}>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "1rem",
                      fontWeight: 600,
                      fontFamily: "'Fira Sans', sans-serif",
                    }}
                  >
                    Availability
                  </Typography>
                </Grid>

                <Grid item style={{ marginLeft: "0.5rem" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={available && available === 10 ? true : false}
                          name="10"
                          size="small"
                          onChange={(e) => {
                            handleAvailable(10);
                          }}
                        />
                      }
                      label="More than 10"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            available && available === 100 ? true : false
                          }
                          name="100"
                          size="small"
                          onChange={() => {
                            handleAvailable(100);
                          }}
                        />
                      }
                      label="More than 100"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            available && available === 200 ? true : false
                          }
                          name="200"
                          size="small"
                          onChange={() => {
                            handleAvailable(200);
                          }}
                        />
                      }
                      label="More than 200"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default React.memo(ProductFilter);
