import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

/* material ui */
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid, Paper, Container, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
// import { StepIconProps } from "@material-ui/core/StepIcon";
import InfoIcon from "@material-ui/icons/Info";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CategoryIcon from "@material-ui/icons/Category";
import PolicyIcon from "@material-ui/icons/Policy";

/* multi step form import */
import { SupplierDetails } from "../../components/seller/signupForm/SupplierDetails";
import { ContactInformation } from "../../components/seller/signupForm/ContactInformation";
import { Address } from "../../components/seller/signupForm/Address";
import { ProductCategory } from "../../components/seller/signupForm/ProductCategory";
import { TermsConditions } from "../../components/seller/signupForm/TermsConditions";

/* Redux actions */
import {
  clearSelectedCategories,
  removeSelectedCategory,
  selectBussinessCategories,
} from "../../redux/actions/otherActions";
import { signupSupplier } from "../../redux/actions/authActions";
import axios from "axios";


/* css */
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        // "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
        "linear-gradient(black, white)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        // "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
        "linear-gradient(black, white)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

/* css */
const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      // "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      "linear-gradient(#53e7bc, white)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      // "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      "linear-gradient(#53e7bc, black)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <InfoIcon />,
    2: <ContactPhoneIcon />,
    3: <LocationOnIcon />,
    4: <CategoryIcon />,
    5: <PolicyIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  signupPage: {
    // background: "#F7F7F7",
    // maxWidth: "1440px",
    // height: "100%",
    // minHeight: "100vh",
    flexWrap: "wrap",
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiStepLabel-label": {
      fontSize: 13,
      fontWeight: 500,
      fontFamily: "Montserrat",
      color: "#000000",
    },
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  gridSection: {
    paddingTop: 2,
  },
  formDiv: {
    "& .MuiFormControl-root": {
      width: "95%",
      margin: theme.spacing(1),
      // background: "#F7F7F7",
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: "6px",
    width: "75%",
    margin: "auto",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)",
  },
}));

function getSteps() {
  return [
    "Supplier Details",
    "Contact Information",
    "Address",
    "Product Category",
    "Verification",
  ];
}

export const SellerSignup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const gstFileValidUrl = process.env.REACT_APP_GST_FILE_VALID_DATA_URL;
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const [categories, setCategories] = useState([]);
  const [inputs, setInputs] = useState({
    userType: "",
    suppliername: "",
    supplierType: "",
    gstNumber: "",
    gstFile: "",
    base64GstFile: "",
    msme: "",
    umaNumber: "",
    memorandum: "",
    msmeType: "",
    msmeDate: null,
    msmeValidFrom: null,
    msmeValidTo: null,
    base64msmeDoc: "",
    msmeDocName: "",
    firstName: "",
    lastname: "",
    mobileNumber: "",
    alternateNumber: "",
    email: "",
    websiteUrl: "",
    password: "",
    confirmPassword: "",
    addressOne: "",
    addressTwo: "",
    country: "India",
    state: "",
    city: "",
    pinCode: "",
  });
const [gstFromPDF, setGstFromPDF] = useState({})
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /* handle inputs */
  const handleInputChange = (event) => {
    const name = event.target.name;
    setInputs({
      ...inputs,
      [name]: event.target.value,
    });
  };

// Function to check whether GST field value matches with GST No in uploaded file 
  const gstUploadTest = async (file) => {
    
    const formData = new FormData();
    formData.append("pdf", file);
    try {
      const response = await axios.post(
        `${gstFileValidUrl}/get/images`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setGstFromPDF(response?.data);
    }
    catch (error) {
      // console.log("Axios Post File Error:", error);
    }
  }

  /* upload GST File */
  const gstUpload = async (event) => {
    let file = event.target.files[0];
    if (file) {
      if(gstFileValidUrl !== null){
        await gstUploadTest(file)
      } 
      const base64 = await encodeFileBase64(file);
      setInputs({
        ...inputs,
        gstFile: file.name,
        base64GstFile: base64,
      });
    };
  }

  /* upload msme doc */
  const msmeUpload = async (event) => {
    let file = event.target.files[0];
    const base64 = await encodeFileBase64(file);
    setInputs({
      ...inputs,
      base64msmeDoc: base64,
      msmeDocName: file.name,
    });
  };

  /* conver file to base64 */
  const encodeFileBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  /* set msme issue date */
  const handleMsmeDate = (date) => {
    setInputs({
      ...inputs,
      msmeDate: date,
    });
  };

  /* set msme valid from date */
  const handleMsmeValidFrom = (date) => {
    setInputs({
      ...inputs,
      msmeValidFrom: date,
    });
  };

  /* msme valid to date */
  const handleMsmeValidTo = (date) => {
    setInputs({
      ...inputs,
      msmeValidTo: date,
    });
  };

  /* set pincode */
  const handlepincode = (data) => {
    setInputs({
      ...inputs,
      pinCode: data.pincode,
    });
  };

  const handleChangeCity = (data) => {
    setInputs({
      ...inputs,
      city: data.hubCity,
    });
  };

  const handleChangeState = (data) => {
    setInputs({
      ...inputs,
      state: data.hubState,
    });
  };

  const handleSetBussinessCategory = (obj) => {
    setCategories([
      ...categories,
      { businessCategoryId: obj.catId, businessSubCategoryId: obj.subCatId },
    ]);

    dispatch(selectBussinessCategories(obj));
  };

  const removeCategoryFromList = (index) => {
    setCategories(categories.filter((obj, ind) => ind !== index));
    dispatch(removeSelectedCategory(index));
  };

  /* handle submit seller signup form */
  const handleSignUp = () => {
    const userObject = {
      active: true,
      defaultLanguage: "en",
      emailAddress: inputs.email.trim(),
      firstName: inputs.firstName.trim(),
      groups: [
        {
          name: "ADMIN",
        },
      ],
      lastName: inputs.lastname.trim(),
      password: inputs.password,
      supplierInfo: {
        address: {
          addressLine1: inputs.addressOne,
          addressLine2: inputs.addressTwo ? inputs.addressTwo : "",
          city: inputs.city,
          pincode: inputs.pinCode,
          state: inputs.state,
          webSiteUrl: inputs.websiteUrl ? inputs.websiteUrl : "",
        },
        approved: false,
        dateOfIssue: inputs.msmeDate ? inputs.msmeDate.toString() : "",
        email: inputs.email.trim(),
        firstName: inputs.firstName.trim(),
        lastName: inputs.lastname.trim(),
        memorandumNumber: inputs.memorandum ? inputs.memorandum.trim() : "",
        mobileNumber:inputs.mobileNumber,
        msme: inputs.msme === "YES" ? true : false,
        msmeType: inputs.msmeType ? inputs.msmeType : "",
        name: inputs.suppliername.trim(),
        gstPdfFile: inputs.base64GstFile,
        gstNumber: inputs.gstNumber.trim(),
        phoneNumber:  inputs.alternateNumber,
        typeOfOnwerShip: inputs.supplierType,
        typeOfVendor: "NON",
        uamNumber: inputs.umaNumber ? inputs.umaNumber.trim() : "",
        validFrom: inputs.msmeValidFrom ? inputs.msmeValidFrom.toString() : "",
        validTo: inputs.msmeValidTo ? inputs.msmeValidTo.toString() : "",
        vendorBusinessCatInformation: categories,
        vendorRegDate: new Date().toISOString(),
      },
      userName: inputs.email.trim(),
      userStatus: "INACTIVE",
      userType: inputs.userType,
    };
    dispatch(signupSupplier(userObject));
  };

  /* clear inputs after form submit */
  const clearInputs = () => {
    setInputs({
      userType: "",
      suppliername: "",
      supplierType: "",
      gstNumber: "",
      gstFile: "",
      base64GstFile: "",
      msme: "",
      umaNumber: "",
      memorandum: "",
      msmeType: "",
      msmeDate: null,
      msmeValidFrom: null,
      msmeValidTo: null,
      base64msmeDoc: "",
      msmeDocName: "",
      firstName: "",
      lastname: "",
      mobileNumber: "",
      alternateNumber: "",
      email: "",
      websiteUrl: "",
      password: "",
      confirmPassword: "",
      addressOne: "",
      addressTwo: "",
      country: "India",
      state: "",
      city: "",
      pinCode: "",
    });
    setCategories([]);
    dispatch(clearSelectedCategories());
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <SupplierDetails
            handleNext={handleNext}
            initialValues={inputs}
            handleInputChange={handleInputChange}
            handleMsmeDate={handleMsmeDate}
            handleMsmeValidFrom={handleMsmeValidFrom}
            handleMsmeValidTo={handleMsmeValidTo}
            gstUpload={gstUpload}
            msmeUpload={msmeUpload}
            gstFromPDF={gstFromPDF}
          />
        );
      case 1:
        return (
          <ContactInformation
            handleNext={handleNext}
            handleBack={handleBack}
            initialValues={inputs}
            handleInputChange={handleInputChange}
          />
        );
      case 2:
        return (
          <Address
            handleNext={handleNext}
            handleBack={handleBack}
            initialValues={inputs}
            handleInputChange={handleInputChange}
            handleChangeState={handleChangeState}
            handleChangeCity= {handleChangeCity}
            handlePincode={handlepincode}
          />
        );
      case 3:
        return (
          <ProductCategory
            handleNext={handleNext}
            handleBack={handleBack}
            handlesetCategory={handleSetBussinessCategory}
            handleRemoveCategory={removeCategoryFromList}
            categories={categories}
          />
        );
      case 4:
        return (
          <TermsConditions
            initialValues={inputs}
            handleBack={handleBack}
            handleSignUp={handleSignUp}
            clearInputs={clearInputs}
          />
        );
      default:
        return "Finished";
    }
  }

  return (
    <Container className={classes.signupPage}>
      <CssBaseline />

      <Container component="main" maxWidth="xs">
        <Box
          style={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img
            alt="logo"
            src={"https://storage.googleapis.com/stockxbid_gc_bng_misc/logo_beta_v/new%20logo%201%20png.svg"}
            style={{
              maxHeight: "60px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/")}
          />
        </Box>
      </Container>

      <Grid item xs={12}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<ColorlibConnector />}
          style={{ background: "#efefef" }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Grid container spacing={5} className={classes.gridSection}>
        <Grid item xs={12} sm={12} md={12}>
          <Paper className={classes.paper}>
            <div className={classes.formDiv}>
              {activeStep === steps.length ? (
                <Fragment>
                  <Typography className={classes.instructions}>
                    All steps completed
                  </Typography>
                </Fragment>
              ) : (
                <Fragment>{getStepContent(activeStep)}</Fragment>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
