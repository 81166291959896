import React, { useState, useEffect } from "react";

/* import react components */
import { AppBars } from "../../components/AppBar";
import { Footer } from "../../components/Footer";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Sitemap from "../Sitemap/sitemap";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  aboutUs: {
    flexGrow: 1,
    background: "#f0e0d4",
    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
  pagetitle: {
    fontSize: 28,
    fontWeight: 600,
    color: "#F24046",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
    fontStretch: "normal",
    margin: "62px 0 10px 0",
    textAlign: "center",
    padding: "60px 0 0 0",
  },
  subtitle: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 300,
    textAlign: "center",
    width: "75%",
    padding: "15px 5px",
    margin: "auto",
    lineHeight: "30px",
    color: "#5c6166",
  },
  description: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: "30px",
  },
}));

export const AboutUs = () => {
  const classes = useStyles();
  const [showSiteMap9, setShowSiteMap9] = useState(false);
  useEffect(() => {
    var metaDescriptionTag = document.querySelector('meta[name="description"]');

    // Check if the meta tag is found
    if (metaDescriptionTag) {
      // Update the content attribute with a new description
      metaDescriptionTag.setAttribute('content', `Outxtock About Us`)
    }
    document.title = 'About Us'
  },[])
  return (
    <div className={classes.aboutUs}>
      <AppBars />
      {!showSiteMap9 ? (
        <>
          <Container fixed>
            <Grid item xs={12} style={{ margin: "10px 0 30px 0" }}>
              <Typography component="h6" className={classes.pagetitle}>
                About OutXtock
              </Typography>

              {/* <Typography component="p" className={classes.subtitle}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography> */}

              <Typography component="p" className={classes.description}>
                <b>Our Vision: </b>
                Founded in 2021 with a vision to "transform the way trade is
                done in India leveraging technology," OutXtock is India's first
                business-to-business e-commerce platform where you can bargain
                directly with the seller. We are committed to revolutionizing
                the landscape of Indian trade by harnessing the power of
                technology.
              </Typography>

              <Typography component="p" className={classes.description}>
                <b>A Solution for India's Unique Trade Challenges: </b>
                OutXtock is dedicated to addressing the distinctive trade
                challenges faced by small and medium-sized businesses in India.
                Through our innovative, India-fit, and cost-effective business
                model, we leverage technology to bring the benefits of
                e-commerce to businesses across various sectors. We serve as a
                one-stop shop for all business requirements in the B2B space.
              </Typography>

              <Typography component="p" className={classes.description}>
                <b>Trusted Partner for Small Businesses: </b>
                Our mission is to be a trusted partner to small businesses,
                empowering them with technology, financial inclusivity, and
                supply chain capabilities, enabling them to thrive in an
                increasingly tech and digital world.
              </Typography>

              <Typography component="p" className={classes.description}>
                <b>Seamless, Secure, and Transparent Transactions: </b>
                OutXtock enables small manufacturers, farmers, and brands to
                market and sell their products across the country at a low cost
                with 100% payment security and complete transparency.
                Simultaneously, it empowers small businesses such as
                manufacturers, vendors, and retailers to access a wide range of
                high-quality products at the best prices, facilitating efficient
                and transparent transactions.
              </Typography>

              <Typography component="p" className={classes.description}>
                <b>Efficient Logistics and Trade Financing: </b>
                Our platform is complemented by a strong logistics and
                operations network, including Xpressbees and Shadowfax, focused
                on B2B trade fulfillment and delivery services.
              </Typography>

              <Typography component="p" className={classes.description}>
                <b>Empowering Brands and Manufacturers: </b>
                OutXtock offers SaaS solutions that enable real-time marketing
                feedback analysis through app data analytics, helping brands and
                manufacturers make well-informed decisions about product
                launches and market testing. Our app-based pricing system
                enhances pricing control, and product listing advertisements and
                in-app advertisements enable manufacturers and wholesalers to
                reach their target audience effectively.
              </Typography>

              <Typography component="p" className={classes.description}>
                <b>A Nationwide Presence: </b>
                Right now, OutXtock is headquartered in Bengaluru, but in the
                future, we will spread to all major cities across India.
              </Typography>

              <Typography component="p" className={classes.description}>
                <b>Founder and Co-Founder: </b>
                Saranga Rao Pani: Founder of OutXtock, Saranga is known as one
                of India's top tech minds. He has a BTech in Computer Science
                from KU, Dharwad.
              </Typography>
              <Typography component="p" className={classes.description}>
                <b>Co-founder: </b>
                Sachin Sharma: Co-founder of OutXtock, Sachin has 23 years of
                experience in e-commerce. At OutXtock, we are committed to
                transforming the landscape of Indian trade, fostering growth,
                and empowering businesses across the country. Join us on our
                journey to revolutionize the way trade is done in India!
              </Typography>
              <Typography component="p" className={classes.description}>
                <b>Address: </b>
                STOCKXBID TECHNOLOGIES PRIVATE LIMITED,
                29/1,First Floor,11 Cross, 6 Cross Swimming Pool Extension, Malleshwaram Bengaluru, Karnataka 560003,
                Ph: +91 9380272287.
              </Typography>
            </Grid>
          </Container>
        </>
      ) : (
        <Sitemap />
      )}

      <Footer showSiteMap={showSiteMap9} setShowSiteMap={setShowSiteMap9} />
    </div>
  );
};
