import { ActionTypes } from "../constants/actionTypes";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getCustomerWishlist = () => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_WISHLIST });
    const { data } = await axios.get(`${baseUrl}/auth/customer/wishlist`);
    dispatch({ type: ActionTypes.GET_CUSTOMER_WISHLIST, payload: data });
    dispatch({ type: ActionTypes.CLEAR_WISHLIST_ERRORS });
  } catch (error) {
    // console.log(error);
  }
};

export const deleteProductFromWishlist = (productId) => async (dispatch) => {
  try {
    await axios.delete(
      `${baseUrl}/auth/customer/wishlist/product/${productId}`
    );
    dispatch({ type: ActionTypes.DELETE_WISHLIST_PRODUCT, payload: productId });
  } catch (error) {
    // console.log(error);
  }
};

export const duplictaeAlertWishlist = () => async (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SNACKBAR,
    payload: {
      alert: "warning",
      message: "Product is already wishlisted.",
    },
  });
};
