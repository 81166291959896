import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { ActionTypes } from "../../redux/constants/actionTypes";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  TextField,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";

/* redux Actions */
import {
  signupUser,
  verifyCustomerDuplicateMobileNumber,
  verifyCustomerDuplicateEmail,
  clearAllMessageState,
} from "../../redux/actions/authActions";
import Loading from "../../util/spinner/Loading";

/* regex for emial validation */
const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

/* regex for mobile number */
const mobileRegex = RegExp(/^[6789]\d{9}$/);

/* material ui css */
const useStyles = makeStyles((theme) => ({
  "@global": {
    ":root": {
      backgroundImage:
        "url('https://storage.googleapis.com/stockxbid_gc_bng_acs/BANNER/Welcomehomepage/Welcome%20Buyer.gif')",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      width: "100vw",
      height: "100vh",
    },
    body: {
      background: "none",
    },
  },

  formDiv: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
      background: "000",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#495057",
      textAlign: "initial",
      background: "#F7F7F7",
    },
    "& .MuiFormControlLabel-label": {
      color: "#4A4A4A",
      fontSize: 12,
    },
    "& .MuiTypography-colorTextSecondary": {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
    },
  },
  msgSection: {
    fontWeight: 600,
    color: "#043c53",
    marginTop: theme.spacing(15),
  },
  captionMsg: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#000",
  },
  subSection: {
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1),
  },
  signInButton: {
    color: "#000",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem 1.25rem",
    cursor: "pointer",
  },
  signinLable: {
    fontWeight: 600,
    color: "#000",
    width: "75%",
    margin: "auto",
    paddingBottom: "2px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: "6px",
    width: "75%",
    height: "100%",
    margin: "auto",
  },
  submitButton: {
    color: "#000",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem 1.25rem",
    width: "75%",
    cursor: "pointer",
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
    color: "#000000",
    backgroundColor: "#F7F7F7",
  },
  outlined: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: 14,
    color: "#000000",
  },
}));

const ALPHA_ONLY = /^[a-zA-Z\s]+$/;
const regexp = /^\S*$/;
const NUMERIC_ONLY = /^[0-9]+$/;
export const CustomerSignup = () => {
  const classes = useStyles();
  const emailInputRef = React.useRef();
  const numberInputRef = React.useRef();
  const { loading, error, serverError } = useSelector((state) => state.UI);
  const { duplicateMobile, duplictaeEmail } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [gstNumber, setGstNumber] = useState('');
  /* signup form input validations */

  const inputValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    // Regex for Name fields

    if (firstname === "" || firstname.length < 3) {
      inputsValid = false;
      formErrors["firstname"] =
        "Please Enter First name (Minimum 3 characters).";
    }

    if (lastname === "") {
      inputsValid = false;
      formErrors["lastname"] = "Please enter your Last name";
    }

    if (email === "") {
      inputsValid = false;
      formErrors["email"] = "Please enter your e-mail.";
    }

    if (email && !emailRegex.test(email)) {
      inputsValid = false;
      formErrors["email"] = "Invalid e-mail address";
    }

    if (mobileNumber === "") {
      inputsValid = false;
      formErrors["mobileNumber"] = "Please enter your Mobile number";
    }

    if (mobileNumber && !mobileRegex.test(mobileNumber)) {
      inputsValid = false;
      formErrors["mobileNumber"] = "Invalid mobile number";
    }

    if (password === "") {
      inputsValid = false;
      formErrors["password"] = "Please enter your Password.";
    }

    if (!confirmPassword) {
      inputsValid = false;
      formErrors["confirmPassword"] = "Please enter your confirm password.";
    }

    if (
      typeof password !== "undefined" &&
      typeof confirmPassword !== "undefined"
    ) {
      if (password !== confirmPassword) {
        inputsValid = false;
        formErrors["password"] = "Passwords don't match.";
      }
    }

    if (!checked) {
      inputsValid = false;
      formErrors["agree"] =
        "Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy";
    }

    setFormErrors(formErrors);
    return inputsValid;
  };

  /* function to handle customer signup */
  const handleCustomerSignup = (event) => {
    event.preventDefault();
    handleCheckDuplicateEmail()
    handleCheckDuplicateMobile()
    if (inputValidation()) {
      const newUserData = {
        emailAddress: email,
        gender: "M",
        language: "en",
        userName: "",
        firstName: firstname,
        lastName: lastname,
        password: password,
        gstNumber: gstNumber,
        userType: "CUST",
        billing: {
          address: "",
          city: "",
          postalCode: "",
          country: "IN",
          zone: "ON",
          firstName: firstname,
          lastName: lastname,
          phone: mobileNumber,
        },
      };

      dispatch(signupUser(newUserData, history));
    }
  };

  /* function to check dupliacte mobile number */
  const handleCheckDuplicateMobile = () => {
    const verifierDto = {
      details: mobileNumber,
      type: "PHONE",
    };
    dispatch(verifyCustomerDuplicateMobileNumber(verifierDto));
  };


  /* function to check dupliacte email*/
  const handleCheckDuplicateEmail = () => {
    const verifierDto = {
      details: email,
      type: "EMAIL",
    };
    dispatch(verifyCustomerDuplicateEmail(verifierDto));
  };

  useEffect(() => {
    if (duplicateMobile) {
      numberInputRef.current.focus();
    }
    if (duplictaeEmail) {
      emailInputRef.current.focus();
    }

  }, [duplictaeEmail, duplicateMobile])

  useEffect(() => {
    return () => {
      dispatch(clearAllMessageState());
      dispatch({ type: ActionTypes.CLEAR_DUPLICATE_MOBILENUMBER });
      dispatch({ type: ActionTypes.CLEAR_CUST_DUPLICATE_EMAIL });
    };
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const trimmedValue = value.trim();
    if (name === "password" && value.includes(" ")) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password cannot contain spaces.",
      }));
    } else if (name === "password") {
      setPassword(trimmedValue);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    } else if (name === "confirmPassword" && value.includes(" ")) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Password cannot contain spaces.",
      }));
    } else if (name === "confirmPassword") {
      setConfirmPassword(trimmedValue);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    } else if (name === "gstNumber") {
      if (/^[a-zA-Z0-9]*$/.test(trimmedValue)) {
        setGstNumber(trimmedValue);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          gstNumber: "",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          gstNumber: "GST Number can only contain alphanumeric characters.",
        }));
      }
    }

  };



  return (
    <>
      {loading && <Loading />}
      <Container component="main" maxWidth="lg">
        {/* <CssBaseline /> */}

        <Container component="main" maxWidth="xs">
          <Box
            style={{
              marginTop: 6,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <img
              alt="logo"
              src={
                "https://storage.googleapis.com/stockxbid_gc_bng_misc/logo_beta_v/new%20logo%201%20png.svg"
              }
              // src={process.env.PUBLIC_URL + "/images/Outxtock-Logo-01.svg"}
              style={{
                maxHeight: "60px",
                cursor: "pointer",
              }}
              onClick={() => history.push("/")}
            />
          </Box>
        </Container>

        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} md={6} align="center">
            <Typography
              component="h1"
              variant="h4"
              className={classes.msgSection}
            >
              Welcome to OutXtock
            </Typography>

            <Typography
              variant="caption"
              component="p"
              className={classes.captionMsg}
            >
              Good to see you again...!
            </Typography>

            <Typography
              variant="subtitle1"
              component="p"
              className={classes.subSection}
            >
              Already have an account?
            </Typography>

            <Link to="/customer-login" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.signInButton}
                style={{ outline: "none" }}
              // onClick={(e) => setFormErrors({})}
              >
                Sign In
              </Button>
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h5" className={classes.signinLable}>
              Sign Up
            </Typography>

            <Paper className={classes.paper}>
              <form
                onSubmit={handleCustomerSignup}
                autoComplete="off"
                className={classes.formDiv}
              >
                <TextField
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  autoFocus
                  value={firstname}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !ALPHA_ONLY.test(value)) {
                      return;
                    }
                    setFirstname(e.target.value);
                  }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.firstname ? formErrors.firstname : ""}
                />
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  value={lastname}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !ALPHA_ONLY.test(value)) {
                      return;
                    }
                    setLastname(e.target.value);
                  }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.lastname ? formErrors.lastname : ""}
                />


                <TextField
                  id="email"
                  name="email"
                  label="Your Email"
                  variant="outlined"
                  inputRef={emailInputRef}

                  size="small"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !regexp.test(value)) {
                      return;
                    }
                    setEmail(e.target.value.trim());
                  }}
                  onBlur={handleCheckDuplicateEmail}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={
                    duplictaeEmail
                      ? "Email is already exist."
                      : formErrors.email
                        ? formErrors.email
                        : ""
                  }
                />

                <TextField
                  id="mobileNumber"
                  name="mobileNumber"
                  placeholder="Mobile Number"
                  variant="outlined"
                  inputRef={numberInputRef}
                  size="small"
                  value={mobileNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !NUMERIC_ONLY.test(value)) {
                      return;
                    }
                    setMobileNumber(e.target.value.trim());
                  }}
                  onBlur={handleCheckDuplicateMobile}
                  style={{ fontFamily: 32 }}
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputProps={{
                    autoComplete: "off",
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={
                    duplicateMobile
                      ? "Mobile number is already exist."
                      : formErrors.mobileNumber
                        ? formErrors.mobileNumber
                        : ""
                  }
                />

                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  size="small"
                  autoComplete="current-password"
                  onChange={handleInputChange}
                  value={password}
                  error={!!formErrors.password}
                  helperText={formErrors.password}
                  // onChange={(e) => setPassword(e.target.value)}
                  inputProps={{ minLength: 8 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    classes: {
                      outlined: classes.outlined,
                    },
                  }}
                // helperText={formErrors.password ? formErrors.password : ""}
                />

                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  size="small"
                  onChange={handleInputChange}
                  value={confirmPassword}
                  error={!!formErrors.password}
                  helperText={formErrors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    classes: {
                      outlined: classes.outlined,
                    },
                  }}
                // helperText={
                //   formErrors.confirmPassword ? formErrors.confirmPassword : ""
                // }
                />

                <TextField
      id="gstNumber"
      name="gstNumber"
                  label="GST Number"
                  variant="outlined"
                  size="small"
                  value={gstNumber}
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VerifiedUserIcon />
                      </InputAdornment>
                    ),
                    inputProps: {
                      autoCapitalize: 'characters',
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      outlined: classes.outlined,
                    },
                  }}
                  helperText={formErrors.gstNumber ? formErrors.gstNumber : ""}
                />


                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                        size="small"
                        name="agree"
                      />
                    }
                    label={
                      <div>
                        <span>By Signing up you are agreeing</span>
                        <Link to={"/pages/terms-and-conditions"} target="_BLANK">
                          {" "}
                          terms & conditions
                        </Link>
                        <span> and </span>
                        <Link to={"/pages/privacy-policy"} target="_BLANK">
                          privacy policy *
                        </Link>
                      </div>
                    }
                  />

                  <FormHelperText style={{ color: "red" }}>
                    {formErrors.agree ? formErrors.agree : ""}
                  </FormHelperText>
                </FormControl>

                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="small"
                  className={classes.submitButton}
                  style={{ outline: "none" }}
                  disabled={!duplicateMobile && !duplictaeEmail ? false : true}
                >
                  Sign Up
                </Button>
              </form>

              {error && (
                <Box
                  style={{
                    fontSize: "12px",
                    color: "red",
                    fontFamily: "'Fira Sans', sans-serif",
                  }}
                >
                  {error}
                </Box>
              )}

              {serverError && (
                <Box
                  style={{
                    fontSize: "12px",
                    color: "red",
                    fontFamily: "'Fira Sans', sans-serif",
                  }}
                >
                  {serverError}
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
