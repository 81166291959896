import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import axios from "axios";

/* import react components */
import sampleImage from "../../../assets/images/8.png";
import Spinner from "../../../util/spinner/spinner";
import { Paginator } from "../../../components/Paginator";
import { ViewBreakUpDialog } from "../../../components/customer/portfolio/ViewBreakUpDialog";
import { ViewOrderDetails } from "../../../components/customer/portfolio/ViewOrderDetails";
import {
  Success,
  SuccessErrorOne,
  SuccessErrorTwo,
  SuccessUndelivered,
  SuccessDelivered,
  SuccessShortage,
  SuccessLost,
} from "./SetDataMap";

/* import redux actions */
import {
  cancelOrder,
  changeAddressOrder,
  getCustomerOrders,
} from "../../../redux/actions/customerActions";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { CancelReason } from "./CancelReason";
import {
  isStringNullorEmpty,
} from "../../../util/commonUtil/commonUtil";
import { ChangeDeliveryList } from "./ChangeDelivery";
import RefundStatus from "./RefundStatus";
import CustomerPayment from "./CustomerPayment";
import SmallSpinner from "../../../util/spinner/SmallSpinner";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  ordersroot: {
    flexGrow: 1,
    border: "solid 1px #d8d8d8",
    borderRadius: "4px",
    padding: theme.spacing(2),
  },
  paper: {
    margin: "auto",
    maxWidth: 930,
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.16)",
    border: "1px solid #53e7bc",
    marginTop: theme.spacing(3),
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100px",
  },
  ordersheader: {
    borderBottom: "solid 1px #53e7bc",
    backgroundColor: "#d5f9ef",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  orderDetails: {
    display: "flex",
    flexDirection: "row",
  },
  orderId: {
    fontSize: "12px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: "bold",
    color: "#3b3b3b",
    margin: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "12px",
    },
  },
  productgrid: {
    position: "relative",
    margin: "auto",
    padding: theme.spacing(1),
  },
  grid: {
    padding: theme.spacing(1),
  },
  productname: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#383838",
    lineHeight: 1.19,
    textTransform: "capitalize",
  },
  label: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#383838",
    lineHeight: 1.19,
    paddingTop: theme.spacing(1),
  },
  span: {
    fontWeight: 600,
    fontSize: 13,
  },
  buybutton: {
    borderRadius: "3px",
    backgroundColor: "#53e7bc",
    color: "#ffffff",
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#53e7bc",
      color: "#ffffff",
    },
  },
  trackbutton: {
    borderRadius: "3px",
    backgroundColor: "#53e7bc",
    color: "#ffffff",
    width: "80%",
    marginBottom: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#53e7bc",
      color: "#ffffff",
    },
  },
  noOrders: {
    fontFamily: "'Fira Sans', sans-serif",
    color: "#383838",
    lineHeight: 1.71,
    textAlign: "center",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily: "'Fira Sans', sans-serif",
    flexBasis: "33.33%",
    flexShrink: 0,
    fontWeight: 600,
  },
  ordersHidden: {
    position: "absolute",
    top: "auto",
    left: "auto",
    width: "97%",
    height: "95%",
    background: "rgba(0, 0, 0, 0.5)" /* Transparent background */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "1",
    color: "#fff",
  },
}));

const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

export const MyOrders = () => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("cust"));
  const { customerOrders, loading, orderError,   cancelClicked } = useSelector(
    (state) => state.notification
  );
  const [width] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState({});

  const [viewDetails, setViewDetails] = useState(false);
  // const [finalViewDetails, setFinalViewDetails] = useState(false);
  const [cancelOrderPopUp, setCancelOrderPopUp] = useState(false);
  const [changeOrderPopUp, setChangeOrderPopUp] = useState(false);
  const [refundStatusPopUp, setRefundStatusPopUp] = useState(false);
  const [orientation, setOrientation] = useState("horizontal");
  const [orderDetail, setOrderDetail] = useState({
    details: {},
    billing: {},
    delivery: {},
  });
  const itemsPerPage = 5;
  const [page, setPage] = useState(1);
  const [expanded, setExpanded] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState([]);
  const [cancelData, setCancelData] = useState({});
  const [changeData, setChangeData] = useState();
  const [refundStatus, setRefundStatus] = useState();
  const [loadingTrack, setLoadingTrack] = useState();

  const baseUrl = process.env.REACT_APP_BASE_URL;

  /* function to handle track payment details */

  /* function to handle track order */
  const handleChange = (panel, lorNumber) => async (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setSteps([]);
    const deliveryData = [
      SuccessErrorOne,
      SuccessErrorTwo,
      Success,
      SuccessUndelivered,
      SuccessDelivered,
      SuccessShortage,
      SuccessLost,
    ];

    if (isExpanded) {
      if (lorNumber) {
        setLoadingTrack(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/getShippOrderTrackStatus/${lorNumber}/?format=json`
        );
        setLoadingTrack(false);
        const productItem = JSON.parse(data);
        productItem.map((itemDetails) => {
          return deliveryData.map((setOfData) => {
            return setOfData.find((ProductStatus) => {
              ProductStatus.key.find((element) => {
                if (element === itemDetails.CurrentShipmentStatus.StatusCode) {
                  setActiveStep(ProductStatus.sortOrder - 1);
                  setSteps(setOfData);
                }
              });
            });
          });
        });
      } else {
        deliveryData.map((setOfData) => {
          return setOfData.find((ProductStatus) => {
            return ProductStatus.key.find((element) => {
              if (element === "DRC") {
                setActiveStep(ProductStatus.sortOrder - 1);
                setSteps(setOfData);
              }
            });
          });
        });
      }
    }
  };
  /* function to open payment info dialog */
  const handleClickOpenPaymentInfo = (item) => {
    setOpen(true);
    setOrder(item);
  };

  /* function to close payment info dialog */
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newValue) => {
    setPage(newValue);
  };

  /* go to write product review page */
  const handleWriteReview = (productId) => {
    history.push(`/review/${productId}/review-your-orderd-product`);
  };

  /* function to handle view order details */
  const handleViewOrderDetails = (order, billing, delivery) => {
    setOrderDetail((prevState) => ({
      ...prevState,
      details: order,
      billing: billing,
      delivery: delivery,
    }));
    setViewDetails(true);
  };
  // const handleViewOrderDetailsPrice = (index, order, billing, delivery) => {
  //   setFinalViewDetails(!finalViewDetails);
  // };

  /* function to close view order details dialog */
  const handleCloseViewOrderDetails = (order) => {
    setViewDetails(false);
  };

  const submitCancelReason = (cancelReason, cancelComments, cancelData) => {
    let { productItemId, orderObj, orderNumber } = cancelData;
    callCancelOrder(
      productItemId,
      orderObj,
      orderNumber,
      cancelReason,
      cancelComments
    );
    setCancelOrderPopUp(false);
  };

  const callCancelOrder = (
    productItemId,
    orderObj,
    orderNumber,
    cancelReason,
    cancelComments
  ) => {
    let productsObj = orderObj?.products.find(
      (prod) => prod.id === productItemId
    );
    let productsList = createProductsList(
      productsObj,
      cancelReason,
      cancelComments
    );

    let cancelOrderRequest = {
      order: [
        {
          orderId: orderObj.id,
          products: productsList,
          orderNumber: productsObj.orderNumber,
          orderPriceSummary: {
            taxTotal: productsObj.orderPriceSummary.taxTotal.replace("Rs.", ""),
            basePrice: productsObj.orderPriceSummary.basePrice.replace(
              "Rs.",
              ""
            ),
            total: productsObj.orderPriceSummary.total.replace("Rs.", ""),
            finalPrice: productsObj.orderPriceSummary.finalPrice.replace(
              "Rs.",
              ""
            ),
            subTotal: productsObj.orderPriceSummary.subTotal.replace("Rs.", ""),
            gstRate: productsObj.orderPriceSummary.gstRate.replace("%", ""),
            taxPricePerProduct:
              productsObj.orderPriceSummary.taxPricePerProduct.replace(
                "Rs.",
                ""
              ),
            shippingTotal: productsObj.orderPriceSummary.shippingTotal.replace(
              "Rs.",
              ""
            ),
            hsnCode: productsObj.orderPriceSummary.hsnCode,
          },
          deliveredDate: productsObj.deliveredDate,
          lorNumber: productsObj.lorNumber,
        },
      ],
    };

    let customerOrderCopy = { ...customerOrders };
    dispatch(
      cancelOrder(
        cancelOrderRequest,
        productItemId,
        customerOrderCopy,
        orderNumber,
        orderObj.id,
    
      )
    );
  };

  /* function order change address */
  const submitChangeDeliverAddress = (
    name,
    lastName,
    mobile,
    pinCode,
    address,
    email,
    deferredDeliveryDate
  ) => {
    let changeDataDetails = {
      readableChangeAddress: {
        name,
        deferredDeliveryDate,
        lastName,
        mobile,
        pinCode,
        address,
        email,
        productItemId: changeData.productItemId,
        orderNumber: changeData.orderNumber,
      },
    };

    if (changeDataDetails) {
      dispatch(changeAddressOrder(changeDataDetails));
      setChangeOrderPopUp(false);
    }
    if (changeOrderPopUp === false) {
      changeDataDetails = [];
    }
  };

  /* function to handle order cancel */
  const handleOrderCancelPopUp = (productItemId, orderObj, orderNumber) => {
    let cancelData = {
      productItemId: productItemId,
      orderObj: orderObj,
      orderNumber: orderNumber,
    };
    setCancelData(cancelData);
    setCancelOrderPopUp(true);
  };

  const handleRefundStatusDelPopUp = async (
    productItemId,
    orderObjId,
    attributesId,
    orderNumber,
    customerID
  ) => {
    let cancelRefundStatus = {
      productAttributeId: attributesId,
      productId: productItemId,
      orderId: orderObjId,
      orderNumber: orderNumber,
      customerId: customerID,
    };

    const { data } = await axios.post(
      `${baseUrl}/auth/payupayment/checkRefundStatusDetail`,
      cancelRefundStatus
    );

    setRefundStatus(data);
    setRefundStatusPopUp(true);
    // console.log("refund pop", refundStatusPopUp);
  };

  /* function to handle order cancel */
  const handleOrderChangePopUp = (
    productItemId,
    orderObj,
    orderNumber,
    deliveryDate
  ) => {
    let changeData = {
      productItemId: productItemId,
      orderObj: orderObj,
      orderNumber: orderNumber,
      deliveryDate: deliveryDate,
    };
    setChangeData(changeData);
    setChangeOrderPopUp(true);
  };
 const handleAllProductCancel = (productsArrayOfOrder)=>{
    try {
        if (!productsArrayOfOrder || !Array.isArray(productsArrayOfOrder)) {
            throw new Error('handleAllProductCancel: Invalid data format. Expected data to contain an array of products.');
        }
        const orderStatusArray = productsArrayOfOrder.map(product => product.orderStatus === 'CANCELLED');
        return orderStatusArray.every(status => status);
    } catch (error) {
        console.error('handleAllProductCancel: Error occurred while checking order status:', error.message);
        return false; 
    }
 }
  const createProductsList = (productsObj, cancelReason, cancelComments) => {
    let productAttributesList = [];
    let productUserDetail;

    let attributesList = productsObj?.attributes;
    if (Array.isArray(attributesList) && attributesList.length > 0) {
      attributesList.forEach((ele) => {
        var attributes = {
          productAttributeId: ele.id,
          orderQuantity: productsObj.orderedQuantity,
          orderStatus: "CANCEL",
        };
        productAttributesList.push(attributes);
      });
    }

    if (productsObj.product?.productUserDetail != null) {
      productUserDetail = {
        userId: productsObj.product.productUserDetail?.userId,
        userName: productsObj.product.productUserDetail?.userName,
        emailAddress: productsObj.product.productUserDetail?.emailAddress,
        userType: productsObj.product.productUserDetail?.userType,
        mid: productsObj.product.productUserDetail?.mid,
      };
    }

    var product = {
      product: {
        orderProductId: productsObj.id,
        cancelComment: cancelComments,
        cancelReason: cancelReason,
        productId: productsObj.product?.id,
        productAttribute: productAttributesList,
        productUserDetail: productUserDetail,
      },
    };
    return [product];
  };




  useEffect(() => {
    if (width < 450) {
      setOrientation("vertical");
    } else {
      setOrientation("horizontal");
    }
  }, [width]);

  useEffect(() => {
    dispatch(getCustomerOrders(user.id))
     
    
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */
  useEffect(() => {
    if (cancelClicked == true) {
      dispatch(getCustomerOrders(user.id))
    }
     
    
  }, [cancelClicked]); /* eslint-disable-line react-hooks/exhaustive-deps */

  // comments is added in order status seller
  const handlefunction = (item) => {
    const commentsString = item.comments || "";
    const wordsArray = commentsString.split(" ");
    const firstThreeWords = wordsArray.slice(0, 3).join(" ");
    // console.log(firstThreeWords);
    if (firstThreeWords.toLowerCase() === "seller has cancelled") {
      return <>BY SELLER</>;
    } else {
      return null;
    }
  };
  return (
    <>
      {loading ? (
        <Spinner />
      ) : orderError ? (
        <div className={classes.border}>
          <Typography className={classes.noOrders}>{orderError}</Typography>
        </div>
      ) : customerOrders.orders && customerOrders.orders.length > 0 ? (
        customerOrders.orders && ( 
          <>
            <div className={classes.ordersroot}>
              {Object.keys(customerOrders).length > 0 &&
              customerOrders.orders.length > 0 ? (
                customerOrders.orders
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  .map((order) => (
                    <Paper className={classes.paper} key={order.id}>
                      {order.products.map((item, index) => (
                        <Fragment key={index}>
                          <Grid item>
                            <div className={classes.ordersheader}>
                              <div className={classes.orderDetails}>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className={classes.orderId}
                                >
                                  ORDER NO : # {item.orderId}
                                </Typography>

                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className={classes.orderId}
                                >
                                  SHIP TO : {order.delivery.firstName}
                                </Typography>
                              </div>

                              <div>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className={classes.orderId}
                                >
                                  Order date -{" "}
                                  {new Date(
                                    order.datePurchased
                                  ).toLocaleDateString("en-GB")}
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            key={index}
                            className={classes.productgrid}
                          >
                            {!item.product && (
                              <div className={classes.ordersHidden}>
                                Product is Not Available
                              </div>
                            )}
                            <Grid item style={{ margin: "auto" }}>
                              <div className={classes.grid}>
                                {order.products[0].image ? (
                                  <img
                                    className={classes.img}
                                    alt="complex"
                                    src={`${imageBaseUrl}${item.image}`}
                                  />
                                ) : (
                                  <img
                                    className={classes.img}
                                    alt="complex"
                                    src={sampleImage}
                                  />
                                )}
                              </div>
                            </Grid>

                            <Grid item xs={12} sm container>
                              <Grid item xs={12} sm={6}>
                                <div className={classes.grid}>
                                  <Typography className={classes.productname}>
                                    {item.productName}
                                  </Typography>

                                  <Typography className={classes.label}>
                                    {item.orderedQuantity} Items
                                  </Typography>

                                  <Typography className={classes.label}>
                                    Total Price for Product including GST:{" "}
                                    <span className={classes.span}>
                                      Rs.{item.orderPriceSummary.taxTotal}
                                    </span>
                                  </Typography>

                                  <Typography className={classes.label}>
                                    Status:{" "}
                                    <span className={classes.span}>
                                      {item.orderStatus} {handlefunction(item)}
                                    </span>
                                  </Typography>

                                  <Typography className={classes.label}>
                                    Order Type:{" "}
                                    <span className={classes.span}>
                                      {item.bargainedOrder === "NO"
                                        ? "Fixed"
                                        : "Bargained"}
                                    </span>
                                  </Typography>
                                  <Typography className={classes.label}>
                                    Delivery Date:{" "}
                                    <span className={classes.span}>
                                      {new Date(item.deliveredDate).getDate()}-
                                      {new Date(item.deliveredDate).getMonth() +
                                        1}
                                      -
                                      {new Date(
                                        item.deliveredDate
                                      ).getFullYear()}
                                    </span>
                                  </Typography>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    className={classes.buybutton}
                                    style={{
                                      height: "25px",
                                      fontSize: "11px",
                                      fontFamily: "'Fira Sans', sans-serif",
                                    }}
                                    onClick={() =>
                                      history.push(
                                        `/product/${
                                          item.product.categories[0].code
                                        }/${item.productName.replace(
                                          / /g,
                                          "-"
                                        )}/${item.product.id}`
                                      )
                                    }
                                  >
                                    Buy it again
                                  </Button>
                                </div>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <div className={classes.grid}>
                                  {(item.orderStatus === "ORDERED" ||
                                    item.orderStatus === "ACCEPTED") && (
                                    <div style={{ textAlign: "center" }}>
                                      <Button
                                        variant="contained"
                                        size="small"
                                        className={classes.trackbutton}
                                        style={{
                                          height: "28px",
                                          fontSize: 12,
                                          fontFamily: "'Fira Sans', sans-serif",
                                          textTransform: "none",
                                        }}
                                        onClick={() =>
                                          handleOrderCancelPopUp(
                                            item.id,
                                            order,
                                            item.orderNumber
                                          )
                                        }
                                      >
                                        Cancel Order
                                      </Button>
                                    </div>
                                  )}

                                  <div style={{ textAlign: "center" }}>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      className={classes.trackbutton}
                                      onClick={(e) =>
                                        handleWriteReview(item.orderId)
                                      }
                                      style={{
                                        height: "28px",
                                        fontSize: 12,
                                        fontFamily: "'Fira Sans', sans-serif",
                                        textTransform: "none",
                                      }}
                                    >
                                      Write a review
                                    </Button>
                                    {item.orderStatus === "CANCELLED" && (
                                      <div style={{ textAlign: "center" }}>
                                        <Button
                                          variant="contained"
                                          size="small"
                                          className={classes.trackbutton}
                                          style={{
                                            height: "28px",
                                            fontSize: 12,
                                            fontFamily:
                                              "'Fira Sans', sans-serif",
                                            textTransform: "none",
                                          }}
                                          onClick={() =>
                                            handleRefundStatusDelPopUp(
                                              item.id,
                                              order.id,
                                              item.attributes[0].id,
                                              item.orderId,
                                              order.customer.id
                                            )
                                          }
                                        >
                                          Refund Status
                                        </Button>
                                      </div>
                                    )}
                                    {(item.orderStatus === "ORDERED" ||
                                      item.orderStatus === "ACCEPTED" ||
                                      item.orderStatus === "CANCELLED") && (
                                      <div style={{ textAlign: "center" }}>
                                        <Button
                                          variant="contained"
                                          size="small"
                                          className={classes.trackbutton}
                                          style={{
                                            height: "28px",
                                            fontSize: 12,
                                            fontFamily:
                                              "'Fira Sans', sans-serif",
                                            textTransform: "none",
                                          }}
                                          onClick={() =>
                                            handleOrderChangePopUp(
                                              item.id,
                                              order,
                                              item.orderNumber,
                                              item.deliveredDate
                                            )
                                          }
                                        >
                                          Change Delivery Address
                                        </Button>
                                      </div>
                                    )}
                                  </div>

                                  <div style={{ textAlign: "center" }}>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="primary"
                                      onClick={(e) =>
                                        handleClickOpenPaymentInfo(item)
                                      }
                                      style={{
                                        height: "28px",
                                        fontSize: 12,
                                        fontFamily: "'Fira Sans', sans-serif",
                                        textTransform: "none",
                                        margin: 10,
                                      }}
                                    >
                                      View Breakup
                                    </Button>

                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="primary"
                                      onClick={(e) =>
                                        handleViewOrderDetails(
                                          item,
                                          order.billing,
                                          order.delivery
                                        )
                                      }
                                      style={{
                                        height: "28px",
                                        fontSize: 12,
                                        fontFamily: "'Fira Sans', sans-serif",
                                        textTransform: "none",
                                      }}
                                    >
                                      View Details
                                    </Button>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <div
                              style={{
                                borderTop: "solid 1px #53e7bc",
                                borderBottom: "solid 1px #53e7bc",
                                width: "100%",
                              }}
                            >
                              {item.orderStatus !== "CANCELLED" ? (
                                <Accordion
                                  expanded={expanded === item.orderId}
                                  onChange={handleChange(
                                    item.orderId,
                                    item.lorNumber
                                  )}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                  >
                                    <Typography className={classes.heading}>
                                      Track your Order
                                    </Typography>
                                  </AccordionSummary>

                                  <AccordionDetails>
                                    <Stepper
                                      alternativeLabel
                                      activeStep={activeStep}
                                      style={{ width: "100%" }}
                                      orientation={orientation}
                                    >
                                      {loadingTrack ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyItems: "center",
                                            width: "100%",
                                          }}
                                        >
                                          <SmallSpinner />
                                        </div>
                                      ) : (
                                        steps?.length > 0 &&
                                        steps.map((label, index) => (
                                          <Step key={index}>
                                            <StepLabel>{label.value}</StepLabel>
                                          </Step>
                                        ))
                                      )}
                                    </Stepper>
                                  </AccordionDetails>
                                </Accordion>
                              ) : (
                                <Typography
                                  variant="subtitle2"
                                  style={{ padding: 18 }}
                                >
                                  {isStringNullorEmpty(item.cancelReason)
                                    ? ""
                                    : item.cancelReason}
                                </Typography>
                              )}
                            </div>
                          </Grid>
                        </Fragment>
                      ))}
                      { !handleAllProductCancel(order?.products) && (
                        <CustomerPayment payDetails={order} />
                      )}
                      {/* {order.totalOrderStatus === "CANCELLED" && 
                        order.products.some(
                         (item) => item.orderStatus === "ACCEPTED"
                     ) && <CustomerPayment payDetails={order} />}  */}
                    </Paper>
                  ))
              ) : (
                <Typography className={classes.noOrders}>
                  Orders not found.
                </Typography>
              )}
            </div>

            {Object.keys(customerOrders).length > 0 &&
              customerOrders.orders.length > 0 &&
              customerOrders.orders.length > itemsPerPage && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paginator
                      page={page}
                      handleChangePage={handleChangePage}
                      count={Math.ceil(
                        customerOrders.orders.length / itemsPerPage
                      )}
                    />
                  </Grid>
                </Grid>
              )}

            <ViewBreakUpDialog
              open={open}
              handleClose={handleClose}
              order={order}
            />

            <ViewOrderDetails
              open={viewDetails}
              handleClose={handleCloseViewOrderDetails}
              order={orderDetail}
            />

            <CancelReason
              open={cancelOrderPopUp}
              handleClose={() => setCancelOrderPopUp(false)}
              submitCancelReason={submitCancelReason}
              cancelData={cancelData}
            />

            <ChangeDeliveryList
              open={changeOrderPopUp}
              handleClose={() => setChangeOrderPopUp(false)}
              submitChangeDeliverAddress={submitChangeDeliverAddress}
              changeData={changeData}
            />
            <RefundStatus
              open={refundStatusPopUp}
              handleClose={() => setRefundStatusPopUp(false)}
              refundStatus={refundStatus}
            />
          </>
        )
      ) : (
        <div className={classes.border}>
          <Typography
            className={classes.noOrders}
            style={{
              marginTop: "80px",
              padding: "10px",
              borderStyle: "groove",
            }}
          >
            No orders found
          </Typography>
        </div>
      )}
    </>
  );
};
