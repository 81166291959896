import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
	paginatorRoot: {
		"& .MuiButtonBase-root": {
			outline: "none",
		},
	},
	paginator: {
		justifyContent: "flex-end",
		padding: "10px",
	},
}));

export const Paginator = (props) => {
	const classes = useStyles();
	const { page, count, handleChangePage } = props;

	return (
		<div className={classes.paginatorRoot}>
			<Pagination
				count={count}
				variant="outlined"
				shape="rounded"
				page={page}
				onChange={handleChangePage}
				classes={{ ul: classes.paginator }}
			/>
		</div>
	);
};
