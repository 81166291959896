import {ActionTypes} from "../constants/actionTypes";

const initialState = {
    paymentLoader: false,
    error: null,
    errorMsg : null
};


export default function (state = initialState, action) {
    switch (action.type) {

        case ActionTypes.AFTER_PAYMENT_LOADING :
            return {
              ...state,
              paymentLoader: true,
              error: null,
              errorMsg : null
            };
        case ActionTypes.CLEAR_PAYMENT_LOAD :
            return {
              ...state,
              paymentLoader: false,
              error: null,
              errorMsg : null
            };
        case ActionTypes.PAYMENT_ERROR_MSG :
            return {
              ...state,
              paymentLoader: false,
              error: null,
              errorMsg : action.payload
            };
        default:
            return state;
    }
}
