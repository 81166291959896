import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

//redux actions
import {
  clearAllMessageState,
  sellerLogin,
} from "../../redux/actions/authActions";

//react component
import { OtpLogin } from "../../components/seller/auth/OtpLogin";
import { AccountStatus } from "../../components/seller/AccountStatus";

// material ui
import { makeStyles } from "@material-ui/core/styles";
// import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Box, Button, IconButton, InputAdornment, TextField } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import InfoIcon from "@material-ui/icons/Info";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { ActionTypes } from "../../redux/constants/actionTypes";

const useStyles = makeStyles((theme) => ({
  '@global': {
    ":root": { 
      backgroundImage: "url('https://storage.googleapis.com/stockxbid_gc_bng_acs/BANNER/Welcomehomepage/sell%2016-2-2023%201.jpg')",
      backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh'
    },
    "body" :{
      background: "none"
    }
},
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: 15,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#000000",
      textAlign: "initial",
      background: "#F7F7F7",
    },
  },
  loginPage: {

    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
  gridSection: {

    paddingTop: theme.spacing(10),

  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: "6px",
    width: "75%",
    height: "100%",
    margin: "auto",
  },
  signUpButton: {
    textTransform: "none",
    color: "#000000",
    background: "#53E7BC",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
    fontSize: "13px",
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem 1.25rem",
  },
  welcomeMsg: {
    fontWeight: 600,
    color: "#043c53",
    marginTop: theme.spacing(9)
  },
  gridContent: {
    fontWeight: 600,
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1),
  },
  captionMsg: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#000",
  },
  loginLable: {
    fontWeight: 600,
    color: "#000000s",
    margin: "auto",
    paddingBottom: "10px",
  },
  submitButton: {
    textTransform: "none",
    color: "#000000",
    background: "#53E7BC",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
    fontSize: "13px",
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem 1.25rem",
    width: "75%",
    margin: "10px 0px 10px",
  },
  forgotPassword: {
    fontWeight: 500,
    color: "#000000",
    "&:hover": {
      textDecoration: "underline",
      color: "#53E7BC",
    },
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
    color: "#000000",
  },
  selected: {
    fontSize: 11,
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#000000",
  },
  list: {
    "& .MuiListItemIcon-root": {
      minWidth: "32px",
    },
  },
  or_label: {
    fontSize: 12,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#212121",
  },
}));

export const SellerLogin = (props) => {
  const classes = useStyles();
  const { loading, error, serverError } = useSelector((state) => state.UI);

  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedIndex, setSelectedIndex] = useState(1);

  const [mobileLogin, setMobileLogin] = useState(false);

  // login form
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    dispatch(clearAllMessageState());
  };

  const inputValidation = () => {
    let errors = {};
    let inputsValid = true;

    if (email === "") {
      inputsValid = false;
      errors["email"] = "Please Enter Your Email";
    }

    if (password === "") {
      inputsValid = false;
      errors["password"] = "Please Enter Your Password";
    }

    setErrors(errors);
    return inputsValid;
  };

  const handleSellerLogin = (event) => {
    event.preventDefault();

    if (inputValidation()) {
      const sellerData = {
        username: email,
        password: password,
      };

      dispatch(sellerLogin(sellerData, history));
    }
  };


  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    dispatch({ type: ActionTypes.CLEAR_ERRORS });
    return () => {
      dispatch(clearAllMessageState());
      
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className={classes.loginPage}>
      {/* <CssBaseline /> */}

      <Container component="main" maxWidth="xs">
        <Box
          style={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img
            alt="logo"
            src={"https://storage.googleapis.com/stockxbid_gc_bng_misc/logo_beta_v/new%20logo%201%20png.svg"}
            style={{
              maxHeight: "60px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/")}
          />
        </Box>
      </Container>

      {/* <Grid item xs={12} align="center">
        <Link
          to="/"
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginTop: 10,
          }}
        >
          <img
            alt="logo"
            src={process.env.PUBLIC_URL + "/images/Outxtock-Logo-01.svg"}
            style={{ width: "100%", height: "100%", maxHeight: "100px" }}
          />
        </Link>
      </Grid> */}

      <Grid container spacing={5} className={classes.gridSection}>
        <Grid item lg={6} sm={4} xs={12} align="center">
          <Typography variant="h4" className={classes.welcomeMsg}>
            Welcome to OutXtock
          </Typography>

          <Typography
            variant="caption"
            component="p"
            className={classes.captionMsg}
          >
            Good to see you again...!
          </Typography>

          <Typography
            variant="subtitle1"
            component="p"
            className={classes.gridContent}
          >
            Don't have an account?
          </Typography>

          <Link to="/seller-signup" style={{ textDecoration: "none" }}>
            <Button variant="contained" className={classes.signUpButton}>
              Register
            </Button>
          </Link>
        </Grid>

        <Grid item lg={6} sm={8} xs={12}>
          <Typography variant="h5" className={classes.loginLable}>
            Sign In
          </Typography>

          <Grid container spacing={1} component={Paper} elevation={6}>
            {selectedIndex === 1 ? (
              <Grid item lg={8} md={8} sm={6} xs={12} className={classes.paper}>
                {!mobileLogin ? (
                  <Fragment>
                    <form onSubmit={handleSellerLogin} className={classes.root}>
                      <TextField
                        id="email"
                        name="email"
                        label="Your Email"
                        variant="outlined"
                        size="small"
                        value={email}
                        autoFocus
                        onChange={(e) => setEmail(e.target.value.trim())}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                          },
                        }}
                        inputProps={{
                          autoComplete: "off",
                        }}
                        helperText={errors.email ? errors.email : ""}
                      />

                      <TextField
                        id="password"
                        name="password"
                        label="Enter Password"
                       
                        variant="outlined"
                        size="small"
                        value={password}
                        type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                        onChange={(e) => setPassword(e.target.value)}
                        InputLabelProps={{
                          classes: {
                            root: classes.labelRoot,
                          },
                        }}
                        InputProps={{

                          endAdornment: 
                            <InputAdornment position="end">
                              <IconButton 
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          
                        }}
                        helperText={errors.password ? errors.password : ""}
                      />

                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submitButton}
                        disabled={loading}
                        size="small"
                      >
                        Sign In
                      </Button>
                    </form>

                    <Link
                      to={{
                        pathname: "/forgot-password",
                        state: { prevPath: props.location.pathname },
                      }}
                    >
                      <Typography
                        variant="caption"
                        className={classes.forgotPassword}
                      >
                        Forgot Password
                      </Typography>
                    </Link>
                  </Fragment>
                ) : (
                  <OtpLogin />
                )}

                <Typography component="p" className={classes.or_label}>
                  OR
                </Typography>

                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  size="small"
                  style={{ outline: "none" }}
                  onClick={() => {setMobileLogin(!mobileLogin)
                    dispatch(clearAllMessageState())}}
                >
                  {mobileLogin ? "Login with Email" : "Login with OTP"}
                </Button>

                {error && (
                  <Box
                    style={{
                      fontSize: "12px",
                      color: "red",
                    }}
                  >
                    {error}
                  </Box>
                )}
                {serverError && (
                  <Box
                    style={{
                      fontSize: "12px",
                      color: "red",
                    }}
                  >
                    {serverError}
                  </Box>
                )}
              </Grid>
            ) : (
              <AccountStatus />
            )}

            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={12}
              style={{ backgroundColor: "#53E7BC" }}
            >
              <List
                component="nav"
                aria-label="main mailbox folders"
                className={classes.list}
              >
                <ListItem
                  button
                  selected={selectedIndex === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                >
                  <ListItemIcon>
                    <AccountCircleIcon style={{ color: "#000000" }} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selected }}
                    primary="Login"
                  />
                </ListItem>
                <Divider />
                <ListItem
                  button
                  selected={selectedIndex === 2}
                  onClick={(event) => handleListItemClick(event, 2)}
                >
                  <ListItemIcon>
                    <InfoIcon style={{ color: "#000000" }} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selected }}
                    primary="Check Account Status"
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
