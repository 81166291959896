import { ActionTypes } from "../constants/actionTypes";
import axios from "axios";
import {getBargainUpdateRequestForDocId} from "../../util/componentUtil/CheckOutUtiils";
import {callTheElasticUpdate} from "./cartActions";
import {isArrayNotEmpty} from "../../util/commonUtil/commonUtil";

const baseUrl = process.env.REACT_APP_BASE_URL;
const elasticSearchUrl = process.env.REACT_APP_ELASTIC_SEARCH_URL;
const bargainurl = process.env.REACT_APP_BARGAIN_REQUEST;

export const getCustomerNotification = (user) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_REQUEST });
    const { data } = await axios.get(
      `${elasticSearchUrl}/api/pushNotification/${user.id}/${user.userType}`
    );
    dispatch({
      type: ActionTypes.SET_CUSTOMER_NOTIFICATION,
      payload: data.data.aggregations.group_by_data.buckets,
    });
    dispatch({ type: ActionTypes.CLEAR_CUSTOMER_ERRORS });
  } catch (error) {
    dispatch({
      type: ActionTypes.SET_CUSTOMER_ERROR,
      payload: "Something went wrong",
    });
  }
};

export const deleteNotification = (item) => async (dispatch) => {
  try {
    await axios.put(`${elasticSearchUrl}/api/changeActiveState`, {
      docId: item._id,
    });

    let elasticUpdate = getBargainUpdateRequestForDocId(item , "REMOVED");
    await callTheElasticUpdate(true , elasticUpdate);

    dispatch({ type: ActionTypes.DELETE_NOTIFICATION, payload: item._id });
  } catch (error) {
    // console.log(error);
  }
};

export const submitBargain = (bargainData) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${bargainurl}/bargainRequest`,
      bargainData
    );
    if (data.success) {
      dispatch({
        type: ActionTypes.SET_SNACKBAR,
        payload: { alert: "success", message: data.message },
      });
    }
  } catch (error) {
    if (error.response && error.response.data.success === false) {
      dispatch({
        type: ActionTypes.SET_SNACKBAR,
        payload: {
          alert: "error",
          message: error.response.data.message,
        },
      });
    } else {
      // console.log(error);
    }
  }
};

export const getCustomerOrders = (id) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_REQUEST });
    const { data } = await axios.get(`${baseUrl}/orders/customers/${id}`);
    dispatch({ type: ActionTypes.GET_CUSTOMER_ORDERS, payload: data });
    dispatch({ type: ActionTypes.CLEAR_CUSTOMER_ERRORS });
  } catch (error) {
    if (error.response.status === 500) {
      dispatch({ type: ActionTypes.GET_CUSTOMER_ORDERS, payload: {} });
    } else {
      dispatch({
        type: ActionTypes.SET_CUSTOMER_ERROR,
        payload: "Something went wrong",
      });
    }
  }
};

export const getCustomerReviews = (id) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_REQUEST });
    const { data } = await axios.get(
      `${baseUrl}/auth/products/reviewsRatings/${id}`
    );
    dispatch({ type: ActionTypes.GET_CUSTOMER_REVIEWS, payload: data });
    dispatch({ type: ActionTypes.CLEAR_CUSTOMER_ERRORS });
  } catch (error) {
    dispatch({
      type: ActionTypes.SET_ORDERS_ERROR,
      payload: "Something went wrong",
    });
  }
};

export const deleteReview = (reviewid, id) => async (dispatch) => {
  try {
    await axios.delete(`${baseUrl}/auth/products/${id}/reviews/${reviewid}`);
    dispatch({ type: ActionTypes.DELETE_CUSTOMER_REVIEW, payload: reviewid });
  } catch (error) {
    // console.log(error);
  }
};

export const submitProductReviews = (data, history) => async (dispatch) => {
  try {
    await axios.post(
      `${baseUrl}/auth/products/${data.productId}/reviews`,
      data
    );
    history.push(`/customer/portfolio`);
  } catch (error) {
    dispatch({
      type: ActionTypes.SET_SNACKBAR,
      payload: { alert: "error", message: error.response.data.message },
    });
  }
};

export const editReview = (reviewData) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      `${baseUrl}/auth/products/${reviewData.productId}/reviews/${reviewData.id}`,
      reviewData
    );
    dispatch({ type: ActionTypes.EDIT_CUSTOMER_REVIEW, payload: data });
  } catch (error) {
    // console.log(error);
  }
};

export const getSearchResults = (searchData) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_REQUEST });
    const sortby = searchData.sortby === "all" ? "id" : searchData.sortby;
    const { data } = await axios.get(
      `${baseUrl}/search/filter/products/?availability=${searchData.available}&bargain=${searchData.type}&brand=${searchData.brand}&categoryCode=${searchData.category}&query=${searchData.queryParam}&pageSize=${searchData.pageSize}&pageNumber=${searchData.pageNumber}&sortBy=${sortby}&gender=${searchData.gender}&size=${searchData.size}`
    );
    dispatch({ type: ActionTypes.CUSTOMER_SEARCH, payload: data });
  } catch (error) {
    // console.log(error);
  }
};

export const changePassword = (userPasswordChange) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_REQUEST });
    await axios.patch(`${baseUrl}/auth/change-password`, userPasswordChange);
    dispatch({ type: ActionTypes.CLEAR_CUSTOMER_ERRORS });
    dispatch({
      type: ActionTypes.SET_SNACKBAR,
      payload: {
        alert: "success",
        message: "Your password has been changed successfully!",
      },
    });
  } catch (error) {
    if (error.response.status === 404) {
      dispatch({
        type: ActionTypes.SET_CUSTOMER_ERROR,
        payload: error.response.data,
      });
    } else {
      // console.log(error);
    }
  }
};

/* update customer delivery address */
export const updateCustomerDeliveryAddress =
  (addressData) => async (dispatch) => {
    try {
      const { data } = await axios.patch(
        `${baseUrl}/auth/customer/address/delivery`,
        addressData
      );
      dispatch({
        type: ActionTypes.UPDATE_USER,
        payload: data,
      });
    } catch (error) {
      // console.log(error);
    }
  };

/* update customer billing address */
export const updateCustomerBillingAddress =
  (addressData) => async (dispatch) => {
    try {
      const { data } = await axios.patch(
        `${baseUrl}/auth/customer/address/billing`,
        addressData
      );
      dispatch({
        type: ActionTypes.UPDATE_USER,
        payload: data,
      });
    } catch (error) {
      // console.log(error);
    }
  };

  export const changeAddressOrder =
  (addressChangeData) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/deferredDeliveryShippingOrderForGivenAwbNumber`,
        addressChangeData
      );
      dispatch({
        type: ActionTypes.UPDATE_USER,
        payload: data,
      });
    } catch (error) {
      // console.log(error);
    }
  };

export const saveRecentSerachQueries = (queryParam) => (dispatch) => {
  dispatch({
    type: ActionTypes.SAVE_CUSTOMER_SEARCH_QURIES,
    payload: queryParam,
  });
};

export const alertSnackBar = (alert, message) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SNACKBAR,
    payload: { alert: alert, message: message },
  });
};

export const cancelOrder = (readableOrderList, productItemId , customerOrders , orderNumber ,orderId, id) => async (dispatch) => {
  try {
    dispatch({type : ActionTypes.LOADING_REQUEST});
     let response = await axios.post(
      `${baseUrl}/auth/orders/cancelOrders`,
      readableOrderList
    );
 
    let cancelResponse ;
    if(isArrayNotEmpty(response.data.order)) {
          let products = response.data.order[0].products;
          if(isArrayNotEmpty(products)) {
                if(products[0].product != null &&  isArrayNotEmpty(products[0].product.productAttribute)) {
                  cancelResponse = products[0].product.productAttribute[0].cancelResponse;
                }
          }
    }

    customerOrders.orders.forEach(order => {
      if(order.id === orderId) {
        order.products.forEach(prod => {
          if(prod.id === productItemId &&  prod.orderNumber === orderNumber) {
            prod.orderStatus="CANCELLED";
            prod.cancelReason = cancelResponse || "";
          }
        })
        
      }
    });

      dispatch({
        type: ActionTypes.CANCEL_CUSTOMER_ORDER,
        payload: { customerOrders : customerOrders }
      });

      dispatch({ type: ActionTypes.CLEAR_CUSTOMER_ERRORS });
 
  } catch (error) {
    // console.log("error in action class");
    // console.log(error);
    dispatch({ type: ActionTypes.SET_CUSTOMER_ERROR });

  }
  
};

