import React, { useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Paper, capitalize } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import Spinner from "../../../util/spinner/spinner";
import { getCategoryList, getCategoryProductCount } from "../../../redux/actions/sellerActions";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiTableCell-head": {
      backgroundColor: "#d5f9ef",
      color: "#3c3c3c",
      fontFamily: "'Fira Sans', sans-serif",
      fontSize: 13,
      fontWeight: "bold",
      letterSpacing: 1,
    },
    "& .MuiTableCell-body": {
      fontSize: 13,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#2d2b2b",
      fontWeight: 500,
    },
  },
}));

export const CategoryTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading, serverError, error, productCountList} = useSelector(
    (state) => state.seller
  );

  useEffect(() => {
    dispatch(getCategoryProductCount());
    dispatch(getCategoryList());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : serverError ? (
        <p>{serverError}</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width : '33%'}} align="center">S.No</TableCell>
                  <TableCell style={{ width : '34%'}} align="center">Category Name</TableCell>
                  <TableCell style={{ width : '33%'}} align="center">Number of Products</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {productCountList ? (
                  productCountList.length > 0 ? (
                    productCountList.map((category, index) => (
                      <StyledTableRow key={index}>
                        <TableCell style={{ width : '33%'}} align="center" component="th" scope="row">
                          {category.categoryId}
                        </TableCell>
                        <TableCell style={{ width : '33%'}} align="center" >{capitalize(category.categoryCode)}</TableCell>
                        <TableCell style={{ width : '33%'}} align="center" >
                          {category.count}
                        </TableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <TableRow key={1}>
                      <TableCell component="th" scope="row">
                        Category not found.
                      </TableCell>
                    </TableRow>
                  )
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};
