import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  brand: {
    color: "#27B23E",
  },
  address: {
    color: "#04AA6D",
    marginTop: theme.spacing(1),
  },
}));

const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

export const ViewOrderDetails = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { open, handleClose, order } = props;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={classes.dialog}
    >
      {Object.keys(order.details).length > 0 && (
        <DialogTitle
          id="responsive-dialog-title"
          style={{ textAlign: "center" }}
        >
          {`Order Details (${order.details.orderId})`}
        </DialogTitle>
      )}
      <DialogContent style={{ textAlign: "center" }}>
        {Object.keys(order.details).length > 0 && (
          <>
            <img
              alt="complex"
              src={`${imageBaseUrl}${order.details.image}`}
              width="200px"
              height="200px"
            />

            <Typography variant="subtitle2" className={classes.brand}>
              {order.details.product.brandName}
            </Typography>

            <Typography variant="h6" className={classes.title}>
              {order.details.productName}
            </Typography>

            <Typography variant="caption">
              {order.details.orderStatus}
            </Typography>
          </>
        )}

        {Object.keys(order.delivery).length > 0 && (
          <div className={classes.address}>
            <Typography variant="subtitle2">Delivery Address</Typography>

            <Typography variant="caption">
              {order.delivery.firstName} {order.delivery.lastName},{" "}
              {order.delivery.address}, {order.delivery.city},{" "}
              {order.delivery.postalCode}, ph-{order.delivery.phone},
            </Typography>
          </div>
        )}

        {Object.keys(order.billing).length > 0 && (
          <div className={classes.address}>
            <Typography variant="subtitle2">Billing Address</Typography>

            <Typography variant="caption">
              {order.billing.firstName} {order.billing.lastName},{" "}
              {order.billing.address}, {order.billing.city},{" "}
              {order.billing.postalCode}, ph-{order.billing.phone},
            </Typography>
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
