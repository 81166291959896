import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  notfoundBlock: {
    position: "relative",
    height: "100vh",
    "& > $notfound": {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      WebkitTransform: "translate(-50%, -50%)",
      msTransform: "translate(-50%, -50%)",
    },
  },
  notfound: {
    maxWidth: 520,
    width: "100%",
    lineHeight: 1.4,
    textAlign: "center",
    "& > $notfound_404": {
      position: "relative",
      margin: "0px auto 80px",
      height: 50,
      zIndex: -1,
    },
    "& > $button": {
      fontFamily: "'Fira Sans', sans-serif",
      fontSize: 18,
      fontWeight: 700,
      color: "#fff",
      textTransform: "uppercase",
      padding: "13px 23px",
      background: "#ff6300",
      transition: "0.2s all",
      WebkitTransition: "0.2s all",
      "&:hover": {
        background: "#ff6300",
      },
    },
    "& h6": {
      fontWeight: 600,
      fontFamily: "'Fira Sans', sans-serif",
      fontSize: 16,
    },
  },
  notfound_404: {
    "& h1": {
      fontFamily: "'Fira Sans', sans-serif",
      fontSize: 236,
      fontWeight: 200,
      margin: 0,
      color: "#211b19",
      textTransform: "uppercase",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      WebkitTransform: "translate(-50%, -50%)",
      msTransform: "translate(-50%, -50%)",
      [theme.breakpoints.between("sm")]: {
        fontSize: 148,
        color: "blue",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 90,
      },
    },
    "& h2": {
      fontFamily: "'Fira Sans', sans-serif",
      fontSize: 28,
      fontWeight: 400,
      textTransform: "uppercase",
      color: "#211b19",
      background: "#fff",
      padding: "10px 5px",
      margin: "auto",
      display: "inline-block",
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },
  },
}));

export const ServerError = () => {
  const classes = useStyles();
  return (
    <div className={classes.notfoundBlock}>
      <div className={classes.notfound}>
        <div className={classes.notfound_404}>
          <Typography variant="h1">Oops!</Typography>
          <Typography variant="h2">500 - Internal server error</Typography>
        </div>

        <Typography variant="h6">
          We are currently trying to fix the problem.
        </Typography>

        <Button
          variant="contained"
          className={classes.button}
          onClick={() => window.location.reload()}
        >
          Let me try agian!
        </Button>
      </div>
    </div>
  );
};
