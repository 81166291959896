import React, { useState, Fragment } from "react";
import axios from "axios";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  uploadSection: {
    padding: theme.spacing(2),
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#202C3B",
      textAlign: "initial",
      background: "#F7F7F7",
    },
    "& em": {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#202C3B",
    },
    "& .MuiListItemText-root": {
      overflow: "hidden",
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    textAlign: "center",
  },
  uploadIcon: {
    color: "#4caf50",
  },
  uploadbutton: {
    color: "#fff",
    fontFamily: "'Fira Sans', sans-serif",
    backgroundColor: "#4caf50",
    textTransform: "capitalize",
    border: "none",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#4caf50",
    },
  },
  input: {
    display: "none",
  },
  error: {
    fontSize: 12,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#FF0000",
  },
  img: {
    margin: "12px 12px 8px 0px",
    width: 100,
    height: 100,
    border: "1px solid #d8d8d8",
    borderRadius: "3px",
    padding: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  urlpaper: {
    padding: theme.spacing(2),
    margin: "auto",
    textAlign: "center",
    marginTop: 10,
    maxHeight: "500px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  validationHeading: {
    fontSize: 24,
    fontWeight: "bold",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#53e7bc",
    textDecoration: "underline",
  },
  listroot: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    "& .MuiListItemText-secondary": {
      fontSize: 13,
      fontWeight: 500,
      fontFamily: "'Fira Sans', sans-serif",
      textAlign: "center",
      margin: "auto",
    },
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "aliceblue",
    padding: 0,
    margin: 6,
  },
}));

const baseUrl = process.env.REACT_APP_BASE_URL;
const imgBaseUrl = process.env.REACT_APP_IMAGE_URL;

export const UploadProductImage = () => {
  const classes = useStyles();

  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [url, setUrl] = useState([]);
  const [open, setOpen] = useState(false);
  const [copy, setCopy] = useState(false);
  const [allImgSize, setAllImgSize] = useState(0);
  const [formErrors, setFormErrors] = useState("");

  /* function to upload images */
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
  
    // Check if the file extension is either jpg or png
    if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png') {
      // Display an error message or handle the error as per your requirements
      alert('Please upload only JPEG, JPG or PNG images.');
      return;
    } else{
    setImages(e.target.files);
    let imgObject = [];
    let imgArray = [];
    imgObject.push(e.target.files);
    for (let i = 0; i < imgObject[0].length; i++) {
      setAllImgSize((prev) => prev + imgObject[0][i].size);
      imgArray.push(URL.createObjectURL(imgObject[0][i]));
    }
    
    setPreviews(imgArray);
  }
  };

  /* remove uploaded image */
  const handleRemoveImage = (e) => {
    e.preventDefault();
    setFormErrors("")
    setImages([]);
    setAllImgSize(0)
    setPreviews([]);
  };

  /* function to upload image to generate url */
  const handleGenerateUrl = async () => {
    try {
      
      if (allImgSize >= 3145728) {
        setFormErrors("Please upload the Images less then 3MB");
      } else {
        setOpen(true);
        const formData = new FormData();
        for (const key of Object.keys(images)) {
          formData.append("file[]", images[key]);
        }

        const { data } = await axios.post(
          `${baseUrl}/private/bulkupload/images`,
          formData
        );
        setUrl(data);
        setOpen(false);
        setImages([]);
        setPreviews([]);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  /* copy to clipboard  */
  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
    setCopy(true);
  };

  return (
    <div className={classes.uploadSection}>
      <Grid container>
        {url?.length > 0 ? (
          <Grid item xs={12}>
            <Paper className={classes.urlpaper} variant="outlined" square>
              <Typography className={classes.validationHeading}>
                Image Urls
              </Typography>

              <List className={classes.listroot} subheader={<li />}>
                {url.map((item, index) => (
                  <li key={index} className={classes.listSection}>
                    <ul className={classes.ul}>
                      <ListItem>
                        <ListItemAvatar>
                          <img
                            src={`${imgBaseUrl}/static/PRODUCT/${
                              item.securedImageUrl.split("/")[7]
                            }/IMAGE/${item.securedImageUrl.split("/")[10]}`}
                            alt={item.imageName}
                            width="100px"
                            height="100px"
                            style={{
                              border: "1px solid #53e7bc",
                              borderRadius: "50%",
                              marginRight: 10,
                            }}
                            loading="lazy"
                          />
                        </ListItemAvatar>
                        <ListItemText secondary={item.securedImageUrl} />
                        <ListItemSecondaryAction>
                          <Tooltip
                            title={copy ? "copied" : "copy"}
                            placement="right-start"
                            arrow
                          >
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() =>
                                copyToClipboard(item.securedImageUrl)
                              }
                              onMouseLeave={() => setCopy(false)}
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </ul>
                  </li>
                ))}
              </List>
            </Paper>
          </Grid>
        ) : (
          <>
            {previews?.length > 0 && (
              <Grid item xs={12}>
                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.removeimage}
                    onClick={handleRemoveImage}
                  >
                    Remove Image
                  </Button>
                  <div>
                    {(previews || []).map((url, index) => (
                      <Fragment key={index}>
                        <img src={url} alt="..." className={classes.img} />
                      </Fragment>
                    ))}
                  </div>
                </div>
              </Grid>
            )}

            <Grid item xs={12}>
              <Paper className={classes.paper} variant="outlined" square>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <CloudUploadIcon className={classes.uploadIcon} />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      style={{ margin: 8, fontWeight: 600 }}
                    >
                      Upload a images to generate urls.
                    </Typography>
                  </Grid>

                  {images?.length > 0 ? (
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGenerateUrl}
                      >
                        Generate url
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        multiple
                        key={Date.now()}
                        onChange={handleFileUpload}
                        disabled={images.length > 0}
                      />

                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<CloudUploadIcon />}
                          className={classes.uploadbutton}
                          disabled={images.length > 0}
                        >
                          Choose a Images to Upload...
                        </Button>
                      </label>
                    </Grid>
                  )}
                </Grid>
                <Grid>
                  {formErrors && (
                    <Typography style={{ color: "red" }}>
                      {formErrors}
                    </Typography>
                  )}
                </Grid>
              </Paper>
            </Grid>
          </>
        )}
      </Grid>

      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
