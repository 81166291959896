import { ActionTypes } from "../constants/actionTypes";

export const clearSnackbar = () => (dispatch) => {
  dispatch({ type: ActionTypes.SNACKBAR_CLEAR });
};

export const userActivationMessage = (alert, message) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SNACKBAR,
    payload: { alert: alert, message: message },
  });
};
