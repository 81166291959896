import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { Editor, EditorState, RichUtils, convertToRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html";

/* import react components */
// import { MyEditor } from "../editor/Editor";
import packageImage from "../../../assets/images/package.jpg";

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { IconButton, Button } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import {
  isStringNullorEmpty,
  isArrayNotEmpty,
  validatePositiveNumberEvent,
} from "../../../util/commonUtil/commonUtil";
import { useEffect } from "react";
import Axios from "axios";
import { alertSnackBar } from "../../../redux/actions/customerActions";
import { SnackBar } from "../../SnackBar";
import { useRef } from "react";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  dilougDiv: {
    background: "#F7F7F7",
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#202C3B",
      textAlign: "initial",
      background: "#F7F7F7",
    },
    "& em": {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#202C3B",
    },
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
    },
  },
  appBar: {
    position: "relative",
    background: "#53E7BC",
    marginBottom: "1rem",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "90%",
    margin: "auto",
  },
  headingSec: {
    padding: theme.spacing(2),
    position: "sticky",
    top: 0,
    color: "#4A4A4A",
    fontSize: "1.75rem",
    fontWeight: 500,
    fontFamily: "'Fira Sans', sans-serif",
  },
  sectionDiv: {
    padding: theme.spacing(2),
  },
  gridSection: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
      // marginTop: '20px',
      // background: '#F7F7F7',
    },
  },
  buttonGroup: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    textTransform: "none",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  cancelButton: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    textTransform: "none",
    "&:hover": {
      background: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  },
  sellingTitle: {
    textAlign: "initial",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#666666",
  },
  radioGroup: {
    "& .MuiTypography-body1": {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#666666",
    },
  },
  labelRoot: {
    fontSize: "12px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
    color: "#666666",
    opacity: "0.8",
  },
  uploadInput: {
    display: "none",
  },
  uploadImageButton: {
    textTransform: "none",
    fontFamily: "'Fira Sans', sans-serif",
    background: "#4AD0AA",
    margin: "20px",
    color: "#FFFFFF",
    fontWeight: 600,
    marginBottom: 0,
    "&:hover": {
      background: "#4AD0AA",
    },
  },
  bargainDiv: {
    display: "flex",
    border: "1px solid #d6d6d6",
    width: "86%",
    color: "#212529",
    borderRadius: "4px",
    padding: "6px",
    backgroundColor: "#f8f8f8",
    margin: "auto",
  },
  dateDiv: {
    display: "flex",
    border: "1px solid #d6d6d6",
    width: "80%",
    color: "#212529",
    borderRadius: "4px",
    padding: "6px",
    backgroundColor: "#f8f8f8",
    margin: "15px 0 15px 52px",
  },
  date: {
    border: "none",
    width: "100%",
    outline: "none",
    fontSize: 15,
    fontFamily: "'Fira Sans', sans-serif",
    opacity: 0.54,
    backgroundColor: "#f8f8f8",
  },
  inputErrorBlock: {
    fontSize: "12px",
    color: "red",
    width: "80%",
    margin: "5px 0px 0px -25px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
  },
  multipreview: {
    width: "79%",
    margin: "auto",
    border: "1px solid #F7F7F7",
  },
  img: {
    margin: "6px",
    width: "85px",
    height: "85px",
    border: "1px solid #d8d8d8",
    borderRadius: "3px",
    padding: theme.spacing(1),
  },
  removeimage: {
    background: "#53E7BC",
    color: "#FFFFFF",
    textTransform: "none",
    fontSize: "10px",
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem 1.25rem",
    margin: "10px",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  menuitem: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#495057",
    "& em": {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#495057",
    },
  },
  dropdownStyle: {
    marginTop: 48,
  },
  childBox: {
    marginLeft: 30,
    width: 150,
    minHeight: 240,
    border: "1px solid grey",
  },

  "@global": {
    ".multi-level-selector-container .multi-level-options-container": {
      zIndex: 5,
      textAlign: "start",
    },
    ".multi-level-selector-container .multi-level-options-container .options-main-menu .options-container:hover >.options-sub-menu-container":
      {
        width: "220px",
      },
    ".options-sub-menu-header ": {
      display: "none",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlPHANUMERIC_ONLY = /^[A-Za-z0-9_]+$/;
const NUMERICSPEL_ONLY = /^[ 0-9_@./#&+-]+$/;
const NUMERIC_ONLY = /^[0-9.]+$/;
const NUM_ONLY = /^[0-9]+$/;

export const AddproductForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const textRef = React.useRef();
  const { open, handleClose, handleAddProduct } = props;

  const { account } = useSelector((state) => state.auth);
  const { categorylist } = useSelector((state) => state.seller);

  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [categoryArray, setCategoryArray] = useState([]);
  // const [categoryArray, setCategoryArray] = useState("");
  const [category, setCategory] = useState([]);
  const [sku, setSku] = useState("");
  const [brand, setBrand] = useState("");
  const [idealFor, setIdealfor] = useState("");
  const [productGroup, setProductGroup] = useState("");
  const [hsn, setHsn] = useState("");
  const [countryOfOrigin, setCountyOfOrigin] = useState("India");
  const [season, setSeason] = useState("");
  const [seasonYear, setSeasonYear] = useState("");
  const [taxRate, setTaxRate] = useState(0);
  const [fashionGroup, setFashionGroup] = useState("");
  const [type, setType] = useState("");
  const [startBargain, setStartBargain] = useState("");
  const [endBargain, setEndBargain] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [quantity, setQuantity] = useState("");
  const [packagingType, setPackagingType] = useState("");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [sellfast, setSellfast] = useState("NO");
  const [buyable, setBuyAble] = useState(true);
  const [keyWords, setKeyWords] = useState("");
  const [hsnErr, setHsnErr] = useState("");
  const [skuErr, setSkuErr] = useState("");
  const [hsnVerified, setHsnVerified] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [selectedCategoryLabel, setSelectedCategoryLabel] = useState("");
  const proRef = useRef(null);
  const titRef = useRef(null);
  const skuRef = useRef(null);
  const braRef = useRef(null);
  const hsnRef = useRef(null);
  const qtyRef = useRef(null);
  const lengthRef = useRef(null);
  const widthRef = useRef(null);
  const heightRef = useRef(null);
  const weightRef = useRef(null);
  const datePickerRef = useRef(null);
  const idealRef = useRef(null);
  const fashionRef = useRef(null);
  const selectRef = useRef(null);
  const pkyRef = useRef(null);

  /* clear form after submit */
  const clearForm = () => {
    setName("");
    setTitle("");
    setCategoryArray([]);
    setCategory([]);
    setSku("");
    setBrand("");
    setIdealfor("");
    setProductGroup("");
    setHsn("");
    setSeason("");
    setSeasonYear("");
    setTaxRate("");
    setFashionGroup("");
    setType("");
    setStartBargain("");
    setEndBargain("");
    setFromDate("");
    setQuantity("");
    setPackagingType("");
    setWeight("");
    setLength("");
    setWidth("");
    setHeight("");
    setSellfast(false);
    setBuyAble(true);
    setKeyWords("");
    setHsnErr("");
    setHsnVerified(false);
    setSkuErr("");
  };

  /* basic form vaildation */
  const handleFormValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (isStringNullorEmpty(name)) {
      inputsValid = false;
      formErrors["name"] = "Product name is required";
    }

    // if (!AlPHANUMERIC_ONLY.test(name) || NUMERIC_ONLY.test(name)) {
    //   inputsValid = false;
    //   formErrors["name"] = "Please enter valid product name";
    // }
    if (isStringNullorEmpty(title)) {
      inputsValid = false;
      formErrors["title"] = "Product title is required";
    }

    // if (!AlPHANUMERIC_ONLY.test(title) || NUMERIC_ONLY.test(title)) {
    //   inputsValid = false;
    //   formErrors["title"] = "Please enter valid title";
    // }

    if (isStringNullorEmpty(categoryArray)) {
      inputsValid = false;
      formErrors["category"] = "Select the category";
    }
    if (categoryArray.length === 0) {
      inputsValid = false;
      formErrors["category"] = "Select the category";
    }

    if (isStringNullorEmpty(sku)) {
      inputsValid = false;
      formErrors["sku"] = "Parent SKU is required";
    }

    if (isStringNullorEmpty(brand)) {
      inputsValid = false;
      formErrors["brand"] = "Brand is required";
    }

    if (isStringNullorEmpty(idealFor)) {
      inputsValid = false;
      formErrors["idealFor"] = "This field is required";
    }

    if (isStringNullorEmpty(hsn)) {
      inputsValid = false;
      formErrors["hsn"] = "Product HSN required";
    }

    if (selectedCategoryLabel !== 'toys' && isStringNullorEmpty(fashionGroup)) {
      inputsValid = false;
      formErrors["fashionGroup"] = "Fashion group is required";
    }

    if (isStringNullorEmpty(type)) {
      inputsValid = false;
      formErrors["type"] = "This field is required";
    }

    if (type === "YES") {
      if (isStringNullorEmpty(startBargain)) {
        inputsValid = false;
        formErrors["startBargain"] = "This field is required";
      }

      if (isStringNullorEmpty(endBargain)) {
        inputsValid = false;
        formErrors["endBargain"] = "This field is required";
      }
    }

    if (fromDate === "null" || fromDate === "") {
      inputsValid = false;
      formErrors["fromDate"] = "This field is required";
    }

    if (!quantity || quantity <= 0) {
      inputsValid = false;
      formErrors["quantity"] = "This field is required";
    }

    if (isStringNullorEmpty(packagingType)) {
      inputsValid = false;
      formErrors["packagingType"] = "This field is required";
    }

    // if (!taxRate || taxRate <= 0) {
    //   inputsValid = false;
    //   formErrors["taxRate"] = "Tax Rate is required";
    // }

    if (!weight || weight <= 0) {
      inputsValid = false;
      formErrors["weight"] = "This field is required";
    }

    if (!length || length <= 0) {
      inputsValid = false;
      formErrors["length"] = "This field is required";
    }

    if (!width || width <= 0) {
      inputsValid = false;
      formErrors["width"] = "This field is required";
    }

    if (!height || height <= 0) {
      inputsValid = false;
      formErrors["height"] = "This field is required";
    }
    // console.log(formErrors);
    setFormErrors(formErrors);
    return inputsValid;
  };

  // console.log(JSON.stringify(account))

  /* function to handle form submitt */
  const handleProductUpload = (event) => {
    event.preventDefault();

    if (handleFormValidation()) {
      const productData = {
        price: 0,
        quantity: quantity,
        sku: sku,
        bargain: type,
        sellItFaster: sellfast,
        productLength: length,
        productWidth: width,
        productHeight: height,
        productWeight: weight,
        bargainEndDateTime: endBargain,
        bargainStartDateTime: startBargain,
        dateAvailable: fromDate,
        brandName: brand,
        buyable: buyable,
        collectionCategory: idealFor,
        countryOfOrigin: countryOfOrigin,
        fashionGroup: fashionGroup || 'abcd',
        hsn: hsn,
        packagingSpecifications: {
          dimensionUnitOfMeasure: "cm",
          height: height,
          id: 0,
          length: length,
          packagingType: packagingType,
          weight: weight,
          weightUnitOfMeasure: "g",
          width: width,
        },
        description: {
          language: "en",
          name: name,
          title: title,
          description: "",
          friendlyUrl: "",
          highlights: "",
          id: 0,
          keyWords: keyWords,
          metaDescription: "",
        },
        categories: category,
        productUserDetail: {
          id: 0,
          userId: account.id,
          userName: account.userName,
          firstName: account.firstName,
          lastName: account.lastName,
          emailAddress: account.emailAddress,
        },
        productGroup: productGroup,
        season: season,
        seasonYear: seasonYear || "2024",
        taxRate: taxRate || '0',
      };

      if (hsnVerified) {
        console.log('data', JSON.stringify(productData));
        handleAddProduct(productData);
        clearForm();
        handleClose();
      } else {
        textRef.current.focus();
        dispatch(alertSnackBar("error", "Product HSN code is Invalid."));
      }
    }

    const fields = [
      { label: "Product Name *", ref: proRef, value: name },
      { label: "Product Title *", ref: titRef, value: title },
      { label: "Parent SKU *", ref: skuRef, value: sku },
      { label: "Brand *", ref: braRef, value: brand },
      { label: "HSN Code *", ref: hsnRef, value: hsn },
      { label: "Total Quantity *", ref: qtyRef, value: quantity },
      { label: "Date of Availability*", ref: datePickerRef, value: fromDate },
      { label: "Product Length in cm *", ref: lengthRef, value: length },
      { label: "Product Width in cm *", ref: widthRef, value: width },
      { label: "Product Height in cm *", ref: heightRef, value: height },
      { label: "Package Weight in grams *", ref: weightRef, value: weight },
      { label: "Select Category", ref: idealRef, value: idealFor },
      { label: "Select Category", ref: selectRef, value: categoryArray.code },
      { label: "Fashion Group", ref: fashionRef, value: fashionGroup },
      {
        label: "demo-simple-select-outlined-label",
        ref: pkyRef,
        value: packagingType,
      },
      // Add other fields here
    ];

    for (const field of fields) {
      if (field.ref.current && field.ref.current.value === "") {
        field.ref.current.focus();
        return;
      }
    }
  };

  const handleCheckHsncode = async () => {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_HSN_VALIDATION}/ywc_cas_bng_gg_stxb_hsn_desc?hsn_code=${hsn}`
    );
    if (data.Status === "false") {
      setHsnVerified(false);
      setHsnErr("Product HSN code is Invalid");
    } else {
      setHsnErr("");
      setHsnVerified(true);
    }
  };
  const handleSku = async () => {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_BASE_URL}/private/product/unique?code=${sku}`
    );
    if (data.exists === false) {
      setSkuErr("");
    } else {
      setSkuErr("Parent SKU is Exits");
    }
  };

  /* close dialog window */
  const handleCloseDialog = () => {
    handleClose();
    setFormErrors({});
    setHsnErr("");
  };

  const allCategoryList = (categorylistOption) => {
    return categorylistOption.map((listOption) => {
      var obj = {
        value: listOption.id,
        label: listOption.code,
      };

      if (isArrayNotEmpty(listOption.children)) {
        obj.options = allCategoryList(listOption.children);
      }

      return obj;
    });
  };
  const finalData = allCategoryList(
    categorylist.filter((cat) => cat.visible === true)
  );

  useEffect(() => {
    const categoryIdList = [];

    finalData &&
      finalData.map((categoryId) => {
        if (categoryId.label === categoryArray) {
          categoryIdList.push({ code: categoryId.label, id: categoryId.value });
        }
        return categoryIdList;
      });

    setCategory(categoryIdList);
  }, [categoryArray]);

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setCategoryArray(selectedValue);
    setSelectedCategoryLabel(selectedValue);
    if (selectedValue === "toys") {
      setIdealfor("Kids");
    } else {
      setIdealfor("");
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
      className={classes.dilougDiv}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
            style={{
              outline: "none",
              border: "2px solid #000000",
              padding: 0,
              color: "#000000",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Back
          </Typography>
        </Toolbar>
      </AppBar>

      <form onSubmit={handleProductUpload} autoComplete="off">
        <Grid container spacing={3} className={classes.gridSection}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <div className={classes.sectionDiv}>
                <TextField
                  inputRef={proRef}
                  id="name"
                  name="name"
                  label="Product Name *"
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.name ? formErrors.name : ""}
                />

                <TextField
                  inputRef={titRef}
                  id="title"
                  name="title"
                  label="Product Title *"
                  variant="outlined"
                  size="small"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.title ? formErrors.title : ""}
                />
                <FormControl variant="outlined" size="small">
                  {/* <MultiLevelSelect
                    options={finalData} 
                    onChange={(e) => setCategoryArray(e)}
                  /> */}
                  <InputLabel
                    id="demo-simple-select-label"
                    className={classes.labelRoot}
                  >
                    Category*{" "}
                  </InputLabel>
                  <Select
                    inputRef={selectRef}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={categoryArray.code}
                    onChange={handleChange}
                    className={classes.selecteCategory}
                    label="Select Category"
                    MenuProps={{
                      classes: { paper: classes.dropdownStyle },
                    }}
                  >
                    {finalData &&
                      finalData.map((categoryItem, index) => (
                        <MenuItem
                          value={categoryItem.label}
                          style={{
                            fontSize: 14,
                            fontFamily: "'Fira Sans', sans-serif",
                            fontWeight: 400,
                            color: "#495057",
                          }}
                          key={index}
                        >
                          {categoryItem.label}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>
                    {formErrors.category ? formErrors.category : ""}
                  </FormHelperText>
                </FormControl>

                <TextField
                  inputRef={skuRef}
                  id="sku"
                  name="sku"
                  label="Parent SKU *"
                  variant="outlined"
                  size="small"
                  value={sku}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !AlPHANUMERIC_ONLY.test(value)) {
                      return;
                    }
                    setSku(e.target.value);
                  }}
                  onBlur={handleSku}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={
                    formErrors.sku ? formErrors.sku : skuErr ? skuErr : ""
                  }
                />

                <TextField
                  inputRef={braRef}
                  id="brand"
                  name="brand"
                  label="Brand *"
                  variant="outlined"
                  size="small"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.brand ? formErrors.brand : ""}
                />

                <FormControl variant="outlined" size="small">
                  <InputLabel
                    id="demo-simple-select-label"
                    className={classes.labelRoot}
                  >
                    Ideal For*{" "}
                  </InputLabel>
                  <Select
                    inputRef={idealRef}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedCategoryLabel === "toys" ? "KIDS" : idealFor}
                    onChange={(e) => setIdealfor(e.target.value)}
                    className={classes.selecteCategory}
                    label="Select Category"
                    MenuProps={{
                      classes: { paper: classes.dropdownStyle },
                    }}
                  >
                    <MenuItem
                      value="MEN"
                      style={{
                        fontSize: 14,
                        fontFamily: "'Fira Sans', sans-serif",
                        fontWeight: 400,
                        color: "#495057",
                      }}
                    >
                      Men
                    </MenuItem>
                    <MenuItem
                      value="WOMEN"
                      style={{
                        fontSize: 14,
                        fontFamily: "'Fira Sans', sans-serif",
                        fontWeight: 400,
                        color: "#495057",
                      }}
                    >
                      Women
                    </MenuItem>
                    <MenuItem
                      value="KIDS"
                      style={{
                        fontSize: 14,
                        fontFamily: "'Fira Sans', sans-serif",
                        fontWeight: 400,
                        color: "#495057",
                      }}
                    >
                      Kids
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {formErrors.idealFor ? formErrors.idealFor : ""}
                  </FormHelperText>
                </FormControl>

                {selectedCategoryLabel !== "toys" && (
                  <FormControl variant="outlined" size="small">
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.labelRoot}
                    >
                      Product Groups
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={productGroup}
                      onChange={(e) => setProductGroup(e.target.value)}
                      className={classes.selecteCategory}
                      label="Select Category"
                      MenuProps={{
                        classes: { paper: classes.dropdownStyle },
                      }}
                    >
                      <MenuItem
                        value="Casual"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Casual
                      </MenuItem>

                      <MenuItem
                        value="Evening"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Evening
                      </MenuItem>
                      <MenuItem
                        value="Occasion"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Occasion
                      </MenuItem>
                      <MenuItem
                        value="Work"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Work
                      </MenuItem>
                      <MenuItem
                        value="Active"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Active
                      </MenuItem>
                      <MenuItem
                        value="Universal"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Universal
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}

                <TextField
                  inputRef={hsnRef}
                  id="hsn"
                  name="hsn"
                  type="text"
                  autoComplete="off"
                  label="HSN Code *"
                  variant="outlined"
                  size="small"
                  value={hsn}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !NUMERIC_ONLY.test(value)) {
                      return;
                    }
                    setHsn(e.target.value);
                  }}
                  onBlur={(e) => handleCheckHsncode(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={
                    formErrors.hsn ? formErrors.hsn : hsnErr ? hsnErr : ""
                  }
                />
                {hsnVerified && (
                  <Typography
                    variant="subtitle1"
                    size="small"
                    style={{ color: "green", fontSize: "12px" }}
                  >
                    Product HSN code is valid
                  </Typography>
                )}

                <TextField
                  id="countryOfOrigin"
                  name="countryOfOrigin"
                  label="Country of Origin"
                  variant="outlined"
                  size="small"
                  value={countryOfOrigin}
                  onClick={(e) => setCountyOfOrigin(e.target.value)}
                  disabled={true}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={
                    formErrors.countryOfOrigin ? formErrors.countryOfOrigin : ""
                  }
                />

                {selectedCategoryLabel !== "toys" && (
                  <FormControl variant="outlined" size="small">
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.labelRoot}
                    >
                      Season
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={season}
                      onChange={(e) => setSeason(e.target.value)}
                      className={classes.selecteCategory}
                      label="Select Season *"
                      MenuProps={{
                        classes: { paper: classes.dropdownStyle },
                      }}
                    >
                      <MenuItem
                        value="Summer"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Summer
                      </MenuItem>
                      <MenuItem
                        value="Winter"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Winter
                      </MenuItem>
                      <MenuItem
                        value="Core"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Core
                      </MenuItem>
                      <MenuItem
                        value="Festive"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Festive
                      </MenuItem>
                      <MenuItem
                        value="Monsoon"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Monsoon
                      </MenuItem>
                      <MenuItem
                        value="Fall"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Fall
                      </MenuItem>
                      <MenuItem
                        value="Spring"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Spring
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}

                {selectedCategoryLabel !== "toys" && (
                <Box className={classes.dateDiv}>
                  <DateRangeIcon />

                  <DatePicker
                    placeholderText="Season year"
                    selected={seasonYear}
                    onChange={(date) => setSeasonYear(date)}
                    showYearPicker
                    dateFormat="yyyy"
                    className={classes.date}
                  />
                </Box>
                )}

                {selectedCategoryLabel !== "toys" && (
                <TextField
                  id="taxRate"
                  name="taxRate"
                  label="Tax Rate"
                  variant="outlined"
                  size="small"
                  type="tel"
                  value={taxRate}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !NUMERIC_ONLY.test(value)) {
                      return;
                    }
                    setTaxRate(e.target.value);
                  }}
                  InputProps={{ inputProps: { min: 1, max: 100 } }}
                  onKeyUp={(e) => validatePositiveNumberEvent(e)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.taxRate ? formErrors.taxRate : ""}
                />
                )}

                {selectedCategoryLabel !== "toys" && (
                  <FormControl variant="outlined" size="small">
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.labelRoot}
                    >
                      Fashion Group*{" "}
                    </InputLabel>
                    <Select
                      inputRef={fashionRef}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={fashionGroup}
                      onChange={(e) => setFashionGroup(e.target.value)}
                      className={classes.selecteCategory}
                      label="Fashion Group"
                      MenuProps={{
                        classes: { paper: classes.dropdownStyle },
                      }}
                    >
                      <MenuItem
                        value="FASHION"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Fashion
                      </MenuItem>
                      <MenuItem
                        value="CORE"
                        style={{
                          fontSize: 14,
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 400,
                          color: "#495057",
                        }}
                      >
                        Core
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {formErrors.fashionGroup ? formErrors.fashionGroup : ""}
                    </FormHelperText>
                  </FormControl>
                )}

                <TextField
                  id="keyWords"
                  name="keyWords"
                  label="Keywords"
                  variant="outlined"
                  size="small"
                  value={keyWords}
                  onChange={(e) => setKeyWords(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.headingSec}>
              Basic Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <div className={classes.sectionDiv}>
                <FormControl
                  component="fieldset"
                  style={{ background: "none", margin: "4px 0 0 4px" }}
                >
                  <FormLabel
                    component="legend"
                    className={classes.sellingTitle}
                  >
                    {account.userType !== "MANU" && "Bargain /"} Fixed Price*{" "}
                  </FormLabel>

                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    className={classes.radioGroup}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    {account.userType !== "MANU" && (
                      <FormControlLabel
                        value="YES"
                        control={<Radio color="primary" size="small" />}
                        label="Bargain"
                      />
                    )}

                    <FormControlLabel
                      value="NO"
                      control={<Radio color="primary" size="small" />}
                      label="Fixed"
                    />
                  </RadioGroup>

                  <FormHelperText>
                    {formErrors.type ? formErrors.type : ""}
                  </FormHelperText>
                </FormControl>

                {type === "YES" && (
                  <div>
                    <div style={{ margin: "16px" }}>
                      <Box className={classes.bargainDiv}>
                        <DateRangeIcon />

                        <DatePicker
                          placeholderText="Bargain Start"
                          selected={startBargain}
                          showTimeSelect
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="MM/dd/yyyy h:mm aa"
                          minDate={new Date()}
                          onChange={(date) => setStartBargain(date)}
                          className={classes.date}
                        />
                      </Box>

                      {formErrors.startBargain && (
                        <Box className={classes.inputErrorBlock}>
                          {formErrors.startBargain}
                        </Box>
                      )}
                    </div>

                    <div style={{ margin: "16px" }}>
                      <Box className={classes.bargainDiv}>
                        <DateRangeIcon />

                        <DatePicker
                          placeholderText="Bargain End"
                          selected={endBargain}
                          showTimeSelect
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="MM/dd/yyyy h:mm aa"
                          minDate={new Date()}
                          onChange={(date) => setEndBargain(date)}
                          className={classes.date}
                        />
                      </Box>

                      {formErrors.endBargain && (
                        <Box className={classes.inputErrorBlock}>
                          {formErrors.endBargain}
                        </Box>
                      )}
                    </div>
                  </div>
                )}

                <Grid container spacing={1}>
                  <Grid item xs>
                    <Box className={classes.dateDiv}>
                      <DateRangeIcon />

                      <DatePicker
                        inputRef={datePickerRef}
                        placeholderText="Date of Availability*"
                        selected={fromDate}
                        onChange={(date) => setFromDate(date)}
                        minDate={new Date()}
                        className={classes.date}
                      />
                    </Box>
                    {formErrors.fromDate && (
                      <Box className={classes.inputErrorBlock}>
                        {formErrors.fromDate}
                      </Box>
                    )}
                  </Grid>
                </Grid>

                <TextField
                  inputRef={qtyRef}
                  id="quantity"
                  name="quantity"
                  type="tel"
                  label="Total Quantity *"
                  variant="outlined"
                  size="small"
                  value={quantity}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !NUM_ONLY.test(value)) {
                      return;
                    }
                    setQuantity(e.target.value);
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.quantity ? formErrors.quantity : ""}
                />
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography className={classes.headingSec}>Selling Type</Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <div className={classes.sectionDiv}>
                <img
                  src={packageImage}
                  alt="package"
                  style={{ width: "60%", height: "auto" }}
                />

                <FormControl variant="outlined" size="small">
                  <InputLabel
                    id="demo-simple-select-label"
                    className={classes.labelRoot}
                  >
                    Package Type*{" "}
                  </InputLabel>
                  <Select
                    inputRef={pkyRef}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={packagingType}
                    onChange={(e) => setPackagingType(e.target.value)}
                    className={classes.selecteCategory}
                    label="Select Category"
                    MenuProps={{
                      classes: { paper: classes.dropdownStyle },
                    }}
                  >
                    <MenuItem
                      value="Loose"
                      style={{
                        fontSize: 14,
                        fontFamily: "'Fira Sans', sans-serif",
                        fontWeight: 400,
                        color: "#495057",
                      }}
                    >
                      Loose
                    </MenuItem>
                    <MenuItem
                      value="Boxed"
                      style={{
                        fontSize: 14,
                        fontFamily: "'Fira Sans', sans-serif",
                        fontWeight: 400,
                        color: "#495057",
                      }}
                    >
                      Boxed
                    </MenuItem>
                    <MenuItem
                      value="Non-woven Bag"
                      style={{
                        fontSize: 14,
                        fontFamily: "'Fira Sans', sans-serif",
                        fontWeight: 400,
                        color: "#495057",
                      }}
                    >
                      Non-woven Bag
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {formErrors.packagingType ? formErrors.packagingType : ""}
                  </FormHelperText>
                </FormControl>

                <TextField
                  inputRef={lengthRef}
                  id="length"
                  name="length"
                  type="tel"
                  label="Product Length in cm *"
                  variant="outlined"
                  size="small"
                  value={length}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !NUMERIC_ONLY.test(value)) {
                      return;
                    }
                    setLength(e.target.value);
                  }}
                  InputProps={{ inputProps: { min: 1, max: 100 } }}
                  onKeyUp={(e) => validatePositiveNumberEvent(e)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.length ? formErrors.length : ""}
                />

                <TextField
                  inputRef={widthRef}
                  id="width"
                  name="width"
                  type="tel"
                  label="Product Width in cm *"
                  variant="outlined"
                  size="small"
                  value={width}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !NUMERIC_ONLY.test(value)) {
                      return;
                    }
                    setWidth(e.target.value);
                  }}
                  InputProps={{ inputProps: { min: 1, max: 100 } }}
                  onKeyUp={(e) => validatePositiveNumberEvent(e)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.width ? formErrors.width : ""}
                />

                <TextField
                  inputRef={heightRef}
                  id="height"
                  name="height"
                  type="tel"
                  label="Product Height in cm *"
                  variant="outlined"
                  size="small"
                  value={height}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !NUMERIC_ONLY.test(value)) {
                      return;
                    }
                    setHeight(e.target.value);
                  }}
                  InputProps={{ inputProps: { min: 1, max: 100 } }}
                  onKeyUp={(e) => validatePositiveNumberEvent(e)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.height ? formErrors.height : ""}
                />

                <TextField
                  inputRef={weightRef}
                  id="weight"
                  name="weight"
                  type="tel"
                  label="Package Weight in grams *"
                  variant="outlined"
                  size="small"
                  value={weight}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !NUMERIC_ONLY.test(value)) {
                      return;
                    }
                    setWeight(e.target.value);
                  }}
                  InputProps={{ inputProps: { min: 1, max: 10000 } }}
                  onKeyUp={(e) => validatePositiveNumberEvent(e)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.weight ? formErrors.weight : ""}
                />

                <FormControl
                  component="fieldset"
                  style={{ background: "none", margin: "4px 0 0 4px" }}
                >
                  <FormLabel
                    component="legend"
                    className={classes.sellingTitle}
                  >
                    Sell it faster
                  </FormLabel>

                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    className={classes.radioGroup}
                    // value={sellfast ? "YES" : "NO"}
                    // onChange={(e) =>
                    //   setSellfast(e.target.value === "YES" ? true : false)
                    // }
                    value={sellfast}
                    onChange={(e) => setSellfast(e.target.value)}
                  >
                    <FormControlLabel
                      value="YES"
                      control={<Radio color="primary" size="small" />}
                      label="Yes"
                    />

                    <FormControlLabel
                      value="NO"
                      control={<Radio color="primary" size="small" />}
                      label="No"
                    />
                  </RadioGroup>

                  <FormHelperText>
                    {formErrors.sellfast ? formErrors.sellfast : ""}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  component="fieldset"
                  style={{ background: "none", margin: "4px 0 0 4px" }}
                >
                  <FormLabel
                    component="legend"
                    className={classes.sellingTitle}
                  >
                    Buyable
                  </FormLabel>

                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    className={classes.radioGroup}
                    value={buyable ? "YES" : "NO"}
                    onChange={(e) =>
                      setBuyAble(e.target.value === "YES" ? true : false)
                    }
                  >
                    <FormControlLabel
                      value="YES"
                      control={<Radio color="primary" size="small" />}
                      label="Yes"
                    />

                    <FormControlLabel
                      value="NO"
                      control={<Radio color="primary" size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography className={classes.headingSec}>
              Product Package Details
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <div className={classes.buttonGroup}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.submitButton}
              >
                Add Product to list
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={classes.cancelButton}
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
      <SnackBar />
    </Dialog>
  );
};
