import React, { useCallback, useEffect } from "react";

/* import react components */
import { AppBars } from "../../components/AppBar";
import { Footer } from "../../components/Footer";
// import { CategoryList } from "../../components/CategoryList";

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  TextareaAutosize,
  debounce,
} from "@material-ui/core";
import { useState } from "react";
import MenuHeader from "../../components/MenuHeader";
import { snackBarAlert } from "../../redux/actions/deliveryActions";
import { useDispatch } from "react-redux";
import { SnackBar } from "../../components/SnackBar";
import Sitemap from "../Sitemap/sitemap";
import Axios from "axios";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#f0e0d4",
    fontFamily: "'Fira Sans', sans-serif",
  },
  pagetitle: {
    fontSize: 28,
    fontWeight: 600,
    color: "#F24046",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
    fontStretch: "normal",
    margin: "40px 0 20px 0",
    textAlign: "center",
  },

  description: {
    padding: "20px",
    fontSize: 16,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#666",
    lineHeight: "25px",
    marginBottom: 15,
  },

  formDiv: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
      background: "000",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#495057",
      textAlign: "initial",
      background: "#F7F7F7",
    },
    "& .MuiFormControlLabel-label": {
      color: "#4A4A4A",
      fontSize: 12,
    },
    "& .MuiTypography-colorTextSecondary": {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
    },
  },
  msgSection: {
    fontWeight: 600,
    color: "#043c53",
    marginTop: theme.spacing(15),
  },
  captionMsg: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#000",
  },
  subSection: {
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1),
  },
  signInButton: {
    color: "#000",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem 1.25rem",
    cursor: "pointer",
  },
  signinLable: {
    fontSize: "14px",
    color: "#000",
    width: "75%",
    margin: "auto",
    paddingBottom: "2px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: "6px",
    width: "75%",
    height: "100%",
    margin: "auto",
  },
  submitButton: {
    color: "#000",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem 1.25rem",
    width: "75%",
    cursor: "pointer",
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
    color: "#000000",
    backgroundColor: "#F7F7F7",
  },
  outlined: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: 14,
    color: "#000000",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input": {
      fontWeight: 600,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ALPHA_ONLY = /^[a-zA-Z\s]+$/;
const emailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const regexp = /^\S*$/;
const baseUrl = process.env.REACT_APP_BASE_URL;

export const Contact = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const emailInputRef = React.useRef();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [showSiteMap10, setShowSiteMap10] = useState(false);
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [issue, setIssue] = useState("");
  const [open, setOpen] = useState(false);
  const [pendingSubmission, setPendingSubmission] = useState(false);
  const [id, setId] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false); 

  const inputValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (firstName === "" || firstName.length < 3) {
      inputsValid = false;
      formErrors["firstname"] =
        "Please Enter First name (Minimum 3 characters).";
    }

    if (lastName === "") {
      inputsValid = false;
      formErrors["lastname"] = "Please enter your Last name";
    }

    if (email === "") {
      inputsValid = false;
      formErrors["email"] = "Please enter your e-mail.";
    }

    if (email && !emailRegex.test(email)) {
      inputsValid = false;
      formErrors["email"] = "Invalid e-mail address";
    }

    if (message === "" || message.length < 100) {
      inputsValid = false;
      formErrors["message"] = "Please Enter message (Minimum 100 characters).";
    }

    if (issue === "") {
      inputsValid = false;
      formErrors["issue"] = "Please select an issue.";
    }

    setFormErrors(formErrors);
    return inputsValid;
  };

  const handleCustomerSignup = (event) => {
    event.preventDefault();

    if (inputValidation()) {
      if (issue === "account") {
        setOpen(true);
        setPendingSubmission(true);
      } else {
        submitForm();
      }
    }
  };

  const submitForm = () => {
    const newUserData = {
      emailAddress: email,
      firstName: firstName,
      lastName: lastName,
      message: message,
      issue: issue,
    };

    dispatch(snackBarAlert("success", "Your response has been submitted successfully. We will contact you in two working days"));
    if (newUserData) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setMessage("");
      setIssue("");
    }
  };

  const handleIssueChange = (event) => {
    setIssue(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setPendingSubmission(false);
  };

  const debounceEmailValidation = useCallback(
    debounce(async (email) => {
      if (email && emailRegex.test(email)) {
        try {
          const response = await Axios.post(
            `${baseUrl}/user/getUserByEmail`,
            {
              merchant: "",
              mobileNumber: "",
              type: "",
              userName: email,
            }
          );
          setId(response.data.id);
          setIsEmailValid(true); 
        } catch (error) {
          if (error.response.status === 500) {
            dispatch(snackBarAlert("error", "Invalid email address"));
            setIsEmailValid(false); 
            return;
          }
        }
      } else {
        setIsEmailValid(false);
      }
    }, 1000),
    []
  );

  useEffect(() => {
    debounceEmailValidation(email);
  }, [email, debounceEmailValidation]);

  const handleDeleteAccount = async () => {
    if(!inputValidation()){
      setOpen(false);
      dispatch(snackBarAlert("error", "Enter all the fields"));
      return;
    }
    if (id === "" ) {
      setOpen(false);
      dispatch(snackBarAlert("error", "Invalid email address"));
      return;
    } else {
      try {
        const response = await Axios.post(
          "https://mefev15outxtock-prdnotfy057.outxtock.com:8443/ywc-notification-service/api/v1/notification/htmlemail",
          {
            to: [
              {
                userEmailId: "stockxbid_help@stockxbid.com",
                userName: "Stockxbid",
              },
            ],
            from: {
              userEmailId: email,
              userName: `${firstName} ${lastName}`,
            },
            subject: "Account Deletion",
            body: message,
            attachmentString: "",
            attachmentName: "",
            mailServiceProvider: "stockxbid",
          }
        );
        setOpen(false);
        dispatch(
          snackBarAlert(
            "success",
            "Account deletion requested! Your account will be deleted in 30 days."
          )
        );
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
        setIssue("");
      } catch (error) {
        console.log("err:", error.response);
      }
    }
  };

  useEffect(() => {
    var metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute("content", "Outxtock Contact Us");
    }
    document.title = "Contact Us";
  }, []);

  return (
    <div className={classes.root}>
      <AppBars />
      {!showSiteMap10 ? (
        <>
          <MenuHeader />
          <Container fixed style={{ marginBottom: 20 }}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Paper className={classes.paper}>
                  <Typography component="h6" className={classes.pagetitle}>
                    CONTACT US
                  </Typography>
                  <Typography variant="h6" className={classes.signinLable}>
                    We're here to help and answer any question you might have.
                    We look forward to hearing from you.
                  </Typography>
                  <form
                    onSubmit={handleCustomerSignup}
                    autoComplete="off"
                    className={classes.formDiv}
                  >
                    <TextField
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      size="small"
                      type="text"
                      autoFocus
                      value={firstName}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !ALPHA_ONLY.test(value)) {
                          return;
                        }
                        setFirstName(e.target.value.trim());
                      }}
                      inputProps={{
                        maxLength: 50,
                      }}
                      helperText={
                        formErrors.firstname ? formErrors.firstname : ""
                      }
                    />

                    <TextField
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      size="small"
                      type="text"
                      value={lastName}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !ALPHA_ONLY.test(value)) {
                          return;
                        }
                        setLastName(e.target.value.trim());
                      }}
                      inputProps={{
                        maxLength: 50,
                      }}
                      helperText={
                        formErrors.lastname ? formErrors.lastname : ""
                      }
                    />

                    <TextField
                      id="email"
                      name="email"
                      label="Your Email"
                      variant="outlined"
                      size="small"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      helperText={formErrors.email}
                    />

                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                      size="small"
                      error={!!formErrors.issue}
                    >
                      <InputLabel id="issue-label">Select Option</InputLabel>
                      <Select
                        labelId="issue-label"
                        id="issue"
                        value={issue}
                        onChange={handleIssueChange}
                        label="Select Option"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="payment">Payment Issue</MenuItem>
                        <MenuItem value="orders">Orders</MenuItem>
                        <MenuItem value="account">Delete Account</MenuItem>
                        <MenuItem value="technical">Technical Support</MenuItem>
                        <MenuItem value="feedback">Feedback</MenuItem>
                        <MenuItem value="complaint">Complaint</MenuItem>
                        <MenuItem value="others">Others</MenuItem>
                      </Select>
                      <FormHelperText>{formErrors.issue}</FormHelperText>
                    </FormControl>

                    <TextField
                      minRows={3}
                      id="message"
                      name="message"
                      label="Message"
                      variant="outlined"
                      type="text"
                      style={{ width: "80%" }}
                      value={message}
                      multiline
                      onChange={(e) => setMessage(e.target.value)}
                      inputProps={{
                        minLength: 100,
                      }}
                      helperText={formErrors.message ? formErrors.message : ""}
                    />

                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.submitButton}
                      style={{ outline: "none" }}
                      disabled={!isEmailValid} 
                    >
                      Send Message
                    </Button>
                  </form>
                </Paper>
              </Grid>
            </Grid>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Confirm Account Deletion"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Do you really want to delete your account? This action cannot
                  be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleDeleteAccount}
                  color="secondary"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
        </>
      ) : (
        <Sitemap />
      )}
      <Footer showSiteMap={showSiteMap10} setShowSiteMap={setShowSiteMap10} />
    </div>
  );
};
