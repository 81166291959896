import React, { useEffect, useState } from "react";

/* import react components */
import { AppBars } from "../../components/AppBar";
import { Footer } from "../../components/Footer";
// import { CategoryList } from "../../components/CategoryList";

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
// import parse from "html-react-parser";
import { TermsOfUseFooter } from "./../../util/documents/TermsOfUseFooter";
import MenuHeader from "../../components/MenuHeader";
import Sitemap from "../Sitemap/sitemap";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#f0e0d4",
  },
  pagetitle: {
    fontSize: 28,
    fontWeight: 600,
    color: "#F24046",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
    fontStretch: "normal",
    margin: "90px 0 10px 0",
    textAlign: "center",
  },
  label: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    paddingLeft: 15,
    margin: "auto",
    lineHeight: "30px",
    color: "#212121",
  },
  text: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 300,
    padding: 8,
    margin: "auto",
    lineHeight: "30px",
    color: "#5c6166",
  },
  heading: {
    textAlign: "center",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
  },
  subheading: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    margin: "15px 0 0 8px",
    fontWeight: 600,
  },
}));

export const TermsConditions = () => {
  const classes = useStyles();
  const [showSiteMap16, setShowSiteMap16] = useState(false);

  const TERMS_OF_USE_DOC = "Terms of Use";

  // const getDocument = (documentStr) => {
  //   if (TERMS_OF_USE_DOC === documentStr) {
  //     return TermsOfUseFooter;
  //   }
  //   return "";
  // };

  useEffect(() => {
    var metaDescriptionTag = document.querySelector('meta[name="description"]');

    // Check if the meta tag is found
    if (metaDescriptionTag) {
      // Update the content attribute with a new description
      metaDescriptionTag.setAttribute('content', `OutStock Terms and Conditions`)
    }
    document.title = 'Terms and Condition'
  },[])

  return (
    <div className={classes.root}>
      <AppBars />
      {/* <CategoryList /> */}
      {!showSiteMap16 ? (
        <>
          <MenuHeader />
          <Container fixed style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <TermsOfUseFooter />
            </Grid>
          </Container>
        </>
      ) : (
        <Sitemap />
      )}
      <Footer showSiteMap={showSiteMap16} setShowSiteMap={setShowSiteMap16} />
    </div>
  );
};
