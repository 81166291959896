import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  authenticated: false,
  loading: false,
  account: {},
  alert: false,
  duplicateMobile: false,
  duplictaeEmail: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.SET_USER:
      return {
        authenticated: true,
        account: action.payload,
        loading: false,
      };
    case ActionTypes.SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case ActionTypes.SET_UNAUTHENTICATED:
      return initialState;
    case ActionTypes.UPDATE_USER:
      return {
        ...state,
        account:
          state.account.id === action.payload.id
            ? { ...action.payload }
            : state.account,
        loading: false,
      };
    case ActionTypes.ADD_SELLER_BANK_DETAILS:
      return {
        ...state,
        account:
          state.account.id === action.payload.id
            ? { ...state.account, bankDetails: action.payload.bankDetails }
            : state.account,
            loading: false,
      };
    case ActionTypes.AUTH_ALERT:
      return {
        ...state,
        alert: !state.alert,
      };
    case ActionTypes.CHECK_DUPLICATE_MOBILENUMBER:
      return {
        ...state,
        duplicateMobile: action.payload,
      };
    case ActionTypes.CLEAR_DUPLICATE_MOBILENUMBER:
      return {
        ...state,
        duplicateMobile: null,
      };
    case ActionTypes.CHECK_CUST_DUPLICATE_EMAIL:
      return {
        ...state,
        duplictaeEmail: action.payload,
      };
    case ActionTypes.CLEAR_CUST_DUPLICATE_EMAIL:
      return {
        ...state,
        duplictaeEmail: null,
      };
    default:
      return state;
  }
}
