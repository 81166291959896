import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { bargainResponse } from "../../../redux/actions/sellerActions";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
// import { snackBarAlert } from "../../../redux/actions/deliveryActions";
import { SnackBar } from "../../SnackBar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
  dilogAppbar: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#53E7BC",
    border: "none",
    boxShadow: "0 1px 10px rgba(0, 0, 0, 0.1)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#FFFFFF",
  },
  modalTitle: {
    fontSize: "1rem",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#FFFFFF",
  },
  table: {
    minWidth: 500,
    "& .MuiTableCell-head": {
      fontWeight: 800,
      fontSize: "13px",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#363636",
    },
    "& .MuiTableCell-body": {
      fontWeight: 500,
      fontSize: "13px",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#363636",
    },
  },
  tableHead: {
    background: "#d5f9ef",
  },
  actionButtons: {
    "& > *": {
      margin: theme.spacing(1),
      textTransform: "none",
      borderRadius: "4px",
    },
  },
  accetButton: {
    backgroundColor: "#53E7BC",
    fontSize: "13px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#363636",
    "&:hover": {
      backgroundColor: "#53E7BC",
    },
  },
  submitButton: {
    backgroundColor: "transparent",
    color: "#363636",
    fontSize: "13px",
    fontFamily: "'Fira Sans', sans-serif",
    border: "1px solid #53E7BC",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  offerPrice: {
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
    marginRight: "1rem",
  },
}));

const NUMARIC_ONLY =/^[0-9]+$/;
export const ProductBargainsDialog = (props) => {
  const classes = useStyles();
  const { bargains } = useSelector((state) => state.sales) || [];
  const { product } = props || {};
  const dispatch = useDispatch();

  const [newOffer, setNewOffer] = useState();
  const [errors, setErrors] = useState({});
  const [inputData, setInputData] = useState();

  const bargainInputsValidation = (id) => {
    let errors = {};
    let inputsValid = true;

    if (newOffer === 0 || newOffer === "0" || newOffer <= 0) {
      inputsValid = false;
      errors[id] = "Please enter a valid bargain price ";
    }

    setErrors(errors);
    return inputsValid;
  }; 
  const handleBargainResponse = (item, status, id) => {
    if (bargainInputsValidation(id)) {
      const responseData = {
        docId: item._id,
        ACTIVE_STATUS: true,
        ATTRIBUTE_ID: item._source.attribute_id,
        ATTRIBUTE_COLOR: item._source.attribute_color,
        ATTRIBUTE_COLOR_CODE: item._source.attribute_color_code,
        ATTRIBUTE_PRICE: item._source.attribute_price,
        ATTRIBUTE_QUANTITY: item._source.attribute_quantity,
        ATTRIBUTE_SIZE: item._source.attribute_size,
        ATTRIBUTE_SKU: item._source.attribute_sku,
        BRAND: item._source.brand,
        BARGAIN_START_DATE: item._source.bargain_start_date,
        BARGAIN_END_DATE: item._source.bargin_end_date,
        BARGAIN_PRICE: item._source.bargin_price,
        BARGAIN_QUANTITY: item._source.bargin_quantity,
        BARGAIN_TITLE: status,
        CATEGORY: item._source.category,
        CUST_ID: item._source.customer_ID,
        CUST_NAME: item._source.customer_Name,
        CUST_MOBILE: item._source.customer_number,
        event_saga_store_id: "",
        FASHION_GROUP: item._source.fashion_group,
        IDEAL_FOR: item._source.ideal_for,
        LOWEST_PRICE: item._source.lowest_price,
        MANUFACTURER: item._source.manufacture,
        MERCHANT_STORE_ID: "",
        MERCHANT: item._source.merchant,
        MANUFACTURER_ID: item._source.manufacture_ID,
        MERCHANT_ID: item._source.merchant_ID,
        OFFERING_PRICE: newOffer ? parseInt(newOffer) : "",
        PARENT_SKU_ID: item._source.parent_sku_id,
        PRODUCT_ID: item._source.product_ID,
        PRODUCT_BUYABLE: item._source.product_buyable,
        PRODUCT_IMAGE_URL: item._source.product_image_url,
        PRODUCT_HSN: item._source.product_hsn,
        PRODUCT_TOTAL_QUANTITY: item._source.product_total_quantity,
        STATUS: status,
        SKU_DESC: "",
        SUB_CATEGORY: "",
        SKU_NAME: item._source.sku_name,
        SELLER_NUMBER: item._source.seller_number,
        uid: "",
        UPC: "",
        VNDR_ID: item._source.vendor_ID,
        VENDOR: item._source.vendor,
        YWC_ID: "",
      };

      let msg = "";

      if (status === "ACCEPT") {
        msg = "Bargain Request is Accepted.";
      } else if (status === "REJECT") {
        msg = "Bargain Request is Rejected.";
      } else if (status === "NOTIFICATION") {
        msg = "New Offer Price given.";
      }
      setErrors({});
      dispatch(bargainResponse(responseData, item._id, msg));
      props.handleCloseProductBargains();
    }
  };

  const handleBargainResponseProduct = (item, status) => {
    const responseData = {
      docId: item._id,
      ACTIVE_STATUS: true,
      ATTRIBUTE_ID: item._source.attribute_id,
      ATTRIBUTE_COLOR: item._source.attribute_color,
      ATTRIBUTE_COLOR_CODE: item._source.attribute_color_code,
      ATTRIBUTE_PRICE: item._source.attribute_price,
      ATTRIBUTE_QUANTITY: item._source.attribute_quantity,
      ATTRIBUTE_SIZE: item._source.attribute_size,
      ATTRIBUTE_SKU: item._source.attribute_sku,
      BRAND: item._source.brand,
      BARGAIN_START_DATE: item._source.bargain_start_date,
      BARGAIN_END_DATE: item._source.bargin_end_date,
      BARGAIN_PRICE: item._source.bargin_price,
      BARGAIN_QUANTITY: item._source.bargin_quantity,
      BARGAIN_TITLE: status,
      CATEGORY: item._source.category,
      CUST_ID: item._source.customer_ID,
      CUST_NAME: item._source.customer_Name,
      CUST_MOBILE: item._source.customer_number,
      event_saga_store_id: "",
      FASHION_GROUP: item._source.fashion_group,
      IDEAL_FOR: item._source.ideal_for,
      LOWEST_PRICE: item._source.lowest_price,
      MANUFACTURER: item._source.manufacture,
      MERCHANT_STORE_ID: "",
      MERCHANT: item._source.merchant,
      MANUFACTURER_ID: item._source.manufacture_ID,
      MERCHANT_ID: item._source.merchant_ID,
      OFFERING_PRICE: newOffer ? parseInt(newOffer) : "",
      PARENT_SKU_ID: item._source.parent_sku_id,
      PRODUCT_ID: item._source.product_ID,
      PRODUCT_BUYABLE: item._source.product_buyable,
      PRODUCT_IMAGE_URL: item._source.product_image_url,
      PRODUCT_HSN: item._source.product_hsn,
      PRODUCT_TOTAL_QUANTITY: item._source.product_total_quantity,
      STATUS: status,
      SKU_DESC: "",
      SUB_CATEGORY: "",
      SKU_NAME: item._source.sku_name,
      SELLER_NUMBER: item._source.seller_number,
      uid: "",
      UPC: "",
      VNDR_ID: item._source.vendor_ID,
      VENDOR: item._source.vendor,
      YWC_ID: "",
    };

    let msg = "";

    if (status === "ACCEPT") {
      msg = "Bargain Request is Accepted.";
    } else if (status === "REJECT") {
      msg = "Bargain Request is Rejected.";
    } else if (status === "NOTIFICATION") {
      msg = "New Offer Price given.";
    }
    setErrors({});

    dispatch(bargainResponse(responseData, item._id, msg));
    props.handleCloseProductBargains();
  };

  const ClosehandleCloseProductBargains= ()=>{
    setErrors({})
    setInputData(bargains);
    props.handleCloseProductBargains();
    
  }

  const handleInputChange = (index, value) => {
    const newData = [
      ...inputData.filter((item) =>
        item._source.attribute_id
          ? item._source.attribute_id === product.attributId
          : item._source.attribute_sku === product.skuId
      ),
    ];
    newData[index]._source.offering_PRICE = value;
    setNewOffer(value);
    setInputData(newData);
  };
  useEffect(() => {
    setInputData(bargains);
  }, [bargains]);

  return (
    <div>
      <Dialog
        onClose={ClosehandleCloseProductBargains}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className={classes.root}
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.dilogAppbar}
        >
          <Typography className={classes.modalTitle}>All Bids</Typography>

          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={ClosehandleCloseProductBargains}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell align="center">User</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Offering Price</TableCell>
                  <TableCell align="center">Ask Price</TableCell>
                  <TableCell align="center">Difference</TableCell>
                  <TableCell align="center">Bargain Date</TableCell>
                  {/* <TableCell align="right">Final Price</TableCell>
                <TableCell align="right">Profit</TableCell> */}
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody className={classes.tableBody}>
                {inputData && inputData.length > 0 ? (
                  inputData
                    .filter((item) =>
                      item._source.attribute_id
                        ? item._source.attribute_id === product.attributId
                        : item._source.attribute_sku === product.skuId
                    )
                    .map((bargain, index) => (
                      <TableRow key={bargain._id}>
                        <TableCell component="th" align="center" scope="row">
                          {bargain._source.customer_Name}
                        </TableCell>
                        <TableCell align="center">
                          {bargain._source.bargin_quantity}
                        </TableCell>
                        <TableCell align="center">
                          {bargain._source.attribute_price}/-
                        </TableCell>
                        <TableCell align="center">
                          {bargain._source.bargin_price}/-
                        </TableCell>

                        {Number(bargain._source.bargin_price) -
                          Number(bargain._source.attribute_price) >=
                        0 ? (
                          <TableCell align="center" style={{ color: "green" }}>
                            {(
                              Number(bargain._source.bargin_price) -
                              Number(bargain._source.attribute_price)
                            ).toFixed(2)}
                            {""}/-
                          </TableCell>
                        ) : (
                          <TableCell align="center" style={{ color: "red" }}>
                            {(
                              Number(bargain._source.bargin_price) -
                              Number(bargain._source.attribute_price)
                            ).toFixed(2)}
                            {""}/-
                          </TableCell>
                        )}

                        <TableCell align="center">
                          {new Date(bargain._source.datetime).toLocaleString()}
                        </TableCell>

                        {/* <TableCell align="center">
                        {bargain._source.lowest_price}/-
                      </TableCell> */}

                        {/* {Number(bargain._source.bargin_price) -
                          Number(bargain._source.lowest_price)>= 0 ?  <TableCell align="right" style={{color: 'green'}}>
                          {(Number(bargain._source.bargin_price) -
                            Number(bargain._source.lowest_price || bargain._source.attribute_price)).toFixed(2)}{""}/-
                        </TableCell> :  <TableCell align="right" style={{color: 'red'}}>
                        {(Number(bargain._source.bargin_price) -
                          Number(bargain._source.lowest_price || bargain._source.attribute_price)).toFixed(2)}{""}/-
                      </TableCell>} */}

                        <TableCell align="center">
                          <Box className={classes.actionButtons}>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() =>
                                handleBargainResponseProduct(bargain, "ACCEPT")
                              }
                              className={classes.accetButton}
                            >
                              Accept
                            </Button>

                            <Button
                              size="small"
                              variant="contained"
                              onClick={() =>
                                handleBargainResponseProduct(bargain, "REJECT")
                              }
                              className={classes.accetButton}
                            >
                              Reject
                            </Button>

                            <Box style={{ display: "flex" }}>
                              <Box>
                              <TextField
                                id="outlined-number"
                                label="Offer new price"
                                variant="outlined"
                                type="tel"
                                
                                size="small"
                                value={bargain._source.offering_PRICE}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (
                                    value !== "" &&
                                    !NUMARIC_ONLY.test(value)
                                  ) {
                                    return;
                                  }
                                  handleInputChange(index, e.target.value);
                                }}
                                className={classes.offerPrice}
                                
                              />
                              
                              </Box>

                              <Button
                                
                                variant="contained"
                                onClick={() =>
                                  handleBargainResponse(
                                    bargain,
                                    "NOTIFICATION",
                                    bargain._id
                                  )
                                }
                                size="small"
                                className={classes.submitButton}
                                disabled={
                                  bargain._source.offering_PRICE ? false : true
                                }
                              >
                                Send
                              </Button>
                            </Box>
                            <span style={{ color: 'red'}}>{errors[bargain._id] ? errors[bargain._id] : ""}</span>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>Bargains not Found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      <SnackBar />
    </div>
  );
};
