import React from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import BuildIcon from "@material-ui/icons/Build";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  PageNotFound: {
    margin: "50px 0",
    display: "flex",
    justifyContent: "center",
  },
  PageNotFoundMain: {
    textAlign: "center",
    lineHeight: "140px",
    "& > svg": {
      height: "100px",
      width: "100px",
      color: "#777",
    },
    "& > h5": {
      fontSize: 28,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 600,
      lineHeight: 2,
      color: "#777",
      margin: "20px 0",
    },
    "& > p": {
      fontSize: 16,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#777",
    },
  },
  button: {
    color: "#fff",
    background: "#27B23E",
    padding: "10px 20px",
  },
}));

export const PageNotFound = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.PageNotFound}>
      <Grid item xs={12} md={6}>
        <div className={classes.PageNotFoundMain}>
          <BuildIcon />

          <Typography variant="h5">Look like you're lost</Typography>
          <Typography component="p">
            the page you are looking for is not available!
          </Typography>

          <Button
            variant="contained"
            className={classes.button}
            onClick={() => history.push("/")}
          >
            Go to Home
          </Button>
        </div>
      </Grid>
    </div>
  );
};
