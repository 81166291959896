import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBars } from "../../components/AppBar";
import { SellerMenu } from "../../components/seller/SellerMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: "#f8f8f8",
    // maxWidth: "1440px",
    // height: "909px"
  },
}));

export const SellerDashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBars />
      <SellerMenu />
    </div>
  );
};
