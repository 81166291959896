import React from "react";
import { useParams } from "react-router";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    margin: theme.spacing(1),
  },
  image: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

/* Image base url */
const imgBaseUrl = process.env.REACT_APP_IMAGE_URL;

export const SecuredImage = () => {
  const classes = useStyles();
  const { sellerId, imageName } = useParams();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography className={classes.title}>StockXBid</Typography>
        <Typography variant="subtitle2">
          <Typography component="span" variant="subtitle2">
            Note *:
          </Typography>
          This image is belongs to stockXbid.
        </Typography>
        <img
          src={`${imgBaseUrl}/static/PRODUCT/${sellerId}/IMAGE/${imageName}`}
          alt={imageName}
          className={classes.image}
        />
      </div>
    </Container>
  );
};
