import React from "react";

/* import react components */

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";


/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#f0e0d4",
  },
  pagetitle: {
    fontSize: 28,
    fontWeight: 600,
    color: "#F24046",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
    fontStretch: "normal",
    margin: "90px 0 10px 0",
    textAlign: "center",
  },

  text: {
    fontSize: 16,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 300,
    margin: "auto",
    lineHeight: "30px",
    color: "#5c6166",
  },
  heading: {
    textAlign: "start",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    margin: "15px 0 15px 0",
    color: "#F24046",
  },
  subheading: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    margin: "15px 0 0 8px",
    fontWeight: 600,
  },
}));

export const ShippingPolicyFooterContent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container fixed style={{ marginBottom: 20 }}>
        <Grid item xs={12}>
          <Typography component="h6" className={classes.pagetitle}>
            DELIVERY AND SHIPPING POLICY
          </Typography>

          <Typography component="h6" className={classes.heading}>
            GENERAL
          </Typography>

          <Typography component="p" className={classes.text}>
            1. OutXtock is managed and operated by Stockxbid Technologies
            Private Limited (“OutXtock ”, “we”, “us” or “our”), a company
            incorporated under the Companies Act, 1956, having its registered
            office at J.Anjenya block Seshadripuram Bangalore-20. For the
            purpose of these Terms,{" "}
            <a href="https://outxtock.com" target="_blank">
              www.outxtock.com
            </a>{" "}
            means the website (“Website”) which includes its corresponding
            desktop application named “outxtock” (collectively “Platform”),
            which is a B2B Buy and Bargain Ecommerce Marketplace, where various
            brands including lifestyle, fashion, bags, watches, Toys, etc. are
            listed ("Products") for the users of the Platform ("User(s)").
          </Typography>

          <Typography component="p" className={classes.text}>
            2. This delivery and shipping policy (“Policy”), together with the{" "}
            <a
              href="https://business-standard.com/terms-conditions"
              target="_blank"
            >
              Terms of Use
            </a>{" "} 
            sets out our policies and procedures for the delivery and shipping
            of Products purchased on the Platform.
          </Typography>

          <Typography component="p" className={classes.text}>
            3. We provide shipping and delivery of Products all over India. We
            aim to provide the best customer experience for you by tying up and
            partnering with leading logistics service providers to handle your
            order in the best possible way and to ensure that you have a
            hassle-free experience in receiving the Product that you have
            ordered from the Platform. We make all commercially reasonable
            endeavors to ensure that the Products are delivered to you in a
            timely fashion.
          </Typography>

          <Typography component="p" className={classes.text}>
            4. By agreeing to use the Platform and/ or purchasing a Product on
            the Platform, you agree to be bound by the terms of this Policy
            without modification. We encourage you to read and understand the
            terms of this Policy every time you visit the Platform. If you do
            not agree to the terms contained in this Policy, you are advised not
            to use, access, or transact on the Platform.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            TERMS OF SHIPPING AND DELIVERY
          </Typography>

          <Typography component="p" className={classes.text}>
            We partner with third-party logistic service providers in order to
            effectuate Product shipping and delivery to you ("Logistic
            Partners"). We shall provide the details of the Logistic Partner
            which will be responsible for processing the shipping and delivery
            of any Product(s) purchased by you on the Platform at the time such
            Product is processed and successfully handed over to the Logistic
            Partner by the respective Brand or Seller. In any case, the user
            will be provided with an estimated timeline for the delivery of the
            Product purchased from the Platform. You can check the status of
            your order in the ‘My Orders’ section on the Platform. Once you open
            the page, you can get details regarding the order status of
            individual products in your order. We keep the status information of
            your order updated every few hours and also notify you in case of
            delays. The estimated timeline shall be notified to the user on the
            order confirmation page displayed at the time the order is confirmed
            by us. We will also share details about your orders pursuant to
            their dispatch on the e-mail ID and/or mobile number provided by
            you/ registered with us.
          </Typography>
          <Typography component="p" className={classes.text}>
            1. You agree and understand that though we endeavor to ship and
            deliver our Products all across India, we may, in our sole
            discretion, determine a select list of areas that are unserviceable
            for delivery of Products. We or our Logistic Partners do not provide
            shipping and delivery services in such unserviceable areas and may
            not process your orders on the Platform in such cases. In the event
            an area has been deemed unserviceable by us, we shall notify such
            users at the time of placing an order for the purchase of Products
            on the Platform. You may also verify whether an area is
            unserviceable for deliveries by us by entering the relevant area
            pin-code on the Platform.
          </Typography>
          <Typography component="p" className={classes.text}>
            2. You agree and acknowledge that to effectuate timely delivery of
            the purchased Products to you we may inquire or collect specific
            information like your name, shipping address, billing address,
            landmarks, contact details, etc. You shall ensure that all
            information that is submitted by you to us on the Platform is true,
            complete, accurate, and sufficient to identify the actual place of
            delivery. You understand that you shall bear absolute liability in
            case of any failure by us in delivering the purchased Products due
            to your failure to provide correct, complete, sufficient, and
            accurate information at the time of placing the order. It is further
            clarified that we shall not be liable in any manner and at any point
            in time due to your failure to provide correct and complete
            information.
          </Typography>

          <Typography component="p" className={classes.text}>
            3. We will attempt to deliver the purchased Product to your
            designated address within the estimated timeline of delivery
            notified to you. In the event you are not available or present to
            accept the delivery of the Product, our Logistic Partners will make
            a maximum of 3 (three) attempts to deliver the purchased Product(s)
            to you. If the third delivery attempt is unsuccessful and you
            continue to remain unavailable, we reserve the right to cancel the
            order of the purchased Products at our sole discretion and process
            the return of such Products to the respective Brand/Seller. We
            further reserve the right to deduct the shipping and delivery
            charges borne by us while processing any refunds subsequent to such
            cancellation.
          </Typography>

          <Typography component="p" className={classes.text}>
            4. While we make reasonable endeavors in ensuring that purchased
            Products are delivered to you in a timely manner and within the
            timeline notified to you, you accept and acknowledge that the
            delivery may be delayed on account of:
          </Typography>

          <div style={{ paddingLeft: "10px" }}>
            <Typography component="p" className={classes.text}>
              a) Logistical issues beyond our control;
            </Typography>

            <Typography component="p" className={classes.text}>
              b) Unsuitable weather conditions;
            </Typography>

            <Typography component="p" className={classes.text}>
              c) Political disruptions, strikes, employee lockouts, etc.;
            </Typography>

            <Typography component="p" className={classes.text}>
              d) Acts of God such as floods, earthquakes, etc.;
            </Typography>

            <Typography component="p" className={classes.text}>
              e) Other unforeseeable circumstances.
            </Typography>

            <Typography component="p" className={classes.text}>
              f) Although we strive for 100% on-time delivery, delays happen
              sometimes due to unforeseen circumstances at our delivery team’s
              end. If you haven’t received any communication from us regarding a
              late order for more than 48 hours, feel free to raise a ticket
              through the app/website or mail us at care@stockxbid.com or by
              using the ‘Chat with Us’ option. We disclaim all liabilities that
              may arise on account of our failure to inform or notify you of
              delays in the delivery of purchased Products on the Platform.
              Further, we shall be under no obligation to compensate you for any
              claim that may otherwise arise on account of a delay in the
              shipment or delivery, or use of the purchased Products.
            </Typography>
          </div>
          <Typography component="p" className={classes.text}>
            5. We endeavor to engage Logistic Partners, employees, and agents
            with the highest regard for ethics and integrity; and behave in a
            fashion that exudes thorough professionalism, competence, and good
            mannerism. You agree and acknowledge that the actions, and inactions
            of delivery individuals are not in our control, and it is not
            possible for us to monitor and observe each delivery executive.
            Since we are merely facilitating the delivery of a Product purchased
            by you, we shall not be liable for any acts or omissions on part of
            our delivery agents, employees, or personnel and/ or the Logistic
            Partner or their employees, agents, or personnel including
            deficiency in service, wrong delivery of Product, time taken to
            deliver the Product, Product package tampering, etc. For the sake of
            abundant clarity, it is stated that any ill-mannerism, impoliteness,
            discourtesy, or offensiveness showed by our delivery executives or
            the employees, agents, or personnel of the Logistic Partners is
            beyond our control, and any issue arising between you and our
            delivery executive or an employee, agent, personnel of the Logistic
            Partner will have to be resolved by you independently. You agree and
            acknowledge that you will not hold us responsible or require us to
            settle, mediate or resolve any disputes between you and the delivery
            personnel delivering the Products to you.
          </Typography>
          <Typography component="p" className={classes.text}>
            6. Once you place an order on the Platform, we process such an order
            and hand over the purchased Product to our Logistic Partner. The
            User will receive a unique tracking identity number once the
            purchased Product is handed over to the Logistics Partner, which
            will enable the User in tracking the status of delivery of the
            purchased Product. You can check the status of your order in the ‘My
            Orders’ section on the Platform. Once you open the page, you can get
            details regarding the order status of individual products in your
            order. We keep the status information of your order updated every
            few hours and also notify you in case of delay. Our customer service
            team coordinates with the Logistic Partners to ensure that the
            Products are delivered to you at the earliest and make all
            reasonable efforts in ensuring that the Logistic Partners update the
            tracking status of the purchased Products on a real-time basis. It
            is clarified that we engage third-party service providers to
            effectuate deliveries of the Products and hence, we do not guarantee
            the accuracy or correctness of the tracking status and the status
            may be subject to inconsistencies arising out of time lags in
            updating the information and/ or other technical difficulties which
            are not in our control. In case you have an issue, please reach out
            for help to our customer service team at{" "}
            <b>“support_help@stockxbid.com”</b>.
          </Typography>
          <Typography component="p" className={classes.text}>
            7. We reserve the right to charge or collect shipping fees on
            Products from time to time. Shipping charges may vary based on the
            value of the Product, type of Product, area of delivery, payment
            mechanism, etc. There is a nominal delivery charge of Rs. 129/- on
            orders up to Rs. 25,000/-(after applying all coupon discounts and
            cashback). You agree that we are authorized to collect, on behalf of
            the Logistic Partner, the shipping and delivery fees for the
            delivery service provided by the Logistic Partner. In the event we
            charge shipping fees for the delivery of a purchased Product, such
            shipping fees will not be refunded by us pursuant to any return
            request raised by you. However, we may make exceptions and refund
            the shipping fees in the event a defected, damaged, deficient or
            incorrect Product (for reasons attributable to, and accepted by us
            after due verification in our sole discretion) has been delivered.
            You acknowledge and accept that the title and risk of all Products
            ordered by you shall pass on to you upon the delivery of the
            purchased Products to you. You acknowledge and accept that the
            delivery fees cannot be paid via Cashback, Credits, or coupon codes.
          </Typography>
          <Typography component="p" className={classes.text}>
            8. Return of purchased Products are facilitated through our
            reverse-Logistics Partners. On receipt of the request for return of
            Product on the Platform and the same being duly acknowledged by us,
            our reverse-Logistics Partners shall get in touch with you in order
            to collect the purchased Products from you. We process returns and
            exchanges of purchased Products in accordance with our{" "}
            <Link to={"/pages/Refund-policy"} target="_blank"> Cancellation, Return and Refund Policy</Link>
            .
          </Typography>

          <Typography component="h6" className={classes.heading}>
            CUSTOMER SUPPORT
          </Typography>
          <Typography component="p" className={classes.text}>
            If the User has any concerns, feedback, review, or any request, the
            User is free to contact the Company at Customer care email id:{" "}
            <b>care@stockxbid.com</b> or for quick assistance, the User may use
            the ‘Chat with Us’ option available on the Platform.
          </Typography>
        </Grid>
      </Container>
    </div>
  );
};
