import React from "react";

// material ui
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  dilougDiv: {
    background: "#F7F7F7",
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#202C3B",
      textAlign: "initial",
      background: "#F7F7F7",
    },
    "& em": {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#202C3B",
    },
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
    },
  },
  rootdiv: {
    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
  appBar: {
    position: "relative",
    background: "#53E7BC",
    marginBottom: "1rem",
  },
  title: {
    fontSize: 20,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    color: "#383838",
    margin: "auto",
  },
  lable: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    textAlign: "left",
    color: "#242021",
  },
  value: {
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#66757F",
    padding: theme.spacing(0.5),
    textAlign: "left",
  },
  cardContent: {
    border: "1px solid pink",
    borderRadius: "2px",
    margin: "10px",
  },
  heading: {
    padding: theme.spacing(2),
    position: "sticky",
    top: 0,
    color: "#4A4A4A",
    fontSize: "1.75rem",
    fontWeight: 500,
    fontFamily: "'Fira Sans', sans-serif",
  },
  line: {
    color: "pink",
    margin: "24px 0px auto",
  },
  colorbox: {
    width: "20px",
    height: "20px",
    border: "2px solid #383838",
    borderRadius: "50%",
    margin: "auto",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#50DBBE",
    color: "black",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: "400",
    fontSize: "15px",
  },
  body: {
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export const ViewProductDetails = (props) => {
  const classes = useStyles();
  const { open, productDetails, handleProductDetailsClose } = props;

  return (
    <div className={classes.rootdiv}>
      <Dialog
        fullScreen
        open={open}
        onClose={handleProductDetailsClose}
        className={classes.dilougDiv}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleProductDetailsClose}
              aria-label="close"
              style={{ outline: "none" }}
            >
              <CloseIcon />
            </IconButton>

            <Typography variant="h6" className={classes.title}>
              Product Details
            </Typography>
          </Toolbar>
        </AppBar>

        {productDetails && Object.keys(productDetails).length > 0 && (
          <Grid item lg={12} md={12} xs={12}>
            <Card className={classes.cardContent}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <FormLabel component="legend" className={classes.lable}>
                      Product ID :
                    </FormLabel>
                    <Typography className={classes.value}>
                      {productDetails.id}
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <FormLabel component="legend" className={classes.lable}>
                      Product Name :
                    </FormLabel>
                    <Typography className={classes.value}>
                      {productDetails.description.name}
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <FormLabel component="legend" className={classes.lable}>
                      Category :
                    </FormLabel>
                    <Typography className={classes.value}>
                      {productDetails.categories[0].code}
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <FormLabel component="legend" className={classes.lable}>
                      Parent SKU :
                    </FormLabel>
                    <Typography className={classes.value}>
                      {productDetails.sku}
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <FormLabel component="legend" className={classes.lable}>
                      Brand :
                    </FormLabel>
                    <Typography className={classes.value}>
                      {productDetails.brandName}
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <FormLabel component="legend" className={classes.lable}>
                      Ideal For :
                    </FormLabel>
                    <Typography className={classes.value}>
                      {productDetails.collectionCategory}
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <FormLabel component="legend" className={classes.lable}>
                      Product Groups :
                    </FormLabel>
                    <Typography className={classes.value}>
                      {productDetails.productGroup}
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <FormLabel component="legend" className={classes.lable}>
                      HSN Code :
                    </FormLabel>
                    <Typography className={classes.value}>
                      {productDetails.hsn}
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <FormLabel component="legend" className={classes.lable}>
                      Country :
                    </FormLabel>
                    <Typography className={classes.value}>
                      {productDetails.countryOfOrigin}
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <FormLabel component="legend" className={classes.lable}>
                      Fashion Group :
                    </FormLabel>
                    <Typography className={classes.value}>
                      {productDetails.fashionGroup}
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <FormLabel component="legend" className={classes.lable}>
                      Bargain :
                    </FormLabel>
                    <Typography className={classes.value}>
                      {productDetails.bargain}
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <FormLabel component="legend" className={classes.lable}>
                      Total quantity :
                    </FormLabel>
                    <Typography className={classes.value}>
                      {productDetails.quantity}
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <FormLabel component="legend" className={classes.lable}>
                      Package Type :
                    </FormLabel>
                    <Typography className={classes.value}>
                      {productDetails.packagingSpecifications.packagingType}
                    </Typography>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <FormLabel component="legend" className={classes.lable}>
                      Attribute article specification :
                    </FormLabel>
                    <Typography className={classes.value}>
                      {productDetails.packagingSpecifications.length}l x{" "}
                      {productDetails.packagingSpecifications.width}w x{" "}
                      {productDetails.packagingSpecifications.height}h,{" "}
                      {productDetails.packagingSpecifications.weight}g
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.line}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.heading}>
                    Attribute Details
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">Size</StyledTableCell>
                          <StyledTableCell align="center">Color</StyledTableCell>
                          <StyledTableCell align="center">Price</StyledTableCell>
                          <StyledTableCell align="center">
                            Quantity
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {productDetails.attributes &&
                        productDetails.attributes.length > 0 ? (
                          productDetails.attributes.map((attribute, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" align="center" scope="row">
                                {attribute.optionCode}
                              </TableCell>

                              <TableCell align="center" >
                                {attribute.attributeOptionValues.map(
                                  (color, index) => (
                                    <div style={{padding: "5px"}}>
                                      <p
                                      key={index}
                                      className={classes.colorbox}
                                      style={{
                                        backgroundColor: `${color.colorCode}`,
                                      }}
                                    />
                                    </div>
                                  )
                                )}
                              </TableCell>

                              <TableCell align="center">
                                {attribute.attributeOptionValues.map(
                                  (price, index) => (
                                    <Typography
                                      style={{ fontSize: 13, height: "30px" }}
                                      component="p"
                                      key={index}
                                    >
                                      {price.attributePrice}
                                    </Typography>
                                  )
                                )}
                              </TableCell>

                              <TableCell align="center">
                                {attribute.attributeOptionValues.map(
                                  (quantity, index) => (
                                    <Typography
                                      style={{ fontSize: 13, height: "30px" }}
                                      component="p"
                                      key={index}
                                    >
                                      {quantity.attributeQuantity}
                                    </Typography>
                                  )
                                )}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <StyledTableRow>
                            <StyledTableCell component="th" colSpan={4} align="center" scope="row">
                             Attributes not found
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Dialog>
    </div>
  );
};
