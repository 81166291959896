import React, { useState } from "react";
import { useDispatch } from "react-redux";

/*Import react component */
import { EditAttribute } from "./EditAttribute";
import { AttributeImage } from "./AttributeImage";
import { ConfirmDialog } from "../../ConfirmDialog";

/*Import redux actions */
import { deleteAttributeOption } from "../../../redux/actions/sellerActions";

/* material ui */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { IconButton, Box, capitalize } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import ImageIcon from "@material-ui/icons/Image";
import Tooltip from "@material-ui/core/Tooltip";
import { SnackBar } from "../../SnackBar";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#d5f9ef",
    color: "#3c3c3c",
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: 12,
    fontWeight: "bold",
    letterSpacing: 1,
  },
  body: {
    fontSize: 12,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#2d2b2b",
    fontWeight: 500,
  },
}))(TableCell);

/* material ui css */
const useStyles = makeStyles((theme) => ({
  deleteButton: {
    border: "1px solid #27B23E",
    background: "transparent",
    color: "#27B23E",
    borderRadius: "0.2rem",
    margin: "0 2px auto",
  },
  noFound: {
    color: "#030104",
    textAlign: "center",
  },
  colorbox: {
    width: "20px",
    height: "20px",
    paddingLeft: '10px',
    border: "2px solid #383838",
    borderRadius: "50%",
    margin: "auto",
  },
  table: {
    borderCollapse: 'collapse', // Ensure table cells collapse borders
    '& th, td': {
      border: '1px solid #ddd', // Add a 1px solid border to cells
      padding: '8px', // Adjust padding as needed
      textAlign: 'left', // Adjust text alignment if needed
    },
    '& th': {
      backgroundColor: '#f2f2f2', // Optional: Add a background color to header cells
    },
  },
}));

export const AttributeTable = (props) => {
  const classes = useStyles();
  const { product } = props;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openImgDialog, setOpenImgDialog] = useState(false);
  const [attribute, setAttribute] = useState({
    id: "",
    code: "",
    defaultAttribute: "",
    option: "",
  });
  const [images, setImages] = useState({ id: "", images: [] });

  const [attrObject, setAttrObject] = useState({
    productId: "",
    attributeId: "",
  });
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleOpenDeleteConfirm = (productId, attributeId) => {
    setConfirmOpen(true);
    setAttrObject((prevState) => ({
      ...prevState,
      productId: productId,
      attributeId: attributeId,
    }));
  };

  /* function to handle delete attribute */
  const handleDeleteAttribute = () => {
    dispatch(
      deleteAttributeOption(attrObject.productId, attrObject.attributeId)
    );
  };

  /* function to handle edit product attribute */
  const handleEditAttribute = (id, code, defaultAttribute, option) => {
    setAttribute((prevState) => ({
      ...prevState,
      code: code,
      id: id,
      defaultAttribute: defaultAttribute,
      option: option,
    }));
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleAttributeImages = (images, data) => {
    setImages((prevState) => ({
      ...prevState,
      id: data.attributeId,
      images: images,
    }));
    setOpenImgDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImgDialog(false);
  };

  return (
    <React.Fragment>
      <Box margin={1}>
        <Typography variant="h6"  gutterBottom component="div">
          Attribute List
        </Typography>

        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Item SKU</StyledTableCell>
              <StyledTableCell align="center">Size</StyledTableCell>
              <StyledTableCell align="center">Color</StyledTableCell>
              <StyledTableCell align="center">Price</StyledTableCell>
              <StyledTableCell align="center">Quantity</StyledTableCell>
              <StyledTableCell align="center">Images</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {product.attributes?.length > 0 ? (
              product.attributes.map((attribute, index) => (
                <TableRow key={index}>
                  <StyledTableCell component="th" align="center" scope="row">
                    {attribute.attributeOptionValues 
                    .slice() // Create a shallow copy of the array to avoid mutating the original
                    .sort((a, b) => {
                      // Sort based on the item_sku property
                      const skuA = a.item_sku.toUpperCase(); // Convert to uppercase for case-insensitive sorting
                      const skuB = b.item_sku.toUpperCase();
                
                      if (skuA < skuB) {
                        return -1;
                      }
                      if (skuA > skuB) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((otpion, index) => (
                      <p key={index}>{capitalize(otpion.item_sku)}</p>
                    ))}
                  </StyledTableCell>

                  <StyledTableCell component="th" align="center" scope="row">
                    {attribute.optionCode}
                  </StyledTableCell>

                  <StyledTableCell  component="th" align="center" scope="row" >
                    {attribute.attributeOptionValues.map((otpion, index) => (
                      <div style={{marginBottom: "12px"}}>
                        <p 
                        key={index}
                        className={classes.colorbox}
                        style={{
                          backgroundColor: `${otpion.colorCode}`,
                        }}
                      />
                      </div>
                    ))}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {attribute.attributeOptionValues.map((otpion, index) => (
                      <p key={index}>{otpion.attributePrice}</p>
                    ))}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {attribute.attributeOptionValues.map((otpion, index) => (
                      <p key={index}>{otpion.attributeQuantity}</p>
                    ))}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {attribute.attributeOptionValues.map((attr, index) => (
                      <div key={index} style={{padding: '5px'}}>
                        <Tooltip
                          title="Edit Attribute Image"
                          placement="top"
                          arrow
                        >
                          <IconButton
                            aria-label="delete"
                            className={classes.deleteButton}
                            style={{
                              outline: "none",
                              height: "0px",
                            }}
                            onClick={() =>
                              handleAttributeImages(attr.images, attr)
                            }
                          >
                            <ImageIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ))}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {attribute.attributeDefault
                      ? attribute.attributeOptionValues.map((option, index) => (
                          <div key={index} style={{padding: '5px'}}>
                            {option.optionValueDefault ? (
                              <Tooltip
                                title="Edit Attribute"
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  aria-label="delete"
                                  className={classes.deleteButton}
                                  style={{
                                    outline: "none",
                                    height: "0px",
                                  }}
                                  onClick={(e) =>
                                    handleEditAttribute(
                                      attribute.optionId,
                                      attribute.optionCode,
                                      attribute.attributeDefault,
                                      option
                                    )
                                  }
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <>
                                <Tooltip
                                  title="Delete Attribute"
                                  placement="top"
                                  arrow
                                 
                                >
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.deleteButton}
                                    style={{
                                      outline: "none",
                                      height: "0px",
                                    }}
                                    onClick={(e) =>
                                      handleOpenDeleteConfirm(
                                        product.id,
                                        option.attributeId
                                      )
                                    }
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip
                                  title="Edit Attribute"
                                  placement="top"
                                  arrow
                                >
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.deleteButton}
                                    style={{
                                      outline: "none",
                                      height: "0px",
                                    }}
                                    onClick={(e) =>
                                      handleEditAttribute(
                                        attribute.optionId,
                                        attribute.optionCode,
                                        attribute.attributeDefault,
                                        option
                                      )
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </div>
                        ))
                      : attribute.attributeOptionValues.map((option, index) => (
                          <div key={index}  style={{padding: '4px'}}>
                            <Tooltip
                              title="Delete Attribute"
                              placement="top"
                              arrow
                            >
                              <IconButton
                                aria-label="delete"
                                className={classes.deleteButton}
                                style={{
                                  outline: "none",
                                  height: "0px",
                                }}
                                onClick={(e) =>
                                  handleOpenDeleteConfirm(
                                    product.id,
                                    option.attributeId
                                  )
                                }
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title="Edit Attribute"
                              placement="top"
                              arrow
                            >
                              <IconButton
                                aria-label="delete"
                                className={classes.deleteButton}
                                style={{
                                  outline: "none",
                                  height: "0px",
                                }}
                                onClick={(e) =>
                                  handleEditAttribute(
                                    attribute.optionId,
                                    attribute.optionCode,
                                    attribute.attributeDefault,
                                    option
                                  )
                                }
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ))}
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow key={1}>
                <StyledTableCell colSpan={7} className={classes.noFound}>
                  Add Attributes to show.
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>

      <EditAttribute
        open={open}
        productId={product.id}
        attribute={attribute.option}
        optionId={attribute.id}
        optionCode={attribute.code}
        defaultAttribute={attribute.defaultAttribute}
        handleCloseDialog={handleCloseDialog}
      />

      <AttributeImage
        openImgDialog={openImgDialog}
        handleClose={handleCloseImageDialog}
        images={images.images}
        attributeId={images.id}
      />

      <ConfirmDialog
        title="Delete Attribute?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDeleteAttribute}
      >
        Are you sure you want to delete this Attribute?{" "}
      </ConfirmDialog>
      <SnackBar />
    </React.Fragment>
  );
};
