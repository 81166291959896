import React from "react";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiListItemText-primary": {
      fontSize: 12,
      fontFamily: "'Fira Sans', sans-serif",
    },
    "& .MuiListItemSecondaryAction-root": {
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 600,
      fontSize: 12,
    },
  },
}));

const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

export const ViewBreakUpDialog = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { open, handleClose, order } = props;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={classes.dialog}
    >
      {Object.keys(order).length > 0 && (
        <>
          <DialogTitle
            id="responsive-dialog-title"
            style={{ textAlign: "center" }}
          >
            {"Payment Summary"}
            <img
              // className={classes.img}
              alt="complex"
              src={`${imageBaseUrl}${order.image}`}
              width="50px"
              height="50px"
            />
          </DialogTitle>

          <DialogContent>
            <List>
              <Typography variant="subtitle1" className={classes.title}>
                Per Product
              </Typography>

              <Divider />

              <ListItem style={{ padding: 0 }}>
                <ListItemText>Base Price</ListItemText>
                <ListItemSecondaryAction>
                  {order.orderPriceSummary.basePrice}
                </ListItemSecondaryAction>
              </ListItem>

              <ListItem style={{ padding: 0 }}>
                <ListItemText>GST Tax</ListItemText>
                <ListItemSecondaryAction>
                  {order.orderPriceSummary.gstRate}
                </ListItemSecondaryAction>
                
              </ListItem>

              <Divider />

              <ListItem style={{ padding: 0 }}>
                <ListItemText >Final price</ListItemText>
                <ListItemSecondaryAction style={{ paddingRight: 0 }}>
                  {order.orderPriceSummary.finalPrice}
                </ListItemSecondaryAction>
              </ListItem>

              <Divider />

              <Typography variant="subtitle1" className={classes.title}>
                For {order.orderedQuantity} Products
              </Typography>

              <Divider />

              <ListItem style={{ padding: 0 }}>
                <ListItemText>Final price</ListItemText>
                <ListItemSecondaryAction>
                  Rs.{order.orderPriceSummary.taxTotal}
                </ListItemSecondaryAction>
              </ListItem>

              {/* <ListItem style={{ padding: 0 }}>
                <ListItemText>Tax total</ListItemText>
                <ListItemSecondaryAction>
                  {order.orderPriceSummary.taxTotal}
                </ListItemSecondaryAction>
              </ListItem> */}

              {/* <ListItem style={{ padding: 0 }}>
                <ListItemText>Shipping</ListItemText>
                <ListItemSecondaryAction>
                  {order.orderPriceSummary.shippingTotal}
                </ListItemSecondaryAction>
              </ListItem>

              <ListItem style={{ padding: 0 }}>
                <ListItemText>Service Tax</ListItemText>
                <ListItemSecondaryAction>
                  {order.orderPriceSummary.serviceTax}
                </ListItemSecondaryAction>
              </ListItem>

              <Divider />

              <ListItem style={{ padding: 0 }}>
                <ListItemText>Total</ListItemText>
                <ListItemSecondaryAction>
                  {order.orderPriceSummary.total}
                </ListItemSecondaryAction>
              </ListItem> */}
            </List>
          </DialogContent>

          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
