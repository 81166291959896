import React, { useState } from "react";
import { useHistory } from "react-router";

/* react components */
import { AppBars } from "../../../components/AppBar";
import { CategoryList } from "../../../components/CategoryList";
import { Footer } from "../../../components/Footer";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import Sitemap from "../../Sitemap/sitemap";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
}));

export const PayuFailure = () => {
  const classes = useStyles();
  const history = useHistory();
  const [showSiteMap7, setShowSiteMap7] = useState(false);

  const { errorMsg } = useSelector((state) => state.checkout);

  return (
    <div>
      <CssBaseline />
      <AppBars position="absolute" color="default" className={classes.appBar} />
      {!showSiteMap7 ? (
        <>
          <CategoryList />

          <Container component="main" maxWidth="sm" style={{ height: "50vh" }}>
            <Box
              style={{
                margin: 40,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Oops! Something went wrong.
              </Typography>

              {errorMsg != undefined ? (
                <Typography component="p" variant="subtitle1">
                  {errorMsg}
                </Typography>
              ) : (
                <Typography component="p" variant="subtitle1">
                  While trying to reserve money from your account !! Will be
                  refunded within 48 - 72 hours. During bank holidays it may
                  take longer.
                </Typography>
              )}

              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: 20 }}
                onClick={() => history.push("/")}
              >
                Back to Home
              </Button>
            </Box>
          </Container>
        </>
      ) : (
        <Sitemap />
      )}
      <Footer showSiteMap={showSiteMap7} setShowSiteMap={setShowSiteMap7} />
    </div>
  );
};
