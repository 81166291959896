import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  loading: false,
  error: null,
  availability: null,
  notAvailable: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.DELIVERY_AVAILABILITY_BY_PINCODE:
      return {
        ...state,
        availability: action.payload,
      };
    case ActionTypes.DELIVERY_NOT_AVAILABLE:
      return {
        ...state,
        notAvailable: action.payload.message,
      };
    case ActionTypes.RESET_PINCODE:
      return {
        ...state,
        availability: null,
        notAvailable: null,
      };
    case ActionTypes.DELIVERY_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.CLEAR_DELIVERY_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
