import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

/* redux actions */
import {
  clearInventoryError,
  inventorySnackBar,
  uploadedFileValidation,
} from "../../../redux/actions/InventoryActions";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Loading from "../../../util/spinner/Loading";

const useStyles = makeStyles((theme) => ({
  uploadSection: {
    padding: theme.spacing(2),
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#202C3B",
      textAlign: "initial",
      background: "#F7F7F7",
    },
    "& em": {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#202C3B",
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    textAlign: "center",
  },
  uploadIcon: {
    color: "#4caf50",
  },
  uploadbutton: {
    color: "#fff",
    fontFamily: "'Fira Sans', sans-serif",
    backgroundColor: "#4caf50",
    textTransform: "capitalize",
    border: "none",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#4caf50",
    },
  },
  input: {
    display: "none",
  },
  error: {
    fontSize: 12,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#FF0000",
  },
  validationpaper: {
    padding: theme.spacing(2),
    margin: "auto",
    textAlign: "center",
    marginTop: 10,
    maxHeight: "200px",
    overflow: "auto",
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  validationHeading: {
    fontSize: 24,
    fontWeight: "bold",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#53e7bc",
    textDecoration: "underline",
  },
  listroot: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    "& .MuiListItemText-secondary": {
      fontSize: 13,
      fontWeight: 500,
      fontFamily: "'Fira Sans', sans-serif",
      textAlign: "center",
      margin: "auto",
    },
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "aliceblue",
    padding: 0,
    margin: 6,
  },
  formControl: {
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
    color: "#666666",
    opacity: "0.8",
  },
  menuitem: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#495057",
    "& em": {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#495057",
    },
  },
  dropdownStyle: {
    marginTop: 48,
  },
}));

const productInventory = process.env.REACT_APP_PRODUCT_INVENTORY;
 
export const UploadComponent = () => {
  const classes = useStyles();
  const { fileValidation } = useSelector((state) => state.Inventory);
  const { account } = useSelector((state) => state.auth);
  const { categorylist } = useSelector((state) => state.seller);

  const dispatch = useDispatch();

  /* upload file info */
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [category, setCategory] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [validation, setValidation] = useState(false);
  /* function to cancel file upload  */
  const handleCancel = () => {
    setFile(null);
    setFileName("");
    setCategory("");
    setUploadError("");
    setValidation(false);
    dispatch(clearInventoryError());
  };

  /* function to handle uploaded file vaidation */
  const handleFileValidation = () => {
    let fileTitle = fileName.split(".")[0].split(" ")[0];

    if (fileTitle.toLowerCase() === category.code.toLowerCase()) {
      setValidation(true);
      setUploadError("");
      dispatch(uploadedFileValidation(file, fileTitle.toLowerCase()));
    } else {
      setValidation(false);
      setUploadError("Uploaded file does not belong to a selected category.");
    }
  };

  /* function to handle file upload */
  const handleFileUpload = (e) => {
    let uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setFileName(uploadedFile.name);
    }
  };

  /* clear upload component afert successfull upload */
  const handleClear = () => {
    setFileName(null);
    setFile(null);
    setCategory("");
    setUploadError("");
    setValidation(false);
  };
 
  /* function upload validated xlxs to backend */
  const handleUploadToSave = async () => {
    try {
      setLoading(true)
      const formData = new FormData();
      formData.append("file", file);
      const data = await axios.post(
        `${productInventory}/multipart-file/upload-filePart?type=${category.code.toUpperCase()}&sellerId=${
          account.id
        }`,
        formData
      );

      if (data.status === 200) {
        setLoading(false)
        if (data.data.status === "FAILED") {
          dispatch(inventorySnackBar("error", "Duplicate Sku erros, Please Check The file !!!"));
        } else {
          dispatch(
            inventorySnackBar("success", "Products uploaded successfully.")
          );
          handleClear();
        }
      } else {
        setLoading(false)
        dispatch(
          inventorySnackBar(
            "error",
            "Something went wrong please try again later."
          )
        );
      }
    } catch (error) {
      // console.log(error);
    }
  };
  
  return (
    <div className={classes.uploadSection}>
      <Grid container>
        {loading && <Loading />}
        <Grid item xs={12}>
          <Paper className={classes.paper} variant="outlined" square>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CloudUploadIcon className={classes.uploadIcon} />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  style={{ margin: 8, fontWeight: 600 }}
                >
                  Upload a spreadsheet to import products.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.formControl}
                >
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    className={classes.labelRoot}
                  >
                    Please Select Category*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className={classes.selecteCategory}
                    label="Please Select Category"
                    MenuProps={{
                      classes: { paper: classes.dropdownStyle },
                    }}
                  >
                    {categorylist &&
                      categorylist.filter(cat => cat.visible === true).map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item}
                          className={classes.menuitem}
                        >
                          <em>{item.code}</em>
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>
                    {/* {formErrors.category ? formErrors.category : ""} */}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <input
                  accept=".xlsx"
                  className={classes.input}
                  id="contained-button-file"
                  type="file"
                  onChange={handleFileUpload}
                  disabled={!category || validation}
                  key={Date.now()}
                />

                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    className={classes.uploadbutton}
                    disabled={!category || validation}
                  >
                    {fileName ? fileName : "Choose a File to Upload..."}
                  </Button>
                </label>
              </Grid>

              {uploadError ? (
                <Grid item xs={12}>
                  <Typography className={classes.error}>
                    {uploadError}
                  </Typography>
                </Grid>
              ) : null}
              {fileValidation && fileValidation.length > 0 && <Grid item xs={12}>
                  <Typography className={classes.error}>
                  Please logout and login again! OR Try after sometime!
                  </Typography>
                </Grid>}
              {file && (
                <Grid item xs={12}>
                  {validation ? (
                    <Button
                      variant="contained"
                      onClick={handleUploadToSave}
                      disabled={fileValidation && fileValidation.length > 0}
                      style={{
                        margin: 8,
                        backgroundColor: "#879ceb",
                        color: "#fff",
                      }}
                    >
                      Upload  
                    </Button>
                    
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleFileValidation}
                      style={{
                        margin: 8,
                        backgroundColor: "#879ceb",
                        color: "#fff",
                      }}
                    >
                      Validate
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    onClick={handleCancel}
                    style={{
                      margin: 8,
                      backgroundColor: "#FF0000",
                      color: "#fff",
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      
        {fileValidation && fileValidation.length > 0 && (
          <Paper className={classes.validationpaper} variant="outlined" square>
            <Typography className={classes.validationHeading}>
              Validation Errors
            </Typography>

            <List className={classes.listroot} subheader={<li />}>
              {fileValidation.map((item, index) => (
                <li key={index} className={classes.listSection}>
                  <ul className={classes.ul}>
                    <ListItem>
                      <ListItemText secondary={item} />
                    </ListItem>
                  </ul>
                </li>
              ))}
            </List>
          </Paper>
        )}
      </Grid>
    </div>
  );
};
