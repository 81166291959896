import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

/* Import redux actions */
import { editReview } from "../../redux/actions/customerActions";

/* Import material ui */
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Rating from "@material-ui/lab/Rating";
import Paper from "@material-ui/core/Paper";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  dilougDiv: {
    background: "#F7F7F7",
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "16px",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#495057",
      textAlign: "initial",
      background: "#F7F7F7",
    },
    "& em": {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#495057",
    },
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
    },
  },
  appBar: {
    position: "relative",
    background: "#53E7BC",
    marginBottom: "1rem",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  details: {
    margin: 24,
  },
  reviewTitle: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#383838",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
    fontStretch: "normal",
  },
  productTitlediv: {
    margin: "auto",
  },
  productTitle: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#383838",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
  },
  imagediv: {
    margin: "auto",
  },
  img: {
    width: 150,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  input: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "blue",
      fontSize: 14,
      opacity: 0.4,
    },
  },
  overallTitle: {
    fontSize: 18,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    color: "#000000",
  },
  featureslable: {
    fontSize: 16,
    fontWeight: 500,
    color: "#5e5e5e",
    width: 120,
    padding: 6,
    fontFamily: "'Fira Sans', sans-serif",
  },
  submitButton: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.29,
    textAlign: "center",
    background: "#4AD0AA",
    color: "#FFFFFF",
    textTransform: "none",
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  cancelButton: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.29,
    textAlign: "center",
    background: "#ffffff",
    color: "#3b3b3b",
    textTransform: "none",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    border: "1px solid #3b3b3b",
    "&:hover": {
      background: "#ffffff",
      color: "#3b3b3b",
    },
  },
  spacing: {
    marginTop: theme.spacing(1),
  },
  notFoundBlock: {
    width: "80%",
    margin: "auto",
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    background: "#F7F7F7",
    textAlign: "center",
  },
  notFoundText: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#747474",
    fontFamily: "'Fira Sans', sans-serif",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/* Image base url */
const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;


export const EditReview = (props) => {
  const classes = useStyles();
  const { open, onClose, item } = props;
  const { account } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(item).length !== 0) {
      setReview(item.description);
      setRating(item.rating);
      setQuality(item.product_quality);
      setValues(item.product_values);
      setMatches(item.product_matches);
      setPros(item.product_pros);
      setCons(item.product_cons);
      setCommentSeller(item.userComment);
      setSellerRating(item.userRating);
      setProductId(item.productId);
      setTitle(item.product.description.title);
      setImage(
        item.product.attributes[0].attributeOptionValues[0].images[0].imageUrl
      );
      setId(item.id);
    }
  }, [item]); /* eslint-disable-line react-hooks/exhaustive-deps */


  const handleCancel = () => {
    onClose();
    setReview(item.description);
    setRating(item.rating);
    setQuality(item.product_quality);
    setValues(item.product_values);
    setMatches(item.product_matches);
    setPros(item.product_pros);
    setCons(item.product_cons);
    setCommentSeller(item.userComment);
    setSellerRating(item.userRating);

  }

  const [id, setId] = useState("");
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [productId, setProductId] = useState("");
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const [quality, setQuality] = useState(0);
  const [values, setValues] = useState(0);
  const [matches, setMatches] = useState(0);
  const [pros, setPros] = useState("");
  const [cons, setCons] = useState("");
  const [commentSeller, setCommentSeller] = useState("");
  const [sellerRating, setSellerRating] = useState(0);
  const [formErrors, setFormErrors] = useState({});

  const handleValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (!review) {
      inputsValid = false;
      formErrors["review"] = "this field is required";
    }


    if (!pros) {
      inputsValid = false;
      formErrors["pros"] = "this field is required";
    }
    

    if (!cons) {
      inputsValid = false;
      formErrors["cons"] = "this field is required";
    }

   

    if (!commentSeller) {
      inputsValid = false;
      formErrors["commentSeller"] = "this field is required";
    }

   
    setFormErrors(formErrors);
    return inputsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      const reviewData = {
        id: id,
        customerId: account.id,
        description: review,
        language: "en",
        productId: productId,
        product_cons: cons,
        product_pros: pros,
        product_matches: matches,
        product_quality: quality,
        product_values: values,
        rating: rating,
        userComment: commentSeller,
        userRating: sellerRating,
        date: new Date(),
      };

      dispatch(editReview(reviewData));
      onClose();
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      className={classes.dilougDiv}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            style={{ outline: "none" }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Back
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid container justify="center" alignItems="center">
        <Grid item xs={10}>
          <div className={classes.details}>
            <Typography component="h6" className={classes.reviewTitle}>
              Edit your review
            </Typography>
          </div>
        </Grid>

        <Grid item xs={10}>
          <div style={{ display: "flex" }}>
            <div className={classes.imagediv}>
              <img
                className={classes.img}
                alt="complex"
                src={`${imageBaseUrl}${image}`}
              />
            </div>

            <div className={classes.productTitlediv}>
              <Typography component="h6" className={classes.productTitle}>
                {title}
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid item xs={10}>
          <div className={classes.details}>
            {item && Object.keys(item).length !== 0 ? (
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className={classes.spacing}>
                  <FormLabel>
                    Write your review <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="outlined-multiline-static"
                    placeholder="What did you like or dislike? What did you use this product for?"
                    multiline
                    fullWidth={true}
                    rows={10}
                    variant="outlined"
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    InputProps={{
                      classes: { input: classes.input },
                    }}
                    helperText={formErrors.review ? formErrors.review : ""}
                  />
                </div>

                <div className={classes.spacing}>
                  <Typography className={classes.overallTitle}>
                    Overall rating <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Rating
                    name="rating"
                    value={rating}
                    precision={1}
                    onChange={(e, newValue) => setRating(newValue)}
                    size="large"
                  />
                </div>

                <div className={classes.spacing}>
                  <Typography className={classes.overallTitle}>
                    Features
                  </Typography>

                  <div style={{ display: "flex" }}>
                    <Typography className={classes.featureslable}>
                      Quality<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Rating
                      name="quality"
                      value={quality}
                      precision={1}
                      onChange={(e, newValue) => setQuality(newValue)}
                      size="large"
                    />
                  </div>

                  <div style={{ display: "flex" }}>
                    <Typography className={classes.featureslable}>
                      Values<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Rating
                      name="values"
                      value={values}
                      precision={1}
                      onChange={(e, newValue) => setValues(newValue)}
                      size="large"
                    />
                  </div>

                  <div style={{ display: "flex" }}>
                    <Typography className={classes.featureslable}>
                      Matches<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Rating
                      name="matches"
                      value={matches}
                      precision={1}
                      onChange={(e, newValue) => setMatches(newValue)}
                      size="large"
                    />
                  </div>
                </div>

                <div className={classes.spacing}>
                  <Typography className={classes.overallTitle}>
                    Seller rating
                  </Typography>
                  <Rating
                    name="sellerRating"
                    value={sellerRating}
                    precision={1}
                    onChange={(e, newValue) => setSellerRating(newValue)}
                    size="large"
                  />
                </div>

                <div className={classes.spacing}>
                  <FormLabel>
                    Add Pros of the product{" "}
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="pros"
                    name="pros"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    placeholder="Ex: color, bargain"
                    value={pros}
                    onChange={(e) => setPros(e.target.value)}
                    InputProps={{
                      classes: { input: classes.input },
                    }}
                    helperText={formErrors.pros ? formErrors.pros : ""}
                  />
                </div>

                <div className={classes.spacing}>
                  <FormLabel>
                    Add Cons of the product{" "}
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="cons"
                    name="cons"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    placeholder="Ex: size, delivery"
                    value={cons}
                    onChange={(e) => setCons(e.target.value)}
                    InputProps={{
                      classes: { input: classes.input },
                    }}
                    helperText={formErrors.cons ? formErrors.cons : ""}
                  />
                </div>

                <div className={classes.spacing}>
                  <FormLabel>
                    Comment Seller <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="commentSeller"
                    name="commentSeller"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    placeholder="Ex: good, bad"
                    value={commentSeller}
                    onChange={(e) => setCommentSeller(e.target.value)}
                    InputProps={{
                      classes: { input: classes.input },
                    }}
                    helperText={
                      formErrors.commentSeller ? formErrors.commentSeller : ""
                    }
                  />
                </div>

                <Button
                  type="button"
                  variant="contained"
                  className={classes.cancelButton}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submitButton}
                >
                  Submit
                </Button>
              </form>
            ) : (
              <Paper className={classes.notFoundBlock}>
                <Typography component="p" className={classes.notFoundText}>
                  Product not found for this ID.
                </Typography>
              </Paper>
            )}
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};
