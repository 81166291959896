import { reOrderCategories } from "../../util/commonUtil/commonUtil";
import { ActionTypes } from "../constants/actionTypes";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

/* get All product groups to home page */
export const getAllProductGroups = () => (dispatch) => {
  dispatch({ type: ActionTypes.LOADING_HOME_PAGE_UI });

  const fetchGroups = fetch(`${baseUrl}/catalogs/products`);
  const fetchCategories = fetch(`${baseUrl}/category`);

  Promise.all([fetchGroups, fetchCategories])
    .then((response) => {
      return Promise.all(response.map((result) => result.json()));
    })
    .then(([groups, categories]) => {
      categories.categories = reOrderCategories(categories.categories);
      dispatch({
        type: ActionTypes.PRODUCT_GROUPS,
        payload: { groups, categories },
      });
    })
    .catch((err) => {
      dispatch({ type: ActionTypes.SET_HOME_PAGE_ERROR });
    });
};

export const getProductsByGroupWithFilter =
  (filterData) => async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.LOADING_HOME_PAGE_UI });
      const sortby = filterData.sortby === "all" ? "id" : filterData.sortby;

      var url;
      if(filterData.queryParam !== undefined && filterData.queryParam  !== '') {
        url =  `${baseUrl}/search/filter/products/?availability=${filterData.available}&bargain=${filterData.type}&brand=${filterData.brand}&categoryCode=${filterData.category}&query=${filterData.queryParam}&pageSize=${filterData.pageSize}&pageNumber=${filterData.pageNumber}&sortBy=${sortby}&gender=${filterData.gender}&size=${filterData.size}`;
      } else {
        url = `${baseUrl}/version/v2/filter/products/?availability=${filterData.available}&bargain=${filterData.type}&brand=${filterData.brand}&categoryCode=${filterData.category}&groupName=${filterData.group}&pageSize=${filterData.pageSize}&pageNumber=${filterData.pageNumber}&sortBy=${sortby}&defaultGroup=${filterData.defaultGroup}&size=${filterData.size}&gender=${filterData.gender}&query=${filterData.queryParam}`;
      }

      const { data } = await axios.get(url);

      dispatch({
        type: ActionTypes.PRODUCT_LISTING_WITH_FILTERS,
        payload: data,
      });
    } catch (error) {
      // console.log(error);
    }
  };

/* function to get all filters to product listing page */
export const getAllFilters = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseUrl}/filters`);
    dispatch({ type: ActionTypes.GET_PRODUCT_FILTERS, payload: data });
  } catch (error) {
    // console.log(error);
  }
};

export const getBannerImages = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseUrl}/banners/images`);
    dispatch({ type: ActionTypes.GET_BANNERS, payload: data.banners });
  } catch (error) {
    // console.log(error);
  }
};

export const getCategoryList = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseUrl}/category`);
    dispatch({ type: ActionTypes.GET_CATEGORY_LIST, payload: data });
  } catch (error) {
    // console.log(error);
  }
};
