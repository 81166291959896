import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import LinearProgress from "@material-ui/core/LinearProgress";
import Avatar from "@material-ui/core/Avatar";
import { getReviewsByProduct } from "../../redux/actions/productActions";
import moment from "moment";
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#36dead",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  reviewTitle: {
    fontSize: 22,
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#212121",
    margin: "8px 0px 0px 8px",
  },
  ratingDiv: {
    marginBottom: theme.spacing(2),
  },
  averageRating: {
    fontSize: "24px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    lineHeight: 1.22,
    color: "#5e5e5e",
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  averageRatingBlock: {
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  reviewscount: {
    fontSize: "12px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    color: "#5e5e5e",
    lineHeight: 1.29,
  },
  ratingtext: {
    fontSize: "12px",
    color: "#5e5e5e",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    lineHeight: 1.29,
    marginLeft: "10px",
    width: 90,
  },
  featuresDiv: {
    marginTop: "12px",
  },
  features: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: 1.19,
    color: "#5e5e5e",
    marginLeft: "15px",
  },
  profileImg: {
    margin: "auto",
  },
  reviewuser: {
    fontSize: 16,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    lineHeight: 1.19,
    color: "#5e5e5e",
    textAlign: "center",
  },
  verified: {
    textAlign: "center",
    margin: "auto",
    fontSize: 12,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: "bold",
    color: "#36dead",
    lineHeight: 1.25,
  },
  text: {
    textAlign: "center",
    margin: "auto",
    fontSize: 12,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#5e5e5e",
    lineHeight: 1.25,
  },
  reviewtext: {
    margin: "auto",
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.29,
    color: "#5e5e5e",
    marginTop: "12px",
  },
  review: {
    margin: "auto",
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    lineHeight: 1.29,
    color: "#5e5e5e",
  },
  tagstitle: {
    margin: "auto",
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    lineHeight: 1.29,
    color: "#5e5e5e",
  },
  tagstext: {
    margin: "auto",
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    lineHeight: 1.29,
    color: "#5e5e5e",
  },
}));

export const Reviews = (props) => {
  const classes = useStyles();
  const { productId } = props;
  const { reviews } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReviewsByProduct(productId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {Object.keys(reviews).length > 0 && (
        <Fragment>
          <Grid item xs={12}>
            <Grid
              container
              spacing={1}
              style={{
                border: "1px solid #f0f0f0",
                margin: "10px 0px auto",
              }}
            >
              <Grid item xs={12}>
                <Typography className={classes.reviewTitle}>
                Reviews and Ratings 
                </Typography>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <div className={classes.ratingDiv}>
                  <Typography className={classes.averageRating}>
                    {reviews.rating}
                  </Typography>
                  <div className={classes.averageRatingBlock}>
                    <Rating
                      name="half-rating-read"
                      defaultValue={reviews.rating}
                      precision={0.5}
                      readOnly
                      style={{ fontSize: "1.2rem" }}
                    />
                    <Typography className={classes.reviewscount}>
                      ({reviews.rating_count} Reviews)
                    </Typography>
                  </div>
                </div>
              </Grid>

              <Grid item lg={5} md={5} sm={6} xs={12}>
                <div className={classes.progressDiv}>
                  <Box display="flex" alignItems="center" padding={"8px"}>
                    <Typography className={classes.ratingtext}>
                      5 Star(
                      {reviews.ratings.five_start
                        ? reviews.ratings.five_start
                        : 0}
                      )
                    </Typography>

                    <Box width="65%" margin={"auto"}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          reviews.ratings.five_start
                            ? reviews.ratings.five_start
                            : 0
                        }
                      />
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" padding={"8px"}>
                    <Typography className={classes.ratingtext}>
                      4 Star(
                      {reviews.ratings.four_start
                        ? reviews.ratings.four_start
                        : 0}
                      )
                    </Typography>

                    <Box width="65%" margin={"auto"}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          reviews.ratings.four_start
                            ? reviews.ratings.four_start
                            : 0
                        }
                      />
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" padding={"8px"}>
                    <Typography className={classes.ratingtext}>
                      3 Star(
                      {reviews.ratings.three_start
                        ? reviews.ratings.three_start
                        : 0}
                      )
                    </Typography>

                    <Box width="65%" margin={"auto"}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          reviews.ratings.three_start
                            ? reviews.ratings.three_start
                            : 0
                        }
                      />
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" padding={"8px"}>
                    <Typography className={classes.ratingtext}>
                      2 Star(
                      {reviews.ratings.two_start
                        ? reviews.ratings.two_start
                        : 0}
                      )
                    </Typography>

                    <Box width="65%" margin={"auto"}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          reviews.ratings.two_start
                            ? reviews.ratings.two_start
                            : 0
                        }
                      />
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" padding={"8px"}>
                    <Typography className={classes.ratingtext}>
                      1 Star(
                      {reviews.ratings.one_start
                        ? reviews.ratings.one_start
                        : 0}
                      )
                    </Typography>

                    <Box width="65%" margin={"auto"}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          reviews.ratings.one_start
                            ? reviews.ratings.one_start
                            : 0
                        }
                      />
                    </Box>
                  </Box>
                </div>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <div className={classes.featuresDiv}>
                  <Typography className={classes.features}>Features</Typography>

                  <Box display="flex" alignItems="center" padding={"4px"}>
                    <Typography className={classes.ratingtext}>
                      Quality
                    </Typography>

                    <Box margin={"auto"}>
                      <Rating
                        name="size-small"
                        defaultValue={reviews.product_quality}
                        size="small"
                        precision={0.5}
                        readOnly
                      />
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" padding={"4px"}>
                    <Typography className={classes.ratingtext}>
                      Values
                    </Typography>

                    <Box margin={"auto"}>
                      <Rating
                        name="size-small"
                        defaultValue={reviews.product_values}
                        size="small"
                        precision={0.5}
                        readOnly
                      />
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" padding={"4px"}>
                    <Typography className={classes.ratingtext}>
                    Matches
                    </Typography>

                    <Box margin={"auto"}>
                      <Rating
                        name="size-small"
                        defaultValue={reviews.product_matches}
                        size="small"
                        precision={0.5}
                        readOnly
                      />
                    </Box>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {reviews.reviews && reviews.reviews.length > 0 && (
              <Fragment>
                {reviews.reviews.map((review, index) => (
                  <Grid
                    container
                    spacing={1}
                    style={{
                      margin: "auto",
                      border: "1px solid rgb(240, 240, 240)",
                      padding: 8,
                    }}
                    key={index}
                  >
                    <Grid item style={{ margin: "auto" }}>
                      <Avatar
                        src="/broken-image.jpg"
                        className={classes.profileImg}
                      />
                      <Typography className={classes.reviewuser}>
                        {review.customer.firstName}
                      </Typography>
                      <Rating
                        name="size-small"
                        defaultValue={review.rating}
                        size="small"
                        readOnly
                        style={{ margin: 10 }}
                      />

                      <Typography className={classes.verified}>
                        VERIFIED PURCHASE
                      </Typography>
                      <Typography className={classes.text}>
                        {moment(review.date).format('DD-MM-YYYY')}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm container>
                      <Grid item xs>
                        <Typography className={classes.reviewtext}>
                          {review.description}
                        </Typography>

                        <Grid
                          item
                          xs={12}
                          container
                          style={{ marginTop: "12px" }}
                        >
                          <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Typography className={classes.review}>
                              Quality
                            </Typography>
                            <Rating
                              name="size-small"
                              defaultValue={review.product_quality}
                              size="small"
                              precision={0.5}
                              readOnly
                            />
                          </Grid>

                          <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Typography className={classes.review}>
                              Values
                            </Typography>
                            <Rating
                              name="size-small"
                              defaultValue={review.product_values}
                              size="small"
                              precision={0.5}
                              readOnly
                            />
                          </Grid>

                          <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Typography className={classes.review}>
                              Matches
                            </Typography>
                            <Rating
                              name="size-small"
                              defaultValue={review.product_matches}
                              size="small"
                              precision={0.5}
                              readOnly
                            />
                          </Grid>
                        </Grid>

                        <Typography>
                          <Box component="span" className={classes.tagstitle}>
                            Pros
                          </Box>
                          <Box component="span" className={classes.tagstext}>
                            : {review.product_pros}
                          </Box>
                        </Typography>

                        <Typography>
                          <Box component="span" className={classes.tagstitle}>
                            Cons
                          </Box>
                          <Box component="span" className={classes.tagstext}>
                            : {review.product_cons}
                          </Box>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Fragment>
            )}
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};
