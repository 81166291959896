import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SnackBar } from "../../../components/SnackBar";

/* Redux actions */
import {
  alertSnackBar,
  updateCustomerBillingAddress,
  updateCustomerDeliveryAddress,
} from "../../../redux/actions/customerActions";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import CardHeader from "@material-ui/core/CardHeader";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Axios from "axios";
// import { InputAdornment } from "@material-ui/core";
// import NotListedLocationOutlinedIcon from "@material-ui/icons/NotListedLocationOutlined";
import { Autocomplete } from "@material-ui/lab";
import { isEmptyObject } from "jquery";
import {
  getCities,
  getPinCodes,
  getStates,
} from "../../../redux/actions/otherActions";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiAutocomplete-endAdornment": {
      top: "calc(50% - 30px)",
    },
    ".MuiAutocomplete-listbox": {
      fontSize: "12px",
    },
  },
  card: {
    border: "1px solid #d6d6d6",
    "& .MuiCardHeader-root": {
      backgroundColor: "#53E7BC",
      padding: "0 16px",
    },
    "& .MuiCardHeader-title": {
      fontSize: "14px",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 600,
    },
    "& .MuiFab-sizeSmall": {
      height: 25,
    },
  },
  notfound: {
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#3b3b3b",
    opacity: 0.5,
    textAlign: "center",
  },
  adresstext: {
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
    padding: 4,
  },
  backtocart: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  lable: {
    textAlign: "start",
    margin: "10px 0px",
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#000000",
    fontWeight: 600,
  },
  labelRoot: {
    fontSize: 12,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#666666",
  },
}));

const ALPHA_ONLY = /^[a-zA-Z\s]+$/;
const NUMERIC_ONLY = /^[0-9]+$/;
const AlPHANUMERIC_ONLY = /^[ A-Za-z0-9_@./#&+-]+$/;
const SPECIAL_ONLY = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
const NUMERIC_SPECIAL_ONLY = /^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;

export const Address = (props) => {
  const classes = useStyles();
  const { account } = props;

  const dispatch = useDispatch();
  const { pincodes, cities, states } = useSelector((state) => state.Others);
  const [editDelivery, setEditDelivery] = useState(false);
  const [editBilling, setEditBilling] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  /* delivery address */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [stateSeleted, setStateSeleted] = useState();
  const [citySeleted, setCitySeleted] = useState();
  const [pinSeleted, setPinSeleted] = useState();

  /* billing address */
  const [billingFirstName, setBillingFirstName] = useState("");
  const [billingLastName, setBillingLastName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingPostalCode, setBillingPostalCode] = useState("");
  const [billingStateProvince, setBillingStateProvince] = useState("");
  const [billingPhone, setBillingPhone] = useState("");
  const [stateBillingSeleted, setStateBillingSeleted] = useState();
  const [cityBillingSeleted, setCityBillingSeleted] = useState();
  const [pinBillingSeleted, setPinBillingSeleted] = useState();

  useEffect(() => {
    setFirstName(account.delivery ? account.delivery.firstName : "");
    setLastName(account.delivery ? account.delivery.lastName : "");
    setMobile(account.delivery ? account.delivery.phone : "");
    setPostalCode(account.delivery ? account.delivery.postalCode : "");
    setAddress(account.delivery ? account.delivery.address : "");
    setStateProvince(account.delivery ? account.delivery.stateProvince : "");
    setCity(account.delivery ? account.delivery.city : "");
    setCitySeleted(account.delivery ? account.delivery : "");
    setPinSeleted(account.delivery ? account.delivery : "");
    setStateSeleted(account.delivery ? account.delivery : "");
    setBillingFirstName(account.billing.firstName);
    setBillingLastName(account.billing.lastName);
    setBillingPhone(account.billing.phone);
    setBillingPostalCode(
      account.billing.postalCode ? account.billing.postalCode : ""
    );
    setBillingAddress(account.billing.address ? account.billing.address : "");
    setBillingCity(account.billing.city ? account.billing.city : "");
    setBillingStateProvince(
      account.billing.stateProvince ? account.billing.stateProvince : ""
    );
    setStateBillingSeleted(account.billing ? account.billing : "");
    setCityBillingSeleted(account.billing ? account.billing : "");
    setPinBillingSeleted(account.billing ? account.billing : "");
  }, [account]);

  /* Delivery address inputs validation */
  const deliveryAddressValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (!firstName) {
      inputsValid = false;
      formErrors["firstName"] = "This field is required";
    }

    if (!lastName) {
      inputsValid = false;
      formErrors["lastName"] = "This field is required";
    }

    if (!address) {
      inputsValid = false;
      formErrors["address"] = "This field is required";
    }


    if (mobile.length !== 10) {
      inputsValid = false;

      formErrors["mobile"] = "mobile number should be 10 digit";
    }

    if (!mobile) {
      inputsValid = false;

      formErrors["mobile"] = "This field is required";
    }

    if (!city) {
      inputsValid = false;
      formErrors["city"] = "This field is required";
    }

    if (!postalCode) {
      inputsValid = false;
      formErrors["postalCode"] = "This field is required";
    }

    if (!stateProvince) {
      inputsValid = false;
      formErrors["stateProvince"] = "This field is required";
    }

    setFormErrors(formErrors);
    return inputsValid;
  };

  const handleClickState = (state) => {
    if (!isEmptyObject(state)) {
      setStateSeleted(state);
      setStateProvince(state.hubState);
      setCitySeleted()
      setPinSeleted()
      setCity()
      setPostalCode()
      dispatch(getCities(state.hubState));
    }
  };

  const handleClickCity = (city) => {
    if (!isEmptyObject(city)) {
      setCitySeleted(city);
      setCity(city.hubCity);
      setPinSeleted()
      setPostalCode()
      dispatch(getPinCodes(city.hubCity));
    }
  };

  const handleClickPinCode = (pinCode) => {
    if (!isEmptyObject(pinCode)) {
      setPinSeleted(pinCode);
      setPostalCode(pinCode.pincode);
    }
  };

  /* function to handle edit delivery address */
  const handleEditDeliveryAddress = async () => {
    if (deliveryAddressValidation()) {
      const { data } = await Axios.get(
        `${process.env.REACT_APP_SHIPPING_URL}/api/v1/searchByPinCode/${postalCode}`
      );

      if (data.pincode === postalCode) {
        const addressData = {
          delivery: {
            address: address,
            billingAddress: false,
            bilstateOther: "No",
            city: city,
            company: "",
            country: "IN",
            countryCode: "IN",
            firstName: firstName,
            lastName: lastName,
            postalCode: postalCode,
            phone: mobile,
            stateProvince: stateProvince,
            zone: "IN",
          },
        };

        dispatch(updateCustomerDeliveryAddress(addressData));

        setEditDelivery(false);
        setFormErrors({});
      } else {
        dispatch(
          alertSnackBar(
            "error",
            "Unfortunately we do not ship to your pincode."
          )
        );
      }
    }
  };

  /* Billing address input validation */
  const billingAddressValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (!billingFirstName) {
      inputsValid = false;
      formErrors["billingFirstName"] = "This field is required";
    }

    if (!billingLastName) {
      inputsValid = false;
      formErrors["billingLastName"] = "This field is required";
    }

    if (!billingAddress) {
      inputsValid = false;
      formErrors["billingAddress"] = "This field is required";
    }



    if (!billingPhone) {
      inputsValid = false;
      formErrors["billingPhone"] = "This field is required";
    }

    if (!billingCity) {
      inputsValid = false;
      formErrors["billingCity"] = "This field is required";
    }

    if (!billingPostalCode) {
      inputsValid = false;
      formErrors["billingPostalCode"] = "This field is required";
    }

    if (!billingStateProvince) {
      inputsValid = false;
      formErrors["billingStateProvince"] = "This field is required";
    }

    setFormErrors(formErrors);
    return inputsValid;
  };

  const handleClickBillingState = (state) => {
    if (!isEmptyObject(state)) {
      setStateBillingSeleted(state);
      setBillingStateProvince(state.hubState);
      setCityBillingSeleted();
      setPinBillingSeleted();
      setBillingCity()
      setBillingPostalCode()
      dispatch(getCities(state.hubState));
    }
  };

  const handleClickBillingCity = (city) => {
    if (!isEmptyObject(city)) {
      setCityBillingSeleted(city);
      setBillingCity(city.hubCity);
      setPinBillingSeleted()
      setBillingPostalCode()
      dispatch(getPinCodes(city.hubCity));
    }
  };

  const handleClickBillingPinCode = (pinCode) => {
    if (!isEmptyObject(pinCode)) {
      setPinBillingSeleted(pinCode);

      setBillingPostalCode(pinCode.pincode);
    }
  };

  /* function to handle edit Billing address */
  const handleEditBillingAddress = () => {
    if (billingAddressValidation()) {
      const addressData = {
        billing: {
          address: billingAddress,
          billingAddress: true,
          bilstateOther: "No",
          city: billingCity,
          company: "",
          country: "IN",
          countryCode: "IN",
          firstName: billingFirstName,
          lastName: billingLastName,
          postalCode: billingPostalCode,
          phone: billingPhone,
          stateProvince: billingStateProvince,
          zone: "IN",
        },
      };

      dispatch(updateCustomerBillingAddress(addressData));
      setEditBilling(false);
      setFormErrors({});
    }
  };

  useEffect(() => {
    dispatch(getStates());
  }, []);

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card className={classes.card}>
              <CardHeader
                title="Delivery address"
                action={
                  <IconButton
                    aria-label="settings"
                    onClick={() => setEditDelivery(!editDelivery)}
                  >
                    {!editDelivery ? (
                      <EditIcon size="small" style={{ color: "#212121" }} />
                    ) : (
                      <HighlightOffIcon
                        size="small"
                        style={{ color: "#212121" }}
                      />
                    )}
                  </IconButton>
                }
              />

              <CardContent>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <React.Fragment>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <InputLabel className={classes.lable}>
                          First Name *
                        </InputLabel>
                        {!editDelivery && (
                          <Typography className={classes.adresstext}>
                            {account?.delivery?.firstName}{" "}
                          </Typography>
                        )}

                        {editDelivery && (
                          <TextField
                            id="firstName"
                            name="firstName"
                            variant="outlined"
                            size="small"
                            value={firstName}
                            onChange={(event) => {
                              const value = event.target.value;
                              if (value !== "" && !ALPHA_ONLY.test(value)) {
                                return;
                              }
                              setFirstName(value);
                            }}
                            InputProps={{
                              autoComplete: "off",
                              classes: {
                                root: classes.labelRoot,
                              },
                            }}
                            helperText={
                              formErrors.firstName ? formErrors.firstName : ""
                            }
                          />
                        )}
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <InputLabel className={classes.lable}>
                          Last Name *
                        </InputLabel>
                        {!editDelivery && (
                          <Typography className={classes.adresstext}>
                            {account?.delivery?.lastName}
                          </Typography>
                        )}
                        {editDelivery && (
                          <TextField
                            id="lastName"
                            name="lastName"
                            variant="outlined"
                            size="small"
                            value={lastName}
                            onChange={(event) => {
                              const value = event.target.value;
                              if (value !== "" && !ALPHA_ONLY.test(value)) {
                                return;
                              }
                              setLastName(value);
                            }}
                            InputProps={{
                              autoComplete: "off",
                              classes: {
                                root: classes.labelRoot,
                              },
                            }}
                            helperText={
                              formErrors.lastName ? formErrors.lastName : ""
                            }
                          />
                        )}
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <InputLabel className={classes.lable}>
                          Address *
                        </InputLabel>
                        {!editDelivery && (
                          <Typography className={classes.adresstext}>
                            {account?.delivery?.address}
                          </Typography>
                        )}
                        {editDelivery && (
                          <TextField
                            id="address"
                            name="address"
                            variant="outlined"
                            size="small"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            InputProps={{
                              autoComplete: "off",
                              classes: {
                                root: classes.labelRoot,
                              },
                            }}
                            helperText={
                              formErrors.address ? formErrors.address : ""
                            }
                          />
                        )}
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <InputLabel className={classes.lable}>
                          Phone Number *
                        </InputLabel>
                        {!editDelivery && (
                          <Typography className={classes.adresstext}>
                            {account?.delivery?.phone}{" "}
                          </Typography>
                        )}

                        {editDelivery && (
                          <TextField
                            id="mobile"
                            name="mobile"
                            variant="outlined"
                            size="small"
                            value={mobile}
                            onChange={(event) => {
                              const value = event.target.value;
                              if (value !== "" && !NUMERIC_ONLY.test(value)) {
                                return;
                              }
                              setMobile(value);
                            }}
                            inputProps={{
                              maxLength: 10,
                            }}
                            InputProps={{
                              autoComplete: "off",
                              classes: {
                                root: classes.labelRoot,
                              },
                            }}
                            helperText={
                              formErrors.mobile ? formErrors.mobile : ""
                            }
                          />
                        )}
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <InputLabel className={classes.lable}>
                          State *
                        </InputLabel>

                        {!editDelivery && (
                          <Typography className={classes.adresstext}>
                            {account?.delivery?.stateProvince}
                          </Typography>
                        )}
                        {editDelivery && (
                          <Autocomplete
                            id="asynchronous-demo"
                            classes={{
                              option: classes.option,
                              noOptions: classes.noOptions,
                            }}
                            ListboxProps={{ style: { maxHeight: 150, textTransform: "uppercase" } }}
                            options={states}
                            value={stateSeleted}
                            getOptionSelected={(option, value) =>
                              option.hubState === value.stateProvince
                            }
                            getOptionLabel={(option) =>
                              option.hubState !== "HubState"
                                  ? option.hubState
                                    ? option.hubState
                                    : stateSeleted.stateProvince
                                  : ""
                            }
                            onChange={(event, newValue) => {
                              handleClickState(newValue);
                            }}
                            style={{ width: "100%"}}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                style={{ width: 150, height: 29 }}
                                InputProps={{
                                  ...params.InputProps,
                                  autoComplete: "new-password",
                                  classes: {
                                    root: classes.labelRoot,
                                  },
                                }}
                                helperText={
                                  formErrors.stateProvince
                                    ? formErrors.stateProvince
                                    : ""
                                }
                              />
                            )}
                          />

                          // <TextField
                          //   id="stateProvince"
                          //   name="stateProvince"
                          //   variant="outlined"
                          //   size="small"
                          //   value={stateProvince}
                          //   onChange={(e) => setStateProvince(e.target.value)}
                          //   InputProps={{
                          //     autoComplete: "off",
                          //     classes: {
                          //       root: classes.labelRoot,
                          //     },
                          //   }}
                          //   helperText={
                          //     formErrors.stateProvince
                          //       ? formErrors.stateProvince
                          //       : ""
                          //   }
                          // />
                        )}
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <InputLabel className={classes.lable}>
                          City *
                        </InputLabel>

                        {!editDelivery && (
                          <Typography className={classes.adresstext}>
                            {account?.delivery?.city}
                          </Typography>
                        )}

                        {editDelivery && (
                          <Autocomplete
                            id="asynchronous-demo"
                            classes={{
                              option: classes.option,
                              noOptions: classes.noOptions,
                            }}
                            ListboxProps={{ style: { maxHeight: 150,  textTransform: "uppercase" } }}
                            options={cities || []}
                            value={citySeleted || []}
                            getOptionSelected={(option, value) =>
                              option.hubCity === value.city
                            }
                            getOptionLabel={(option) =>
                              option.hubCity
                                ? option.hubCity
                                : stateSeleted.city
                            }
                            onChange={(event, newValue) => {
                              handleClickCity(newValue);
                            }}
                            style={{ width: "100%"}}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                style={{ width: 150, height: 29 }}
                                InputProps={{
                                  ...params.InputProps,
                                  autoComplete: "new-password",
                                  classes: {
                                    root: classes.labelRoot,
                                  },
                                }}
                                helperText={
                                  formErrors.city ? formErrors.city : ""
                                }
                              />
                            )}
                          />

                          // <TextField
                          //   id="city"
                          //   name="city"
                          //   variant="outlined"
                          //   size="small"
                          //   value={city}
                          //   onChange={(e) => setCity(e.target.value)}
                          //   InputProps={{
                          //     autoComplete: "off",
                          //     classes: {
                          //       root: classes.labelRoot,
                          //     },
                          //   }}
                          //   helperText={
                          //     formErrors.city ? formErrors.city : ""
                          //   }
                          // />
                        )}
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <InputLabel className={classes.lable}>
                          Pincode *
                        </InputLabel>

                        {!editDelivery && (
                          <Typography className={classes.adresstext}>
                            {account?.delivery?.postalCode}
                          </Typography>
                        )}
                        {editDelivery && (
                          <Autocomplete
                            id="asynchronous-demo"
                            classes={{
                              option: classes.option,
                              noOptions: classes.noOptions,
                            }}
                            ListboxProps={{ style: { maxHeight: 150 } }}
                            options={pincodes || []}
                            value={pinSeleted || []}
                            getOptionSelected={(option, value) =>
                              option.pincode === value.postalCode
                            }
                            getOptionLabel={(option) =>
                              option.pincode
                                ? option.pincode
                                : stateSeleted.postalCode
                            }
                            onChange={(event, newValue) => {
                              handleClickPinCode(newValue);
                            }}
                            style={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                style={{ width: 150, height: 29 }}
                                InputProps={{
                                  ...params.InputProps,
                                  autoComplete: "new-password",
                                  classes: {
                                    root: classes.labelRoot,
                                  },
                                }}
                                helperText={
                                  formErrors.postalCode
                                    ? formErrors.postalCode
                                    : ""
                                }
                              />
                            )}
                          />

                          // <TextField
                          //   id="postalCode"
                          //   name="postalCode"
                          //   variant="outlined"
                          //   size="small"
                          //   value={postalCode}
                          //   onChange={(e) => setPostalCode(e.target.value)}
                          //   InputProps={{
                          //     autoComplete: "off",
                          //     classes: {
                          //       root: classes.labelRoot,
                          //     },
                          //   }}
                          //   helperText={
                          //     formErrors.postalCode
                          //       ? formErrors.postalCode
                          //       : ""
                          //   }
                          // />
                        )}
                      </Grid>
                    </React.Fragment>
                  </Grid>
                </Grid>
              </CardContent>

              {editDelivery && (
                <CardActions style={{ justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ height: 30, fontSize: 13 }}
                    onClick={handleEditDeliveryAddress}
                  >
                    Update
                  </Button>
                </CardActions>
              )}
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card className={classes.card}>
              <CardHeader
                title="Billing address"
                action={
                  <IconButton
                    aria-label="settings"
                    onClick={() => setEditBilling(!editBilling)}
                  >
                    {!editBilling ? (
                      <EditIcon size="small" style={{ color: "#212121" }} />
                    ) : (
                      <HighlightOffIcon
                        size="small"
                        style={{ color: "#212121" }}
                      />
                    )}
                  </IconButton>
                }
              />

              <CardContent>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {account.billing ? (
                      <React.Fragment>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                          <InputLabel className={classes.lable}>
                            First Name *
                          </InputLabel>

                          {!editBilling && (
                            <Typography className={classes.adresstext}>
                              {account.billing.firstName}{" "}
                            </Typography>
                          )}

                          {editBilling && (
                            <TextField
                              id="billingFirstName"
                              name="billingFirstName"
                              variant="outlined"
                              size="small"
                              value={billingFirstName}
                              onChange={(event) => {
                                const value = event.target.value;
                                if (value !== "" && !ALPHA_ONLY.test(value)) {
                                  return;
                                }
                                setBillingFirstName(value);
                              }}
                              InputProps={{
                                autoComplete: "off",
                                classes: {
                                  root: classes.labelRoot,
                                },
                              }}
                              helperText={
                                formErrors.billingFirstName
                                  ? formErrors.billingFirstName
                                  : ""
                              }
                            />
                          )}
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                          <InputLabel className={classes.lable}>
                            Last Name *
                          </InputLabel>

                          {!editBilling && (
                            <Typography className={classes.adresstext}>
                              {account.billing.lastName}
                            </Typography>
                          )}

                          {editBilling && (
                            <TextField
                              id="billingLastName"
                              name="billingLastName"
                              variant="outlined"
                              size="small"
                              value={billingLastName}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value !== "" && !ALPHA_ONLY.test(value)) {
                                  return;
                                }
                                setBillingLastName(value);
                              }}
                              InputProps={{
                                autoComplete: "off",
                                classes: {
                                  root: classes.labelRoot,
                                },
                              }}
                              helperText={
                                formErrors.billingLasttName
                                  ? formErrors.billingLasttName
                                  : ""
                              }
                            />
                          )}
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                          <InputLabel className={classes.lable}>
                            Address *
                          </InputLabel>

                          {!editBilling && (
                            <Typography className={classes.adresstext}>
                              {account.billing.address}
                            </Typography>
                          )}

                          {editBilling && (
                            <TextField
                              id="billingAddress"
                              name="billingAddress"
                              variant="outlined"
                              size="small"
                              value={billingAddress}
                              onChange={(e) =>
                                setBillingAddress(e.target.value)
                              }
                              InputProps={{
                                autoComplete: "off",
                                classes: {
                                  root: classes.labelRoot,
                                },
                              }}
                              helperText={
                                formErrors.billingAddress
                                  ? formErrors.billingAddress
                                  : ""
                              }
                            />
                          )}
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                          <InputLabel className={classes.lable}>
                            Phone Number *
                          </InputLabel>
                          {!editBilling && (
                            <Typography className={classes.adresstext}>
                              {account.billing.phone}
                            </Typography>
                          )}

                          {editBilling && (
                            <TextField
                              disabled
                              id="billingPhone"
                              name="billingPhone"
                              variant="outlined"
                              size="small"
                              value={billingPhone}
                              onChange={(e) => setBillingPhone(e.target.value)}
                              InputProps={{
                                autoComplete: "off",
                                classes: {
                                  root: classes.labelRoot,
                                },
                              }}
                              helperText={
                                formErrors.billingPhone
                                  ? formErrors.billingPhone
                                  : ""
                              }
                            />
                          )}
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                          <InputLabel className={classes.lable}>
                            State *
                          </InputLabel>

                          {!editBilling && (
                            <Typography className={classes.adresstext}>
                              {account.billing.stateProvince}
                            </Typography>
                          )}

                          {editBilling && (
                            <Autocomplete
                              id="asynchronous-demo"
                              classes={{
                                option: classes.option,
                                noOptions: classes.noOptions,
                              }}
                              ListboxProps={{ style: { maxHeight: 150, textTransform: "uppercase" } }}
                              options={states}
                              value={stateBillingSeleted}
                              getOptionSelected={(option, value) =>
                                option.hubState === value.stateProvince
                              }
                              getOptionLabel={(option) =>
                                option.hubState !== "HubState"
                                  ? option.hubState
                                    ? option.hubState
                                    : stateBillingSeleted.stateProvince
                                  : ""
                              }
                              onChange={(event, newValue) => {
                                handleClickBillingState(newValue);
                              }}
                              style={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                  style={{ width: 150, height: 29 }}
                                  InputProps={{
                                    ...params.InputProps,
                                    autoComplete: "new-password",
                                    classes: {
                                      root: classes.labelRoot,
                                    },
                                  }}
                                  helperText={
                                    formErrors.billingStateProvince
                                      ? formErrors.billingStateProvince
                                      : ""
                                  }
                                />
                              )}
                            />

                            // <TextField
                            //   id="billingStateProvince"
                            //   name="billingStateProvince"
                            //   variant="outlined"
                            //   size="small"
                            //   value={billingStateProvince}
                            //   onChange={(e) =>
                            //     setBillingStateProvince(e.target.value)
                            //   }
                            //   InputProps={{
                            //     autoComplete: "off",
                            //     classes: {
                            //       root: classes.labelRoot,
                            //     },
                            //   }}
                            //   helperText={
                            //     formErrors.billingStateProvince
                            //       ? formErrors.billingStateProvince
                            //       : ""
                            //   }
                            // />
                          )}
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                          <InputLabel className={classes.lable}>
                            City *
                          </InputLabel>

                          {!editBilling && (
                            <Typography className={classes.adresstext}>
                              {account.billing.city}
                            </Typography>
                          )}

                          {editBilling && (
                            <Autocomplete
                              id="asynchronous-demo"
                              classes={{
                                option: classes.option,
                                noOptions: classes.noOptions,
                              }}
                              ListboxProps={{ style: { maxHeight: 150,  textTransform: "uppercase" } }}
                              options={cities || []}
                              value={cityBillingSeleted || [] }
                              getOptionSelected={(option, value) =>
                                option.hubCity === value.city
                              }
                              getOptionLabel={(option) =>
                                option.hubCity
                                  ? option.hubCity
                                  : stateBillingSeleted.city
                              }
                              onChange={(event, newValue) => {
                                handleClickBillingCity(newValue);
                              }}
                              style={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                  style={{ width: 150, height: 29, textTransform: "uppercase" }}
                                  InputProps={{
                                    ...params.InputProps,
                                    autoComplete: "new-password",
                                    classes: {
                                      root: classes.labelRoot,
                                    },
                                  }}
                                  helperText={
                                    formErrors.billingCity
                                      ? formErrors.billingCity
                                      : ""
                                  }
                                />
                              )}
                            />

                            // <TextField
                            //   id="billingCity"
                            //   name="billingCity"
                            //   variant="outlined"
                            //   size="small"
                            //   value={billingCity}
                            //   onChange={(e) => setBillingCity(e.target.value)}
                            //   InputProps={{
                            //     autoComplete: "off",
                            //     classes: {
                            //       root: classes.labelRoot,
                            //     },
                            //   }}
                            //   helperText={
                            //     formErrors.billingCity
                            //       ? formErrors.billingCity
                            //       : ""
                            //   }
                            // />
                          )}
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                          <InputLabel className={classes.lable}>
                            Pincode *
                          </InputLabel>

                          {!editBilling && (
                            <Typography className={classes.adresstext}>
                              {account.billing.postalCode}
                            </Typography>
                          )}

                          {editBilling && (
                            <Autocomplete
                              id="asynchronous-demo"
                              classes={{
                                option: classes.option,
                                noOptions: classes.noOptions,
                              }}
                              ListboxProps={{ style: { maxHeight: 150 } }}
                              options={pincodes || []}
                              value={pinBillingSeleted || []}
                              getOptionSelected={(option, value) =>
                                option.pincode === value.postalCode
                              }
                              getOptionLabel={(option) =>
                                option.pincode
                                  ? option.pincode
                                  : stateBillingSeleted.postalCode
                              }
                              onChange={(event, newValue) => {
                                handleClickBillingPinCode(newValue);
                              }}
                              style={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                  style={{ width: 150, height: 29 }}
                                  InputProps={{
                                    ...params.InputProps,
                                    autoComplete: "new-password",
                                    classes: {
                                      root: classes.labelRoot,
                                    },
                                  }}
                                  helperText={
                                    formErrors.billingPostalCode
                                      ? formErrors.billingPostalCode
                                      : ""
                                  }
                                />
                              )}
                            />

                            // <TextField
                            //   id="billingPostalCode"
                            //   name="billingPostalCode"
                            //   variant="outlined"
                            //   size="small"
                            //   value={billingPostalCode}
                            //   onChange={(e) =>
                            //     setBillingPostalCode(e.target.value)
                            //   }
                            //   InputProps={{
                            //     autoComplete: "off",
                            //     classes: {
                            //       root: classes.labelRoot,
                            //     },
                            //   }}
                            //   helperText={
                            //     formErrors.billingPostalCode
                            //       ? formErrors.billingPostalCode
                            //       : ""
                            //   }
                            // />
                          )}
                        </Grid>
                      </React.Fragment>
                    ) : (
                      <Typography className={classes.notfound}>
                        Billing address not found
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>

              {editBilling && (
                <CardActions style={{ justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ height: 30, fontSize: 13 }}
                    onClick={handleEditBillingAddress}
                  >
                    Update
                  </Button>
                </CardActions>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>

      <SnackBar />
    </React.Fragment>
  );
};
