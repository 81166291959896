import { createTheme } from "@material-ui/core/styles";

const theme = createTheme ({
  palette: {
    primary: {
      main: "#27B23E",
      contrastText: "#fff",
    },
    secondary: {
      main: "#53E7BC",
      contrastText: "#fff",
    },
    error: {
      main: "#ff0000",
      contrastText: "#fff",
    },
  },
  typography: {
    useNextVariants: true,
  },
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application!
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          minHeight: "max-content",
          minWidth: "100vw",
          backgroundColor: "#efefef",
        },
      },
    },
    MuiButton: {
      label: {
        fontFamily: "'Fira Sans', sans-serif",
        textTransform: "none",
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: "'Fira Sans', sans-serif",
      },
      h4: {
        fontFamily: "'Fira Sans', sans-serif",
      },
      h5: {
        fontFamily: "'Fira Sans', sans-serif",
      },
      h6: {
        fontFamily: "'Fira Sans', sans-serif",
      },
      body2: {
        fontFamily: "'Fira Sans', sans-serif",
      },
      caption: {
        fontFamily: "'Fira Sans', sans-serif",
      },
      subtitle1: {
        fontFamily: "'Fira Sans', sans-serif",
      },
      subtitle2: {
        fontFamily: "'Fira Sans', sans-serif",
      },
      overline: {
        fontFamily: "'Fira Sans', sans-serif",
      },
    },
    MuiFormHelperText: {
      contained: {
        fontFamily: "'Fira Sans', sans-serif",
        color: "#ff0000",
      },
    },
    MuiOutlinedInput: {
      input: {
        fontFamily: "'Fira Sans', sans-serif",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontFamily: "'Fira Sans', sans-serif",
      },
    },
    MuiTooltip: {
      tooltip: {
        color: "#FFFFFF",
        fontWeight: 600,
      },
      arrow: {
        color: "#53E7BC",
      },
    },
  },
});

export default theme;
