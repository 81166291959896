import React, { useState } from "react";
import { useSelector } from "react-redux";

/* import custome hook components */
import { AppBars } from "../../components/AppBar";
// import { CategoryList } from "../../components/CategoryList";
// import { Wardrobe } from "../../components/customer/portfolio/Wardrobe";
import { Address } from "../../components/customer/portfolio/Address";
import { MyOrders } from "../../components/customer/portfolio/MyOrders";
import { ReviewsRatings } from "../../components/customer/portfolio/ReviewsRatings";

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import MenuHeader from "../../components/MenuHeader";

/* import material css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  large: {
    width: "91px",
    height: "91px",
  },
  userInfo: {
    width: "85%",
    margin: "auto",
  },
  username: {
    fontSize: "28px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.21,
    color: "#3b3b3b",
  },
  portfoliomenus: {
    "& .MuiPaper-root .MuiButtonBase-root": {
      outline: "none",
      textTransform: "capitalize",
      fontFamily: "'Fira Sans', sans-serif",
      fontSize: "16px",
      fontWeight: 600,
      height: "59px",
      letterSpacing: "0.19px",
      lineHeight: 1.38,
    },
  },
  portfolioappbar: {
    color: "inherit",
    background: "transparent",
    boxShadow: "none",
    borderBottom: "1px solid #E9E9E9",
    marginTop: "16px",
    "& .MuiTab-wrapper": {
      flexDirection: "initial",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 0,
    },
    "& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child": {
      marginBottom: "1px",
      paddingRight: "5px",
    },
    "& .MuiAvatar-img": {
      height: "60%",
      width: "60%",
    },
    "& .Mui-selected": {
      color: "#27B23E",
    },
  },
  indicator: {
    backgroundColor: "#27B23E",
  },
}));

export const Portfolio = () => {
  const classes = useStyles();
  const { account } = useSelector((state) => state.auth);
  const [value, setValue] = useState("1");

  const handleChangeActiveTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBars />
      {/* <CategoryList /> */}
      <MenuHeader />

      <Grid item xs={12}>
        <Box display="flex" flexDirection="row" p={5} mt={0} bgcolor="#d8d8d8">
          <Avatar
            // alt={account.firstName}
            src="/broken-image.jpg"
            className={classes.large}
          />

          <Box className={classes.userInfo}>
            <Typography className={classes.username}>
              Hi, {account.firstName}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.portfoliomenus}>
              <TabContext value={value}>
                <AppBar position="static" className={classes.portfolioappbar}>
                  <TabList
                  variant="scrollable"
                    onChange={handleChangeActiveTab}
                    aria-label="simple tabs example"
                    classes={{
                      indicator: classes.indicator,
                    }}
                  >
                    <Tab
                      icon={
                        <Avatar
                          alt="ordersImage"
                          src={process.env.PUBLIC_URL+"/images/default (3).svg"}
                        />
                      }
                      label="My Orders"
                      value="1"
                    />

                    <Tab
                      icon={
                        <Avatar
                          alt="paymentImage"
                          src={process.env.PUBLIC_URL+"/images/default (2).svg"}
                        />
                      }
                      label="My Reviews & Ratings"
                      value="2"
                    />
                    <Tab
                      icon={
                        <Avatar
                          alt="addressImage"
                          src={process.env.PUBLIC_URL+"/images/default (1).svg"}
                        />
                      }
                      label="Manage Address"
                      value="3"
                    />
                    {/* <Tab
                      icon={<Avatar alt="wardrobeImage" src={"/images/default(4).svg"} />}
                      label="My wardrobe"
                      value="4"
                    /> */}
                  </TabList>
                </AppBar>

                <TabPanel value="1">
                  <MyOrders />
                </TabPanel>

                <TabPanel value="2">
                  <ReviewsRatings />
                </TabPanel>

                <TabPanel value="3">
                  <Address account={account} />
                </TabPanel>

                {/* <TabPanel value="4">
                  <Wardrobe />
                </TabPanel> */}
              </TabContext>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
