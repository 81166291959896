/* react libs */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/* redux imports */
import { ActionTypes } from "../redux/constants/actionTypes";

/* react components */
import Spinner from "../util/spinner/spinner";
import { DemoItem } from "./DemoItem";
import { Paginator } from "./Paginator";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  rootDiv: {
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiGrid-root :hover": {
      textDecoration: "none",
    },
    "& .MuiCardMedia-img": {
      objectFit: "contain",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#383838",
      fontFamily: "'Fira Sans', sans-serif",
      textAlign: "center",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "15px",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#383838",
    },
    "& .MuiSelect-select": {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#140101",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  menuItem: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 2.13,
    color: "#140101",
  },
  selectSortBy: {
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.09,
    color: "#140101",
  },
  sort: {
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 2.13,
    color: "#140101",
  },
  typebuttons: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#140101",
    textTransform: "none",
  },
  activetypebuttons: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#27B23E",
    textTransform: "none",
    borderBottom: "2px solid #27B23E",
  },
  dropdownStyle: {
    marginTop: 48,
  },
  showMore: {
    color: "#0000FF",
    textTransform: "uppercase",
    fontSize: 14,
  },
}));

export const stickyBoxStyle = {
  position: "sticky",
  top: 80,
  backgroundColor: "#fff",
  zIndex: 3,
  paddingLeft: "1rem",
};

const ProductList = (props) => {
  const classes = useStyles();

  const { filterProducts, loading } = useSelector((state) => state.home);

  const { selectedPriceSort, setSelectedPriceSort } = props;

  const dispatch = useDispatch();

  const [type, setType] = useState(selectedPriceSort);
  const [page, setPage] = useState(1);

  // constants for the component
  // const PRICE_HIGH_TO_LOW = "hightolow";
  // const PRICE_LOW_TO_HIGH = "lowtohigh";

  const handleChangeSortBy = (event) => {
    var val = event.target.value;
    setSelectedPriceSort(val);
    dispatch({
      type: ActionTypes.PRODUCT_SORT_BY_CLICKED,
      payload: val,
    });
  };

  // const filterTheProductsForSortBy = (type) => {
  //   // let products = filterProducts.products;
  //   const sortData = [...filterProducts.products];
  //   let data = undefined;
  //   if (PRICE_HIGH_TO_LOW === type) {
  //     data = sortData.sort(
  //       (a, b) =>
  //         Math.trunc(parseInt(b.mrpPrice))-
  //         Math.trunc(parseInt(a.mrpPrice))
  //     );
  //   } else if (PRICE_LOW_TO_HIGH === type) {
  //     data = sortData.sort(
  //       (a, b) =>
  //       Math.trunc(parseInt(a.mrpPrice))-
  //       Math.trunc(parseInt(b.mrpPrice))
  //     );
  //   } else {
  //     data = sortData.sort((a, b) => a.id - b.id);
  //   }
  //   filterProducts.products = data;
  // };

  // const filterTheProductsForSortBy = (type) => {
  //   // let products = filterProducts.products;
  //   const sortData = [...filterProducts.products];
  //   let data = undefined;
  //   if (PRICE_HIGH_TO_LOW === type) {
  //     data = sortData.sort(
  //       (a, b) =>
  //         b.attributes
  //           .reduce((prev, curr) =>
  //             prev.optionId < prev.optionId ? prev : curr
  //           )
  //           .attributeOptionValues.reduce((old, nw) =>
  //             old.attributeId < old.attributeId ? old : nw
  //           ).attributePrice -
  //         a.attributes
  //           .reduce((prev, curr) =>
  //             prev.optionId < prev.optionId ? prev : curr
  //           )
  //           .attributeOptionValues.reduce((old, nw) =>
  //             old.attributeId < old.attributeId ? old : nw
  //           ).attributePrice
  //     );
  //   } else if (PRICE_LOW_TO_HIGH === type) {
  //     data = sortData.sort(
  //       (a, b) =>
  //         a.attributes
  //           .reduce((prev, curr) =>
  //             prev.optionId < prev.optionId ? prev : curr
  //           )
  //           .attributeOptionValues.reduce((old, nw) =>
  //             old.attributeId < old.attributeId ? old : nw
  //           ).attributePrice -
  //         b.attributes
  //           .reduce((prev, curr) =>
  //             prev.optionId < prev.optionId ? prev : curr
  //           )
  //           .attributeOptionValues.reduce((old, nw) =>
  //             old.attributeId < old.attributeId ? old : nw
  //           ).attributePrice
  //     );
  //   } else {
  //     data = sortData.sort((a, b) => a.id - b.id);
  //   }

  //   filterProducts.products = data;
  // };

  const handleChangeType = (value) => {
    setType(value);
    dispatch({
      type: ActionTypes.PRODUCT_PRICE_TYPE_CLICKED,
      payload: {
        type: value,
        page: 1,
      },
    });
  };

  const handleChangePage = (event, newValue) => {
    setPage(newValue);
    dispatch({
      type: ActionTypes.PRODUCT_PAGE_NUMBER_CLICKED,
      payload: newValue,
    });
  };

  useEffect(() => {
    return () => {
      // un mount code

      dispatch({
        type: ActionTypes.PRODUCT_PRICE_TYPE_CLICKED,
        payload: "all",
      });
      setType("all");
    };
  }, []);

  return (
    <Grid item md={9} lg={10}>
      <Grid>
        <Hidden xsDown>
          <Grid item container alignItems="center" style={stickyBoxStyle}>
            <Grid item sm={8}>
              <div style={{ display: "flex", padding: "11px" }}>
                <Typography className={classes.sort}>Product Type:</Typography>

                <div>
                  <Button
                    className={
                      type === "all"
                        ? classes.activetypebuttons
                        : classes.typebuttons
                    }
                    onClick={() => handleChangeType("all")}
                  >
                    All
                  </Button>

                  <Button
                    className={
                      type === "YES"
                        ? classes.activetypebuttons
                        : classes.typebuttons
                    }
                    onClick={() => handleChangeType("YES")}
                  >
                    Bargain
                  </Button>

                  <Button
                    className={
                      type === "NO"
                        ? classes.activetypebuttons
                        : classes.typebuttons
                    }
                    onClick={() => handleChangeType("NO")}
                  >
                    Fixed
                  </Button>
                </div>
              </div>
            </Grid>

            <Grid
              item
              container
              justifyContent="flex-end"
              sm={4}
              style={{ padding: "0 2.5rem 0.22rem 0" }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedPriceSort}
                  onChange={handleChangeSortBy}
                  className={classes.selectSortBy}
                  MenuProps={{
                    classes: { paper: classes.dropdownStyle },
                  }}
                >
                  <MenuItem
                    value="all"
                    className={classes.menuItem}
                    // onClick={() => filterTheProductsForSortBy("all")}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    value="hightolow"
                    className={classes.menuItem}
                    // onClick={() => filterTheProductsForSortBy("hightolow")}
                  >
                    Price: High to Low
                  </MenuItem>
                  <MenuItem
                    value="lowtohigh"
                    className={classes.menuItem}
                    // onClick={() => filterTheProductsForSortBy("lowtohigh")}
                  >
                    Price: Low to High
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Hidden>

        <Divider />

        <Grid
          item
          container
          spacing={2}
          xs={12}
          md={12}
          style={{ padding: "1rem 0 1rem 1rem", margin: 0 }}
        >
          {loading ? (
            <Spinner />
          ) : Object.keys(filterProducts).length > 0 &&
            filterProducts.products.length > 0 ? (
            filterProducts.products.map(
              (
                product // <Grid item key={product.id}>
              ) => (
                //   <ItemCard product={product} />
                // </Grid>

                <Grid item xs={12} sm={6} md={3} key={product.id}>
                  <DemoItem product={product} />
                </Grid>
              )
            )
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: "100%", height: "100%" }}
            >
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                style={{ height: "50vh" }}
              >
                <Box alignSelf="center" pb={8}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/searchMatchesNotFound404.png"
                    }
                    alt="searchMatchesNotFoundImage"
                  />
                </Box>
                <Box
                  alignSelf="center"
                  style={{
                    fontSize: "2rem",
                    fontWeight: 400,
                    fontFamily: "'Fira Sans', sans-serif",
                  }}
                >
                  We couldn't find any matches!
                </Box>
                <Box
                  alignSelf="center"
                  pt={2}
                  style={{
                    fontSize: "1rem",
                    fontWeight: 300,
                    fontFamily: "'Fira Sans', sans-serif",
                  }}
                >
                  Please check the spelling or try searching something else
                </Box>
              </Box>
            </Box>
          )}
        </Grid>

        {Object.keys(filterProducts).length > 0 &&
          filterProducts.products.length > 0 &&
          filterProducts.totalPages > 1 && (
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ padding: "30px 0 100px 0" }}
            >
              <Paginator
                variant="outlined"
                shape="rounded"
                page={page}
                handleChangePage={handleChangePage}
                count={filterProducts.totalPages}
              />
            </Grid>
          )}
      </Grid>
    </Grid>
  );
};

export default React.memo(ProductList);
