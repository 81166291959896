import { ActionTypes } from "../constants/actionTypes";
import axios from "axios";
import {checkoutUpdateBargain} from "./cartActions";

const baseUrl = process.env.REACT_APP_BASE_URL;


export const addAddress = (addressData) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING_USER });
    const { data } = await axios.patch(
      `${baseUrl}/auth/customer/address`,
      addressData
    );
    dispatch({
      type: ActionTypes.UPDATE_USER,
      payload: data,
    });
  } catch (error) {
    // console.log(error);
  }
};

export const paymentSuccess =
  (jsonResponse, cartCode, history , updateRequest) => async (dispatch) => {
    try {

      dispatch( {type : ActionTypes.AFTER_PAYMENT_LOADING , payload : {}});
      await axios.post(
        `${baseUrl}/auth/cart/${cartCode}/checkout`,
        jsonResponse
      );

      await dispatch(checkoutUpdateBargain(updateRequest));

      dispatch({ type: ActionTypes.GET_CART_ITEM, payload: {} });
      history.push("/payupayment/success/response");

    } catch (error) {
      // console.log(error);
    }
  };


export const alertSnackBar = (alert, message) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SNACKBAR,
    payload: { alert: alert, message: message },
  });
};
