import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

/* react components */
import { OtpLogin } from "../../components/customer/auth/OtpLogin";

/* redux action */
import {
  clearAllMessageState,
  customerLogin,
} from "../../redux/actions/authActions";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
// import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Paper, Typography, Box, Button, TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

/* material css */
const useStyles = makeStyles((theme) => ({

  '@global': {
    ":root": {
      backgroundImage: "url('https://storage.googleapis.com/stockxbid_gc_bng_acs/BANNER/Welcomehomepage/Welcome%20Buyer.gif')",
      backgroundRepeat: "no-repeat",
      backgroundPosition: 'center',
      backgroundSize: "cover",
      width: '100vw',
      height: '100vh'
    },
    "body" :{
      background: "none"
    }
},
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      textAlign: "initial",
      background: "#F7F7F7",
    },
  },
  gridSection: {
    paddingTop: theme.spacing(5),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: "6px",
    width: "75%",
    height: "100%",
    margin: "auto",
  },
  signUpButton: {
    color: "#000000",
    "&:hover": {
      background: "#4AD0AA",
      color: "#ffffff",
    },
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem 1.25rem",
    cursor: "pointer",
  },
  welcomeMsg: {
    fontWeight: 600,
    color: "#043c53",
    marginTop: theme.spacing(15),
  },
  gridContent: {
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1),
  },
  captionMsg: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#000000",
  },
  loginLable: {
    fontWeight: 600,
    color: "#000",
    width: "75%",
    margin: "auto",
    paddingBottom: "10px",
  },
  submitButton: {
    color: "#000000",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem 1.25rem",
    width: "75%",
    cursor: "pointer",
  },
  forgotPassword: {
    fontWeight: 500,
    color: "#000",
    marginTop: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      color: "#53E7BC",
    },
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
    color: "#000000",
  },
  or_label: {
    margin: 10,
    color: "#212121",
  },
}));

export const CustomerLogin = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, error, serverError } = useSelector((state) => state.UI);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobileLogin, setMobileLogin] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  


  const handleInputsValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (!email) {
      inputsValid = false;
      formErrors["email"] = "Please Enter Your Email";
    }

    if (!password) {
      inputsValid = false;
      formErrors["password"] = "Please Enter Your Password";
    }

    setFormErrors(formErrors);
    return inputsValid;
  };

  /* function to handle form submit */
  const handleSubmit = (event) => {
    event.preventDefault();

    if (handleInputsValidation()) {
      const customerData = {
        username: email,
        password: password,
      };

      dispatch(customerLogin(customerData, history));
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    return () => {
      dispatch(clearAllMessageState());
    };
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <Container component="main" maxWidth="lg" className={classes.container}>
      {/* <CssBaseline /> */}

      <Container component="main" maxWidth="xs">
        <Box
          style={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img
            alt="logo"
            src={"https://storage.googleapis.com/stockxbid_gc_bng_misc/logo_beta_v/new%20logo%201%20png.svg"}
            style={{
              maxHeight: "60px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/")}
          />
        </Box>
      </Container>

      {/* <Grid item xs={12} align="center">
        <Link
          to="/"
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginTop: 10,
          }}
        >
          <img
            alt="logo"
            src={process.env.PUBLIC_URL + "/images/Outxtock-Logo-01.svg"}
            style={{ width: "100%", height: "100%", maxHeight: "100px" }}
          />
        </Link>
      </Grid> */}

      <Grid container spacing={5} className={classes.gridSection}>
        <Grid item xs={12} sm={6} align="center">
          <Typography
            component="h1"
            variant="h4"
            className={classes.welcomeMsg}
          >
            Welcome to OutXtock
          </Typography>

          <Typography
            variant="caption"
            component="p"
            className={classes.captionMsg}
          >
            Good to see you again...!
          </Typography>

          <Typography
            variant="subtitle1"
            component="p"
            className={classes.gridContent}
          >
            Don't have an account?
          </Typography>

          <Link to="/customer-signup" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.signUpButton}
              style={{ outline: "none" }}
            >
              Sign Up
            </Button>
          </Link>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h5" className={classes.loginLable}>
            Sign In
          </Typography>

          <Paper className={classes.paper}>
            {!mobileLogin ? (
              <Fragment>
                <form onSubmit={handleSubmit} className={classes.root}>
                  <TextField
                    id="email"
                    name="email"
                    label="Your Email"
                    variant="outlined"
                    size="small"
                    value={email}
                    autoFocus
                    onChange={(e) => setEmail(e.target.value.trim())}
                    InputLabelProps={{
                      classes: {
                        
                      },
                    }}
                    inputProps={{
                      autoComplete: "off",
                    }}
                    helperText={formErrors.email ? formErrors.email : ""}
                  />

                  <TextField
                    id="password"
                    name="password"
                    label="Enter Password"
                    
                    variant="outlined"
                    size="small"
                    value={password}
                    type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                  
                    onChange={(e) => setPassword(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        
                      },
                    }}
                    InputProps={{

                      endAdornment: 
                        <InputAdornment position="end" style={{ background: "#F7F7F7",}}>
                          <IconButton 
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      
                    }}
                    helperText={formErrors.password ? formErrors.password : ""}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.submitButton}
                    disabled={loading}
                    size="small"
                    style={{ outline: "none" }}
                  >
                    Sign In
                  </Button>
                </form>

                <Link
                  to={{
                    pathname: "/forgot-password",
                    state: { prevPath: props.location.pathname },
                  }}
                >
                  <Typography
                    variant="caption"
                    className={classes.forgotPassword}
                  >
                    Forgot Password
                  </Typography>
                </Link>
              </Fragment>
            ) : (
              <OtpLogin />
            )}

            <Typography
              variant="overline"
              component="p"
              className={classes.or_label}
            >
              OR
            </Typography>

            <Button
              type="button"
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              size="small"
              style={{ outline: "none" }}
              onClick={() => {setMobileLogin(!mobileLogin)
                dispatch(clearAllMessageState())}}
            >
              {mobileLogin ? "Login with Email" : "Login with OTP"}
            </Button>
           
            {Object.keys(formErrors).length === 0 && error && (
              <Box
                style={{
                  fontSize: "12px",
                  color: "red",
                  fontFamily: "'Fira Sans', sans-serif",
                  margin: 10,
                }}
              >
                {error}
              </Box>
            )}
            {serverError && (
              <Box
                style={{
                  fontSize: "12px",
                  color: "red",
                  fontFamily: "'Fira Sans', sans-serif",
                  margin: 10,
                }}
              >
                {serverError}
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
