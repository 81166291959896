import React from "react";
import { useSelector } from "react-redux";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Spinner from "../util/spinner/spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .sk-circle": {
      height: "50px",
      width: "50px",
    },
    "& .MuiTableCell-head": {
      backgroundColor: "#d5f9ef",
      color: "#3c3c3c",
      fontFamily: "'Fira Sans', sans-serif",
      fontSize: "16px",
      fontWeight: 900,
      position: "initial",
    },
    "& .MuiTableCell-body": {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#2d2b2b",
    },
  },
  contentSection: {
    maxHeight: "200px",
    minWidth: "400px",
  },
  dialogBar: {
    background: "#53E7BC",
    padding: "3px",
  },
  dialogTitle: {
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: "1.25rem",
  },
  closeIcon: {
    color: "#212529",
    float: "right",
    position: "relative",
  },
  table: {
    minWidth: 500,
    "& .MuiTableCell-head": {
      fontWeight: 800,
      fontSize: "14px",
    },
  },
  tableHead: {
    background: "#d5f9ef",
  },
  noFound: {
    color: "#030104",
    textAlign: "center",
  },
  colorbox: {
    width: "20px",
    height: "20px",
    border: "2px solid #383838",
    borderRadius: "50%",
    margin: "auto",
  },
}));

export const SeeallBids = (props) => {
  const classes = useStyles();
  const { allBids, allAsks, loading, error, serverError } = useSelector(
    (state) => state.allbids
  );

  return (
    <Dialog
      open={props.allbids}
      onClose={props.handleCloseOpenBids}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="scroll-dialog-title" className={classes.dialogBar}>
        <Typography className={classes.dialogTitle}>
          {props.type === "allBids" ? "All Bids" : "All Asks"}

          <IconButton
            edge="start"
            color="inherit"
            onClick={props.handleCloseOpenBids}
            aria-label="close"
            className={classes.closeIcon}
          >
            <HighlightOffRoundedIcon />
          </IconButton>
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          id="scroll-dialog-description"
          className={classes.contentSection}
          component="div"
        >
          {loading ? (
            <Spinner />
          ) : serverError ? (
            <Typography>{serverError}</Typography>
          ) : error ? (
            <Typography>{error}</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell align="center">Size</TableCell>
                    <TableCell align="center">Color</TableCell>
                    <TableCell align="center">
                      {props.type === "allBids" ? "Bargain Price" : "Ask Price"}
                    </TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>

                {props.type === "allBids" ? (
                  <TableBody >
                    {allBids && allBids.length > 0 ? (
                      allBids.map((bids) => (
                        <TableRow key={bids._id}>
                          <TableCell component="th"  align="center" scope="row">
                            {bids._source.attribute_size
                              ? bids._source.attribute_size
                              : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {bids._source.attribute_color_code ? (
                              <p
                                className={classes.colorbox}
                                style={{
                                  backgroundColor: `${bids._source.attribute_color_code}`,
                                }}
                              />
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {bids._source.bargin_price}
                          </TableCell>
                          <TableCell align="center">
                            {bids._source.bargin_quantity}
                          </TableCell>
                          <TableCell align="center">
                            {bids._source.status}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} className={classes.noFound}>
                          Bargains not found for this product.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    {allAsks && allAsks.length > 0 ? (
                      allAsks.map((asks) => (
                        <TableRow key={asks._id}>
                          <TableCell component="th" align="center" scope="row">
                            {asks._source.attribute_size
                              ? asks._source.attribute_size
                              : "-"}
                          </TableCell>
                          <TableCell align="center">
                            {asks._source.attribute_color_code ? (
                              <p
                                className={classes.colorbox}
                                style={{
                                  backgroundColor: `${asks._source.attribute_color_code}`,
                                }}
                              />
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {asks._source.offering_PRICE}
                          </TableCell>
                          <TableCell align="center">
                            {asks._source.bargin_quantity}
                          </TableCell>
                          <TableCell align="center">
                            {asks._source.status}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} className={classes.noFound}>
                          Asks not found for this product.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
