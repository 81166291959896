import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux action
import {
  getBussinessCategory,
  getSubCategory,
} from "../../../redux/actions/otherActions";

// material ui
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import StoreIcon from "@material-ui/icons/Store";
import AddIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: "steelblue",
      color: theme.palette.common.white,
      fontFamily: "'Fira Sans', sans-serif",
    },
    body: {
      fontSize: 13,
      fontFamily: "'Fira Sans', sans-serif",
      padding: 0,
      color: "#000000",
    },
  })
)(TableCell);

const useStyles = makeStyles((theme) =>
  createStyles({
    productroot: {
      "& .MuiFormControlLabel-label": {
        fontSize: 13,
        fontFamily: "'Fira Sans', sans-serif",
        color: "#4A4A4A",
      },
      "& em": {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
        fontFamily: "'Fira Sans', sans-serif",
        color: "#495057",
      },
      "& .MuiSelect-select.MuiSelect-select": {
        textAlign: "initial",
      },
    },
    title: {
      fontWeight: "bold",
      color: "#53e7bc",
      textDecoration: "underline",
    },
    lable: {
      textAlign: "start",
      margin: "0px 10px",
      fontSize: 12,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#000000",
    },
    usertype: {
      display: "flex",
      alignItems: "center",
      borderRadius: 3,
      margin: "8px 7px",
    },
    iconButton: {
      padding: 6,
      border: "1px solid pink",
      borderRadius: "4px",
      color: "#000000",
    },
    select: {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#666666",
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    backbutton: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      backgroundColor: "#53e7bc",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#53e7bc",
        color: "#fff",
      },
    },
    addbutton: {
      margin: theme.spacing(3),
      // backgroundColor: "#2565AE",
      backgroundColor: "#53e7bc",
      "&:hover": {
        backgroundColor: "#53e7bc",
      },
    },
    menuitem: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#495057",
      "& em": {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
        fontFamily: "'Fira Sans', sans-serif",
        color: "#495057",
      },
    },
    dropdownStyle: {
      marginTop: 48,
    },
    errorblock: {
      fontSize: 12,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#FF0000",
    },
  })
);

export const ProductCategory = (props) => {
  const classes = useStyles();
  const { handleBack, handlesetCategory, handleRemoveCategory, categories } =
    props;
  const { bussinessCategories, subCategories, selectedCategories } =
    useSelector((state) => state.Others);
  const dispatch = useDispatch();

  const [catObject, setCatObject] = useState({
    catId: "",
    catName: "",
    subCatId: "",
    subCatName: "",
  });
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [errors, setErrors] = useState({});

  const handleSelectCategory = (event) => {
    setCategory(event.target.value);
    dispatch(getSubCategory());
  };

  const handleSelectSubCategory = (event) => {
    setSubcategory(event.target.value);
  };

  const handlecategoeryObj = (category) => {
    setCatObject((prevState) => ({
      ...prevState,
      catId: category.id,
      catName: category.name,
    }));
  };

  const handlesubcategoryObj = (subcategory) => {
    setCatObject((prevState) => ({
      ...prevState,
      subCatId: subcategory.id,
      subCatName: subcategory.name,
    }));
  };

  const handleSubmit = () => {
    const nonDupObjects = categories.filter((oldFile) => {
      return (
        catObject.catId === oldFile.businessCategoryId &&
        catObject.subCatId === oldFile.businessSubCategoryId
      );
    });

    if (nonDupObjects.length === 0) {
      handlesetCategory(catObject);
      setCategory("");
      setSubcategory("");
      setCatObject({ catId: "", catName: "", subCatId: "", subCatName: "" });
      setErrors({});
    } else {
      let errors = {};
      errors["duplicate"] =
        "Store format is already selected for the category.";
      setErrors(errors);
    }
  };

  const handleRemoveSelectedCategory = (row) => {
    handleRemoveCategory(row);
  };

  const handleNext = () => {
    if (categories.length > 0) {
      props.handleNext();
    } else {
      let errors = {};
      errors["bussinessCategory"] = "Please select Category and Store format.";
      setErrors(errors);
    }
  };

  useEffect(() => {
    dispatch(getBussinessCategory());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.productroot}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>
            Product Category & Regulatory Information
          </Typography>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <InputLabel className={classes.lable}>Product Category</InputLabel>
          <div className={classes.usertype}>
            <IconButton className={classes.iconButton} aria-label="menu">
              <LocalOfferIcon />
            </IconButton>

            <FormControl
              variant="outlined"
              size="small"
              style={{ margin: 0, backgroundColor: "#fff" }}
            >
              <Select
                className={classes.select}
                value={category}
                onChange={handleSelectCategory}
                MenuProps={{
                  classes: { paper: classes.dropdownStyle },
                }}
              >
                {bussinessCategories &&
                  bussinessCategories.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      className={classes.menuitem}
                      onClick={(e) => handlecategoeryObj(item)}
                    >
                      <em>{item.name}</em>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <InputLabel className={classes.lable}>Store Format</InputLabel>
          <div className={classes.usertype}>
            <IconButton className={classes.iconButton} aria-label="menu">
              <StoreIcon />
            </IconButton>

            <FormControl
              variant="outlined"
              size="small"
              style={{ margin: 0, backgroundColor: "#fff" }}
              disabled={subCategories.length > 0 ? false : true}
            >
              <Select
                className={classes.select}
                value={subcategory}
                onChange={handleSelectSubCategory}
                MenuProps={{
                  classes: { paper: classes.dropdownStyle },
                }}
              >
                {subCategories &&
                  subCategories.length > 0 &&
                  subCategories.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      className={classes.menuitem}
                      onClick={(e) => handlesubcategoryObj(item)}
                    >
                      <em>{item.name}</em>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={category === "" || subcategory === "" ? true : false}
            className={classes.addbutton}
            startIcon={<AddIcon />}
            onClick={handleSubmit}
          >
            Add Product Category
          </Button>
        </Grid>

        {errors.bussinessCategory ? (
          <Grid item xs={12}>
            <Typography className={classes.errorblock}>
              {errors.bussinessCategory}
            </Typography>
          </Grid>
        ) : errors.duplicate ? (
          <Grid item xs={12}>
            <Typography className={classes.errorblock}>
              {errors.duplicate}
            </Typography>
          </Grid>
        ) : null}

        {selectedCategories && selectedCategories.length > 0 && (
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      Product Category
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Store Format
                    </StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedCategories.map((row, index) => (
                    <TableRow key={index}>
                      <StyledTableCell align="center">
                        {row.catName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.subCatName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleRemoveSelectedCategory(index)}
                        >
                          <DeleteIcon style={{ color: "#FF0000" }} />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>

      <div className={classes.buttons}>
        <Button onClick={handleBack} className={classes.button}>
          Back
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleNext}
          className={classes.backbutton}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
