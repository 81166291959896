import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";

const SmallSpinner = () => (
  <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" style={{ width: "100%" }}>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      
    >
      <CircularProgress color="primary"/>
    </Box>
    <Typography style={{ 
    fontFamily: "'Fira Sans', sans-serif",
    flexBasis: "33.33%",
    flexShrink: 0,
    fontWeight: 600}}>Please wait few seconds...! </Typography>
  </Box>
);

export default SmallSpinner;