import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stepper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "14px",
        fontFamily: "'Fira Sans', sans-serif",
        color: "#383838",
        lineHeight: 1.19,
        paddingTop: theme.spacing(1),
      },
      heading: {
        fontSize: '15px',
        fontFamily: "'Fira Sans', sans-serif",
        flexBasis: "33.33%",
        flexShrink: 0,
        fontWeight: 600,
      },
      span: {
        minWidth: '30%',
        fontWeight: 600,
        fontSize: 13,
      },
}))

const CustomerPayment = (props) => {
    const { payDetails} = props;
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);


  const handlePayment = (panel) => async (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setActiveStep(0)

  }
  


  return (
    <Grid item>
      
        <Accordion
          expanded={expanded === payDetails.orderSubTotal}
          onChange={handlePayment(payDetails.orderSubTotal)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>Payment Details</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              style={{
                display: "flex",
                
                flexDirection: "column",
                paddingLeft: '75%'
              }}
            >
              <Typography className={classes.label}>
              <span className={classes.span}>
              Sub Total:{" "}
              </span>
               
                <span className={classes.span}>
                  {payDetails.orderSubTotal}
                </span>
              </Typography>
              <Typography className={classes.label}>
              <span className={classes.span}>
              GST Total:{" "}
              </span>
               
                <span className={classes.span}>
                  {payDetails.orderTaxTotal}
                </span>
              </Typography>
              {/* <Typography className={classes.label}>
              <span className={classes.span}>
              Service Tax:{" "}
              </span>
               
                <span className={classes.span}>
                  {payDetails.orderServiceTotal}
                </span>
              </Typography> */}
              <Typography className={classes.label}>
              <span className={classes.span}>
              Shipping Total:{" "}
              </span>
                
                
                <span className={classes.span}>
                  {payDetails.ordershippingTotal}
                </span>
              </Typography>
              <Typography className={classes.label}>
              <span>
             ------------------------
              </span>
                
              </Typography>
              
              <Typography className={classes.label}>
              <span className={classes.span}>
              Total:{" "}
              </span>
                
                <span className={classes.span}>
                  {payDetails.grandTotal}
                </span>
              </Typography>
            </Stepper>
          </AccordionDetails>
        </Accordion>
     
    </Grid>
  );
};

export default React.memo(CustomerPayment);
