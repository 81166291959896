import React, { useState } from "react";
import { useDispatch } from "react-redux";

//redux actions
import { createUserBankDetails } from "../../redux/actions/sellerActions";

//material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "750px",
    },
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiTypography-h6": {
      fontSize: 16,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 600,
      color: "#fff",
    },
    "& .MuiDialogTitle-root": {
      backgroundColor: "#53e7bc",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 13,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#4A4A4A",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
      textAlign: "center",
      fontSize: 9,
    },
  },
  content: {
    fontSize: 16,
    fontFamily: "'Fira Sans', sans-serif",
  },
  lable: {
    textAlign: "start",
    margin: "10px 0px",
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#000000",
    fontWeight: 600,
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
    color: "#666666",
  },
  addbutton: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: "#53e7bc",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#53e7bc",
      color: "#fff",
    },
  },
}));

const ifscRegEx = RegExp(/^[A-Za-z]{4}0[A-Z0-9]{6}$/);
const mobileRegex = RegExp(/^[6789]\d{9}$/);
const ALPHA_ONLY = /^[a-zA-Z\s]+$/;
const AlPHANUMERIC_ONLY = /^[ A-Za-z0-9_@./#&+-]+$/; 
const NUMERIC_ONLY =/^[0-9]+$/;

export const BankDetailsAlert = (props) => {
  const classes = useStyles();
  const { bankDetails, userId } = props;
  const dispatch = useDispatch();

  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [micrNo, setMicrNo] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const inputValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (!bankName) {
      inputsValid = false;
      formErrors["bankName"] = "Please enter your Bank name.";
    }

    if (!branchName) {
      inputsValid = false;
      formErrors["branchName"] = "Please enter your Bank branch name.";
    }

    if (!micrNo) {
      inputsValid = false;
      formErrors["micrNo"] = "Please enter your Micr NO.";
    }

    if (!ifscCode) {
      inputsValid = false;
      formErrors["ifscCode"] = "Please enter your IFSC code.";
    }

    if (!ifscRegEx.test(ifscCode)) {
      inputsValid = false;
      formErrors["ifscCode"] = "Please enter valid IFSC code.";
    }

    if (!accountNumber) {
      inputsValid = false;
      formErrors["accountNumber"] = "Please enter your Account number.";
    }

    if (!phoneNumber) {
      inputsValid = false;
      formErrors["phoneNumber"] = "Please enter your Mobile number.";
    }

    if (!mobileRegex.test(phoneNumber)) {
      inputsValid = false;
      formErrors["phoneNumber"] = "Please enter valid Mobile number.";
    }

    setFormErrors(formErrors);
    return inputsValid;
  };

  const handleSubmitBankDetails = (e) => {
    e.preventDefault();

    if (inputValidation()) {
      const userBankDetails = {
        accountNumber: accountNumber,
        bankName: bankName,
        branchCode: branchCode,
        branchName: branchName,
        detailsExist: true,
        id: 0,
        ifsc: ifscCode,
        micrNumber: micrNo,
        phoneNumber: phoneNumber,
      };

      dispatch(createUserBankDetails(userBankDetails, userId));
    }
  };

  return (
    <Dialog
      open={bankDetails ? false : true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialogRoot}
    >
      <DialogTitle id="alert-dialog-title">
        {"Complete your Profile"}
      </DialogTitle>

      <form onSubmit={handleSubmitBankDetails}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <InputLabel className={classes.lable}>
                    Bank Name{" "}
                    <Box component="span" color="red">
                      *
                    </Box>
                  </InputLabel>
                  <TextField
                    id="bankName"
                    name="bankName"
                    variant="outlined"
                    size="small"
                    value={bankName}
                    onChange={(e) =>{
                      const value = e.target.value;
                      if (value !== "" && !ALPHA_ONLY.test(value)) {
                        return;
                      }
                       setBankName(e.target.value)}}
                    InputProps={{
                      autoComplete: "off",
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.bankName ? formErrors.bankName : ""}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <InputLabel className={classes.lable}>
                    Branch Name{" "}
                    <Box component="span" color="red">
                      *
                    </Box>
                  </InputLabel>
                  <TextField
                    id="branchName"
                    name="branchName"
                    variant="outlined"
                    size="small"
                    value={branchName}
                    onChange={(e) =>setBranchName(e.target.value)}
                    InputProps={{
                      autoComplete: "off",
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={
                      formErrors.branchName ? formErrors.branchName : ""
                    }
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <InputLabel className={classes.lable}>Branch Code</InputLabel>
                  <TextField
                    id="branchCode"
                    name="branchCode"
                    variant="outlined"
                    size="small"
                    value={branchCode}
                    onChange={(e) =>{
                      const value = e.target.value;
                      if (value !== "" && !NUMERIC_ONLY.test(value)) {
                        return;
                      }
                      setBranchCode(e.target.value)}}
                      inputProps={{
                        maxLength: 6,
                      }}
                    InputProps={{
                      autoComplete: "off",
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <InputLabel className={classes.lable}>
                    IFSC{" "}
                    <Box component="span" color="red">
                      *
                    </Box>
                  </InputLabel>
                  <TextField
                    id="ifscCode"
                    name="ifscCode"
                    variant="outlined"
                    size="small"
                    style={{ textTransform: 'none'}}
                    value={ifscCode}
                    onChange={(e) =>{
                      const value = e.target.value;
                      if (value !== "" && !AlPHANUMERIC_ONLY.test(value)) {
                        return;
                      } 
                      setIfscCode(e.target.value.toUpperCase())}}
                    InputProps={{
                      autoComplete: "off",
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.ifscCode ? formErrors.ifscCode : ""}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <InputLabel className={classes.lable}>
                    Account number{" "}
                    <Box component="span" color="red">
                      *
                    </Box>
                  </InputLabel>
                  <TextField
                    id="accountNumber"
                    name="accountNumber"
                    variant="outlined"
                    size="small"
                    value={accountNumber}
                    onChange={(e) =>{
                      const value = e.target.value;
                      if (value !== "" && !NUMERIC_ONLY.test(value)) {
                        return;
                      }
                      setAccountNumber(e.target.value)}}
                      inputProps={{
                        minLength: 6,
                        maxLength: 18
                      }}
                    InputProps={{
                      autoComplete: "off",
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={
                      formErrors.accountNumber ? formErrors.accountNumber : ""
                    }
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <InputLabel className={classes.lable}>
                    Micr NO.{" "}
                    <Box component="span" color="red">
                      *
                    </Box>
                  </InputLabel>
                  <TextField
                    id="micrNo"
                    name="micrNo"
                    variant="outlined"
                    size="small"
                    value={micrNo}
                    onChange={(e) =>{
                      const value = e.target.value;
                      if (value !== "" && !AlPHANUMERIC_ONLY.test(value)) {
                        return;
                      } 
                      setMicrNo(e.target.value.toUpperCase())}}
                      inputProps={{
                        maxLength: 9
                      }}
                    InputProps={{
                      autoComplete: "off",
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.micrNo ? formErrors.micrNo : ""}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel className={classes.lable}>
                Mobile No.{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                variant="outlined"
                size="small"
                value={phoneNumber}
                onChange={(e) =>{
                  const value = e.target.value;
                  if (value !== "" && !NUMERIC_ONLY.test(value)) {
                    return;
                  }
                  setPhoneNumber(e.target.value)}}
                  inputProps={{
                    maxLength: 10
                  }}
                InputProps={{
                  autoComplete: "off",
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                helperText={
                  formErrors.phoneNumber ? formErrors.phoneNumber : ""
                }
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.addbutton}
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
