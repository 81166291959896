import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch } from "react-redux";

/* redux actions */
import { updateProductType } from "../../redux/actions/productActions";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  timerRoot: {
    margin: "10px 0 auto",
  },
  bargaintimer: {
    display: "flex",
    flexDirection: "row",
    color: "#212529",
  },
  timediv: {
    margin: 4,
    border: "1px solid #d8d8d8",
    background: "#d8d8d8",
  },
  timer: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    textAlign: "center",
    padding: 4,
    backgroundColor: "#FFF",
  },
  timertext: {
    fontSize: 10,
    fontFamily: "'Fira Sans', sans-serif",
    backgroundColor: "#ff9d00",
    padding: "4px 12px",
    color: "#fff",
    fontWeight: 600,
  },
  endslable: {
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
  },
  endstext: {
    fontSize: 11,
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: "17px",
  },
}));

export const BargainTimer = (props) => {
  const classes = useStyles();
  const { product } = props;
  const dispatch = useDispatch();

  const [timeDays, setTimeDays] = useState("00");
  const [timeHours, setTimeHours] = useState("00");
  const [timeMinutes, setTimeMinutes] = useState("00");
  const [timeSeconds, setTimeSeconds] = useState("00");

  let interval = useRef();

  const startTimer = () => {
    const countdownDate = new Date(product.bargainEndDateTime).getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
        dispatch(updateProductType(product.id));
      } else {
        setTimeDays(days);
        setTimeHours(hours);
        setTimeMinutes(minutes);
        setTimeSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    if (
      product.bargain === "YES" &&
      new Date(product.bargainStartDateTime) <= new Date() &&
      new Date(product.bargainEndDateTime) >= new Date()
    ) {
      startTimer();
    }
    return () => {
      clearInterval(interval);
    };
  });


  return (
    <div className={classes.timerRoot}>
      {product.bargain === "YES" &&
        product.bargainStartDateTime &&
        product.bargainEndDateTime &&
        new Date(product.bargainStartDateTime) <= new Date() &&
        new Date(product.bargainEndDateTime) >= new Date() && (
          <Fragment>
            <div className={classes.bargaintimer}>
              <div className={classes.timediv}>
                <Typography className={classes.timer}>{timeDays}</Typography>
                <Typography className={classes.timertext}>DAYS</Typography>
              </div>

              <div className={classes.timediv}>
                <Typography className={classes.timer}>{timeHours}</Typography>
                <Typography className={classes.timertext}>HRS</Typography>
              </div>

              <div className={classes.timediv}>
                <Typography className={classes.timer}>{timeMinutes}</Typography>
                <Typography className={classes.timertext}>MINS</Typography>
              </div>

              <div className={classes.timediv}>
                <Typography className={classes.timer}>{timeSeconds}</Typography>
                <Typography className={classes.timertext}>SECS</Typography>
              </div>
            </div>

            <div style={{ margin: 6 }}>
              <Typography className={classes.endslable}>
                Ends on{" "}
                {product.bargainEndDateTime
                  ? new Date(product.bargainEndDateTime).toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric'})
                  : ""}
              </Typography>

              <Typography className={classes.endstext}>
                To be safe, place bargain 2 hours before the bargain ends.
              </Typography>
            </div>
          </Fragment>
        )}
    </div>
  );
};
