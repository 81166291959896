import React from "react";

/* import react components */
import { AppBars } from "../../components/AppBar";
import { ShippingAddress } from "../../components/customer/checkout/ShippingAddress";
import { ReviewOrder } from "../../components/customer/checkout/ReviewOrder";
import { Payment } from "../../components/customer/checkout/Payment";
import { Footer } from "../../components/Footer";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import Sitemap from "../Sitemap/sitemap";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      // width: 800,
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
  paper: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(12),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    "& .MuiStepLabel-label": {
      fontFamily: "'Fira Sans', sans-serif",
    },
  },
  checkout: {
    textAlign: "center",
    fontFamily: "'Fira Sans', sans-serif",
  },
}));

const steps = ["Shipping address", "Review your order", "Payment"];

export const ProductCheckout = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [openaddAddressUpdated, setAddAddressUpdated] = useState(false);
  const [showSiteMap5, setShowSiteMap5] = useState(false);
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <ShippingAddress
            handleNext={handleNext}
            setAddAddressUpdated={setAddAddressUpdated}
          />
        );
      case 1:
        return (
          <ReviewOrder
            handleBack={handleBack}
            handleNext={handleNext}
            openaddAddressUpdated={openaddAddressUpdated}
          />
        );
      case 2:
        return <Payment handleBack={handleBack} />;
      default:
        throw new Error("Unknown step");
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBars position="absolute" color="default" className={classes.appBar} />
      {!showSiteMap5 ? (
        <>
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <Typography
                component="h1"
                variant="h4"
                className={classes.checkout}
              >
                Checkout
              </Typography>
              <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <React.Fragment>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                      Thank you for your order.
                    </Typography>
                    <Typography variant="subtitle1">
                      Your order number is #2001539. We have emailed your order
                      confirmation, and will send you an update when your order
                      has shipped.
                    </Typography>
                  </React.Fragment>
                ) : (
                  <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
                )}
              </React.Fragment>
            </Paper>
          </main>
        </>
      ) : (
        <Sitemap />
      )}

      <Footer showSiteMap={showSiteMap5} setShowSiteMap={setShowSiteMap5} />
    </React.Fragment>
  );
};
