import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

/* react components */
import { AddAddressForm } from "../checkout/AddAddressForm";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid #d6d6d6",
    "& .MuiCardHeader-root": {
      backgroundColor: "#53E7BC",
    },
    "& .MuiCardHeader-title": {
      fontSize: "14px",
      fontFamily: "'Fira Sans', sans-serif",
    },
    "& .MuiCardActions-root": {
      justifyContent: "center",
      borderTop: "1px solid #d6d6d6",
    },
    // "& .MuiFab-sizeSmall": {
    //   height: 25,
    // },
  },
  notfound: {
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#3b3b3b",
    opacity: 0.5,
    textAlign: "center",
  },
  margin: {
    margin: theme.spacing(1),
    backgroundColor: "#53E7BC",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    "&:hover": {
      backgroundColor: "#53E7BC",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  nextbutton: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  errorblock: {
    fontSize: "12px",
    fontFamily: "'Fira Sans', sans-serif",
    textAlign: "center",
    margin: "4px",
    color: "red",
  },
  addresstype: {
    fontSize: 16,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
  },
  adresstext: {
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
    padding: 4,
  },
  backtocart: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export const ShippingAddress = (props) => {
  const classes = useStyles();

  const {setAddAddressUpdated } =props;
  const { account } = useSelector((state) => state.auth);
  const history = useHistory();

  const [openaddAddress, setAddAddress] = useState(false);
  const [errors, setErrors] = useState({});

  const handleCancel = () => {
    setAddAddress(false);
  };

  const handleNext = () => {
    if (account.delivery === null) {
      let errors = {};
      errors["address"] = "Delivery address is required";
      setErrors(errors);
    } else {
      props.handleNext();
    }
  };

  /* back to cart page */
  const handleBacktoCart = () => {
    history.push("/cart");
  };
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardHeader title="Shipping address" />

              {!openaddAddress ? (
                <React.Fragment>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12} style={{ textAlign: "center" }}>
                        <Typography className={classes.addresstype}>
                          Delivery address
                        </Typography>
                        {account.delivery ? (
                          <React.Fragment>
                            <Typography className={classes.adresstext}>
                              {account.delivery.firstName}{" "}
                              {account.delivery.lastName}
                            </Typography>
                            <Typography className={classes.adresstext}>
                              {account.delivery.address}
                            </Typography>
                            <Typography className={classes.adresstext}>
                              {account.delivery.city} <span>{"-"}</span>
                              {account.delivery.postalCode}
                            </Typography>
                            <Typography className={classes.adresstext}>
                              {account.delivery.stateProvince}
                            </Typography>
                            <Typography className={classes.adresstext}>
                              Ph: {account.delivery.phone}
                            </Typography>
                          </React.Fragment>
                        ) : (
                          <Typography className={classes.notfound}>
                            Delivery address not found
                          </Typography>
                        )}
                      </Grid>

                      <Grid item md={6} xs={12} style={{ textAlign: "center" }}>
                        <Typography className={classes.addresstype}>
                          Billing address
                        </Typography>
                        {account.billing ? (
                          <React.Fragment>
                            <Typography className={classes.adresstext}>
                              {account.billing.firstName}{" "}
                              {account.billing.lastName}
                            </Typography>
                            <Typography className={classes.adresstext}>
                              {account.billing.address}
                            </Typography>
                            <Typography className={classes.adresstext}>
                              {account.billing.city} <span>{"-"}</span>
                              {account.billing.postalCode}
                            </Typography>
                            <Typography className={classes.adresstext}>
                              {account.billing.stateProvince}
                            </Typography>
                            <Typography className={classes.adresstext}>
                              Ph: {account.billing.phone}
                            </Typography>
                          </React.Fragment>
                        ) : (
                          <Typography className={classes.notfound}>
                            Billing address not found
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>

                  {errors.address && (
                    <p className={classes.errorblock}>{errors.address}</p>
                  )}

                  <CardActions>
                    <Fab
                      variant="extended"
                      size="small"
                      color="primary"
                      aria-label="add"
                      className={classes.margin}
                      onClick={(e) => setAddAddress(true)}
                    >
                      Add/Edit Address
                    </Fab>
                  </CardActions>
                </React.Fragment>
              ) : (
                <AddAddressForm setAddAddressUpdated={setAddAddressUpdated} handleCancel={handleCancel} account={account} />
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>

      {!openaddAddress && (
        <div className={classes.buttons}>
          <Button onClick={handleBacktoCart} className={classes.backtocart}>
            Back to cart
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.nextbutton}
          >
            Next
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};
