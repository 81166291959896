import React, {useRef, useState} from "react";

/* import react components */
import { AppBars } from "../components/AppBar";
import { Footer } from "../components/Footer";

/* import redux actions */

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid } from "@material-ui/core";

/* ui components */
import  ProductFilter  from "../components/ProductFilter";
import  ProductList  from "../components/ProductList";
import MenuHeader from "../components/MenuHeader";
import Sitemap from "./Sitemap/sitemap";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  searchroot: {
    "& .MuiButtonBase-root": {
      outline: "none",
    }
  }
}));

export const stickyBoxStyle = {
  position: "sticky",
  top: 80,
  backgroundColor: "#fff",
  zIndex: 3,
  paddingLeft: "1rem",
};


export const Search = (props) => {
  const classes = useStyles();
  const params = new URLSearchParams(props.location.search);
  const queryParam = params.get("query");

  const [selectedPriceSort, setSelectedPriceSort] = useState("all");
  const [showSiteMap4, setShowSiteMap4] = useState(false);

  const categoryRef = useRef();

  const categoryClickedFromCategoryTab = (categoryName) => {
    categoryRef.current.categoryClickedFromTab(categoryName);
  };


  return (
    <div className={classes.searchroot}>
      <CssBaseline />
      <AppBars />
      {/* <CategoryList categoryClicked={categoryClickedFromCategoryTab}  fromProductOrSearchPage={true}/> */}
      {!showSiteMap4 ? (<>
      <MenuHeader categoryClicked={categoryClickedFromCategoryTab}  fromProductOrSearchPage={true}/>
      <section style={{ marginTop: 10, backgroundColor: "#fff" }}>
        <Grid container style={{ marginBottom: 10 }}>

          <ProductFilter ref={categoryRef} queryParam = {queryParam} setSelectedPriceSort={setSelectedPriceSort}/>
          <ProductList  selectedPriceSort={selectedPriceSort} setSelectedPriceSort={setSelectedPriceSort}/>

        </Grid>
        </section>
        </>):(<Sitemap />)}

      <Footer  showSiteMap={showSiteMap4} setShowSiteMap={setShowSiteMap4} />
    </div>
  );
};

