import { ActionTypes } from "../constants/actionTypes.js";

const initialState = {
  loadingHome: false,
  categories: [],
  error: null,
  serverError: false,
  filterProducts: {},
  productGroups: {},
  filters: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOADING_HOME_PAGE_UI: 
      return {
        ...state,
        loadingHome: true,
      };
    case ActionTypes.PRODUCT_GROUPS:
      return {
        ...state,
        categories: action.payload.categories.categories,
        productGroups: action.payload.groups,
        loadingHome: false,
        // landingPageData: action?.payload?.landingPageData || {}
      };
    case ActionTypes.SET_HOME_PAGE_ERROR:
      return {
        ...state,
        loadingHome: false,
        error: action.payload,
      };
    case ActionTypes.SET_HOME_PAGE_SERVER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
        serverError: action.payload,
      };
    case ActionTypes.GET_PRODUCT_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case ActionTypes.PRODUCT_LISTING_WITH_FILTERS:
      return {
        ...state,
        loading: false,
        filterProducts: action.payload,
      };
    case ActionTypes.PRODUCT_LIST_SORTING:
        return {
          ...state,
          loading: false,
          filterProducts: action.payload,
      };
    case ActionTypes.GET_BANNERS:
      return {
        ...state,
        bannerImages: action.payload,
      };
    case ActionTypes.GET_CATEGORY_LIST:
      return {
        ...state,
        categories: action.payload.categories,
      };
    case  ActionTypes.PRODUCT_PRICE_TYPE_CLICKED:
      return {
        ...state,
        type: action.payload.type,
        page : action.payload.page
      };
    case  ActionTypes.PRODUCT_PAGE_NUMBER_CLICKED:
      return {
        ...state,
        page: action.payload,
      };
    case  ActionTypes.PRODUCT_SORT_BY_CLICKED:
      return {
        ...state,
        sortby: action.payload,
      };


    default:
      return state;
  }
}
