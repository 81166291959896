import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { ActionTypes } from "./redux/constants/actionTypes";
import {
  getUserData,
  getSellerData,
  logoutAction,
} from "./redux/actions/authActions";

//axios
import axios from "axios";

//jwt-decode
import jwtDecode from "jwt-decode";

//theme
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../src/util/theme";

//packages
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "popper.js/dist/popper.js";
import "font-awesome/css/font-awesome.min.css";

//restrict routes
import { AuthRoute, SellerRoute, CustomerRoute } from "./util/route";

//pages
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { Search } from "./pages/Search";
import { CustomerLogin } from "./pages/customer/CustomerLogin";
import { CustomerSignup } from "./pages/customer/CustomerSignup";
import { SellerLogin } from "./pages/seller/SellerLogin";
import { SellerSignup } from "./pages/seller/SellerSignup";
import { ForgotPassword } from "./pages/ForgotPassword";
import { VerifyToken } from "./pages/VerifyToken";
import { ResetForgotPassword } from "./pages/ResetForgotPassword";
import { SellerDashboard } from "./pages/seller/SellerDashboard";
import { ProductDetailsPage } from "./pages/ProductDetailsPage";
import { ProductsListByGroup } from "./pages/groups/ProductsListByGroup";
import { Cart } from "./pages/Cart";
import { Portfolio } from "./pages/customer/Portfolio";
import { ProductCheckout } from "./pages/customer/ProductCheckout";
import { CategoryProductsList } from "./pages/CategoryProductsList";
import { WriteReviews } from "./pages/customer/WriteReviews";
import { Settings } from "./pages/customer/Settings";
// import { OurTeam } from "./pages/footerlinks/OurTeam";
import { AboutUs } from "./pages/footerlinks/AboutUs";
import { TermsConditions } from "./pages/footerlinks/TermsConditions";
import { ShippingPolicy } from "./pages/footerlinks/ShippingPolicy";
import { ScrollToTop } from "./components/ScrollToTop";
import { WishList } from "./pages/customer/WishList";
import { SecuredImage } from "./pages/errors/SecuredImage";
import { PayuSuccess } from "./pages/customer/payu/PayuSuccess";
import { PayuFailure } from "./pages/customer/payu/PayuFailure";
import { useHistory } from "react-router";
import { PaymentResponse } from "./pages/PaymentResponse";
import { PrivacyPolicyFooter } from "./pages/footerlinks/PrivacyPolicyFooter";
import { RefundPolicy } from "./pages/footerlinks/RefundPolicy";
import { Contact } from "./pages/footerlinks/Contact";
import { PrivacyPolicyFooterNew } from "./pages/footerlinks/PrivacyPolicyFooterNew";
// import Sitemap from "./pages/Sitemap/sitemap";
import { WorkProgress } from "./pages/WorkInProgress";
const token = localStorage.jwt;

if (token) {
  const decodedToken = jwtDecode(token);

  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutAction());
    window.location.href = "/";
  } else {
    if (localStorage.sell) {
      store.dispatch({ type: ActionTypes.SET_AUTHENTICATED });
      axios.defaults.headers.common["Authorization"] = token;
      store.dispatch(getSellerData());
    } else {
      store.dispatch({ type: ActionTypes.SET_AUTHENTICATED });
      axios.defaults.headers.common["Authorization"] = token;
      store.dispatch(getUserData());
    }
  }
}

function App() {
  let inactivityTimer;

  function resetInactivityTimer() {
    clearTimeout(inactivityTimer);

    inactivityTimer = setTimeout(() => {
      store.dispatch(logoutAction());
    }, 5 * 60 * 10000); // 5 minutes in milliseconds
  }

  // Event listeners for user interactions

  document.addEventListener("mousemove", resetInactivityTimer);
  document.addEventListener("mousedown", resetInactivityTimer);
  document.addEventListener("keypress", resetInactivityTimer);

  // Initial start of the timer
  resetInactivityTimer();
  const history = useHistory();

  // 401 error redirect to the login page
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = error.response?.status || 500;
      if (status === 401) {
        // console.log("removing the token due to 401");
        localStorage.clear();
        delete axios.defaults.headers.common["Authorization"];
        history.push("/");
      } else {
        return Promise.reject(error);
      }
    }
  );






  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Router basename={process.env.REACT_APP_BASE_NAME}>
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/search" component={Search} />
            <Route
              exact
              path="/:category/products"
              component={CategoryProductsList}
            />
            <Route
              exact
              path="/product/:category/:productName/:productId"
              component={ProductDetailsPage}
            />
            <Route
              exact
              path="/:category/:groupName/:default/products"
              component={ProductsListByGroup}
            />
            <Route exact path="/about-us" component={AboutUs} />
            {/* <Route exact path="/meet-our-team" component={OurTeam} /> */}
            <Route
              exact
              path="/pages/terms-and-conditions"
              component={TermsConditions}
            />
            <Route
              exact
              path="/pages/shipping-policy"
              component={ShippingPolicy}
            />

            <Route exact path="/pages/Refund-policy" component={RefundPolicy} />
            <Route exact path="/pages/contact" component={Contact} />
            <Route
              exact
              path="/pages/privacy-policy"
              component={PrivacyPolicyFooter}
            />
            <Route
              exact
              path="/pages/privacy-policy-1"
              component={PrivacyPolicyFooterNew}
            />
            <Route
              exact
              path="/stockxbid/notifyuser/product/image/:sellerId/IMAGE/:dynamicText/:imageName"
              component={SecuredImage}
            />
            <CustomerRoute exact path="/cart" component={Cart} />
            <CustomerRoute
              exact
              path="/customer/wish-list"
              component={WishList}
            />
            <CustomerRoute
              exact
              path="/customer/portfolio"
              component={Portfolio}
            />
            <CustomerRoute
              exact
              path="/customer/account-settings"
              component={Settings}
            />
            <CustomerRoute exact path="/checkout" component={ProductCheckout} />
            <CustomerRoute
              exact
              path="/api/v1/payupayment/response"
              component={PayuSuccess}
            />
            <CustomerRoute
              exact
              path="/payupayment/success/response"
              component={PayuSuccess}
            />
            <CustomerRoute
              exact
              path="/payupayment/failure/response"
              component={PayuFailure}
            />
            <CustomerRoute
              exact
              path="/payment/response"
              component={PaymentResponse}
            />
            <CustomerRoute
              exact
              path="/review/:id/review-your-orderd-product"
              component={WriteReviews}
            />
            <AuthRoute exact path="/customer-login" component={CustomerLogin} />
            <AuthRoute
              exact
              path="/customer-signup"
              component={CustomerSignup}
            />
            <AuthRoute exact path="/seller-login" component={SellerLogin} />
            <AuthRoute exact path="/seller-signup" component={SellerSignup} />
            <AuthRoute
              exact
              path="/forgot-password"
              component={ForgotPassword}
            />
            <AuthRoute
              exact
              path="/reset-password/:token"
              component={VerifyToken}
            />
            <AuthRoute
              exact
              path="/ywc/reset-password"
              component={ResetForgotPassword}
            />
            <SellerRoute
              exact
              path="/seller-dashboard"
              component={SellerDashboard}
            />
            <Route exact path="/work-in-progress" component={WorkProgress} />

            <Route component={NotFound} />
          </Switch>
        </Router>
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
