export const Success = [
    {
      "sortOrder": 1,
      "key": ["DRC"],
      "value": "Data Received"
    },
    {
      "sortOrder": 2,
      "key": ["OFP"],
      "value": "Out for Pickup"
    },
    {
      "sortOrder": 3,
      "key": ["PUD"],
      "value": "PickDone"
    },
    {
      "sortOrder": 4,
      "key": ["PKD"],
      "value": "Picked"
    },
    {
      "sortOrder": 5,
      "key": ["IT"],
      "value": "InTransit"
    },
    {
      "sortOrder": 6,
      "key": ["RAD"],
      "value": "Reached at Destination"
    },
    {
      "sortOrder": 7,
      "key": ["OFD"],
      "value": "Out for Delivery"
    },
    {
      "sortOrder": 8,
      "key": ["DLVD"],
      "value": "Delivered"
    }
  ]

export const SuccessErrorOne = [
    {
        "sortOrder": 1,
        "key": ["DRC"],
        "value": "Data Received"
      },
      {
        "sortOrder": 2,
        "key": ["OFP"],
        "value": "Out for Pickup"
      },
      {
      "sortOrder": 3,
      "key": ["PND"],
      "value": "Pickup Not Done"
    },
];

export const SuccessErrorTwo = [
    {
        "sortOrder": 1,
        "key": ["DRC"],
        "value": "Data Received"
      },
      {
        "sortOrder": 2,
        "key": ["OFP"],
        "value": "Out for Pickup"
      },
      {
        "sortOrder": 3,
        "key": ["PUD"],
        "value": "PickDone"
      },
      {
        "sortOrder": 4,
        "key": ["PKD"],
        "value": "Picked"
      },
      {
        "sortOrder": 5,
        "key": ["IT"],
        "value": "InTransit"
      },
      {
        "sortOrder": 6,
        "key": ["RAD"],
        "value": "Reached at Destination"
      },
      {
        "sortOrder": 7,
        "key": ["OFD"],
        "value": "Out for Delivery"
      },
      {
        "sortOrder": 8,
        "key": ["UD"],
        "value": "[Status of NDR Reason]"
      },
];


export const SuccessDelivered = [
  {
    "sortOrder": 1,
    "key": ["RTO"],
    "value": "Return to Origin"
  },
  {
    "sortOrder": 2,
    "key": ["RTO-IT"],
    "value": "Return to Origin Intransit"
  },
  {
    "sortOrder": 3,
    "key": ["RAO"],
    "value": "Reached at Origin"
  },
  {
    "sortOrder": 4,
    "key": ["RTO-OFD"],
    "value": "Return to Origin Out for Delivery"
  },
  {
    "sortOrder": 5,
    "key": ["RTD"],
    "value": "Return Delivered"
  },
]

export const SuccessUndelivered = [
  {
    "sortOrder": 1,
    "key": ["RTO"],
    "value": "Return to Origin"
  },
  {
    "sortOrder": 2,
    "key": ["RTO-IT"],
    "value": "Return to Origin Intransit"
  },
  {
    "sortOrder": 3,
    "key": ["RAO"],
    "value": "Reached at Origin"
  },
  {
    "sortOrder": 4,
    "key": ["RTO-OFD"],
    "value": "Return to Origin Out for Delivery"
  },
  {
    "sortOrder": 5,
    "key": ["RTU"],
    "value": "Return Undelivered"
  },
]


export const SuccessShortage = [
  {
    "sortOrder": 1,
    "key": ["STD"],
    "value": "ShipmentTransit"
  },
  {
    "sortOrder": 2,
    "key": ["STG"],
    "value": "Shortage"
  },
  {
    "sortOrder": 3,
    "key": ["RTO-STG"],
    "value": "Return To Origin Shortage"
  }
];

export const SuccessLost = [
  {
    "sortOrder": 1,
    "key": ["LOST"],
    "value": "Lost"
  }
]