 export const  isStringNullorEmpty = (str) => {
    if (str === undefined || str === '' || str === null) {
        return true;
    } else {  
        try {
            return str.trim().length === 0;
        } catch(error) {
            // console.log(str);
            // console.log(error);
        }
    }
};


export const isArrayNotEmpty = (list) => {
    return (Array.isArray(list) && list.length);
 };

 export const validatePositiveNumberEvent = (e) => {
    if (!e)
      return;

    if ((e.code === 'Minus' && e.keyCode == 189 && e.key === '-')
         || (e.keyCode == 187 && e.key === '+')
         || (e.code === 'KeyE' && e.keyCode == 69 && e.key === 'e')) {
          e.target.value = "";
      e.preventDefault();
    }
  };

  export const isNumberKey = (evt) => {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.target.value = "";
        evt.preventDefault();
    }
};

export const reOrderCategories = (data) => {
    const ind = data.findIndex((item) => item.code == "streetwears");
    let resultCategories = [];
    if (ind !== 0) {
      resultCategories.push({ ...data[ind], id: 1 });
      for (let i = 0; i < ind; i++) {
        resultCategories.push({ ...data[i], id: data[i].id + 1 });
      }
      for (let i = ind + 1; i < data.length; i++) {
        resultCategories.push({ ...data[i], id: data[i].id + 1 });
      }
      return resultCategories;
    }

   return data;
  };