import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  validNumber: false,
  validMessage: "",
  invalidNumber: false,
  invalidMessage: "",
  states: [],
  cities:[],
  pincodes: [],
  termsConditions: [],
  bussinessCategories: [],
  subCategories: [],
  selectedCategories: [],
  openModel: false,
  validGst: false,
  validGstMessage: "",
  invalidGst: false,
  invalidGstMessage: "",
  clickedPolicy: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.VALID_OTP:
      return {
        ...state,
        validNumber: true,
        validMessage: action.payload,
      };
    case ActionTypes.INVALID_OTP:
      return {
        ...state,
        invalidNumber: true,
        invalidMessage: action.payload,
      };
    case ActionTypes.CLEAR_OTP_MESSAGE:
      return {
        ...state,
        validNumber: false,
        validMessage: "",
        invalidNumber: false,
        invalidMessage: "",
      };
      case ActionTypes.GET_ALL_STATE:
      return {
        ...state,
        states: action.payload,
      };
    case ActionTypes.CITY_BY_STATE:
      return {
        ...state,
        cities: action.payload,
      };
      case ActionTypes.PINCODES_BY_CITY:
      return {
        ...state,
        pincodes: action.payload,
      };
    case ActionTypes.USER_TERMS_CONDITIONS:
      return {
        ...state,
        termsConditions: action.payload.termsConditions,
        clickedPolicy : action.payload.clickedPolicy
      };
    case ActionTypes.BUSSINESS_CATEGORIES:
      return {
        ...state,
        bussinessCategories: action.payload,
      };
    case ActionTypes.BUSSINESS_SUB_CATEGORIES:
      return {
        ...state,
        subCategories: action.payload,
      };
    case ActionTypes.SELECTED_PRODUCT_CATEGORIES:
      return {
        ...state,
        selectedCategories: [...state.selectedCategories, action.payload],
      };
    case ActionTypes.REMOVE_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategories: state.selectedCategories.filter(
          (item, index) => index !== action.payload
        ),
      };
    case ActionTypes.CLEAR_SELECTED_CATERORIES:
      return {
        ...state,
        selectedCategories: [],
      };
    case ActionTypes.SHOW_USER_SIGNUP_ALERT:
      return {
        ...state,
        openModel: true,
      };
    case ActionTypes.CLOSE_USER_SIGNUP_ALERT:
      return {
        ...state,
        openModel: false,
      };
    case ActionTypes.VALID_GST:
      return {
        ...state,
        validGst: true,
        validGstMessage: action.payload,
      };
    case ActionTypes.INVALID_GST:
      return {
        ...state,
        invalidGst: true,
        invalidGstMessage: action.payload,
      };
    case ActionTypes.CLEAR_GST_MESSAGE:
      return {
        ...state,
        validGst: false,
        validGstMessage: "",
        invalidGst: false,
        invalidGstMessage: "",
      };
    case ActionTypes.UPDATE_CLICKED_POLICY:
      return {
        ...state,
        clickedPolicy : action.payload
      }
    default:
      return state;
  }
}
