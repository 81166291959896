import React,{useState, useEffect} from "react";

/* import react components */
import { Footer } from "../../components/Footer";

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { AppBars } from "../../components/AppBar";
import MenuHeader from "../../components/MenuHeader";
import Sitemap from "../Sitemap/sitemap";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#f0e0d4",
  },
  pagetitle: {
    fontSize: 28,
    fontWeight: 600,
    color: "#F24046",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
    fontStretch: "normal",
    margin: "90px 0 10px 0",
    textAlign: "center",
  },

  text: {
    fontSize: 16,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 300,
    margin: "auto",
    lineHeight: "30px",
    color: "#5c6166",
  },
  heading: {
    textAlign: "start",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    margin: "15px 0 15px 0",
    color: "#F24046",
  },
  subheading: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    margin: "15px 0 0 8px",
    fontWeight: 600,
  },
}));

export const RefundPolicy = () => {
  const classes = useStyles();
  const [showSiteMap14, setShowSiteMap14] = useState(false);
  useEffect(() => {
    var metaDescriptionTag = document.querySelector('meta[name="description"]');

    // Check if the meta tag is found
    if (metaDescriptionTag) {
      // Update the content attribute with a new description
      metaDescriptionTag.setAttribute('content', `Outxtock Refund Policy`)
    }
    document.title = 'Refund Policy'
  },[])
  return (
    <div className={classes.root}>
      <AppBars />
      {/* <CategoryList /> */}
      {!showSiteMap14 ? (
        <>
          <MenuHeader />
          <Container fixed style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <Typography component="h6" className={classes.pagetitle}>
                REFUND POLICY
              </Typography>

              <Typography component="h6" className={classes.heading}>
                Contractual Agreements
              </Typography>

              <Typography component="p" className={classes.text}>
                B2B transactions often involve formal contracts or purchase
                agreements that outline the terms and conditions of the sale,
                including any applicable refund or return policies. The refund
                policy is typically agreed upon and documented in these
                contracts. Negotiated Terms: B2B transactions are often more
                flexible and subject to negotiation. Both the buyer and seller
                can negotiate the terms of refunds and returns based on their
                specific needs and business relationship. This can include
                factors such as return time frames, restocking fees, or
                conditions for accepting returns. Product or Service Specifics:
                Refund policies can vary depending on the type of product or
                service being sold. Some items, such as custom-made products or
                specialized services, may have limited or no refund options due
                to their unique nature. On the other hand, standardized products
                may have more lenient refund policies. Partial Refunds or
                Credits: B2B sellers may offer partial refunds or store credits
                instead of full monetary refunds. This approach allows the buyer
                to use the credit toward future purchases, maintaining the
                business relationship while addressing any concerns. Return
                Authorization: B2B sellers may require buyers to obtain a return
                authorization or approval before initiating a return. This
                process helps ensure that the return is valid and aligns with
                the established refund policy. It's important for both buyers
                and sellers in B2B e-commerce transactions to clearly define and
                understand the refund policies before entering into a
                transaction. This helps to avoid any potential disputes or
                misunderstandings down the line. If you have specific questions
                about a particular B2B refund policy, it's best to consult the
                terms and conditions set forth by the seller or contact their
                customer support for further clarification.
              </Typography>
            </Grid>
          </Container>
        </>
      ) : (
        <Sitemap />
      )}
      <Footer showSiteMap={showSiteMap14} setShowSiteMap={setShowSiteMap14} />
    </div>
  );
};
