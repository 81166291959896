import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  open: false,
  scrollType: "paper",
  titleContent: ""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SHOW_POLICY_MODAL:
      return {
        ...state,
        open: true,
        titleContent : action.payload.titleContent
      };
    case ActionTypes.CLOSE_POLICY_MODAL:
      return {
        ...state,
        open: false,
        titleContent: ""
      };
    default:
      return state;
  }
}
