import React, { useState } from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import { isStringNullorEmpty } from "../../../util/commonUtil/commonUtil";
// import Axios from "axios";
// import { alertSnackBar } from "../../../redux/actions/customerActions";
import { useDispatch } from "react-redux";
// import DateRangeIcon from "@material-ui/icons/DateRange";

const useStyles = makeStyles((theme) => ({
  brand: {
    color: "#27B23E",
  },
  address: {
    color: "#04AA6D",
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: "10px",
  },
  dialog: {
    "& .MuiListItemText-primary": {
      fontSize: 12,
      fontFamily: "'Fira Sans', sans-serif",
    },
    "& .MuiListItemSecondaryAction-root": {
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 600,
      fontSize: 12,
    },
    lineHeight: "5px",
  },

  labelRoot: {
    fontSize: "12px",

    color: "#5d5d5d",
    letterSpacing: 0.17,
  },
  bargainDiv: {
    display: "flex",
    border: "1px solid #d6d6d6",
    width: "100%",
    color: "#212529",
    borderRadius: "4px",
    padding: "8px",
    margin: "auto",
  },
  datePicker: {
    border: "none",
    width: "100%",
    outline: "none",
    fontSize: 15,
    fontFamily: "'Fira Sans', sans-serif",
    opacity: 0.54,
  },

  button: {
    backgroundColor: "#53e7bc",
    fontSize: 12,
    "&:hover": {
      backgroundColor: "#53e7bc",
      fontSize: 12,
    },
  },
}));

const EMAIL = /^[ A-Za-z_@]+$/;
const ALPHA_ONLY = /^[a-zA-Z\s]+$/;
const NUMERIC_ONLY = /^[0-9]+$/;
const AlPHANUMERIC_ONLY = /^[ A-Za-z0-9_@./#&+-]+$/;
const SPECIAL_ONLY = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
const NUMERIC_SPECIAL_ONLY = /^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
const ChangeDelivery = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  // const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { open, handleClose, submitChangeDeliverAddress, changeData } = props;

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [changeDataList, setChangeDataList] = useState();
  // const [deferredDeliveryDate, setDeferredDeliveryDate] = useState("");

  const [formErrors, setFormErrors] = useState({});

  const handleCloseForModal = () => {
    setFormErrors({});
    handleClose();
    setName("");
    setLastName("");
    setMobile("");
    setPinCode("");
    setAddress("");
    setAddressTwo("");
    setEmail("");
    setChangeDataList()
  };

  
  // const delDate = changeData && changeData.deliveryDate;
    // const deliveredDate = `${new Date(delDate).getMonth() + 1}-${new Date(delDate).getDate()}-${new Date(delDate).getFullYear()}`;

  
  const inputValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (isStringNullorEmpty(name)) {
      inputsValid = false;
      formErrors["name"] = "Please enter name";
    }

    if (isStringNullorEmpty(lastName)) {
      inputsValid = false;
      formErrors["lastname"] = "Please enter lastname";
    }

    if (isStringNullorEmpty(mobile)) {
      inputsValid = false;
      formErrors["mobile"] = "Please enter number";
    }

    if (mobile.length !== 10) {
      inputsValid = false;
      formErrors["mobile"] = "mobile number should be 10 digit";
    }

    if (isStringNullorEmpty(pinCode) && pinCode.length !== 6) {
      inputsValid = false;
      formErrors["pincode"] = "Please enter valid pincode";
    }

    if (isStringNullorEmpty(address)) {
      inputsValid = false;
      formErrors["address"] = "Please enter address";
    }

    if (
      !AlPHANUMERIC_ONLY.test(address) ||
      NUMERIC_SPECIAL_ONLY.test(address) ||
      NUMERIC_ONLY.test(address) ||
      SPECIAL_ONLY.test(address)
    ) {
      inputsValid = false;
      formErrors["address"] = "Please enter valid address";
    }

    if (isStringNullorEmpty(email)) {
      inputsValid = false;
      formErrors["email"] = "Please enter email";
    }

    if (EMAIL.test(email)) {
      inputsValid = false;
      formErrors["email"] = "Please enter valid email";
    }

    setFormErrors(formErrors);
    return inputsValid;
  };

  // const handleFromDate = (date) => {
  //   setDeferredDeliveryDate(date);
  // };

  // const handleChangeAddress = async () => {
  //   if (inputValidation()) {
  //     const { data } = await Axios.get(
  //       `${process.env.REACT_APP_SHIPPING_URL}/api/v1/searchByPinCode/${pinCode}`
  //     );

  //     if (data.pincode === pinCode) {
  //       submitChangeDeliverAddress(
  //         name,
  //         lastName,
  //         mobile,
  //         pinCode,
  //         address,
  //         email,
  //         deferredDeliveryDate
  //       );
  //       handleCloseForModal();
  //     } else {
  //       dispatch(
  //         alertSnackBar(
  //           "error",
  //           "Unfortunately we do not ship to your pincode."
  //         )
  //       );
  //     }
  //   }
  // };


  return (
    <form autoComplete="off">
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseForModal}
        aria-labelledby="responsive-dialog-title"
        className={classes.dialog}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{ textAlign: "center" }}
        >
          Delivery Address
        </DialogTitle>

        <DialogContent style={{ textAlign: "center" }}>
          <Grid container spacing={3}>
            <Typography className={classes.labelRoot}>We will get back to you soon...!</Typography>
            {/* <React.Fragment>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="First Name *"
                  id="firstName"
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !ALPHA_ONLY.test(value)) {
                      return;
                    }
                    setName(e.target.value);
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.name ? formErrors.name : ""}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Last Name *"
                  id="lastName"
                  variant="outlined"
                  size="small"
                  value={lastName}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !ALPHA_ONLY.test(value)) {
                      return;
                    }
                    setLastName(e.target.value);
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.lastname ? formErrors.lastname : ""}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Mobile Number *"
                  id="mobile"
                  variant="outlined"
                  size="small"
                  value={mobile}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !NUMERIC_ONLY.test(value)) {
                      return;
                    }
                    setMobile(e.target.value);
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.mobile ? formErrors.mobile : ""}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Pin Code *"
                  id="pincode"
                  variant="outlined"
                  size="small"
                  value={pinCode}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !NUMERIC_ONLY.test(value)) {
                      return;
                    }
                    setPinCode(e.target.value);
                  }}
                  inputProps={{
                    maxLength: 6,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.pincode ? formErrors.pincode : ""}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Address 1 *"
                  id="address"
                  variant="outlined"
                  size="small"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.address ? formErrors.address : ""}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Address 2"
                  id="address"
                  variant="outlined"
                  size="small"
                  value={addressTwo}
                  onChange={(e) => setAddressTwo(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="email *"
                  id="email"
                  variant="outlined"
                  size="small"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={formErrors.email ? formErrors.email : ""}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Box className={classes.bargainDiv}>
                  <DatePicker
                    placeholderText="Select date"
                    selected={deferredDeliveryDate}
                    minDate={new Date(deliveredDate)}
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => handleFromDate(date)}
                    className={classes.datePicker}
                  />
                </Box>
              </Grid>
            </React.Fragment> */}
          </Grid>
        </DialogContent>

        <DialogActions>
          {/* <Button
            autoFocus
            onClick={handleChangeAddress}
            className={classes.button}
          >
            Update
          </Button>*/}

          <Button autoFocus onClick={handleCloseForModal}>
            Close
          </Button> 
        </DialogActions>
      </Dialog>
    </form>
  );
};



export const ChangeDeliveryList = React.memo(ChangeDelivery)
