import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { Box, Button, TextField } from "@material-ui/core";
import { checkSellerAccountStatus } from "../../redux/actions/authActions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: 15,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#495057",
      textAlign: "initial",
      background: "#F7F7F7",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: "6px",
    width: "75%",
    height: "100%",
    margin: "auto",
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
    color: "#666666",
  },
  submitButton: {
    textTransform: "none",
    color: "#FFFFFF",
    background: "#53E7BC",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
    fontSize: "13px",
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem 1.25rem",
    width: "75%",
    margin: "10px 0px 18px",
  },
  menuitem: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#495057",
    "& em": {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 13,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#495057",
    },
  },
  dropdownStyle: {
    marginTop: 48,
  },
}));

export const AccountStatus = () => {
  const classes = useStyles();
  const { loading, error, sellerAccStatus } = useSelector((state) => state.UI);
  const dispatch = useDispatch();

  const [statusType, setStatusType] = useState("email");
  const [statusId, setStatusId] = useState("");
  const [errors, setErrors] = useState({});

  // check account status
  const handleCheckAccountStatus = (event) => {
    event.preventDefault();
    if (statusId) {
      const statusData = {
        emailId: statusType === "email" ? statusId : "",
        registrationNumber: statusType === "regNo" ? statusId : "",
        userId: 0,
      };

      dispatch(checkSellerAccountStatus(statusData));
      setStatusId("");
      setStatusType(statusType);
      setErrors({});
    } else {
      let errors = {};
      if (statusType === "email") {
        errors["statusId"] = "Please enter your email-id";
      } else {
        errors["statusId"] = "Please enter your Registration number";
      }
      setErrors(errors);
    }
  };

  return (
    <Grid item lg={8} md={8} sm={6} xs={12} className={classes.paper}>
      <form
        onSubmit={handleCheckAccountStatus}
        className={classes.root}
        autoComplete="off"
      >
        <FormControl variant="outlined" size="small">
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={statusType}
            onChange={(e) => setStatusType(e.target.value)}
            className={classes.selecteCategory}
            MenuProps={{
              classes: { paper: classes.dropdownStyle },
            }}
          >
            <MenuItem value="email" className={classes.menuitem}>
              Email ID
            </MenuItem>
            <MenuItem value="regNo" className={classes.menuitem}>
              Registration Number
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="statusId"
          name="statusId"
          variant="outlined"
          size="small"
          placeholder={
            statusType === "email"
              ? "Enter your email"
              : "Enter registration No."
          }
          value={statusId}
          onChange={(e) => setStatusId(e.target.value)}
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
            },
          }}
          helperText={errors.statusId ? errors.statusId : ""}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submitButton}
          size="small"
          disabled={loading}
        >
          Check
        </Button>
      </form>
      {!errors.statusId && error && (
        <Box
          style={{
            fontSize: "12px",
            color: "red",
          }}
        >
          {error}
        </Box>
      ) }
      { !errors.statusId && !error && sellerAccStatus ? (
        <Box
          style={{
            fontSize: "12px",
            color: sellerAccStatus === "Your current account status is ACTIVE" ? "" : "red",
          }}
        >
          {sellerAccStatus}
        </Box>
      ) : null}
    </Grid>
  );
};
