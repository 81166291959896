import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import WarningIcon from "@material-ui/icons/Warning";
import { resetPassword } from "../redux/actions/authActions";
import { Box } from "@material-ui/core";

/* import material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 1440,
    minHeight: 832,
    backgroundColor: "#F7F7F7",
    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
  paper: {
    height: "auto",
    borderRadius: "6px",
    "&:hover": {
      boxShadow:
        "0 2px 4px rgba(27, 27, 27, 0.1), 0 20px 40px rgba(27, 27, 27, 0.2)",
    },
  },
  titlediv: {
    textAlign: "center",
  },
  heading: {
    fontSize: "30px",
    fontWeight: 600,
    color: "#4A4A4A",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.2,
  },
  lable: {
    fontSize: "12px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    color: "#212529",
    lineHeight: 1.5,
    padding: "8px",
    margin: "auto",
  },
  formroot: {
    textAlign: "center",
    "& .MuiFormControl-root": {
      width: "80%",
      marginTop: "1.2rem",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "16px",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#495057",
      textAlign: "initial",
      background: "#F7F7F7",
    },
  },
  button: {
    background: "#53E7BC",
    color: "#FFFFFF",
    margin: "1rem",
    fontSize: "13px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "none",
    borderRadius: "0.375rem",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    color: "#666666",
  },
  errorBlock: {
    fontSize: "12px",
    color: "red",
    textAlign: "center",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    textTransform: "lowercase",
  },
}));

export const ResetForgotPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.UI);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const inputValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (!password) {
      inputsValid = false;
      formErrors["password"] = "Please enter your Password.";
    }

    if (!confirmPassword) {
      inputsValid = false;
      formErrors["confirmPassword"] = "Please enter your confirm password.";
    }

    if (
      typeof password !== "undefined" &&
      typeof confirmPassword !== "undefined"
    ) {
      if (password !== confirmPassword) {
        inputsValid = false;
        formErrors["password"] = "Passwords don't match.";
      }
    }

    setFormErrors(formErrors);
    return inputsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (inputValidation()) {
      const userPasswordDto = { 
        emailAddress: props.location.state.email,
        message: "SUCCESS",
        password: password,
        userType: props.location.state.type,
      };

      dispatch(resetPassword(userPasswordDto, history));
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        direction="column"
        alignItems="center"
        style={{ minHeight: "100vh", maxWidth: "100%" }}
        spacing={3}
      >
        <Grid item xs={8}>
          <Grid container justify="center">
            {props.location.state ? (
              <Fragment>
                <div className={classes.titlediv}>
                  <Typography className={classes.heading}>
                    Change Password
                  </Typography>
                  <Typography className={classes.lable}>
                    Enter your new password and confirm new password
                  </Typography>
                </div>

                <Paper className={classes.paper}>
                  <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    className={classes.formroot}
                  >
                    <TextField
                      id="password"
                      name="password"
                      label="new Password"
                      type="password"
                      variant="outlined"
                      size="small"
                      onChange={(e) => setPassword(e.target.value)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.password ? formErrors.password : ""
                      }
                    />

                    <TextField
                      id="confirmPassword"
                      name="confirmPassword"
                      label="Confirm new Password"
                      type="password"
                      variant="outlined"
                      size="small"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.confirmPassword
                          ? formErrors.confirmPassword
                          : ""
                      }
                    />

                    <div className={classes.buttongroup}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        size="small"
                        disabled={loading}
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                  {error && <Box className={classes.errorBlock}>{error}</Box>}
                </Paper>
              </Fragment>
            ) : (
              <Paper className={classes.paper} style={{ textAlign: "center" }}>
                <Typography
                  variant="h4"
                  className={classes.heading}
                  style={{ padding: 18, color: "red" }}
                >
                  <WarningIcon fontSize="large" /> 404 page not found
                </Typography>

                <Typography
                  className={classes.lable}
                  style={{ width: "90%", fontSize: 14 }}
                >
                  We are sorry but the page you are looking for does not exist.
                </Typography>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  size="small"
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  Home
                </Button>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
