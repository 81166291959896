import React, { Fragment } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  soldout: {
    fontFamily: "'Fira Sans', sans-serif",
    color: "#000000",
    fontWeight: 750,
    fontSize: 28,
  },
  soldoutText: {
    fontFamily: "'Fira Sans', sans-serif",
    color: "#ff9d00",
    fontWeight: 400,
    fontSize: 18,
  },
  notify: {
    margin: "0 10px",
    color: "#fff",
    border: "1px solid #fb641b",
    fontSize: 15,
    fontWeight: 500,
    padding: "10px 30px",
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 20%)",
    backgroundColor: "#fb641b",
    textAlign: "center",
    height: 40,
    "&:hover": {
      backgroundColor: "#fb641b",
      color: "#fff",
    },
  },
  notifyText: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: 14,
    lineHeight: 2.21,
  },
}));

export const SoldOut = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Typography variant="h6" className={classes.soldout}>
        Sold Out
      </Typography>

      <Typography variant="subtitle1" className={classes.soldoutText}>
        This item is currently out of stock
      </Typography>

      <Typography className={classes.notifyText}>
        Enter your email and we'll notify when it's available.
      </Typography>

      <TextField variant="outlined" placeholder="Email Address" size="small" />

      <Button variant="contained" color="primary" className={classes.notify}>
        NOTIFY
      </Button>
    </Fragment>
  );
};
