import React, { Fragment, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/* import redux actions */
import { clearErrorMsg, verifyToken } from "../redux/actions/authActions";

/*import react component */
import Spinner from "../util/spinner/spinner";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 1440,
    minHeight: 832,
    backgroundColor: "#F7F7F7",
    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
  paper: {
    height: "auto",
    width: "25rem",
    borderRadius: "6px",
    textAlign: "center",
    "&:hover": {
      boxShadow:
        "0 2px 4px rgba(27, 27, 27, 0.1), 0 20px 40px rgba(27, 27, 27, 0.2)",
    },
  },
  lable: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    color: "#27B23E",
    lineHeight: 1.5,
    padding: "8px",
    width: "65%",
    margin: "auto",
  },
  requestButton: {
    background: "#53E7BC",
    color: "#FFFFFF",
    margin: "1rem",
    fontSize: "13px",
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "none",
    borderRadius: "0.375rem",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
}));

export const VerifyToken = () => {
  const classes = useStyles();
  let { token } = useParams();
  const { loading, error } = useSelector((state) => state.UI);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleBack = () => {
    history.push("/");
    dispatch(clearErrorMsg());
  };

  useEffect(() => {
    dispatch(verifyToken(token, history));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <div className={classes.root}>
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            style={{ minHeight: "100vh", maxWidth: "100%" }}
            spacing={3}
          >
            <Grid item xs={8}>
              <Grid container justify="center">
                <Paper className={classes.paper}>
                  <Typography className={classes.lable}>{error}</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.requestButton}
                    size="small"
                    onClick={handleBack}
                  >
                    Home
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </Fragment>
  );
};
