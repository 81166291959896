import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles(() => ({
  sitemapText: {
    fontSize: "20px",
    fontWeight: 800,
  },
  sitemapContainer: {
    width: "100%",
    padding: "5px",
    marginLeft: "20px",
    marginTop:'90px',
    marginRight: "20px",
  },
  sitemapContainer2: {
    width: "100%",
    padding: "5px",
    marginLeft: "20px",
    marginRight: "20px",
  },
  sitemapInfoContainer: {
    display: "flex",
  },
  sitemapAnchor: {
    "&:hover": {
      textDecoration: "none",
    },
  },
}));
const Sitemap = () => {
  const classes = useStyles();
  const sitemapInfoData = [
    {
      itemName: "About Us",
      itemLink: `/stockxbid/about-us`,
    },
    {
      itemName: "B2B Market",
      itemLink: `/stockxbid/work-in-progress`,
    },
    {
      itemName: "Contact Us",
      itemLink: `/stockxbid/pages/contact`,
    },
    {
      itemName: "Free Returns",
      itemLink: `/stockxbid/work-in-progress`,
    },
    {
      itemName: "Free Shipping",
      itemLink: `/stockxbid/work-in-progress`,
    },
    {
      itemName: "Low Price Gurantee",
      itemLink: `/stockxbid/work-in-progress`,
    },
    {
      itemName: "Payment and Shipping",
      itemLink: `/stockxbid/work-in-progress`,
    },
    {
      itemName: "Privacy Policy",
      itemLink: `/stockxbid/pages/privacy-policy`,
    },
    {
      itemName: "Returns and Exchanges",
      itemLink: `/stockxbid/work-in-progress`,
    },
    {
      itemName: "Reward Points",
      itemLink: `/stockxbid/work-in-progress`,
    },
    {
      itemName: "Shipping Policy",
      itemLink: `/stockxbid/pages/shipping-policy`,
    },
    {
      itemName: "Terms Of Use",
      itemLink: `/stockxbid/pages/terms-and-conditions`,
    },
  ];
  const myAccountInfo = [
    {
      itemName: "View Cart",
      itemLink: `/stockxbid/cart`,
    },
    {
      itemName: "Checkout",
      itemLink: `/stockxbid/checkout`,
    },
    {
      itemName: "Wishlist",
      itemLink: `/stockxbid/customer/wish-list`,
    },
  ];
  return (
    <>
      <div className={classes.sitemapContainer}>
        <h3 className={classes.sitemapText}>Sitemap</h3>
      </div>
      <div className={classes.sitemapContainer2}>
        <h5 style={{ fontSize: "16px", fontWeight: "bold" }}>Site info</h5>
        <Divider />
        <div className={classes.sitemapInfoContainer}>
          <div style={{ width: "50%" }}>
            <h3 style={{ fontSize: "16px", fontWeight: "bold", marginTop: 25 }}>
              Information
            </h3>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {sitemapInfoData.map((item, index) => (
                <li key={index * 2}>
                  <a className={classes.sitemapAnchor} href={item.itemLink}>
                    {item.itemName}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div style={{ width: "50%" }}>
            <h3 style={{ fontSize: "16px", fontWeight: "bold", marginTop: 25 }}>
              My account
            </h3>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {myAccountInfo.map((item, index) => (
                <li key={index}>
                  <a href={item.itemLink} className={classes.sitemapAnchor}>
                    {item.itemName}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sitemap;
