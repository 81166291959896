import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

/* Import redux actions */
import { getCustomerNotification } from "../redux/actions/customerActions";
import { getCartItemsByUser } from "../redux/actions/cartActions";
import { authAlert, logoutAction } from "../redux/actions/authActions";

/* Import react components */
import { SearchComponent } from "./SearchComponent";
import { Notification } from "./seller/Notification";
import { CustomerNotification } from "./customer/CustomerNotification";
import { BankDetailsAlert } from "./seller/BankDetailsAlert";

/* Import material ui */
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Box, Divider, Avatar } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HelpIcon from "@material-ui/icons/Help";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { getCustomerWishlist } from "../redux/actions/customerWishlistActions";
import Loading from "../util/spinner/Loading";

// interface Props {
/**
 * Injected by the documentation to work in an iframe.
 * You won't need it on your project.
 */
//   window?: () => Window;
//   children: React.ReactElement;
// }

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiSvgIcon-root": {
      outline: "none",
    },
    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    marginRight: theme.spacing(2),
    color: "#000000",
  },
  search: {
    position: "relative",
    borderRadius: "17.5px",
    backgroundColor: "#CCCCCC",
    "&:hover": {
      backgroundColor: "#d8d8d8",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
    color: "#000000",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    color: "#000000",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    color: "#000000",
  },
  appBar: {
    backgroundColor: "#F2F2F2",
  },
  buyerDiv: {
    display: "flex",
    height: "36px",
    "& .MuiAvatar-img": {
      maxWidth: "24px",
      maxHeight: "24px",
    },
    "& .MuiAvatar-root": {
      maxWidth: "24px",
      maxHeight: "24px",
      margin: "7px",
      background: "transparent",
      color: "#27B23E",
    },
  },
  menbarFont: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: 1.19,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#000000",
    paddingLeft: "5px",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
      fontWeight: 600,
      backgroundColor: "transparent",
    },
  },
  menuBar: {
    display: "block",
    marginTop: theme.spacing(3),
  },
  userName: {
    padding: theme.spacing(1),
  },
  ywcLogo: {
    color: "#000000",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  mobileMenu: {
    "& .MuiPaper-root": {
      marginTop: theme.spacing(3),
    },
  },
  pTag: {
    marginTop: "1.2rem",
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    lineHeight: 1.19,
    color: "#383838",
  },
  iconButtonLabel: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    lineHeight: 1.21,
    color: "#000000",
  },
  iconbuttonRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export const AppBars = (props) => {
  const classes = useStyles();
  const { loading, authenticated, account } = useSelector((state) => state.auth);

  const { bargains } = useSelector((state) => state.sales);
  const { cartItem } = useSelector((state) => state.shoppingCart);
  const { notification } = useSelector((state) => state.notification);
  const { wishlist } = useSelector((state) => state.Wishlist);

  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [customerNotification, setCustomerNotification] = useState(false);
  const [sellerNotification, setSellerNotification] = useState(false);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(logoutAction(history));
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };

  const goToCustomerPortFolio = () => {
    history.push("/customer/portfolio");
  };
  // const url = process.env.REACT_APP_HELP_DESK_URL;
  // const goToCustomerHelpDesk = () => {
  //   window.open(url, '_blank');
  // };

  const buyurl = `http://customer-support.stockxbid.com:2055/index.php?a=add`;
  const goToCustomerHelpDeskBuyer = () => {
    window.open(buyurl, '_blank');
  };

  const goToCart = () => {
    history.push("/cart");
  };

  /* function to handle customer notification dialog open */
  const handleCustomerNotificationOpen = () => {
    setCustomerNotification(true);
  };

  /* functiom to handle customer notifictaion dialog close */
  const handleCustomerNotificationClose = () => {
    setCustomerNotification(false);
  };

  /* function to handle seller notification dialog open */
  const handleSellerNotificationOpen = () => {
    setSellerNotification(true);
  };

  /* function to handle seller notification dialog close */
  const handleSellerNotificationClose = () => {
    setSellerNotification(false);
  };

  const handleHome = () =>{
   !authenticated && dispatch(authAlert());
    history.push('/')
  }

  useEffect(() => {
    const fetchMyAPI = async () => {
      if (authenticated && account.userType === "CUST") {
        await dispatch(getCartItemsByUser(account.id));
        await dispatch(getCustomerNotification(account));
        await dispatch(getCustomerWishlist());
      }
    };
    fetchMyAPI();
  }, [account.userType === "CUST"]); // eslint-disable-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   const fetchMyAPI = async () => {
  //     if (authenticated && account.userType === "CUST") {
  //       await dispatch(getCustomerNotification(account));
  //     }
  //   };
  //   cartItem.customer  && fetchMyAPI();
  // }, [cartItem.customer && account.userType === "CUST"]);
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.menuBar}
    >
      {authenticated ? (
        account.userType === "CUST" ? (
          <div>
            <Box className={classes.buyerDiv} onClick={goToCustomerPortFolio}>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
              <MenuItem className={classes.menbarFont}>Portfolio</MenuItem>
            </Box>
            <Divider />
            <Box className={classes.buyerDiv} onClick={goToCustomerHelpDeskBuyer}>
            {/* <Box className={classes.buyerDiv} onClick={goToCustomerHelpDesk}> */}
              <Avatar>
                <HelpIcon />
              </Avatar>
              <MenuItem className={classes.menbarFont}>Help desk</MenuItem>
              </Box>
              
            <Divider />
            <Box
              className={classes.buyerDiv}
              onClick={() => {
                history.push("/customer/account-settings");
              }}
            >
              <Avatar>
                <SettingsIcon />
              </Avatar>
              <MenuItem className={classes.menbarFont}>Settings</MenuItem>
            </Box>
            <Divider />
            <Box className={classes.buyerDiv} onClick={handleLogout}>
              <Avatar>
                <ExitToAppIcon />
              </Avatar>
              <MenuItem className={classes.menbarFont}>Logout</MenuItem>
            </Box>
          </div>
        ) : (
          <div>
          
            <Box className={classes.buyerDiv} onClick={goToCustomerHelpDeskBuyer}>
              <Avatar>
                <HelpIcon />
              </Avatar>
              <MenuItem className={classes.menbarFont}>Help desk</MenuItem>
            </Box>
            <Divider />
            <Box className={classes.buyerDiv} onClick={handleLogout}>
              <Avatar>
                <ExitToAppIcon />
              </Avatar>
              <MenuItem className={classes.menbarFont}>Logout</MenuItem>
            </Box>
          </div>
        )
      ) : (
        <div>
          <Link to="/customer-login" style={{ textDecoration: "none" }}>
            <Box className={classes.buyerDiv}>
              <Avatar
                alt="Buyer"
                src={process.env.PUBLIC_URL + "/images/buyer.svg"}
              />
              <MenuItem className={classes.menbarFont}>I am Buyer</MenuItem>
            </Box>
          </Link>

          <Divider />

          <Link to="/seller-login" style={{ textDecoration: "none" }}>
            <Box className={classes.buyerDiv}>
              <Avatar
                alt="Buyer"
                src={process.env.PUBLIC_URL + "/images/seller.svg"}
              />
              <MenuItem className={classes.menbarFont}>I am Seller</MenuItem>
            </Box>
          </Link>
        </div>
      )}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className={classes.mobileMenu}
    >
      {authenticated ? (
        account.userType === "CUST" ? (
          <div>
            <MenuItem onClick={handleCustomerNotificationOpen}>
              <IconButton aria-label="show 4 new mails" color="inherit">
                <Badge overlap="rectangular"
                  badgeContent={notification ? notification.length : ""}
                  color="secondary"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <p className={classes.pTag}>Notifications</p>
            </MenuItem>

            <Divider />

            <MenuItem onClick={goToCart}>
              <IconButton aria-label="show 4 new mails" color="inherit">
                <Badge overlap="rectangular"
                  badgeContent={
                    Object.keys(cartItem).length === 0
                      ? 0
                      : cartItem.products
                      ? cartItem.products.length
                      : 0
                  }
                  color="secondary"
                >
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
              <p className={classes.pTag}>Cart</p>
            </MenuItem>

            <Divider />

            <MenuItem
              onClick={() => {
                history.push("/customer/wish-list");
              }}
            >
              <IconButton aria-label="wishlist" color="inherit">
                <Badge overlap="rectangular"
                  badgeContent={wishlist ? wishlist.length : ""}
                  color="secondary"
                >
                  <FavoriteBorderIcon />
                </Badge>
              </IconButton>
              <p className={classes.pTag}>Wishlist</p>
            </MenuItem>

            <Divider />

            <MenuItem onClick={goToCustomerPortFolio}>
              <IconButton aria-label="show 4 new mails" color="inherit">
                <AccountCircleIcon />
              </IconButton>
              <p className={classes.pTag}>Portfolio</p>
            </MenuItem>

            <Divider />
            

            <MenuItem
              onClick={() => {
                history.push("/customer/account-settings");
              }}
            >
              <IconButton aria-label="show 4 new mails" color="inherit">
                <SettingsIcon />
              </IconButton>
              <p className={classes.pTag}>Settings</p>
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleLogout}>
              <IconButton aria-label="show 4 new mails" color="inherit">
                <ExitToAppIcon />
              </IconButton>
              <p className={classes.pTag}>Logout</p>
            </MenuItem>
          </div>
        ) : (
          <div>
            <MenuItem>
              <IconButton aria-label="show 4 new mails" color="inherit">
                {bargains && (
                  <Badge overlap="rectangular" badgeContent={bargains.length} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                )}
              </IconButton>
              <p className={classes.pTag}>Notifications</p>
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleLogout}>
              <IconButton aria-label="show 4 new mails" color="inherit">
                <ExitToAppIcon />
              </IconButton>
              <p className={classes.pTag}>Logout</p>
            </MenuItem>
          </div>
        )
      ) : (
        <div>
          <Link to="/customer-login" style={{ textDecoration: "none" }}>
            <Box className={classes.buyerDiv}>
              <Avatar
                alt="Buyer"
                src={process.env.PUBLIC_URL + "/images/buyer.svg"}
              />
              <MenuItem className={classes.menbarFont}>I am Buyer</MenuItem>
            </Box>
          </Link>

          <Divider />

          <Link to="/seller-login" style={{ textDecoration: "none" }}>
            <Box className={classes.buyerDiv}>
              <Avatar
                alt="Buyer"
                src={process.env.PUBLIC_URL + "/images/seller.svg"}
              />
              <MenuItem className={classes.menbarFont}>I am Seller</MenuItem>
            </Box>
          </Link>
        </div>
      )}
    </Menu>
  );

  const renderSellerNotification = (
    <Notification
      open={sellerNotification}
      onClose={handleSellerNotificationClose}
    />
  );

  const renderCustomerNotification = (
    <CustomerNotification
      open={customerNotification}
      onClose={handleCustomerNotificationClose}
    />
  );

  return (
    <div className={classes.root}>
      <CssBaseline />

      <ElevationScroll {...props}>
        <AppBar className={classes.appBar}>
          <Toolbar style={{ height: 80 }}>
            <div>
              <Link onClick={handleHome} className={classes.ywcLogo}>
                <img
                  alt="outxtock"
                  src={"https://storage.googleapis.com/stockxbid_gc_bng_misc/logo_beta_v/outxtock.svg"}
                  style={{  maxHeight: "40px" }}
                  title="B2B Online Marketplace - Outxtock"
                />
              </Link>
            </div>

            {!authenticated ? (
              <React.Fragment>
                <div className={classes.search}>
                  <SearchComponent />
                </div>

                <div className={classes.grow} />

                <div className={classes.sectionDesktop}>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    classes={{
                      label: classes.iconButtonLabel,
                      root: classes.iconbuttonRoot,
                    }}
                  >
                    <AccountCircleOutlinedIcon />
                    <div>Sign up/Login</div>
                  </IconButton>
                </div>

                <div className={classes.sectionMobile}>
                  <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MoreVertIcon />
                  </IconButton>
                </div>
              </React.Fragment>
            ) : authenticated ? (
              account.userType === "CUST" ? (
                <React.Fragment>
                  <div className={classes.search}>
                    <SearchComponent />
                  </div>

                  <div className={classes.grow} />

                  <div className={classes.sectionDesktop}>
                    <IconButton color="inherit" title="Cart" onClick={goToCart}>
                      <Badge overlap="rectangular"
                        badgeContent={
                          Object.keys(cartItem).length === 0
                            ? 0
                            : cartItem.products
                            ? cartItem.products.length
                            : 0
                        }
                        color="secondary"
                      >
                        <ShoppingCartIcon />
                      </Badge>
                    </IconButton>

                    <IconButton
                      aria-label="show 17 new notifications"
                      color="inherit"
                      title="wishlist"
                      onClick={() => history.push("/customer/wish-list")}
                    >
                      <Badge overlap="rectangular"
                        badgeContent={wishlist ? wishlist.length : ""}
                        color="secondary"
                      >
                        <FavoriteIcon />
                      </Badge>
                    </IconButton>

                    <IconButton
                      aria-label="show 17 new notifications"
                      color="inherit"
                      title="Notification"
                      onClick={handleCustomerNotificationOpen}
                    >
                      <Badge overlap="rectangular"
                        badgeContent={notification ? notification.length : ""}
                        color="secondary"
                      >
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>

                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls="primary-search-account-menu"
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                      title="Portfolio"
                    >
                      <AccountCircle />

                      <Box className={classes.userName}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontFamily: "'Fira Sans', sans-serif",
                          }}
                        >
                          {account.firstName}
                        </Typography>
                      </Box>
                    </IconButton>
                  </div>

                  <div className={classes.sectionMobile}>
                    <IconButton
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      color="inherit"
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className={classes.grow} />

                  <div className={classes.sectionDesktop}>
                    <IconButton
                      aria-label="show 17 new notifications"
                      color="inherit"
                      onClick={handleSellerNotificationOpen}
                      title="Notification"
                    >
                      {bargains && (
                        <Badge overlap="rectangular" badgeContent={bargains.length} color="secondary">
                          <NotificationsIcon />
                        </Badge>
                      )}
                    </IconButton>

                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls="primary-search-account-menu"
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                    >
                      <AccountCircle />

                      <Box className={classes.userName}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontFamily: "'Fira Sans', sans-serif",
                          }}
                        >
                          {account.firstName}
                        </Typography>
                      </Box>
                    </IconButton>
                  </div>

                  <div className={classes.sectionMobile}>
                    <IconButton
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      color="inherit"
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                </React.Fragment>
              )
            ) : null}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      {renderMenu}
      {renderMobileMenu}
      {renderSellerNotification}
      {renderCustomerNotification}

      {authenticated &&
        Object.keys(account).length > 0 &&
        account.userType !== "CUST" && (
          <>
          {loading && <Loading />}
          <BankDetailsAlert
            bankDetails={account.bankDetails.detailsExist}
            userId={account.id}
          />
          </>
        )}
    </div>
  );
};
