import React, { Fragment, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";

/* React components */
import { UploadComponent } from "./UploadComponent";
import { DownloadTemplate } from "./DownloadTemplate";
import { ImageCloud } from "./ImageCloud";
import { SnackBar } from "../../../components/SnackBar";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { userActivationMessage } from "../../../redux/actions/SnackbarActions";

/* Material ui css */
const useStyles = makeStyles((theme) => ({
  listingSection: {
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    textAlign: "center",
  },
  label: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: 14,
    fontWeight: 600,
  },

  appBar: {
    color: "inherit",
    background: "transparent",
    boxShadow: "none",
    borderBottom: "1px solid #E9E9E9",
    "& .Mui-selected": {
      color: "#27B23E",
    },
    "& .MuiTab-wrapper": {
      fontSize: 13,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 600,
      letterSpacing: "0.19px",
      lineHeight: 1.38,
      textTransform: "capitalize",
    },
  },
  indicator: {
    backgroundColor: "#27B23E",
  },
}));

export const BulkUpload = () => {
  const classes = useStyles();
  const { account } = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();
  const selectTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Fragment>
      {account.userStatus === "ACTIVE"? (<TabContext value={activeTab}>
        <AppBar position="static" className={classes.appBar}>
          <TabList
          variant="scrollable"
            onChange={selectTab}
            aria-label="simple tabs example"
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab
              label="Download Template"
              value="1"
              style={{ outline: "none" }}
            />
            <Tab
              label="Upload Template"
              value="2"
              style={{ outline: "none" }}
            />
            <Tab
              label="Product Image cloud service"
              value="3"
              style={{ outline: "none" }}
            />
          </TabList>
        </AppBar>

        <TabPanel value="1">
          <DownloadTemplate />
        </TabPanel>

        <TabPanel value="2">
          <UploadComponent />
        </TabPanel>

        <TabPanel value="3">
          <ImageCloud />
        </TabPanel>
      </TabContext>): 
      dispatch(
        userActivationMessage(
          "warning",
          `Account activation is in progress and current status is ${account.userStatus}`
        )
      )
      }
      

      <SnackBar />
    </Fragment>
  );
};
