import React from "react";
import { useHistory } from "react-router";

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";
import { useEffect } from "react";
import { isStringNullorEmpty } from "../util/commonUtil/commonUtil";
import { getBargainUpdateRequest } from './../util/componentUtil/CheckOutUtiils';
import { checkoutUpdateBargain } from "../redux/actions/cartActions";
import { useSelector } from 'react-redux';
import axios from "axios";
import { useDispatch } from 'react-redux';
import { ActionTypes } from './../redux/constants/actionTypes';


/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  NotFoundCode: {
    left: "50%",
    fontSize: "60px",
    marginTop: "60px"
  },
  subTitle: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(4)
  },
  homeButton: {
    color: "#FFFFFF",
    background: "#53E7BC",
    marginTop: theme.spacing(2),
    fontSize: "13px",
    fontWeight: 600,
    borderRadius: "0.375rem",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
}));

export const PaymentResponse = (props) => {
  
  const { cartItem } = useSelector((state) => state.shoppingCart);

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const params = new URLSearchParams(props.location.search);
  const karnagId = params.get("karnagId");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  


  useEffect(() => {
    // console.log("use effect");
    // console.log(cartItem);
    const onPageLoad = () => {
      // console.log("page loaded");
      setTimeout(() => {              
        dispatch(callForPaymentResponse());
      }, 5000);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      // console.log("completed");
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // console.log("in else");
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  const getPaymentCheck = async(karnagId) => {
    var querystring = require('querystring');
    let url = `${baseUrl}/checkTransc/webhooks`;

    try {
      const data  = await axios.post(url,
        querystring.stringify({
            transactionId: karnagId
        }), {
         headers: { 
            "Content-Type": "application/x-www-form-urlencoded"
        }
      });
      return data;
    } catch(err) {
      // console.log("error in the payment status " , err);
      return null;
    }
};


  const callForPaymentResponse = () => async (dispatch) => {
    // console.log("calling checkout");
    
    if (!isStringNullorEmpty(karnagId)) {
      try {

        const { data } = await getPaymentCheck(karnagId);
        // console.log(data);
        let success = false;

        if (data !== null && data !== undefined) {
          success = ("FAILURE" !== data.orderPaymentTransactionStatus);
        }

        if (success) {

          let url = `${baseUrl}/auth/cart/checkoutByTransacationId?transactionId=${karnagId}`;
          const { datas } = await axios.post(url);

          try {
            let updateQuery = getBargainUpdateRequest(cartItem.products, cartItem.customer, "PAID");
            await dispatch(checkoutUpdateBargain(updateQuery));
            dispatch({ type: ActionTypes.GET_CART_ITEM, payload: {} });
          } catch (error) {
            // console.log("error in checkoutUpdateBargain");
            // console.log(error);
          }

          history.push("/payupayment/success/response");
        } else if (
          data.message === "Cancelled by user" ||
          data.message === "User interrupted by pressing back button" ||
          data.message === "Technical Failure" 
        ) {
          history.push("/cart");
        }
        else{
          history.push("/payupayment/failure/response");
        }

      } catch (error) {
        dispatch({ type: ActionTypes.PAYMENT_ERROR_MSG, payload: error?.response?.data?.message });
        history.push("/payupayment/failure/response");
      }
    }

  };


  return (
    <div className={classes.root}>
      <Container>
        <Typography variant="h1" className={classes.NotFoundCode}>
          Processing your Order
        </Typography>

        <Typography variant="subtitle1" component="p" className={classes.subTitle}>
          * Don't close this Page *
        </Typography>

        <Typography variant="subtitle1" component="p" className={classes.subTitle}>
          Please wait !!!
        </Typography>

        {/* <Typography
          variant="subtitle1"
          component="p"
          className={classes.subTitle}
        >
          THE PAGE YOU REQUESTED FOR IS NOT FOUND
        </Typography> */}

        {/* <Button
          variant="contained"
          size="small"
          className={classes.homeButton}
          onClick={goHome}
        >
          Home
        </Button> */}

          <Box className={classes.subTitle}>
            <CircularProgress />
          </Box>

      </Container>
    </div>
  );
};