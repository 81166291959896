import React from "react";

/* import react components */
import { Sellings } from "./Sellings";
import { SellerProfile } from "./SellerProfile";
import { Collections } from "./Collections";
import { BulkUpload } from "./bulkUpload/BulkUpload";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import BackupIcon from "@material-ui/icons/Backup";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  sellerMenu: {
    flexGrow: 1,
  },
  sellerTab: {
    backgroundColor: "#ffffff",
    color: "#3E3E3E",
    marginTop: 80,
    "& .MuiTab-wrapper": {
      flexDirection: "initial",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 0,
    },
    "& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child": {
      marginBottom: "1px",
      paddingRight: "5px",
    },
    "& .Mui-selected": {
      color: "#27B23E",
    },
    "& .MuiButtonBase-root": {
      outline: "none",
      textTransform: "capitalize",
      fontFamily: "'Fira Sans', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      height: "59px",
      letterSpacing: "0.19px",
      lineHeight: 1.38,
    },
  },
  tabs: {
    height: "59px",
    borderTop: "1px solid #53E7BC",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    "& .MuiButtonBase-root": {
      borderLeft: "1px solid #53E7BC",
    },
  },
  indicator: {
    backgroundColor: "#27B23E",
  },
}));

export const SellerMenu = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState("1");

  const handleChangeActiveTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.sellerMenu}>
      <TabContext value={value}>
        <AppBar position="static" className={classes.sellerTab}>
          <TabList
            variant="scrollable"
            onChange={handleChangeActiveTab}
            aria-label="simple tabs example"
            className={classes.tabs}
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab icon={<LocalMallOutlinedIcon />} label="Selling" value="1" />
            <Tab
              icon={<AccountCircleOutlinedIcon />}
              label="Profile"
              value="2"
            />
            <Tab icon={<AddIcon />} label="Add Product" value="3" />
            <Tab icon={<BackupIcon />} label="Bulk Upload" value="4" />
            {/* <Tab
              icon={<AddIcon />}
              label="Product Inventory"
              href="https://ci-sdd-gocd.stockxbid.com:8443/stockxbid"
              target="_blank"
              value="5"
              style={{  textDecoration: 'none'}}
            /> */}
            {/* <Tab icon={<InsertPhotoIcon />} label="Portfolio" value="5" /> */}
          </TabList>
        </AppBar>

        <TabPanel value="1">
          <Sellings />
        </TabPanel>

        <TabPanel value="2">
          <SellerProfile />
        </TabPanel>

        <TabPanel value="3">
          <Collections />
        </TabPanel>

        <TabPanel value="4">
          <BulkUpload />
        </TabPanel>

        {/* <TabPanel value="5">Coming soon...</TabPanel> */}
      </TabContext>
    </div>
  );
};
