import React, { useState } from "react";
import { useHistory } from "react-router";

/* react components */
import { AppBars } from "../../../components/AppBar";
// import { CategoryList } from "../../../components/CategoryList";
import { Footer } from "../../../components/Footer";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CheckIcon from "@material-ui/icons/Check";
import MenuHeader from "../../../components/MenuHeader";
import Sitemap from "../../Sitemap/sitemap";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  icon: {
    border: "1px solid #26cf36",
    borderRadius: "50%",
    backgroundColor: "#26cf36",
    width: "50px",
    height: "50px",
  },
}));

export const PayuSuccess = () => {
  const classes = useStyles();
  const history = useHistory();

  const [showSiteMap8, setShowSiteMap8] = useState(false);
  return (
    <div>
      <CssBaseline />
      <AppBars position="absolute" color="default" className={classes.appBar} />
      {/* <CategoryList /> */}
      {!showSiteMap8 ? (
        <>
          <MenuHeader />
          <Container component="main" maxWidth="sm" style={{ height: "50vh" }}>
            <Box
              style={{
                margin: 40,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CheckIcon className={classes.icon} />
              <Typography
                component="h1"
                variant="h5"
                style={{ color: "#26cf36" }}
              >
                Order confirmed!.
              </Typography>

              <Typography component="p" variant="subtitle2">
                Your order is confirmed. You will receive an order confirmation
                email/SMS shortly with the expected delivery date for your
                items.
              </Typography>

              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: 20 }}
                  onClick={() => history.push("/")}
                >
                  Continue Shopping
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: 20 }}
                  onClick={() => history.push("/customer/portfolio")}
                >
                  View Order
                </Button>
              </div>
            </Box>
          </Container>
        </>
      ) : (
        <Sitemap />
      )}
      <Footer showSiteMap={showSiteMap8} setShowSiteMap={setShowSiteMap8} />
    </div>
  );
};
