import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

const Spinner = () => (
  <Box display="flex" pb={15} justifyContent="center" style={{ width: "100%" }}>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "60vh", flexDirection: "column" }}
    >
      <CircularProgress color="primary" style={{ marginBottom: 20 }} />
    </Box>
  </Box>
);

export default Spinner;
