// import { Button, Grid, Menu, Paper, Typography } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
// import { TreeView } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

import React, { useState } from "react";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import CategoryItems from "./CategoryItems";

const useStyles = makeStyles((theme) => ({

  CategoryDropdown: {
    left: 'auto',
    right: 0,
    fontSize: 14,
    zIndex: 100,
    position:'relative',
    minWidth: "180px",
    padding: '5px 10px',
    whiteSpace: "nowrap",
    textTransform: 'capitalize',
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    cursor: 'Pointer',
    color:'Gray',
    paddingLeft: '5px',
    background: 'white',
    fontFamily: "'Fira Sans', sans-serif",
    "&:hover": {
      color:'#111',
      fontSize: 16,
    },

  },

  menuPaper: {
    // display:'flex',
    whiteSpace: "nowrap",
    top: 0,
  },
  childClasses:{
  // paddingLeft: '5px'

  }
}));

const CategoryDropdown = (props) => {
  
  const classes = useStyles();
  const [dropdown, setDropdown] = useState(false);
  // const dispatch = useDispatch();
  const history = useHistory();
  const { categoryClicked , fromProductOrSearchPage} = props;

  const onCategoryClick = (catCode) => {

    if(fromProductOrSearchPage) {
      categoryClicked(catCode)
    } else {
      history.push(`/${catCode}/products`); 
    }
    setDropdown(false)
  };
  const CategoryDropdownChild = ({ submenus, depthLevel, dropdown }) => {
    depthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? "dropdown-children" : "";
    // console.log(submenus)
    return (
       <div>
       
       {dropdown && <div className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""} ${classes.childClasses}`}>
   
        
   <CategoryDropdown items={submenus} depthLevel={depthLevel}/>

 </div>}
 </div>
    );
  };
 
  return (
    <div onMouseLeave={() => setDropdown(false)}>
      {props.items.map((child , index) => (
        <div className="menu-items" key={index}>
  
      {child.children.length > 0  ? (
        <div className={classes.menuPaper}>
          <div  type="button" aria-haspopup="menu"
          aria-expanded={dropdown ? "true" : "false"}
          // onClick={() => setDropdown((prev) => !prev)}
          onMouseEnter={() => setDropdown(true)}
          
          className={classes.CategoryDropdown}
          >
            {child.code}{child.children.length > 0 ?  <ArrowDropDown /> : ''}
            
          </div>
          <CategoryDropdownChild depthLevel={props.depthLevel} submenus={child.children} dropdown={dropdown} />
        </div>
      ) : (
        <div className={classes.CategoryDropdown} onClick={() => onCategoryClick(child.code)}>{child.code}</div>
      )}
    </div>
    ))}
    </div>
  );
};

export default CategoryDropdown;
