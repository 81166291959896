import { ActionTypes } from "../constants/actionTypes";

export const showPolicyModal = (policyHeader) => (dispatch) => {
    dispatch({ type: ActionTypes.SHOW_POLICY_MODAL  ,
        payload: {  titleContent : policyHeader }
    });
};

export const closePolicyModal = () => (dispatch) => {
    dispatch({
        type: ActionTypes.CLOSE_POLICY_MODAL
    });
};
