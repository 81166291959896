import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";

/* redux actions */
import { getCategoryList } from "../redux/actions/homepageActions";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
// import Toolbar from "@material-ui/core/Toolbar";
// import Button from "@material-ui/core/Button";
import CategoryItems from "./CategoryItems";
import { Paper } from "@material-ui/core";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  toolbarSecondary: {
    display: 'flex',
    justifyContent: "space-evenly",
    overflowX: "auto",
    marginTop: 80,
    boxShadow: "0 1px 1px 0 rgb(0 0 0 / 16%)",
    backgroundColor: "#fff",
    borderBottom: '1px solid gray'
    
    
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    color: "#27B23E",
    cursor: "pointer",
    fontWeight: 600,
    transition: 'all 0.5s ease-out',
    fontFamily: "'Fira Sans', sans-serif",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "#27B23E",
      
    },
  },
}));

export const CategoryList = (props) => {
  const classes = useStyles();
  const { categories } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  // const history = useHistory();

  // const { categoryClicked , fromProductOrSearchPage} = props;


  // const onCategoryClick = (catCode) => {

  //   if(fromProductOrSearchPage) {
  //     categoryClicked(catCode)
  //   } else {
  //     history.push(`/${catCode}/products`);
  //   }
    
  // };

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(getCategoryList());
    }
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */
// console.log(JSON.stringify(categories))
  return (
    <Paper
      component="nav"
      variant="dense"
      className={classes.toolbarSecondary}
    >
      {categories
        .sort((a, b) => a.id - b.id)
        .map((cat, index) => {
          const depthLevel = 0;
          return <CategoryItems items={cat} key={index} depthLevel={depthLevel} />
        }
          )}
    </Paper>
  );
};
