import {isArrayNotEmpty} from "../commonUtil/commonUtil";

export const getBargainUpdateRequest = (products , customerId , statusToUpdate) => {
    let itemSkus = products.filter(prod => prod.bargained === "YES")
        .flatMap(prod =>
        prod.attributes.flatMap(attributes =>
            attributes.attributeOptionValues.flatMap(option =>  option.item_sku)));

    let vendorIds = products.filter(prod => prod.bargained === "YES")
        .map(prod => prod.productUserDetail.userId);

    // to remove duplicates from array
    vendorIds = Array.from(new Set(vendorIds));
    itemSkus = Array.from (new Set(itemSkus));

    let updateQuery = null;

    if(isArrayNotEmpty(itemSkus) && isArrayNotEmpty(vendorIds) && isArrayNotEmpty(products)) {
        updateQuery=   {
            STATUS_TO_UPDATE : statusToUpdate,
            CURRENT_STATUS : "CART",
            VENDOR_IDS : vendorIds,
            CUSTOMER_ID : customerId,
            ATTRIBUTE_SKUS : itemSkus,
            BARGAIN_PRICE : products[0].bargainPrice
        };
    }
    return updateQuery;
};



export const getBargainUpdateRequestForDocId = (item , status) => {

    const elasticUpdate = {
        "index" : item._index,
        "docId" : item._id,
        "customer_ID" :item._source.customer_ID,
        "attribute_id" : item._source.attribute_id,
        "source" : {...item._source, status : status , bargain_title  : status},
        "sourceUpdate" : {...item._source, active_status : false }
    };

    return elasticUpdate;
};


