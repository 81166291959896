import React from "react";
import { useSelector } from "react-redux";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Divider,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Avatar from "@material-ui/core/Avatar";
import { useState } from "react";
import { PlayArrow } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({

 
  section: {
    overflow: "hidden",
    width: "auto",
    backgroundColor: "#fff",
  },
  categoryDiv: {
    "& .MuiButtonBase-root": {
      outline: "none",
      textTransform: "capitalize",
      fontSize: "20px",
      fontWeight: "bold",
      color: "#000000",
      transition: "all 0.4s ease-out",
      fontFamily: `Fira Sans',sans-serif`,
      cursor: "pointer",
      "&:hover": {
        color: "#53E7BC",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 8,
      },
    },
    "& .Mui-selected": {
      color: "#27B23E",
    },
  },

  Avatar:{
    height: "70px",
    width: "70px",
    '@media(max-Width: 780px)' : {
      height: "50px",
    width: "50px"
    },
    '@media(max-Width: 500px)' : {
      height: "45px",
    width: "45px"
    },
    '@media(max-Width: 200px)' : {
      height: "40px",
    width: "40px"
    }
  },
  indicator: {
    backgroundColor: "#27B23E",
  },
  ahref: {
    fontSize: "10px",
  },
  ChildrenContainer: {
    zIndex: 6,

    // marginTop: '5px'
  },
  ChildrenList: {
    display: "flex",
  },
  SubMenuItem: {
    minWidth: "250px",
    "&:hover": {
      color: '#53E7BC',
    }
  },
 
  SubChildMenuItem: {
    minWidth: "250px",
    "&:hover": {
      // backgroundColor: '#53E7BC',
      color: '#53E7BC',
      
    },
  },
}));

export const CategoryListOne = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { category, handleSelectCategory } = props;
  const { categories } = useSelector((state) => state.home);
  const [childData, setChildData] = useState([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [handleChildOpen, setHandleChildOpen] = useState([]);
  const { categoryClicked, fromProductOrSearchPage } = props;

  const handleMenuOpen = (event, data) => {
    setChildData(data);
    setOpen(true);
    setAnchorEl(event.currentTarget);
    // window.scroll({
    //   top: 275,
    //   behavior: "smooth",
    // });
  };

  const HandleChildOpenClick =(children) =>{
    setHandleChildOpen(children);
  }

  const handleMenuClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setHandleChildOpen(null);
    setChildData([]);
  };

  const onCategoryClick = (catCode) => {
    if (fromProductOrSearchPage) {
      categoryClicked(catCode);
    } else {
      history.push(`/${catCode}/products`);
    }
  };

  const imageObj = {
    sneakers: "/images/sneakers1-icon.png",
    streetwears: "/images/jacket.png",
    bags: "/images/handbag.png",
    watches: "/images/stopwatch.png",
    toys: "/images/toys.png",
  };


  return (
    <div onMouseLeave={handleMenuClose}>
      <AppBar position="static">
        <Paper>
          <Tabs
            value={category}
            onChange={handleSelectCategory}
            indicatorColor="primary"
            textColor="primary"
            centered
            className={classes.categoryDiv}
            classes={{
              indicator: classes.indicator,
            }}
          >
            {categories
              .filter((cate) => cate.visible === true).sort((a, b) => a.id - b.id)
              .map((cat, index) => (
                <Tab
                  id={`simple-tab-${index}`}
                  key={index}
                  label={cat.code}
                  value={cat.code}
                  icon={
                    <Avatar
                    className={classes.Avatar}
                      alt="test avatar"
                      src={process.env.PUBLIC_URL + imageObj[cat.code]}
                    />
                  }
                  onMouseEnter={(event) => handleMenuOpen(event, cat.children)}
                  data-key={index}
                  aria-owns={open ? "menu-list-grow" : undefined}
                  aria-haspopup={"true"}
                />
              ))}
          </Tabs>
          {childData.length > 0  && (
            <Popper
              open={open}
              anchorEl={anchorEl}
              id="menu-list-grow"
              onMouseLeave={() => setHandleChildOpen(null)}
              className={classes.ChildrenContainer}
            >
              <Paper className={classes.ChildrenList}>
                <MenuList>
                  {childData.map((item, index) => (
                    <MenuItem
                      value={category}
                      onChange={handleSelectCategory}
                      key={index}
                      onClick={() => onCategoryClick(category.code)}
                      onMouseEnter={() => HandleChildOpenClick(item.children)}
                      className={classes.SubMenuItem}
                    >
                      {item.code}{" "}
                      {item.children.length ? (
                        <PlayArrow className={classes.ahref} />
                      ) : (
                        ""
                      )}
                    </MenuItem>
                  ))}
                </MenuList>
                <Divider orientation="vertical" flexItem />
                <MenuList>
                  {handleChildOpen &&
                    handleChildOpen.map((item, index) => (
                      <MenuItem
                        value={category}
                        onChange={handleSelectCategory}
                        key={index}
                        onClick={() => onCategoryClick(category.code)}
                        className={classes.SubChildMenuItem}
                      >
                        {item.code}
                      </MenuItem>
                    ))}
                </MenuList>
              </Paper>
            </Popper>
          )}
        </Paper>
      </AppBar>
    </div>
  );
};
