import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

/* import Redux actions */
import { deleteNotification } from "../../redux/actions/customerActions";
import { addToCartFromBargain } from "../../redux/actions/cartActions";
import moment from "moment";
/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {
  getBargainUpdateRequestForDocId,
} from "../../util/componentUtil/CheckOutUtiils";
import { isArrayNotEmpty } from "../../util/commonUtil/commonUtil";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperWidthXl": {
      width: "700px",
      maxHeight: "400px",
    },
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .sk-circle": {
      height: "50px",
      width: "50px",
    },
    "& .MuiCardMedia-img": {
      objectFit: "contain",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#383838",
      fontFamily: "'Fira Sans', sans-serif",
      textAlign: "center",
    },
  },
  dialogBar: {
    background: "#53E7BC",
    padding: "3px",
  },
  dialogTitle: {
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: "1.25rem",
    lineHeight: 2.3,
    fontFamily: "'Fira Sans', sans-serif",
  },
  closeIcon: {
    color: "#212529",
    float: "right",
    position: "relative",
  },
  cardroot: {
    display: "flex",
    marginBottom: "10px",
    border: "1px solid #53E7BC",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, .08)",
    "@media(max-width: 360px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "366px",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 175,
    height: 150,
    margin: "auto",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    "@media(max-width: 360px)": {
      width: "50%",
      flexWrap: "wrap",
    },
  },
  productname: {
    fontSize: "16px",
    color: "#36dead",
    marginBottom: 0,
    textTransform: "capitalize",
    fontFamily: "'Fira Sans', sans-serif",
  },
  availabletext: {
    fontSize: "12px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#000000",
    marginTop: 0,
    marginBottom: "1rem",
  },
  lastoffer: {
    fontSize: "12px",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: 0,
    color: "#383838",
    marginBottom: 0,
  },
  selleoffertxt: {
    fontSize: "16px",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: 0,
    color: "#000000",
  },
  priceDiv: {
    marginLeft: "80px",
  },
  responsediv: {
    display: "flex",
    marginBottom: "1rem",
  },
  acceptButton: {
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    fontSize: "13px",
    borderRadius: "6px",
    padding: "0.375rem",
    backgroundColor: "#53e7bc",
    color: "#ffffff",
    textTransform: "none",
    width: "100%",
    margin: theme.spacing(1),
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#53e7bc",
      color: "#ffffff",
    },
  },
  bargainButton: {
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    fontSize: "13px",
    // padding: "0.375rem",
    backgroundColor: "#ffffff",
    color: "#53e7bc",
    border: "1px solid #53e7bc",
    borderRadius: "6px",
    textTransform: "none",
    width: "100%",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#ffffff",
      color: "#53e7bc",
    },
  },
  removeButton: {
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    fontSize: "13px",
    padding: "0.375rem",
    backgroundColor: "#dc3545",
    color: "#ffffff",
    border: "1px solid #dc3545",
    textTransform: "none",
    borderRadius: "6px",
    width: "100%",
    margin: theme.spacing(1),
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#dc3545",
      color: "#ffffff",
    },
  },
  notfound: {
    textAlign: "center",
    padding: "25px",
    border: "1px solid #53E7BC",
  },
  notfoundtext: {
    fontSize: "18px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#383838",
  },
}));

/* image base url */
const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

export const CustomerNotification = (props) => {
  const classes = useStyles();
  const { open, onClose } = props;
  const { account } = useSelector((state) => state.auth);

  const { loading, notification, error } = useSelector(
    (state) => state.notification
  );
  const [notificationBoolMap, setNotificationBoolMap] = useState(new Map());
  const dispatch = useDispatch();
  const history = useHistory();
  const [valueDispatch, setValueDispatch] = useState(true);

  /* go to product details page */
  const sortedHits = notification.map((item) =>
    item.max_date.hits.hits.map((itemNotification) => ({
      time: moment(itemNotification._source.datetime).format("hh:mm A"),
      item: itemNotification,
    }))
  );
  sortedHits.sort((a, b) => {
    const timeA = a[0].time;
    const timeB = b[0].time;
    return timeB.localeCompare(timeA);
  });
  let newArr = []; // Clear the existing array
  for (let i = 0; i < sortedHits.length; i++) {
    newArr.push(sortedHits[i][0].item);
  }


  const gotoProductPage = (item) => {
    history.push(
      `/product/${item._source.category}/${item._source.sku_name.replace(
        / /g,
        "-"
      )}/${item._source.product_ID}`
    );
    onClose();
  };

  /* Delete item from customer notification */
  const handleDeleteNotification = (item) => {
    dispatch(deleteNotification(item));
  };

  /* add product to cart from customer notification */
  const handleAddToCart = async (item) => {
    let notificationMap = new Map(notificationBoolMap);
    notificationMap.set(item._id, true);
    setNotificationBoolMap(notificationMap);

    const cartData = {
      attributes: [
        {
          id: item._source.attribute_id ? item._source.attribute_id : 0,
        },
      ],
      bargainPrice:
        item._source.status === "ACCEPT"
          ? item._source.bargin_price
          : item._source.status === "NOTIFICATION"
          ? item._source.offering_PRICE
          : 0,
      bargained: "YES",
      product: item._source.product_ID,
      quantity: item._source.bargin_quantity,
    };
    if (valueDispatch === true) {
      setValueDispatch(false);
      let elasticUpdate = await getBargainUpdateRequestForDocId(item, "CART");
      dispatch(
        addToCartFromBargain(cartData, account.id, history, true, elasticUpdate)
      );
      setValueDispatch(true);
      onClose();
    }
  };

  useEffect(() => {
    if (isArrayNotEmpty(notification)) {
      // console.log("notification called");
      let result = new Map(notification.map((item) => [item._id, false]));
      setNotificationBoolMap(result);
      // console.log("notification bool map", notificationBoolMap);
    }
  }, [notification]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className={classes.root}
      maxWidth="xl"
    >
      <DialogTitle id="scroll-dialog-title" className={classes.dialogBar}>
        <Typography className={classes.dialogTitle}>
          Customer Notification
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            className={classes.closeIcon}
          >
            <HighlightOffRoundedIcon />
          </IconButton>
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        {loading ? (
          <p>loading.....</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div>
            {newArr && newArr.length > 0 ? (
              newArr.map((item) => (
                  <Card className={classes.cardroot} key={item._id}>
                    <CardMedia
                      className={classes.cover}
                      component="img"
                      image={`${imageBaseUrl}${item._source.product_image_url}`}
                      title={item._source.sku_name}
                    />

                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <Typography
                          variant="subtitle1"
                          className={classes.productname}
                        >
                          {item._source.sku_name}
                        </Typography>

                        <Typography
                          variant="subtitle1"
                          className={classes.availabletext}
                        >
                          Quantity - {item._source.bargin_quantity}
                        </Typography>

                        {item._source.status === "ACCEPT" ? (
                          <React.Fragment>
                            <div className={classes.responsediv}>
                              <Box>
                                <Typography
                                  variant="body2"
                                  className={classes.lastoffer}
                                >
                                  Your Last offer
                                </Typography>
                                <Typography
                                  component="h5"
                                  variant="h5"
                                  className={classes.selleoffertxt}
                                >
                                  Seller accepted your price
                                </Typography>
                              </Box>

                              <Box className={classes.priceDiv}>
                                <Typography
                                  variant="body2"
                                  className={classes.lastoffer}
                                >
                                  ₹ {item._source.bargin_price} /-
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className={classes.selleoffertxt}
                                >
                                  ₹ {item._source.bargin_price} /-
                                </Typography>
                              </Box>
                            </div>

                            <div className={classes.controls}>
                              <Button
                                id="buyButton"
                                variant="contained"
                                size="small"
                                onClick={() => handleAddToCart(item)}
                                disabled={notificationBoolMap.get(item._id)}
                                className={classes.acceptButton}
                              >
                                Buy
                              </Button>

                              <Button
                                variant="contained"
                                className={classes.removeButton}
                                onClick={() => handleDeleteNotification(item)}
                              >
                                Remove
                              </Button>
                            </div>
                          </React.Fragment>
                        ) : item._source.status === "REJECT" ? (
                          <React.Fragment>
                            <div className={classes.responsediv}>
                              <Box>
                                <Typography
                                  variant="body2"
                                  className={classes.lastoffer}
                                >
                                  Your Last offer
                                </Typography>
                                <Typography
                                  component="h5"
                                  variant="h5"
                                  className={classes.selleoffertxt}
                                >
                                  Seller rejected your price
                                </Typography>
                              </Box>

                              <Box className={classes.priceDiv}>
                                <Typography
                                  variant="body2"
                                  className={classes.lastoffer}
                                >
                                  ₹ {item._source.bargin_price} /-
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className={classes.selleoffertxt}
                                >
                                  ₹ {item._source.bargin_price} /-
                                </Typography>
                              </Box>
                            </div>

                            <div className={classes.controls}>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => gotoProductPage(item)}
                                className={classes.acceptButton}
                              >
                                Bargain
                              </Button>

                              <Button
                                variant="contained"
                                className={classes.removeButton}
                                onClick={() => handleDeleteNotification(item)}
                              >
                                Remove
                              </Button>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div className={classes.responsediv}>
                              <Box>
                                <Typography
                                  variant="body2"
                                  className={classes.lastoffer}
                                >
                                  Your Last offer
                                </Typography>
                                <Typography
                                  component="h5"
                                  variant="h5"
                                  className={classes.selleoffertxt}
                                >
                                  New offer
                                </Typography>
                              </Box>

                              <Box className={classes.priceDiv}>
                                <Typography
                                  variant="body2"
                                  className={classes.lastoffer}
                                >
                                  ₹ {item._source.bargin_price} /-
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className={classes.selleoffertxt}
                                >
                                  ₹ {item._source.offering_PRICE} /-
                                </Typography>
                              </Box>
                            </div>

                            <div className={classes.controls}>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => handleAddToCart(item)}
                                className={classes.acceptButton}
                              >
                                Accept
                              </Button>

                              <Button
                                variant="contained"
                                onClick={() => gotoProductPage(item)}
                                className={classes.bargainButton}
                              >
                                Bargain
                              </Button>

                              <Button
                                variant="contained"
                                className={classes.removeButton}
                                onClick={() => handleDeleteNotification(item)}
                              >
                                Remove
                              </Button>
                            </div>
                          </React.Fragment>
                        )}
                      </CardContent>
                    </div>
                  </Card>
                ))
              ) : (
              <Card>
                <CardContent className={classes.notfound}>
                  <Typography
                    component="p"
                    variant="h6"
                    className={classes.notfoundtext}
                  >
                    Notifications not found.
                  </Typography>
                </CardContent>
              </Card>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
