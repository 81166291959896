import { Button } from "@material-ui/core";
import { TreeView } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

import React, { useState } from "react";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CategoryDropdown from "./CategoryDropdown";

const useStyles = makeStyles((theme) => ({
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    color: "#27B23E",
    cursor: "pointer",
    display: "flex",
    textTransform: 'capitalize',
    fontWeight: 600,
    position: "relative",
    transition: "all 0.5s ease-out",
    fontFamily: "'Fira Sans', sans-serif",
    
    alignContent: "center",
    justifyContent: "center",
    // textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "#27B23E",
    },

  },
  CategoryDropdown: {
    left:'auto',
    right:'auto',
    position: "absolute",
    zIndex: 10000,
  },

  menuPaper: {
    display: 'flex',
    // position: 'static',
    flexDirection: 'column',
    whiteSpace: "wrap",
    top: 0,
  },
}));

const CategoryItems = (props) => {
  const classes = useStyles();
  const [isShown, setIsShown] = useState(false);
  // const dispatch = useDispatch();
  const history = useHistory();

  const { categoryClicked , fromProductOrSearchPage} = props;
  // const CategoryDropdown = ({ submenus, dropdown, depthLevel }) => {
  //   depthLevel = depthLevel + 1;
  //   const dropdownClass = depthLevel > 1 ? "dropdown-children" : "";

  //   return (
  //     <TreeView
  //       className={`${classes.menuPaper} dropdown ${dropdownClass} ${
  //         dropdown ? "show" : ""
  //       }`}
  //     >
  //       <TreeView>{submenus.code}</TreeView>
  //       {isShown &&
  //         submenus.map((items, index) => (
  //           <TreeView
  //             // className={classes.menuPaper}
  //             // className={classes}
  //           >
  //             {items.children.length > 0 ? (
  //               <TreeView className={classes.menuPaper}>
  //                 <CategoryItems
  //                   items={items}
  //                   key={index}
  //                   depthLevel={depthLevel}
  //                 />
  //               </TreeView>
  //             ) : (
  //               <TreeView className={classes.toolbarLink}>{items.code}</TreeView>
  //             )}
  //           </TreeView>
  //         ))}
  //     </TreeView>
  //   );
  // };


  const onCategoryClick = (catCode) => {

    if(fromProductOrSearchPage) {
      categoryClicked(catCode)
    } else {
      history.push(`/${catCode}/products`);
    }
    
  };

 const dataChilds = [
  {
    "id": 1,
    "code": "sneakers",
    "sortOrder": 0,
    "visible": true,
    "featured": false,
    "lineage": "/1",
    "depth": 0,
    "parent": null,
    "description": {
      "id": 650,
      "language": "en",
      "name": "sneakers",
      "description": "sneakers",
      "friendlyUrl": "http://locahost8080/sneakers",
      "keyWords": "sneakers",
      "highlights": null,
      "metaDescription": "sneakers",
      "title": null
    },
    "productCount": 0,
    "children": []
  },
  {
    "id": 2,
    "code": "streetwears",
    "sortOrder": 0,
    "visible": true,
    "featured": false,
    "lineage": "/2",
    "depth": 0,
    "parent": null,
    "description": {
      "id": 651,
      "language": "en",
      "name": "streetwears",
      "description": "streetwears",
      "friendlyUrl": "http://locahost8080/streetwears",
      "keyWords": "streetwears",
      "highlights": null,
      "metaDescription": "streetwears",
      "title": null
    },
    "productCount": 0,
    "children": []
  },
  {
    "id": 3,
    "code": "watches",
    "sortOrder": 0,
    "visible": true,
    "featured": false,
    "lineage": "/4",
    "depth": 0,
    "parent": null,
    "description": {
      "id": 652,
      "language": "en",
      "name": "watches",
      "description": "watches",
      "friendlyUrl": "http://locahost8080/watches",
      "keyWords": "watches",
      "highlights": null,
      "metaDescription": "watches",
      "title": null
    },
    "productCount": 0,
    "children": []
  },
  {
    "id": 4,
    "code": "bags",
    "sortOrder": 0,
    "visible": true,
    "featured": false,
    "lineage": "/3",
    "depth": 0,
    "parent": null,
    "description": {
      "id": 653,
      "language": "en",
      "name": "bags",
      "description": "bags",
      "friendlyUrl": "http://locahost8080/bags",
      "keyWords": "bags",
      "highlights": null,
      "metaDescription": "bags",
      "title": null
    },
    "productCount": 0,
    "children": [
      {
        "id": 83298,
        "code": "low",
        "sortOrder": 0,
        "visible": true,
        "featured": false,
        "lineage": "/372/373/374/375",
        "depth": 3,
        "parent": {
          "id": 374,
          "code": "lcd"
        },
        "description": {
          "id": 32423,
          "language": "en",
          "name": "low",
          "description": "ele",
          "friendlyUrl": "ele",
          "keyWords": null,
          "highlights": "ele",
          "metaDescription": "ele",
          "title": null
        },
        "productCount": 0,
        "children": []
      }
    ]
  },
  {
    "id": 5,
    "code": "toys",
    "sortOrder": 0,
    "visible": true,
    "featured": false,
    "lineage": "/5",
    "depth": 0,
    "parent": null,
    "description": {
      "id": 654,
      "language": "en",
      "name": "toys",
      "description": "toys",
      "friendlyUrl": "http://locahost8080/toys",
      "keyWords": "toys",
      "highlights": null,
      "metaDescription": "toys",
      "title": null
    },
    "productCount": 0,
    "children": []
  },
  {
    "id": 372,
    "code": "electronics",
    "sortOrder": 0,
    "visible": true,
    "featured": true,
    "lineage": "/372",
    "depth": 0,
    "parent": null,
    "description": {
      "id": 6094,
      "language": "en",
      "name": "electronics",
      "description": "ele",
      "friendlyUrl": "ele",
      "keyWords": null,
      "highlights": "ele",
      "metaDescription": "ele",
      "title": null
    },
    "productCount": 0,
    "children": [
      {
        "id": 373,
        "code": "tv",
        "sortOrder": 0,
        "visible": true,
        "featured": false,
        "lineage": "/372/373",
        "depth": 1,
        "parent": {
          "id": 372,
          "code": "electronics"
        },
        "description": {
          "id": 6095,
          "language": "en",
          "name": "tv",
          "description": "sample",
          "friendlyUrl": "sample",
          "keyWords": null,
          "highlights": "sample",
          "metaDescription": "sample",
          "title": null
        },
        "productCount": 0,
        "children": [
          {
            "id": 374,
            "code": "lcd",
            "sortOrder": 0,
            "visible": true,
            "featured": false,
            "lineage": "/372/373/374",
            "depth": 2,
            "parent": {
              "id": 373,
              "code": "tv"
            },
            "description": {
              "id": 6096,
              "language": "en",
              "name": "lcd",
              "description": "ele",
              "friendlyUrl": "ele",
              "keyWords": null,
              "highlights": "ele",
              "metaDescription": "ele",
              "title": null
            },
            "productCount": 0,
            "children": [
              {
                "id": 375,
                "code": "low",
                "sortOrder": 0,
                "visible": true,
                "featured": false,
                "lineage": "/372/373/374/375",
                "depth": 3,
                "parent": {
                  "id": 374,
                  "code": "lcd"
                },
                "description": {
                  "id": 6097,
                  "language": "en",
                  "name": "low",
                  "description": "ele",
                  "friendlyUrl": "ele",
                  "keyWords": null,
                  "highlights": "ele",
                  "metaDescription": "ele",
                  "title": null
                },
                "productCount": 0,
                "children": []
              }
            ]
          },
          {
            "id": 376,
            "code": "wefewfe",
            "sortOrder": 0,
            "visible": true,
            "featured": false,
            "lineage": "/372/373/376",
            "depth": 2,
            "parent": {
              "id": 373,
              "code": "tv"
            },
            "description": {
              "id": 6098,
              "language": "en",
              "name": "wefewfe",
              "description": "ele",
              "friendlyUrl": "ele",
              "keyWords": null,
              "highlights": "ele",
              "metaDescription": "ele",
              "title": null
            },
            "productCount": 0,
            "children": []
          }
        ]
      }
    ]
  }
 ]
  

  return (
    <TreeView className="menu-items" onMouseLeave={() => setIsShown(false)}>
      {props.items ? (
        <>
          <TreeView
            className={classes.toolbarLink}
            onClick={() => onCategoryClick(props.items.code)}
            onMouseEnter={() => setIsShown(true)}
          >
            {props.items.code}
          
          </TreeView>
          {isShown ? (
            <div className={isShown ? `${classes.CategoryDropdown}` : ""}>
              <CategoryDropdown
                depthLevel={props.depthLevel}
                items={props.items.children}
                // items={dataChilds}
                dropdown={isShown}
              />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <Button className={classes.toolbarLink}>{props.items.code}</Button>
      )}
    </TreeView>
    // <Button
    //   color="inherit"
    //   key={cat.id}
    //   className={classes.toolbarLink}
    //   onClick={() => onCategoryClick(cat.code)}
    // >
    //   {cat.code}
    //   {// console.log(JSON.stringify(cat))}
    // </Button>
  );
};

export default CategoryItems;
