import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

/* redux actions */
import {
  addMiniCartToShoppingCart,
  clearMiniCart,
  deleteAttributeFromMiniCart,
  minimumBuyAlert,
  // showBetaMsg,
  updateMiniCartAttributeQuantity,
} from "../../redux/actions/productActions";

/* material ui css */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#27B23E",
    color: theme.palette.common.white,
    // border: "1px solid #ccc",
    fontFamily: "'Fira Sans', sans-serif",
  },
  body: {
    fontSize: 14,
    // border: "1px solid #ccc",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  miniCartButton: {
    fontSize: "18px",
    borderRadius: "2px",
    padding: "10px 8px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.2)",
    background: "#ff9d00",
    color: "#FFFFFF",
    fontWeight: 600,
    border: "none",
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "none",
    marginRight: theme.spacing(2),
    transition: "ease all 0.3s",
    "&:hover": {
      background: "#f59701",
      color: "#FFFFFF",
      transform: "translateY(-5px)",
    },
  },
  table: {
    marginTop: 10,
  },
  delete: {
    background: "#ff4d4d",
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    "&:hover": {
      background: "#f59701",
      color: "#FFFFFF",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  cancelBbutton: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: "#ff4d4d",
    color: "#FFF",
    "&:hover": {
      background: "#f59701",
      color: "#FFFFFF",
    },
  },
  noFound: {
    fontSize: 13,
    fontWeight: 500,
    fontFamily: "'Fira Sans', sans-serif",
  },
  total: {
    fontSize: 15,
    color: "#fff",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
  },
  iconButton: {
    fontSize: "1rem",
    border: "solid 1px #707070",
    borderColor: "#53e7bc",
    borderRadius: "50%",
  },
  colorbox: {
    width: "20px",
    height: "20px",
    border: "2px solid #383838",
    borderRadius: "50%",
    margin: "auto",
  },
}));

export const MiniCart = (props) => {
  const classes = useStyles();
  const { handleMiniCartClose, addToMiniCart} = props;
  const { miniCart } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const history = useHistory();

  /* function to handle delete attribute */
  const handleDeleteAttribute = (attribute) => {
    dispatch(deleteAttributeFromMiniCart(miniCart.id, attribute.id));
  };

  /* function to add minicart products to shopping cart */
  const handleAddMiniCartToShoppingCart = (miniCartId) => {
    dispatch(addMiniCartToShoppingCart(miniCartId, history));
    handleMiniCartClose(0);
    // dispatch(showBetaMsg());
  };

  /* function to handle remove and update product quantity */
  const removeAndUpdate = (attribute) => {
    if (attribute.minQuantity !== attribute.quantity) {
      const attributeData = {
        productAttributeId: attribute.id,
        quantity: attribute.quantity - 1,
      };

      dispatch(
        updateMiniCartAttributeQuantity(
          miniCart.id,
          attribute.id,
          attributeData
        )
      );
    } else {
      dispatch(
        minimumBuyAlert(
          "Sorry, Quantity should be greater than or equal to minimum buy."
        )
      );
    }
  };

  /* function to handle add and update product quantity */
  const addAndUpdate = (attribute) => {
    const attributeData = {
      productAttributeId: attribute.id,
      quantity: attribute.quantity + 1,
    };

    dispatch(
      updateMiniCartAttributeQuantity(miniCart.id, attribute.id, attributeData)
    );
  };

  useEffect(() => {
    return () => {
      handleMiniCartClose(0);
      dispatch(clearMiniCart());
    };
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <div>
      <Button
        variant="contained"
        className={classes.miniCartButton}
        startIcon={<AddShoppingCartIcon />}
        onClick={() => addToMiniCart(miniCart.id)}
      >
        Add to Minicart
      </Button>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Size</StyledTableCell>
              <StyledTableCell align="center">Color</StyledTableCell>
              <StyledTableCell align="center">Quantity</StyledTableCell>
              <StyledTableCell align="center"><Typography>Price</Typography>  (GST is included)</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
{/* {// console.log(miniCart.miniCartAttributes)} */}
          {Object.keys(miniCart).length > 0 &&
          miniCart.miniCartAttributes.length > 0 ? (
            <TableBody>
              {miniCart.miniCartAttributes.map((attribute, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th"  align="center" scope="row">
                    {attribute.sizeCode}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                  <p
                        className={classes.colorbox}
                        style={{
                          backgroundColor: `${attribute.colorCode}`,
                        }}
                      />

                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="close"
                      size="small"
                      onClick={() => removeAndUpdate(attribute)}
                    >
                      <RemoveRoundedIcon className={classes.iconButton} />
                    </IconButton>
                    {attribute.quantity}
                    <IconButton
                      edge="end"
                      color="inherit"
                      aria-label="close"
                      size="small"
                      onClick={() => addAndUpdate(attribute)}
                    >
                      <AddRoundedIcon className={classes.iconButton} />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {attribute.price}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.delete}
                      onClick={() => handleDeleteAttribute(attribute)}
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              <StyledTableRow style={{ backgroundColor: "#27B23E" }}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  className={classes.total}
                >
                  Total:
                </StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center" className={classes.total}>
                  {miniCart.totalQuantity}
                </StyledTableCell>
                <StyledTableCell align="center" width='30%' className={classes.total}>
                  {miniCart.totalMiniCartPrice}
                </StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell colSpan={5} align="center" className={classes.noFound}>
                  Products not found
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <div className={classes.buttons}>
        <Button
          variant="contained"
          size="small"
          className={classes.cancelBbutton}
          onClick={() => handleMiniCartClose(miniCart.id)}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          disabled={
            Object.keys(miniCart).length > 0 &&
            miniCart.miniCartAttributes.length > 0
              ? false
              : true
          }
          onClick={() => handleAddMiniCartToShoppingCart(miniCart.id)}
        >
          Add to Cart
        </Button>
      </div>
    </div>
  );
};
