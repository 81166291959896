import React from "react";
import { Link } from "react-router-dom";

/* import react components */
// import { PromotionBanner } from "./PromotionBanner";
import { DemoItem } from "../DemoItem";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  section: {
    width: "auto",
    overflow: "hidden",
    backgroundColor: "#fff",
    // margin: 10,
    marginTop: 10,
    borderTop: "1px solid #eaeaea",
    boxShadow: "0 2px 4px 0 rgb(0 0 0 / 8%)",
    borderRadius: 2,
  },
  titleBlock: {
    padding: "15px 20px",
    display: "flex",
    justifyContent: "space-between",
    // borderBottom: "1px solid rgba(0,0,0,.1)",
    // borderTop: "1px solid #eaeaea",
    // backgroundColor: "#53E7BC",
    backgroundColor: "#FFF",
    "&>a": {
      textDecoration: "none",
    },
  },
  grouptitle: {
    fontSize: "18px",
    color: "#000000",
    fontWeight: 600,
  },
  seeall: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)",
    border: "none",
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: 1.29,
    padding: "8px 10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#27B23E",
    },
  },
 
}));

export const GroupCards = (props) => {
  const classes = useStyles();

  const { group, category, defaultGroup } = props;


  
  return (
    <>
      {Object.keys(group).length > 0 && (
        <section className={classes.section}>
          <div className={classes.titleBlock}>
            <Typography variant="h6" className={classes.grouptitle}>
              {group.groupName.replace(/_/g, " ")}
            </Typography>
            {group.groupDetails.number > 0 && (
              <Link
                to={`/${category}/${group.groupName}/${
                  defaultGroup ? 1 : 0
                }/products`}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.seeall}
                >
                  VIEW ALL
                </Button>
              </Link>
            )}
          </div>

          <div style={{ padding: "0px 24px" }}>
            <Grid container spacing={2}>
              {group.groupDetails.number > 0 ? (
                group.groupDetails.products
                  .filter((item) => item.categories[0].code === category)
                  .sort(() => Math.random() - Math.random())
                  .slice(0, 4)
                  .map((newprod) => (
                    <Grid item xs={12} sm={6} md={3} key={newprod.id}>
                      <DemoItem product={newprod} />
                    </Grid>
                  ))
              ) : (
                <Typography
                  variant="body2"
                  style={{ margin: "auto", padding: 10 }}
                >
                  Products not Found.
                </Typography>
              )}
            </Grid>
          </div>
          
        </section>
      )}

      {/* {group.bannerDetails && group.bannerDetails.activeImagesCount > 0 && (
        <PromotionBanner
          bannerImages={group.bannerDetails.bannerImages}
          category={category}
        />
      )} */}
     
    </>
  );
};
