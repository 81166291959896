import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
/* import react components */
import { AppBars } from "../components/AppBar";
import { Footer } from "../components/Footer";
import Banners from "../components/Banners";
// import { CategoryList } from "../components/homepage/CategoryList";
import { GroupCards } from "../components/homepage/GroupCards";
import Spinner from "../util/spinner/spinner";

/* import redux actions */
import { getAllProductGroups } from "../redux/actions/homepageActions";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Button, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { isArrayNotEmpty } from "../util/commonUtil/commonUtil";

import { CategoryListOne } from "../components/homepage/CategoryListOne";
import AuthenticationAlertPop from "./customer/AuthenticationAlertPop";
import { authAlert } from "../redux/actions/authActions";
import Sitemap from "./Sitemap/sitemap";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  rootDiv: {
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiGrid-root :hover": {
      textDecoration: "none",
    },
    "& .MuiTabs-fixed": {
      marginTop: theme.spacing(1),
    },
    "& .MuiCardMedia-img": {
      objectFit: "contain",
      fontSize: "10px",
      fontWeight: "bold",
      color: "#383838",
      fontFamily: `Lato,"Helvetica Neue",Arial,Helvetica,sans-serif`,
      textAlign: "center",
    },
  },
  browse: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)",
    border: "none",
    borderRadius: "4px",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: 1.29,
    textTransform: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#27B23E",
    },
  },
  advertiseText: {
    fontSize:'2rem'
  }
}));

export const Home = () => {
  const classes = useStyles();

  const {
    loading,
    authenticated,
    account: { userType },
    alert,
  } = useSelector((state) => state.auth);
  const { loadingHome, productGroups } = useSelector((state) => state.home);
  const history = useHistory();

  const dispatch = useDispatch();

  const [category, setCategory] = useState("streetwears");
  const handleSelectCategory = (event, newValue) => {
    setCategory(newValue);
  };

  const handleCategoryProductsList = () => {
    history.push(`/${category}/products`);
  };

  const handleCloseAlert = () => {
    dispatch(authAlert());
  };

  useEffect(() => {
    dispatch(getAllProductGroups());
    !authenticated && dispatch(authAlert());
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  const [showSiteMap, setShowSiteMap] = useState(false);

  const getFilteredGroups = () => {
    let filteredArray = [];
    productGroups.groupList.forEach((group) => {
      let result = group.groupDetails.products.filter(
        (prod) =>
          ((prod.categories[0] && prod.categories[0].code === category) ||
            ["New_Uploads", "Top_Rated", "Lowest_Price"].includes(
              prod.categories[0].code
            )) &&
          prod.visible === true
      );
      if (isArrayNotEmpty(result)) {
        filteredArray.push(group);
      }
    });
    return filteredArray;
  };
  useEffect(() => {
   document.title = "B2B Marketplace - Outxtock"
  },[])

  return (
    <Fragment>
      <CssBaseline />
      {loading ? (
        <Spinner />
      ) : authenticated && userType === "VNDR" ? (
        <Redirect to="/seller-dashboard" />
      ) : authenticated && userType === "MERCH" ? (
        <Redirect to="/seller-dashboard" />
      ) : authenticated && userType === "MANU" ? (
        <Redirect to="/seller-dashboard" />
      ) : (
        <div className={classes.rootDiv}>
          {alert && (
            <AuthenticationAlertPop
              open={alert}
              handleCloseAlert={handleCloseAlert}
            />
          )}
          <AppBars />
          {!showSiteMap ? (
            <>
              <Banners type={category} />
              {/* <CategoryList
            category={category}
            handleSelectCategory={handleSelectCategory}
          /> */}
              {loadingHome ? (
                <Spinner />
              ) : (
                <>
                  <CategoryListOne
                    category={category}
                    handleSelectCategory={handleSelectCategory}
                  />

                  {/* <Typography variant="h1"  align="center"  className={classes.advertiseText}>
                    India's first B2B online marketplace where buyers can Bargain.
                  </Typography> */}

                  {productGroups.groupList?.length > 0 &&
                    getFilteredGroups().map((group, index) => (
                      <GroupCards
                        group={group}
                        category={category}
                        defaultGroup={productGroups.defaultGroups}
                        productGroups={productGroups}
                        key={index}
                      />
                    ))}

                  {productGroups.groupList?.length > 0 && (
                    <section style={{ margin: "20px 0 20px" }}>
                      <Grid item xs={12}>
                        <Grid container justify="center">
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.browse}
                            onClick={handleCategoryProductsList}
                          >
                            Browse all {category}
                          </Button>
                        </Grid>
                      </Grid>
                    </section>
                  )}
                </>
              )}
            </>
          ) : (
            <Sitemap />
          )}

          <Footer showSiteMap={showSiteMap} setShowSiteMap={setShowSiteMap} />
        </div>
      )}
    </Fragment>
  );
};
