import React, { useEffect, useRef, useState } from "react";
import { AppBars } from "../../components/AppBar";

/* import react component */

import { Footer } from "../../components/Footer";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid } from "@material-ui/core";
import ProductFilter from "../../components/ProductFilter";
import ProductList from "../../components/ProductList";
import MenuHeader from "../../components/MenuHeader";
import Sitemap from "../Sitemap/sitemap";
import { useParams } from "react-router-dom/cjs/react-router-dom";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  searchroot: {
    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
}));

export const ProductsListByGroup = () => {
  const classes = useStyles();
  const categoryRef = useRef();
  const { category } = useParams();
  const [showSiteMap17, setShowSiteMap17] = useState(false);

  const [selectedPriceSort, setSelectedPriceSort] = useState("all");

  const categoryClickedFromCategoryTab = (categoryName) => {
    categoryRef.current.categoryClickedFromTab(categoryName);
  };
  useEffect(() => {
    var metaDescriptionTag = document.querySelector('meta[name="description"]');

    // Check if the meta tag is found
    if (metaDescriptionTag) {
      // Update the content attribute with a new description
      metaDescriptionTag.setAttribute('content', `${category}`)
    }
    document.title = category
  },[category])

  return (
    <div className={classes.searchroot}>
      <CssBaseline />
      <AppBars />
      {!showSiteMap17 ? (
        <>
          {/* <CategoryList categoryClicked={categoryClickedFromCategoryTab}  fromProductOrSearchPage={true}/> */}
          <MenuHeader
            categoryClicked={categoryClickedFromCategoryTab}
            fromProductOrSearchPage={true}
          />

          <section style={{ marginTop: 10, backgroundColor: "#fff" }}>
            <Grid container style={{ marginBottom: 10 }}>
              <ProductFilter
                ref={categoryRef}
                setSelectedPriceSort={setSelectedPriceSort}
              />
              <ProductList
                selectedPriceSort={selectedPriceSort}
                setSelectedPriceSort={setSelectedPriceSort}
              />
            </Grid>
          </section>
        </>
      ) : (
        <Sitemap />
      )}

      <Footer showSiteMap={showSiteMap17} setShowSiteMap={setShowSiteMap17} />
    </div>
  );
};
