import React from "react";
import { useHistory } from "react-router";

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  NotFoundCode: {
    fontSize: "160px",
    left: "50%",
  },
  subTitle: {
    marginTop: theme.spacing(2),
  },
  homeButton: {
    color: "#FFFFFF",
    background: "#53E7BC",
    marginTop: theme.spacing(2),
    fontSize: "13px",
    fontWeight: 600,
    borderRadius: "0.375rem",
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
}));

export const NotFound = () => {
  const classes = useStyles();
  const history = useHistory();

  const goHome = () => {
    history.push("/");
  };

  return (
    <div className={classes.root}>
      <Container>
        <Typography variant="h1" className={classes.NotFoundCode}>
          404
        </Typography>

        <Typography variant="subtitle1" component="p">
          OOPS! PAGE NOT FOUND
        </Typography>

        <Typography
          variant="subtitle1"
          component="p"
          className={classes.subTitle}
        >
          THE PAGE YOU REQUESTED FOR IS NOT FOUND
        </Typography>

        <Button
          variant="contained"
          size="small"
          className={classes.homeButton}
          onClick={goHome}
        >
          Home
        </Button>
      </Container>
    </div>
  );
};
