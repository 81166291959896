import { ActionTypes } from "../constants/actionTypes";
import axios from "axios";

const pinCodeRegex = RegExp(/^[1-9][0-9]{5}$/);

export const checkDeliveryByPincode = (inputData) => async (dispatch) => {
  try {
   
    if (pinCodeRegex.test(inputData.pincode)) {
     
      const { data } = await axios.get(
        `${process.env.REACT_APP_SHIPPING_URL}/api/v1/searchByPinCode/${inputData.pincode}`
      );
      if (data.pincode === inputData.pincode) {
        dispatch({
          type: ActionTypes.DELIVERY_AVAILABILITY_BY_PINCODE,
          payload: { message: "Delivery available for this pincode." },
        });
      } else {
        dispatch({
          type: ActionTypes.DELIVERY_NOT_AVAILABLE,
          payload: { message: "Unfortunately we do not ship to your pincode." },
        });
      }

      dispatch({ type: ActionTypes.CLEAR_DELIVERY_ERRORS });
    } else {
      dispatch({
        type: ActionTypes.SET_SNACKBAR,
        payload: { alert: "error", message: "Please enter a valid pincode" },
      });
    }
  } catch (error) {
    // console.log(error)
    if (error.response.status === 500) {
      dispatch({
        type: ActionTypes.DELIVERY_NOT_AVAILABLE,
        payload: { message: "Unfortunately we do not ship to your pincode" },
      });
    } else {
      // console.log(error.response);
    }
  }
};

export const resetPincode = () => (dispatch) => {
  try {
    dispatch({ type: ActionTypes.RESET_PINCODE });
  } catch (error) {
    // console.log(error);
  }
};

export const snackBarAlert = (data, message) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SNACKBAR,
    payload: { alert: data, message: message },
  });
};
