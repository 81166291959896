import React from "react";
import { useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import { CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)",
  },
  avatar: {
    height: 100,
    width: 100,
  },
  firstname: {
    fontSize: 18,
    fontFamily: "'Fira Sans', sans-serif",
  },
  username: {
    fontSize: 24,
    fontWeight: 500,
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "capitalize",
  },
  emailAddress: {
    fontSize: 18,
    fontFamily: "'Fira Sans', sans-serif",
  },
  profilecard: {
    "& .MuiCardHeader-title": {
      fontSize: 18,
      fontWeight: 600,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#ffffff",
      textAlign: "center",
    },
    "& .MuiCardHeader-root": {
      backgroundColor: "#f7a692",
    },
  },
  lable: {
    fontSize: 13,
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    textAlign: "center",
  },
  value: {
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#000000",
    padding: theme.spacing(1),
    textAlign: "center",
  },
}));

export const MyProfile = () => {
  const classes = useStyles();
  const { account } = useSelector((state) => state.auth);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Box alignItems="center" display="flex" flexDirection="column">
                <Avatar className={classes.avatar} src="/broken-image.jpg" />
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h3"
                  className={classes.firstname}
                >
                  {account.firstName}
                </Typography>

                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h3"
                  className={classes.emailAddress}
                >
                  {account.emailAddress}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={8} md={6} xs={12}>
          <Card className={classes.profilecard}>
            <CardHeader title="My Profile" />
            <Divider />

            <CardContent>
              <Grid container spacing={3}>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <FormLabel component="legend" className={classes.lable}>
                    First Name
                  </FormLabel>
                  <Typography className={classes.value}>
                    {account.firstName}
                  </Typography>
                </Grid>

                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <FormLabel component="legend" className={classes.lable}>
                    Last name
                  </FormLabel>
                  <Typography className={classes.value}>
                    {account.lastName}
                  </Typography>
                </Grid>

                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <FormLabel component="legend" className={classes.lable}>
                    Email Address
                  </FormLabel>
                  <Typography className={classes.value}>
                    {account.emailAddress}
                  </Typography>
                </Grid>

                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <FormLabel component="legend" className={classes.lable}>
                    Phone Number
                  </FormLabel>
                  <Typography className={classes.value}>
                    {account.billing.phone}
                  </Typography>
                </Grid>

                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <FormLabel component="legend" className={classes.lable}>
                    UserName
                  </FormLabel>
                  <Typography className={classes.value}>
                    {account.userName}
                  </Typography>
                </Grid>

                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <FormLabel component="legend" className={classes.lable}>
                    UserType
                  </FormLabel>
                  <Typography className={classes.value}>
                    {account.userType}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
