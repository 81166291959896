import React from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import { CompanyDetails } from "./profileTabs/CompanyDetails";
import { PersonalDetails } from "./profileTabs/PersonalDetails";
import { BankDetails } from "./profileTabs/BankDetails";

const useStyles = makeStyles({
  profileTabs: {
    color: "inherit",
    background: "transparent",
    boxShadow: "none",
    borderBottom: "1px solid #E9E9E9",
    "& .Mui-selected": {
      color: "#27B23E",
    },
    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
  tablable: {
    fontSize: "13px",
    fontWeight: 600,
    letterSpacing: "0.26px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#383838",
    fontStretch: "normal",
    fontStyle: "normal",
    width: "197px",
    height: "23px",
    textTransform: "capitalize",
  },
  indicator: {
    backgroundColor: "#27B23E",
  },
});

export const SellerProfile = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState("1");

  const handleChangeActiveTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <AppBar position="static" className={classes.profileTabs}>
          <TabList
          variant="scrollable"
            onChange={handleChangeActiveTab}
            aria-label="simple tabs example"
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab label="Bank Details" value="1" className={classes.tablable} />

            <Tab
              label="My Personal Details"
              value="2"
              className={classes.tablable}
            />
            <Tab
              label="My Company Details"
              value="3"
              className={classes.tablable}
            />
          </TabList>
        </AppBar>

        <TabPanel value="1">
          <BankDetails />
        </TabPanel>

        <TabPanel value="2">
          <PersonalDetails />
        </TabPanel>

        <TabPanel value="3">
          <CompanyDetails />
        </TabPanel>
      </TabContext>
    </div>
  );
};
