import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  loading: false,
  serverError: false,
  error: null,
  signUpSuccess: false,
  sellerAccStatus: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOADING_UI:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.SET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
        serverError: false,
      };
    case ActionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        signUpSuccess: true,
      };
    case ActionTypes.SERVER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
        error: action.payload,
      };
    case ActionTypes.CHECK_SELLER_ACCOUNT_STATUS:
      return {
        ...state,
        loading: false,
        sellerAccStatus: action.payload,
      };
    case ActionTypes.CLEAR_FORM_MESSAGES:
      return {
        ...state,
        error: null,
        sellerAccStatus: null,
        serverError: false,
      };
    default:
      return state;
  }
}
