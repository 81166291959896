import React from "react";

/* import react components */
// import { Footer } from "../../components/Footer";

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#f0e0d4",
  },
  pagetitle: {
    fontSize: 28,
    fontWeight: 600,
    color: "#F24046",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
    fontStretch: "normal",
    margin: "90px 0 10px 0",
    textAlign: "center",
  },

  text: {
    fontSize: 16,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 300,
    margin: "auto",
    lineHeight: "30px",
    color: "#5c6166",
  },
  heading: {
    textAlign: "start",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    margin: "15px 0 15px 0",
    color: "#F24046",
  },
  subheading: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    margin: "15px 0 0 8px",
    fontWeight: 600,
  },
}));

export const TermsOfUseFooter = () => {
  const classes = useStyles();

  const TERMS_OF_USE_DOC = "Terms of Use";

  // const getDocument = (documentStr) => {
  //   if (TERMS_OF_USE_DOC === documentStr) {
  //     return TermsOfUseFooter;
  //   }
  //   return "";
  // };

  return (
    <div className={classes.root}>
      <Container fixed style={{ marginBottom: 20 }}>
        <Grid item xs={12}>
          <Typography component="h6" className={classes.pagetitle}>
            TERMS AND CONDITIONS
          </Typography>

          {/* <div>
              {parse(getDocument(TERMS_OF_USE_DOC))}
          </div> */}

          <Typography component="p" className={classes.text}>
            Welcome to the Stockxbid, offered to you by{" "}
            <a href="https://outxtock.com" target="_blank">
              www.outxtock.com
            </a>
            .
          </Typography>

          <Typography component="p" className={classes.text}>
            Stockxbid provides services to you subject to the following notices,
            terms, and conditions (“Terms”).
          </Typography>

          <Typography component="p" className={classes.text}>
            PLEASE READ THESE TERMS CAREFULLY BEOFRE AVAILING AND USING THE
            DOMAIN NAME www.Outxtock.com (HEREINAFTER REFERRED TO AS THE
            “WEBSITE” or “SITES”), WHICH IS OWNED AND OPERATED BY Stockxbid
            Technologies Pvt Ltd (HEREINAFTER REFERRED TO AS “WE”, “US”, OR
            “STOCKXBID”) HAVING ITS REGISTERED OFFICE AT Address: 29/1,First
            Floor, 6th Cross Rd, Sangeetha Apts, Swimming Pool Extension,
            Malleshwaram 11th Cross, Bengaluru, Karnataka 560003. THESE TERMS,
            AS MODIFIED OR AMENDED FROM TIME TO TIME, ARE A BINDING CONTRACT
            BETWEEN AND YOU (“You” or “User” or “Buyer”). THESE TERMS AND
            CONDITIONS (HEREINAFTER REFERRED TO AS “TERMS”) AS WELL AS THE
            PRIVACY POLICY, AND ALL OTHER APPLICABLE LAWS AND REGULATIONS GOVERN
            YOUR ACCESS AND USE OF THE WEBSITE AND SERVICES PROVIDED HEREIN
            IRRESPECTIVE OF WHETHER YOU ARE A “VISITOR” (WHICH MEANS THAT YOU
            SIMPLY BROWSE THE SERVICES WITHOUT BEING REGISTERED AS A MEMBER OF
            THE SITE), OR YOU ARE A “MEMBER” (WHICH MEANS THAT YOU HAVE
            REGISTERED WITH THE SITE AS A MEMBER) IN ADDITION, WHEN YOU USE ANY
            CURRENT OR FUTURE STOCKXBID SERVICE OR VISIT OR PURCHASE FROM ANY
            BUSINESS AFFILIATED WITH STOCKXBID, WHETHER OR NOT INCLUDED IN THE
            SITES, YOU ALSO WILL BE SUBJECT TO THE GUIDELINES AND CONDITIONS
            APPLICABLE TO SUCH SERVICE OR BUSINESS. IF THESE CONDITIONS ARE
            INCONSISTENT WITH SUCH GUIDELINES AND CONDITIONS, SUCH GUIDELINES
            AND CONDITIONS WILL BE APPLICABLE. BY ACCESSING, BROWSING, OR
            OTHERWISE USING THIS WEBSITE INDICATES YOUR AGREEMENT TO BE BOUND BY
            THESE TERMS. IN CASE OF AN ORGANIZATION, YOU REPRESENT AND WARRANT
            THAT YOU HAVE THE AUTHORITY TO LEGALLY BIND YOUR ORGANIZATION AND
            YOUR ORGANIZATION WILL BE BOUND BY THE OBLIGATIONS AND RESTRICTIONS
            OF THIS AGREEMENT. THESE TERMS OF USE CONSTITUTE AN ELECTRONIC
            RECORD WITHIN THE MEANING OF THE APPLICABLE LAW. THIS ELECTRONIC
            RECORD IS GENERATED BY A COMPUTER SYSTEM. FOR CLARITY, THE AGREEMENT
            BETWEEN THE USER AND US IS EFFECTIVE ON THE DATE ON WHICH THE
            WEBSITE IS ACCESSED AND/OR THE DATE ON WHICH TERMS OF THE AGREEMENT
            ARE UPDATED. IF YOU DO NOT AGREE TO BECOME BOUND BY THESE TERMS AND
            CONDITIONS, OR DO NOT HAVE THE AUTHORITY TO BIND YOUR ORGANIZATION
            TO THESE TERMS AND CONDITIONS, THEN DO NOT AVAIL OF USE THE SERVICES
            OR MATERIALS OF THIS WEBSITE.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            ABOUT OUR SERVICES
          </Typography>

          <Typography component="p" className={classes.text}>
            We provide a number of internet-based services through the Website
            (all such services are collectively referred to as the “Service”).
            One such service is offering an online marketplace where registered
            sellers/suppliers can offer to sell their products to the users of
            the Website{" "}
            <a href="https://outxtock.com" target="_blank">
              www.outxtock.com
            </a>{" "}
            (all such products are collectively referred to as the “Products”).
            A website is an online platform that enables You to list (if you are
            a seller on the Website) and sell the products listed on the Website
            at the price indicated therein at any time from any location.
          </Typography>

          <Typography component="p" className={classes.text}>
            Our role will be limited to managing the Website and associated
            marketing activities, facilitating payment collections, order
            management, inquiry management, and other incidental services to
            enable transactions between Sellers and other Users.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            MINIMUM AGE
          </Typography>

          <Typography component="p" className={classes.text}>
            The Sites are intended for use by persons who are at least 18 years
            old, or the legal age required to form a binding contract in your
            jurisdiction if that age is greater than 18. By using the Sites, you
            represent and warrant that you are at least 18 years old and of
            legal age to form a binding contract. As a minor, if You wish to use
            or transact on the Website, such use or transaction may be made only
            with the permission and supervision of your legal guardian or
            parents. We reserve the right to terminate your membership and/or
            refuse to provide You with access to the Website at its sole
            discretion without assigning any specific reason.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            YOUR ACCOUNT
          </Typography>

          <Typography component="p" className={classes.text}>
            We do not permit users to avail of services on the Website without
            prior registration. You are required to register with the Website as
            a Member by filling out the Registration Form provided in the Sign
            Up Area of the Website. You may access the Website as a Visitor
            without registering with the Website, however, this may prevent You
            from availing all the Services offered by Us. Registration of the
            Website for all new Users is vide email, mobile or social media
            connects. During Registration, You will be required to furnish to us
            with certain information and details, including Your name, e-mail
            id, and any other information that is deemed necessary by the
            Website (“Account Information”).
          </Typography>
          <Typography component="p" className={classes.text}>
            You are required to enter a valid phone number or email while
            registering on the Website. By such registration, You consent to be
            contacted by us via phone calls, messages, instant messages or such
            other means of communication inter alia for
            subscription/services/promotional updates, etc. You may opt-out of
            such subscription/services/promotional updates by writing to our
            support team.
          </Typography>
          <Typography component="p" className={classes.text}>
            You are responsible for maintaining the confidentiality of your
            Account and password and for restricting access to your computer to
            prevent unauthorized access to your Account or password. You should
            take all necessary steps to ensure that the password is kept
            confidential and shall take immediate steps to inform us immediately
            if You have any reason to believe that your Account has been hacked
            or comprised by any third party. You expressly agree and acknowledge
            that all Account Information provided by You is accurate and true.
            You hereby agree and understand that we reserve the right to refuse
            access to the Website, terminate your Accounts and remove or edit
            any content posted by you without any notice to you.
          </Typography>

          <Typography component="p" className={classes.text}>
            Membership on the Website is free for all Users. We do not charge
            any fees for browsing or accessing the Website. However, We reserve
            the right to change its fees policy at any point of time. We reserve
            the right to introduce any new premium services along with any fees
            that we determine at our discretion. Any changes to the Fees shall
            be posted on the Website and shall become effective immediately
            after such changes are posted on the Website.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            PRIVACY POLICY
          </Typography>

          <Typography component="p" className={classes.text}>
            Please read our Privacy Policy, which is incorporated into these
            Terms and governs your use and access to this Website. The
            information and/or data provided or collected to us by You during
            the course of usage of the Website will be treated in a confidential
            manner and in accordance with our Privacy Policy as well as the
            applicable laws and regulations.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            ACCESS TO WEBSITE
          </Typography>

          <Typography component="p" className={classes.text}>
            We shall take all necessary and reasonable steps to ensure the
            uninterrupted availability of the Website. However, We do not
            guarantee the uninterrupted availability of the Website at all
            times. Your access to the Website may be occasionally suspended or
            restricted to allow for repairs, maintenance, or the introduction of
            new facilities or services at any time without prior notice.
          </Typography>

          <Typography component="p" className={classes.text}>
            We hereby grant you a limited license to access and make personal
            use of the Website and the Services offered by us in accordance with
            our Terms or Polices as introduced or modified or amended from time
            to time. This license does not include any resale, or commercial use
            of this Website or its contents, any downloading or copying of
            content or account information for the benefit of another seller, or
            any use of data mining, robots, or similar data gathering and
            extract tools while using the Website.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            WEBSITE CONTENTS
          </Typography>

          <Typography component="p" className={classes.text}>
            This Website or any portion of this Website (including but not
            limited to any copyrighted materials, trademarks, or any other
            proprietary information) may be not copied, reproduced, duplicated,
            sold or commercially, or otherwise exploited without obtaining our
            prior written consent. All texts, graphics, designs, user
            interfaces, visual interfaces, marks, logos, and artworks posted on
            the Website are our exclusive property and You shall not copy,
            reproduce, sell or share any such content commercially without
            obtaining our prior written permission.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            CUSTOMER SUPPORT
          </Typography>

          <Typography component="p" className={classes.text}>
            You may contact our Member Services by sending an email to
            <b>support_help@stockxbid.com</b>. You acknowledge that the
            provision of customer support is at our discretion and that we have
            no obligation to provide you with customer support of any kind. We
            may provide you with customer support from time to time, at our sole
            discretion, provided that you have created an Account and that you
            submit your customer support inquiries using such an Account.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            PRODUCT SPECIFICATIONS, ORDERS, AND PRICING
          </Typography>
          <Typography component="p" className={classes.text}>
            We are not infallible, and therefore we do not warrant that product
            specification or other content on the Website is complete, accurate,
            or error-free. You expressly agree and acknowledge that We have the
            right to refuse or cancel any orders from You at any time after
            making such orders, in its sole discretion. If we charged your
            account or received payment from you prior to cancellation, we will
            issue a credit to your account in the amount of the charge. In the
            event of any errors relating to the specifications of any product,
            We shall replace the default product in accordance with our Return
            Policy. Notwithstanding the aforementioned, We have the right to
            refuse or cancel any replacement and/or refund requests, if such
            requests in our opinion fall outside the scope and ambit of the
            Return Policy.
          </Typography>

          <Typography component="p" className={classes.text}>
            While using any of the payments methods available on the Website, We
            are not responsible for any loss or damage incurred directly or
            indirectly by You due to:
          </Typography>
          <Typography component="p" className={classes.text}>
            1. Lack of authorization for any transactions;
          </Typography>
          <Typography component="p" className={classes.text}>
            2. Any payment issues arising out of the transaction; or
          </Typography>
          <Typography component="p" className={classes.text}>
            3. Decline of transaction for any other reason by the Bank.
          </Typography>
          <Typography component="p" className={classes.text}>
            If payments have been made to our Account by Bank in cases of any
            failed transaction, we shall credit such amounts to the bank account
            after receiving appropriate requests from your end.
          </Typography>

          <Typography component="p" className={classes.text}>
            All prices are listed in Rupees. “Price”, as displayed, is inclusive
            of all applicable taxes. We reserve the right to revise the prices
            for the products at any point of time. The items in your shopping
            cart reflect the most recent price of the product. You acknowledge
            that this price may differ from the price shown for the item when
            you first placed it in your cart.
          </Typography>

          <Typography component="p" className={classes.text}>
            Unless otherwise stated all payments made to us shall be made in
            Indian Rupees. You shall be solely responsible for compliance with
            all applicable laws while making payments to us.
          </Typography>

          <Typography component="p" className={classes.text}>
            We reserve the right to impose certain restrictions on the number of
            transactions conducted by the User. We provide the availability
            statements for each product and we may provide the estimated
            timelines for products on receipt of a written request from your
            end. Notwithstanding the aforementioned availability statements, We,
            reserve the right to cancel or refuse or process any order placed by
            You at our sole discretion without assigning any reason to You.
            However, in the event of any such refusal, We shall promptly refund
            any amounts received from your end with respect to such orders or
            replace the product with any other available product on your
            request.
          </Typography>

          <Typography component="p" className={classes.text}>
            The Website allows you to place orders for the products listed by
            suppliers on the Website, subject to the Terms herein, and
            facilitates the placement of orders for the products made by you. On
            receipt of an order from you, We shall send electronically a
            confirmation of such order to you and the supplier concerned. The
            order confirmation shall be provided only after receiving
            confirmation from the concerned supplier.
          </Typography>

          <Typography component="p" className={classes.text}>
            We do not own, sell, or resell any products on our own and do not
            control the suppliers listed on our website. We only facilitate the
            transaction between buyers and sellers and as such we are only a
            facilitating marketplace. We do not have any control or do not
            determine or advise or in any way involved in the offering or
            acceptance of orders between the buyers and sellers. We are not
            responsible for any defects in the products listed by sellers or
            delays in delivery by the sellers. We hold the right to change these
            policies without your permission.
          </Typography>

          <Typography component="p" className={classes.text}>
            We will act as the payment agent on behalf of the supplier for the
            limited purpose of accepting payments for the orders made by the
            buyers. You understand, accept, and agree that the payment facility
            provided by us is neither a banking nor a financial service but is
            merely a facilitator providing an electronic, automated online
            electronic payment or receiving payment through Cash on Delivery
            using the existing payment gateway networks. Further, by providing a
            payment facility, we are neither acting as a trustee nor acting in a
            fiduciary capacity with respect to the transaction or the
            transaction process.
          </Typography>

          <Typography component="p" className={classes.text}>
            We reiterate that the Website is a mere facilitator of transactions
            between the supplier and users and is not responsible for any
            non-performance or breach of any transactions between the supplier
            and users. We do not guarantee that the suppliers will perform or
            complete or fulfill the orders. Moreover, we do not represent any
            supplier and disclaim any liability with respect to any error or
            inconsistency with respect to the supplier.
          </Typography>
          <Typography component="h6" className={classes.heading}>
            POSTING CONTENT ON THE SITES
          </Typography>
          <Typography component="p" className={classes.text}>
            You agree to be liable for any content posted using your Account and
            for any transactions associated with your Account. By displaying or
            publishing (“posting”) any content on the Sites, including without
            limitation text, audio, video, pictures, graphics, music, sound
            clips, images, likenesses, personal information, and other works of
            authorship (collectively, “Content”), you warrant and represent that
          </Typography>
          <Typography component="p" className={classes.text}>
            a) You own all rights in your Content and the performance contained
            in your Content or, alternatively, you have acquired all necessary
            rights in your Content to enable you to grant us the rights in your
            Content described herein and for us to exercise the rights with
            respect to such Content that you grant herein; and
          </Typography>
          <Typography component="p" className={classes.text}>
            b) Your Content is not defamatory in nature, and does not infringe
            the intellectual property rights, privacy, rights to publicity, or
            any other legal or moral rights of any third party.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            GRANT OF LICENSE
          </Typography>

          <Typography component="p" className={classes.text}>
            You hereby grant us a permanent, worldwide, royalty-free, freely
            transferable, freely sub-licensable (through unlimited levels of
            sublicense), non-exclusive license to use, reproduce, modify, adapt,
            transmit, distribute, publicly perform and display (including in
            each case by means of a digital audio transmission), and create
            derivative works of the Content, in any form, media, or technology
            now known or later developed. To the extent permissible under the
            applicable laws, You hereby waive any moral rights you may have in
            such Content under the laws of any jurisdiction. You agree that we
            may (but are not obligated to) display your Content and your
            username or your actual name (according to the preferences you
            select at the time that you register) along with your Content.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            PROHIBITED CONDUCT
          </Typography>

          <Typography component="p" className={classes.text}>
            You agree not to post to the Sites any Content or any other
            materials whatsoever that is or could appear to be:
          </Typography>

          <Typography component="p" className={classes.text}>
            a) Untrue, misleading, harmful, threatening, abusive, harassing,
            tortious, defamatory, vulgar, obscene, libelous, invasive to another
            person’s privacy or protected data, hateful, or racially, ethnically
            or otherwise objectionable;
          </Typography>
          <Typography component="p" className={classes.text}>
            b) Infringing or alleged to be infringing upon a third party’s
            intellectual property rights, including any patent, trademark, trade
            secret, copyright, right of publicity, or other proprietary rights
            of any party, including, without limitation, any Content that is the
            subject of any third party claim of infringement;
          </Typography>
          <Typography component="p" className={classes.text}>
            c) Of a type that you do not have a right to transmit under any law
            or under contractual or fiduciary relationships, such as inside
            information and proprietary and confidential information;
          </Typography>
          <Typography component="p" className={classes.text}>
            d) Unsolicited, undisclosed, or unauthorized advertising;
          </Typography>

          <Typography component="p" className={classes.text}>
            e) Software viruses or any other computer code, files, or programs
            designed to interrupt, destroy, or limit the functionality of any
            computer software or hardware or telecommunications equipment;
          </Typography>

          <Typography component="p" className={classes.text}>
            f) Data or information obtained through access that was not
            authorized by the owner of the data or information, or with respect
            to which such posting would constitute unauthorized use;
          </Typography>
          <Typography component="p" className={classes.text}>
            g) Harasses or advocates harassment of another person;
          </Typography>
          <Typography component="p" className={classes.text}>
            h) Interferes with another user’s use and enjoyment of the website
            or any third party users enjoyment of similar services;
          </Typography>
          <Typography component="p" className={classes.text}>
            i) Impersonate another person;
          </Typography>
          <Typography component="p" className={classes.text}>
            j) Directly or indirectly offers, attempts to offer, trades, or
            attempts to trade any item, dealing of which is prohibited or
            restricted in any manner under the provisions of applicable law,
            rule, regulation, or guidelines for the time being in force; or
          </Typography>
          <Typography component="p" className={classes.text}>
            k) In violation of any applicable local, state, national or
            international law, regulation, or statute (including export laws).
          </Typography>

          <Typography component="p" className={classes.text}>
            You are responsible for all activities that occur under its accounts
            and that all transactions made by you are intended for bonafide sale
            or consumption in course of permitted business activities. Every
            activity undertaken or done by you under your account shall be your
            sole responsibility and we shall not liable for such activity in any
            manner. You understand that you have no ownership or other interest
            in the account. You understand that all rights in and to the account
            are and shall forever be owned by and inure to our benefits.
          </Typography>

          <Typography component="p" className={classes.text}>
            You agree not to do, or attempt to do, any of the following, subject
            to applicable law:
          </Typography>

          <Typography component="p" className={classes.text}>
            a) alter information on or obtained from the Sites;
          </Typography>

          <Typography component="p" className={classes.text}>
            b) tamper with postings, registration information, profiles,
            submissions, or Content of other users;
          </Typography>

          <Typography component="p" className={classes.text}>
            c) use any robot, spider, scraper, or other automated means or
            interface not provided by us to access the Sites or extract data or
            gather or use information, such as email addresses, available from
            the Sites or transmit any unsolicited advertising, “junk mail,”
            “spam,” or “chain letters”;
          </Typography>

          <Typography component="p" className={classes.text}>
            d) frame any part of the Sites or link to the Sites, or otherwise
            make it look like you have a relationship to us or that we have
            endorsed you or your Content for any purpose except as expressly
            permitted in writing by Us;
          </Typography>
          <Typography component="p" className={classes.text}>
            e) impersonate or misrepresent your affiliation with any person or
            entity;
          </Typography>
          <Typography component="p" className={classes.text}>
            f) Bypass or circumvent measures employed to prevent or limit access
            to any area, content, or code of the Sites (except as otherwise
            expressly permitted by law);
          </Typography>

          <Typography component="p" className={classes.text}>
            g) take any action which might impose a significant burden (as
            determined by us) on the Site’s infrastructure or performance, or
            send to or otherwise impact us or the Sites (or anything or anyone
            else) with harmful, illegal, deceptive, or disruptive code such as a
            virus, “spyware,” “adware” or other code that could adversely impact
            the site or any receipt.
          </Typography>
          <Typography component="p" className={classes.text}>
            You shall not attempt to gain unauthorized access to any portion or
            feature of the Website, or any other systems or networks connected
            to the Website or to any server, computer, network, or to any of the
            services offered on or through the Website, by hacking, password
            "mining" or any other illegitimate means.
          </Typography>
          <Typography component="p" className={classes.text}>
            You shall not probe, scan or test the vulnerability of the Website
            or any network connected to the Website nor breach the security or
            authentication measures on the Website or any network connected to
            the Website. You may not reverse look-up, trace, or seek to trace
            any information on any other User of or visitor to Website, or any
            other customer, including any account on the Website not owned by
            You, to its source, or exploit the Website or any service or
            information made available or offered by or through the Website, in
            any way where the purpose is to reveal any information, including
            but not limited to personal identification or information, other
            than Your own information, as provided for by the Website.
          </Typography>
          <Typography component="p" className={classes.text}>
            We reserve the right to disable your account if you have failed to
            comply with any of the provisions of these terms. We shall have all
            rights to take necessary action and claim damages that may occur due
            to the User’s involvement/participation in any way.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            MONITORING OF SITE CONTENT
          </Typography>
          <Typography component="p" className={classes.text}>
            We are under no obligation to restrict or monitor Site Content in
            any way. YOU UNDERSTAND AND ACKNOWLEDGE THAT WE DO NOT REGULARLY
            MONITOR THE ACCURACY OR RELIABILITY OF CONTENT AND THAT USE OF THE
            SITES IS AT YOUR OWN RISK. Notwithstanding the foregoing, we reserve
            the right to modify or remove any Content at any time. Any opinions,
            advice, statements, services, offers, or other information or
            Content expressed or made available by third parties, including
            other users, are those of the respective author(s) or distributor(s)
            and not ours. We neither endorse nor is responsible for the accuracy
            or reliability of any opinion, advice, information, or statement
            made on these Sites by anyone other than authorized our employees
            acting in their official capacities.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            MONITORING OF SITE CONTENT
          </Typography>
          <Typography component="p" className={classes.text}>
            Our Sites are protected by Indian and international intellectual
            property laws, which you agree to respect. All content included
            within the Site, including but not limited to text, logos, icons,
            images, graphics, audio clips, compilations, and downloads, as well
            as the collection, arrangement, and assembly of such content, are
            our exclusive property and is protected under applicable Copyright
            and Trademark Legislations. All software used on the Sites is our
            exclusive property. You shall not at any time copy, distribute,
            modify or share the Content of this Website for any purpose
            whatsoever without obtaining our prior written permission.
          </Typography>
          <Typography component="h6" className={classes.heading}>
            DEALINGS WITH MERCHANTS or THIRD-PARTY LINKS
          </Typography>
          <Typography component="p" className={classes.text}>
            You acknowledge that the Sites may contain advertisements, offers,
            or other links to other websites and resources of third parties that
            we do not control. You understand that information, as well as
            advertisements, may or may not be or remain wholly accurate. You
            acknowledge and agree that We are not responsible or liable for
          </Typography>
          <Typography component="p" className={classes.text}>
            i) the availability or accuracy of such sites or resources; or
          </Typography>
          <Typography component="p" className={classes.text}>
            ii) the content, advertising, or products or services on or
            available from such sites or resources. The inclusion of any link on
            the Sites does not imply that we endorse the linked site. You use
            the links at your own risk. Our Privacy Policy is applicable only
            when you are on our Sites.
          </Typography>

          <Typography component="p" className={classes.text}>
            Your correspondence or business dealings with, or participation in
            promotions of, third-party merchants or advertisers that are found
            on or through the Sites or which provide links on the Sites,
            including, for example, “click to purchase” and other similar
            programs, including payment and delivery of related goods or
            services, and any other terms, conditions, warranties or
            representations associated with such dealings, are solely between
            you and such merchant or advertiser. You waive any claim against us
            and agree to hold us harmless from any loss or damage of any kind
            incurred as the result of any such dealings or as the result of the
            presence of such merchants or advertisers on the Sites.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            ANTI-DISCRIMINATION AND HATE SPEECH
          </Typography>

          <Typography component="p" className={classes.text}>
            We do not endorse and therefore prohibit the use of our services
            including the website to discriminate against people based on race,
            color, ethnicity, religion, gender, sexual orientation, disability,
            etc. Additionally, we do not endorse or tolerate hate speech against
            any users, sellers, or employees. While you are engaging with us,
            writing reviews, or having direct communications with us, you shall
            not engage in any offensive communication.
          </Typography>
          <Typography component="h6" className={classes.heading}>
            SELLER’S POLICY
          </Typography>
          <Typography component="p" className={classes.text}>
            Stockxbid is a unique market place and as such sellers can list
            products or services that are in accordance with our marketplace
            policy.
          </Typography>
          <Typography component="p" className={classes.text}>
            Without prejudice to the foregoing the sale or listing of the
            following types of items are prohibited on our Website:
          </Typography>
          <Typography component="p" className={classes.text}>
            i) Alcohol, Tobacco, Drugs, Drug Paraphernalia, and Medical Drugs
          </Typography>
          <Typography component="p" className={classes.text}>
            ii) Animal Products and Human Remains
          </Typography>
          <Typography component="p" className={classes.text}>
            iii) Dangerous Items: Hazardous Materials, Recalled Items, and
            Weapons
          </Typography>
          <Typography component="p" className={classes.text}>
            iv) Hate Items: Items that Promote, Support, or Glorify Hatred
          </Typography>
          <Typography component="p" className={classes.text}>
            v) Illegal Items, Items Promoting Illegal Activity, and Highly
            Regulated Items
          </Typography>
          <Typography component="p" className={classes.text}>
            vi) Internationally Regulated Items
          </Typography>
          <Typography component="p" className={classes.text}>
            vii) Pornography and Mature Content
          </Typography>
          <Typography component="p" className={classes.text}>
            viii) Violent Items: Items that Promote, Support, or Glorify
            Violence
          </Typography>

          <Typography component="h6" className={classes.heading}>
            INDEMNIFICATION
          </Typography>
          <Typography component="p" className={classes.text}>
            As a condition of your access to and use of the Sites, you agree to
            hold Us, and its subsidiaries, affiliates, officers, directors,
            employees, agents, attorneys, and suppliers, and each of their
            respective successors and assigns, harmless from, and indemnify them
            for, all damages, costs, expenses and other liabilities, including
            but not limited to attorneys’ fees and expenses, relating to any
            claim arising out of or related to:
          </Typography>

          <div style={{ paddingLeft: "10px" }}>
            <Typography component="p" className={classes.text}>
              i) your access to and use of the Sites and the content therein;
            </Typography>
            <Typography component="p" className={classes.text}>
              ii) your violation of these Terms (including terms incorporated
              into them, e.g., the Privacy Policy and Supplemental Terms), and
              any applicable law or the rights of another person or party;
            </Typography>
            <Typography component="p" className={classes.text}>
              iii) any dispute you have or claim to have with one or more users
              of the Sites;
            </Typography>
            <Typography component="p" className={classes.text}>
              iv) Our resolution (if any) of any dispute you have or claim to
              have with one or more users of the Sites;
            </Typography>
            <Typography component="p" className={classes.text}>
              v) your improper authorization enabling us to collect, use or
              disclose any data or Content provided by you; and
            </Typography>
            <Typography component="p" className={classes.text}>
              vi) any disclosures made with your permission (including, without
              limitation, your consent that we may disclose your personal
              information and other information collected as set forth in our
              Privacy Policy). Furthermore, you fully understand and agree that:
            </Typography>

            <Typography component="p" className={classes.text}>
              a) We will have the right but not the obligation to resolve
              disputes between users relating to the Sites and our resolution of
              a particular dispute does not create an obligation to resolve any
              other dispute, and
            </Typography>
            <Typography component="p" className={classes.text}>
              b) Our resolution of a dispute will be final with respect to the
              Sites.
            </Typography>
          </div>

          <Typography component="h6" className={classes.heading}>
            DISCLAIMER OF WARRANTIES
          </Typography>
          <Typography component="p" className={classes.text}>
            THE SITES MAY CONTAIN ADVICE, OPINIONS, INSTRUCTIONS, AND STATEMENTS
            FROM US, ITS USERS, AND OTHER CONTENT AND INFORMATION PROVIDERS.
            THIS CONTENT IS INTENDED TO BE USED FOR INFORMATIONAL AND
            ENTERTAINMENT PURPOSES ONLY. YOU USE THE SITES AND CONTENT AT YOUR
            OWN RISK. THE SITES ARE PROVIDED BY US ON AN “AS IS” AND “AS
            AVAILABLE” BASIS. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE MAKE NO
            REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF ANY KIND, EXPRESS OR
            IMPLIED, AS TO THE OPERATION OF THE SITES OR THE INFORMATION OR
            CONTENT INCLUDED ON THE SITES. WE MAKE NO REPRESENTATIONS OR
            WARRANTIES THAT THE SITES WILL BE UNINTERRUPTED, ERROR-FREE,
            VIRUS-FREE, SECURE, OR TIMELY. TO THE MAXIMUM EXTENT PERMITTED BY
            LAW, WE EXPRESSLY DISCLAIM ALL REPRESENTATIONS, WARRANTIES, OR
            CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT
            NOT LIMITED TO THE IMPLIED REPRESENTATIONS, WARRANTIES, OR
            CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            TITLE, NON-INFRINGEMENT AND THOSE ARISING FROM A COURSE OF DEALING,
            TRADE, USAGE OR PERFORMANCE.
          </Typography>
          <Typography component="p" className={classes.text}>
            ADVERTISEMENTS ON THE WEBSITE ARE INTENDED, SOLELY TO PROVIDE
            GENERAL INFORMATION FOR THE PERSONAL USE OF THE USER(S). WE DO NOT
            REPRESENT, WARRANT OR ENDORSE IN ANY MANNER THE ACCURACY OR
            RELIABILITY OF ANY ADVERTISEMENTS. WE ACCEPT NO LIABILITY IN
            RELATION TO THE ADVERTISEMENTS.
          </Typography>
          <Typography component="h6" className={classes.heading}>
            LIMITATION OF LIABILITY
          </Typography>
          <Typography component="p" className={classes.text}>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU AGREE THAT NEITHER US,
            NOR OUR SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES,
            AGENTS, ATTORNEYS, AND SUPPLIERS, NOR EACH OF THEIR RESPECTIVE
            SUCCESSORS AND ASSIGNS, WILL BE LIABLE TO YOU AND/OR ANY OTHER
            PERSON FOR DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY,
            SPECIAL OR CONSEQUENTIAL DAMAGES, LOST PROFITS, LOST REVENUE, LOSS
            OF DATA, LOSS OF PRIVACY, LOSS OF GOODWILL OR ANY OTHER LOSSES, EVEN
            IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IN THE EVENT
            OF FAULT, TORT (INCLUDING NEGLIGENCE) OR STRICT OR PRODUCT
            LIABILITY. WITHOUT LIMITING THE FOREGOING, IN NO EVENT WILL OUR
            AGGREGATE LIABILITY TO YOU AND ITS SUBSIDIARIES, AFFILIATES,
            OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, ATTORNEYS, AND SUPPLIERS AND
            EACH OF THEIR RESPECTIVE SUCCESSORS AND ASSIGNS EXCEED, IN TOTAL,
            THE AMOUNTS PAID BY YOU TO US.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            TERMINATION OR CANCELLATION
          </Typography>
          <Typography component="p" className={classes.text}>
            You agree that we may, at any time and at our sole discretion, with
            or without cause or any notice to you, terminate these Terms, your
            access to the Sites, and/or your Account, or suspend or block your
            access to the Sites. You will still be liable for any breaches of
            these Terms and/or obligations incurred before the Terms end. If you
            use the Sites after the termination of these Terms, that use will
            constitute your agreement to the Terms then posted on the Sites.
            Notwithstanding the termination of your account, We shall have the
            right to use the contents posted by You on the Website.
          </Typography>
          <Typography component="h6" className={classes.heading}>
            ELECTRONIC COMMUNICATIONS
          </Typography>

          <Typography component="p" className={classes.text}>
            You agree to receive electronic communication for all of your
            transactions and communication with us and the Sites. You agree that
            all postings, notices, disclosures, or other communications that we
            provide to you electronically satisfy any legal requirements that
            such communications be in writing.
          </Typography>
          <Typography variant="subtitle1" className={classes.subheading}>
            NOTICES AND CONTACT INFORMATION: COPYRIGHT COMPLAINTS:
          </Typography>
          <Typography component="p" className={classes.text}>
            Except as otherwise provided in these Terms, We will give you any
            notices by posting them on the Sites, and you agree that such
            posting will constitute effective notice. You authorize us to send
            via electronic mail as well if we decide, in our discretion, to do
            so.
          </Typography>
          <Typography component="p" className={classes.text}>
            We respect the intellectual property of others. If you believe that
            your work has been copied in a way that constitutes copyright
            infringement, please provide our grievance officer with the written
            information of the same to the following email address:{" "}
            <b>support_help@stockxbid.com</b>.
          </Typography>

          <Typography variant="subtitle1" className={classes.subheading}>
            MODIFICATIONS TO TERMS AND SITES:
          </Typography>
          <Typography component="p" className={classes.text}>
            You agree that from time to time we may, at our sole discretion,
            modify, add or remove any or all parts of these Terms and the
            Privacy Policy. Such modifications will be effective immediately
            upon posting of the modified Terms to the Sites. Your continued use
            of the Sites following the posting of changes to these Terms will
            mean that you accept those changes. We reserve the right from time
            to time to temporarily or permanently modify or discontinue, and
            restrict or block access to, the Sites (or any part thereof) without
            notice.
          </Typography>
          <Typography variant="subtitle1" className={classes.subheading}>
            ADDITIONAL TERMS:
          </Typography>
          <Typography component="p" className={classes.text}>
            a)<b>Severance:</b> If any part of the Terms is held by a court of
            competent jurisdiction to be invalid or unenforceable, the invalid
            or unenforceable part will be given effect to the greatest extent
            possible and the remainder will remain in full force and effect,
            provided that the allocation of risks described herein is given
            effect to the fullest extent possible.
          </Typography>

          <Typography component="p" className={classes.text}>
            b) <b>No Assignment:</b> These Terms (including terms incorporated
            into them, e.g., the Privacy Policy) are personal to you and you may
            not transfer, assign or delegate them to anyone without our express
            written permission. Any attempt by you to assign, transfer or
            delegate these Terms without our express written permission will be
            null and void. We shall have the right to transfer, assign and/or
            delegate these Terms to one or more third parties without your
            permission.
          </Typography>

          <Typography component="p" className={classes.text}>
            c) <b>Jurisdiction:</b> The Sites are controlled by us from our
            offices within India. If you choose to access this Site from
            locations outside India, you do so at your own risk and you are
            responsible for compliance with applicable local laws. You may not
            use or export anything from the Sites in violation of Indian laws
            and regulations or the Terms. These Terms and all performances and
            claims of every nature (including without limitation, contract, tort
            and strict liability) relating in any way to any aspect of the Sites
            will be governed by the laws of India, without regard to any
            conflicts of laws principles that would result in the application of
            the law of a different jurisdiction.
          </Typography>

          <Typography component="p" className={classes.text}>
            d) <b>Limitations on Actions:</b> Any action concerning any dispute
            you may have with respect to the Sites must be commenced within one
            year after the cause of the dispute arises, or the cause of action
            is barred.
          </Typography>
          <Typography component="p" className={classes.text}>
            f) <b>Entire Agreement:</b> These Terms (including terms
            incorporated into them, e.g., the Privacy Policy) comprise the
            entire agreement (the “Entire Agreement”) between you and us with
            respect to the use of the Sites and supersedes all contemporaneous
            and prior agreements between the parties regarding the subject
            matter contained herein, and neither party has relied on any
            representations made by the other that are not expressly set forth
            in the Entire Agreement.
          </Typography>

          <Typography component="p" className={classes.text}>
            g) <b>No Waiver:</b> Our failure to exercise or enforce any right or
            provision of these Terms, including any failure to act with respect
            to a breach, will not constitute a waiver of such right or provision
            or our right to act with respect to subsequent or similar breaches.
            We suggest that you print out a copy of these Terms for your
            records.
          </Typography>

          <Typography variant="subtitle1" className={classes.subheading}>
            PROCEDURE FOR CLAIMS OF COPYRIGHT INFRINGEMENT:
          </Typography>
          <Typography component="p" className={classes.text}>
            If you believe that your work has been copied in a way that
            constitutes copyright or IP infringement, please provide our
            grievance officer with the following information:
          </Typography>

          <div style={{ paddingLeft: "10px" }}>
            <Typography component="p" className={classes.text}>
              i) An electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright interest;
            </Typography>
            <Typography component="p" className={classes.text}>
              ii) A description of the copyrighted work that you claim has been
              infringed upon;
            </Typography>
            <Typography component="p" className={classes.text}>
              iii) A description of where the material that you claim is
              infringing is located on the site;
            </Typography>
            <Typography component="p" className={classes.text}>
              iv) Your address, telephone number, and e-mail address;
            </Typography>
            <Typography component="p" className={classes.text}>
              v) A statement by you that you have a good-faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law;
            </Typography>
            <Typography component="p" className={classes.text}>
              vi) A statement by you, made under penalty of perjury, that the
              above information in your notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner’s
              behalf.
            </Typography>
          </div>

          <Typography component="p" className={classes.text}>
            Our grievance officer can be reached at{" "}
            <b>
              Rohan Dhiman Grievance Officer, Address: 29/1, First Floor, 6th
              Cross Rd, Sangeetha Apts, Swimming Pool Extension, Malleshwaram
              11th Cross, Bengaluru, Karnataka 560003, Ph: +918708885991. Email:
              Rohan.dhiman@stockxbid.com Time: Mon - Sat (10:00 AM - 07:00 PM)
            </b>
            .
          </Typography>
        </Grid>
      </Container>
    </div>
  );
};
