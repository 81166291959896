import React, { useState , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

//React components
import { VerifyOtp } from "./VerifyOtp";

//redux Actions
import {
  changeMobileNumber,
  getOTP,
} from "../../../redux/actions/otherActions";

// material ui
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import PersonIcon from "@material-ui/icons/Person";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import LinkIcon from "@material-ui/icons/Link";
import LockIcon from "@material-ui/icons/Lock";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ErrorIcon from "@material-ui/icons/Error";
import Box from "@material-ui/core/Box";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { ActionTypes } from './../../../redux/constants/actionTypes';

const useStyles = makeStyles((theme) =>
  createStyles({
    contactroot: {
      "& .MuiFormControlLabel-label": {
        fontSize: 13,
        fontFamily: "'Fira Sans', sans-serif",
        color: "#4A4A4A",
      },
      "& .MuiOutlinedInput-inputAdornedStart": {
        paddingLeft: 4,
        backgroundColor: "#fff",
        borderLeft: "1px solid pink",
        color: "#000000",
      },
      "& .MuiOutlinedInput-inputAdornedEnd": {
        backgroundColor: "#fff",
        borderRight: "1px solid pink",
      },
      "& .MuiFormHelperText-root": {
        color: "red",
        fontFamily: "'Fira Sans', sans-serif",
        textAlign: "center",
      },
      "& .MuiInputAdornment-positionStart": {
        color: "#000000",
      },
    },
    title: {
      fontWeight: "bold",
      color: "#53e7bc",
      textDecoration: "underline",
    },
    lable: {
      textAlign: "start",
      margin: "0px 10px",
      fontSize: 12,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#000000",
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    backbutton: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      "&:hover": {
        backgroundColor: "#53e7bc",
        color: "#fff",
      },
    },
    labelRoot: {
      fontSize: "14px",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#666666",
    },
    mobilenumber: {
      "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          display: "none",
        },
    },
  })
);

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);
const mobileRegex = RegExp(/^[6789]\d{9}$/);
const ALPHA_ONLY = /^[a-zA-Z\s]+$/;
const NUMERIC_ONLY =/^[0-9]+$/;

export const ContactInformation = (props) => {
  const classes = useStyles();
  const { handleBack, initialValues, handleInputChange } = props;
  const { validNumber, validMessage, invalidNumber, invalidMessage } =
    useSelector((state) => state.Others);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const [duplicatePhone, setDuplicatePhone] = useState(null);
  const [duplicateEmail, setDuplicateEmail] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const hadlecheckDuplicatePhoneNumber = async () => {
    const verifierDto = {
      details: initialValues.mobileNumber,
      type: "PHONE",
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/verify/user/detail`,
      verifierDto
    );

    await setDuplicatePhone(data);
    return data;
  };

  const verifyMobileNumber = async (mobileNumber) => {
    if (!mobileNumber || !mobileRegex.test(mobileNumber)) {
      setDuplicatePhone(null)
      let errors = {};
      errors["mobileNumber"] = "Please enter valid mobile number.";
      setErrors(errors);
    } else {
      const phonenumberExist = await hadlecheckDuplicatePhoneNumber();

      if (!phonenumberExist) {
        errors["mobileNumber"] = "";
        setErrors(errors);
        const phoneNumber = mobileNumber;
        dispatch(getOTP(phoneNumber));
        setOpen(true);
      }
    }
  };


  useEffect(() => {
    dispatch({ type: ActionTypes.CLEAR_OTP_MESSAGE });
  }, []); 

  const handleChangeNumber = () => {
    dispatch(changeMobileNumber());
  };

  const inputValidation = () => {
    let errors = {};
    let inputsValid = true;

    if (!initialValues.firstName) {
      inputsValid = false;
      errors["firstName"] = "Please enter your first name.";
    }

    if (!initialValues.lastname) {
      inputsValid = false;
      errors["lastname"] = "Please enter your last name.";
    }

    if (!initialValues.mobileNumber) {
      inputsValid = false;
      errors["mobileNumber"] = "Please enter your mobile number.";
    }

    if (
      initialValues.mobileNumber &&
      !mobileRegex.test(initialValues.mobileNumber)
    ) {
      inputsValid = false;
      errors["mobileNumber"] = "Please enter valid mobile number.";
    }

    if (
      initialValues.mobileNumber &&
      mobileRegex.test(initialValues.mobileNumber) &&
      !validNumber
    ) {
      inputsValid = false;
      errors["mobileNumber"] = "Please verify mobile number.";
    }

    if (!initialValues.email) {
      inputsValid = false;
      errors["email"] = "Please enter your email-id.";
    }

    if (initialValues.email && !emailRegex.test(initialValues.email)) {
      inputsValid = false;
      errors["email"] = "Please enter valid email address.";
    }

    if (initialValues.password === "") {
      inputsValid = false;
      errors["password"] = "Please enter your Password.";
    }
    if (initialValues.password.length < 8) {
      inputsValid = false;
      errors["password"] = "Password should be minimum 8 characters.";
    }

    if (!initialValues.confirmPassword) {
      inputsValid = false;
      errors["confirmPassword"] = "Please enter your confirm password.";
    }

    if (
      typeof initialValues.password !== "undefined" &&
      typeof initialValues.confirmPassword !== "undefined"
    ) {
      if (initialValues.password.length >= 8 && initialValues.password !== initialValues.confirmPassword) {
        inputsValid = false;
        errors["password"] = "Passwords don't match.";
      }
    }

    setErrors(errors);
    return inputsValid;
  };

  const handleNext = () => {
    if (inputValidation()) {
      props.handleNext();
    }
  };

  const handleDuplictaeEmail = async () => {
    const verifierDto = {
      details: initialValues.email,
      type: "EMAIL",
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/verify/user/detail`,
      verifierDto
    );
    setDuplicateEmail(data);
  };

  return (
    <div className={classes.contactroot}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>
            Contact Information
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                First Name{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>
              <TextField
                id="firstName"
                name="firstName"
                variant="outlined"
                size="small"
                value={initialValues.firstName}
                onChange={
                  (event) => {
                    const value = event.target.value;
                    if (value !== "" && !ALPHA_ONLY.test(value)) {
                      return;
                    } 
                  handleInputChange(event)
                  }
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                helperText={errors.firstName ? errors.firstName : ""}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                Last Name{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>
              <TextField
                id="lastname"
                name="lastname"
                variant="outlined"
                size="small"
                value={initialValues.lastname}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value !== "" && !ALPHA_ONLY.test(value)) {
                    return;
                  } 
                handleInputChange(event)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                helperText={errors.lastname ? errors.lastname : ""}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                Mobile Number{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>
              <TextField
                id="mobileNumber"
                name="mobileNumber"
                variant="outlined"
                type="text"
                size="small"
                disabled={validNumber ? true : false}
                value={initialValues.mobileNumber}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value !== "" && !NUMERIC_ONLY.test(value)) {
                    return;
                  } 
                handleInputChange(event)
                }}
                className={classes.mobilenumber}
                inputProps={{ maxLength: 10, minLength: 10 }}
                InputProps={
                  validNumber
                    ? {
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneAndroidIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleChangeNumber}
                              edge="end"
                              style={{
                                fontSize: 12,
                                fontFamily: "'Fira Sans', sans-serif",
                                color: "#008000",
                              }}
                            >
                              Change
                            </IconButton>
                          </InputAdornment>
                        ),
                        classes: {
                          root: classes.labelRoot,
                        },
                      }
                    : {
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneAndroidIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                verifyMobileNumber(initialValues.mobileNumber)
                              }
                              edge="end"
                              style={{
                                fontSize: 12,
                                fontFamily: "'Fira Sans', sans-serif",
                                color: "#008000",
                              }}
                            >
                              Get OTP
                            </IconButton>
                          </InputAdornment>
                        ),
                        classes: {
                          root: classes.labelRoot,
                        },
                      }
                }
                helperText={
                  duplicatePhone ? (
                    "Phone number already exist."
                  ) : errors.mobileNumber ? (
                    errors.mobileNumber
                  ) : validNumber ? (
                    <Typography
                      component={"span"}
                      style={{
                        fontSize: "14px",
                        color: "green",
                      }}
                    >
                      <DoneAllIcon size="small" style={{ fontSize: "18px" }} />{" "}
                      {validMessage}
                    </Typography>
                  ) : invalidNumber ? (
                    <Typography component={"span"} style={{ fontSize: "14px" }}>
                      <ErrorIcon size="small" style={{ fontSize: "18px" }} />{" "}
                      {invalidMessage}
                    </Typography>
                  ) : (
                    ""
                  )
                }
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                Alternate Contact Number
              </InputLabel>
              <TextField
                id="alternateNumber"
                name="alternateNumber"
                variant="outlined"
                size="small"
                value={initialValues.alternateNumber}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value !== "" && !NUMERIC_ONLY.test(value)) {
                    return;
                  } 
                handleInputChange(event)
                }}
                inputProps={{ maxLength: 10 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CallIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                Email-ID{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>
              <TextField
                id="email"
                name="email"
                variant="outlined"
                size="small"
                value={initialValues.email}
                onChange={handleInputChange}
                onBlur={handleDuplictaeEmail}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                helperText={
                  duplicateEmail
                    ? "Email is already exist."
                    : errors.email
                    ? errors.email
                    : ""
                }
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <InputLabel className={classes.lable}>Website Url</InputLabel>
              <TextField
                id="websiteUrl"
                name="websiteUrl"
                variant="outlined"
                size="small"
                value={initialValues.websiteUrl}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                Password{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>
              <TextField
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                size="small"
                value={initialValues.password}
                onChange={handleInputChange}
                inputProps = {{ minLength: 8 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                helperText={errors.password ? errors.password : ""}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <InputLabel className={classes.lable}>
                Confirm Password{" "}
                <Box component="span" color="red">
                  *
                </Box>
              </InputLabel>
              <TextField
                id="confirmPassword"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                variant="outlined"
                size="small"
                value={initialValues.confirmPassword}
                onChange={handleInputChange}
                inputProps = {{ minLength: 8 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                helperText={
                  errors.confirmPassword ? errors.confirmPassword : ""
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className={classes.buttons}>
        <Button onClick={handleBack} className={classes.button}>
          Back
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleNext}
          className={classes.backbutton}
        >
          Next
        </Button>
      </div>

      <VerifyOtp
        open={open}
        handleClose={handleClose}
        mobileNumber={initialValues.mobileNumber}
      />
    </div>
  );
};
