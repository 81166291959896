import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";

//react components
import {SignupAlert} from "../signupForm/SignupAlert";

// redux actions
import {closeSignupAlert, getTermsCondition,} from "../../../redux/actions/otherActions";

// material ui
import {createStyles, makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import {showPolicyModal} from "../../../redux/actions/PolicyModalAction";
import {PoliciesModal} from "./PoliciesModal";
import {Box} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import {ActionTypes} from "../../../redux/constants/actionTypes";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontWeight: "bold",
      color: "#53e7bc",
      textDecoration: "underline",
    },
    listroot: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      position: "relative",
      overflow: "auto",
      maxHeight: 300,
      "& .MuiListItemText-secondary": {
        fontSize: 13,
        fontWeight: 500,
        fontFamily: "'Fira Sans', sans-serif",
        textAlign: "center",
        margin: "auto",
      },
    },
    listSection: {
      backgroundColor: "inherit",
    },
    ul: {
      backgroundColor: "aliceblue",
      padding: 0,
      margin: 6,
        '&:hover': {
            cursor: "pointer",
            backgroundColor: "#53e7bc",
        }
    },
    subheader: {
      fontSize: 16,
      fontWeight: "bold",
      fontFamily: "'Fira Sans', sans-serif",
      color: "#008000",
        '&:hover': {
            cursor: "pointer",
            backgroundColor: "#53e7bc",
        }
    },
    policytext: {
      fontSize: 13,
      fontWeight: 500,
      fontFamily: "'Fira Sans', sans-serif",
    },
    checkboxlable: {
      "& .MuiFormControlLabel-label": {
        fontSize: 14,
        fontWeight: 500,
        fontFamily: "'Fira Sans', sans-serif",
        color: "#212529",
      },
    },
    helpertext: {
      fontSize: 12,
      fontFamily: "'Fira Sans', sans-serif",
      color: "red",
      textAlign: "center",
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
      "& .MuiButton-contained.Mui-disabled": {
        color: "#fff",
        backgroundColor: "#53e7bc",
      },
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    submitbutton: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      "&:hover": {
        backgroundColor: "#53e7bc",
        color: "#fff",
      },
    },
    lodingbutton: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      "& .MuiButton-label": {
        margin: "0px 8px 0px 8px",
      },
    },
    circle: {
      strokeLinecap: "round",
    },
    top: {
      color: "#000000",
      animationDuration: "800ms",
      position: "absolute",
      left: 4,
    },
    policyClickedShow : {
        display : "block"
    },
    policyClickedHide : {
          display : "none"
    }
  })
);

export const TermsConditions = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { initialValues, handleBack, handleSignUp, clearInputs } = props;
  const { loading, error, serverError } = useSelector((state) => state.UI);
  const { termsConditions, openModel , clickedPolicy} = useSelector((state) => state.Others);
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [triggerValidation, setTriggerValidation] = useState(false);
  const [interactedPolicies, setInteractedPolicies] = useState({});
  const [checkboxInteracted, setCheckboxInteracted] = useState(false);

  const handleChangePolicies = () => {
    setChecked(!checked);
    setTriggerValidation(true);
    setCheckboxInteracted(true);
  };

  const inputValidation = () => {
    let errors = {};
    let inputsValid = true;

      Object.keys(clickedPolicy).forEach((key)  => {
        if (clickedPolicy[key] === false) {
          inputsValid = false;
          if (interactedPolicies[key]) {
            errors[key] = "Please read this terms and conditions.";
          }
        }
  
      });

      if (!checked) {
        inputsValid = false;
        if (checkboxInteracted) {
          errors["policy"] = "Please agree to terms and conditions.";
        }
      }

    setErrors(errors);
    setIsValid(inputsValid);
    return inputsValid;
  };

  const handleSubmit = () => {
    if (inputValidation()) {
      handleSignUp();
    }
  };

  const handleCloseAlert = () => {
    dispatch(closeSignupAlert(history));
    clearInputs();
  };

  const handleShowPolicies = (policy , index) => {
    // window.open(url, "_blank");
      // console.log("opened");
      dispatch(showPolicyModal(policy.policyHeading));

      const keyVal = policy.policyHeading;

      dispatch({ type: ActionTypes.UPDATE_CLICKED_POLICY, payload: {
              ...clickedPolicy,
              [keyVal]: true,
          }});

       setInteractedPolicies({
            ...interactedPolicies,
            [keyVal]: true,
          });
      setTriggerValidation(true);
      // console.log(clickedPolicy);
  };

  useEffect(() => {
    dispatch(getTermsCondition(initialValues.userType));
  }, [dispatch, initialValues.userType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (triggerValidation) {
      inputValidation();
    }
  }, [checked, clickedPolicy, triggerValidation]);


  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>
            Terms and Conditions
          </Typography>
        </Grid>

        {termsConditions && termsConditions.length > 0 && (
          <Grid item xs={12}>
            <List className={classes.listroot} subheader={<li />}>
              {termsConditions.map((policy, index) => (
                <li key={index} className={classes.listSection}>
                  <ul 
                    className={classes.ul} 
                    style={{ border: errors[policy.policyHeading] && !clickedPolicy[policy.policyHeading] ? "2px solid red" : "" }}
                    onClick={() => handleShowPolicies(policy, index)}
                  >
                    <Box display='inline-flex' alignItems='center'>
                      <ListSubheader className={classes.subheader}>
                        {policy.policyHeading}
                      </ListSubheader>
                      <CheckIcon
                        style={{ display: clickedPolicy[policy.policyHeading] ? 'block' : 'none' }}
                        color="action"
                      />
                    </Box>
                    <p style={{ color: "red" }}>
                      {errors[policy.policyHeading] && !clickedPolicy[policy.policyHeading] ? errors[policy.policyHeading] : ""}
                    </p>
                  </ul>
                </li>
              ))}
            </List>
          </Grid>
        )}

        <Grid item xs={12}>
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                color="primary"
                checked={checked}
                onChange={handleChangePolicies}
              />
            }
            label="I agree to the terms"
            labelPlacement="end"
            className={classes.checkboxlable}
          />
          {errors.policy ? (
            <FormHelperText
              id="component-helper-text"
              className={classes.helpertext}
            >
              {!checked && errors.policy}
            </FormHelperText>
          ) : error ? (
            <FormHelperText
              id="component-helper-text"
              className={classes.helpertext}
            >
              {error}
            </FormHelperText>
          ) : serverError ? (
            <FormHelperText
              id="component-helper-text"
              className={classes.helpertext}
            >
              {serverError}
            </FormHelperText>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      <div className={classes.buttons}>
        {!loading ? (
          <Fragment>
            <Button onClick={handleBack} className={classes.button}>
              Back
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              className={classes.submitbutton}
              disabled={!isValid}
              style={{ backgroundColor: !isValid ? 'lightgrey' : undefined }}
            >
              Sign up
            </Button>
          </Fragment>
        ) : (
          <Button variant="contained" className={classes.lodingbutton} disabled>
            <CircularProgress
              variant="indeterminate"
              size={15}
              disableShrink
              className={classes.top}
              classes={{ circle: classes.circle }}
            />
            Signing up...
          </Button>
        )}
      </div>

      <SignupAlert open={openModel} handleClose={handleCloseAlert} />
      <PoliciesModal />
    </Fragment>
  );
};