import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

//redux action
import { updateUserBankDetails } from "../../../redux/actions/sellerActions";

//material ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  rootdiv: {
    "& .MuiFormControlLabel-label": {
      fontSize: 13,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#4A4A4A",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
      textAlign: "center",
    },
    "& .MuiButtonBase-root": {
      outline: "none",
    },
  },
  lable: {
    textAlign: "start",
    margin: "10px 0px",
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#000000",
    fontWeight: 600,
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
    color: "#666666",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  backbutton: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: "#53e7bc",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#53e7bc",
      color: "#fff",
    },
  },
  details: {
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#000000",
  },
}));

const ifscRegEx = RegExp(/^[A-Za-z]{4}\d{7}$/);
const mobileRegex = RegExp(/^[6789]\d{9}$/);
const ALPHA_ONLY = /^[a-zA-Z\s]+$/;
const AlPHANUMERIC_ONLY = /^[ A-Za-z0-9_@./#&+-]+$/;
const NUMERIC_ONLY = /^[0-9]+$/;
export const EditBankDetails = (props) => {
  const classes = useStyles();
  const { sellerProfile, userId } = props;
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode((mode) => !mode);
    setFormErrors({});
  };

  useEffect(() => {
    if (Object.keys(sellerProfile).length !== 0) {
      setBankName(sellerProfile.bankDetails.bankName);
      setBranchName(sellerProfile.bankDetails.branchName);
      setBranchCode(
        sellerProfile.bankDetails.branchCode
          ? sellerProfile.bankDetails.branchCode
          : ""
      );
      setIfsc(sellerProfile.bankDetails.ifsc);
      setAccountNumber(sellerProfile.bankDetails.accountNumber);
      setMicrNumber(sellerProfile.bankDetails.micrNumber);
      setPhoneNumber(sellerProfile.bankDetails.phoneNumber);
      setBankId(sellerProfile.bankDetails.id);
    }
  }, [sellerProfile, editMode]);

  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [micrNumber, setMicrNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bankId, setBankId] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const inputValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (!bankName) {
      inputsValid = false;
      formErrors["bankName"] = "Please enter your Bank name.";
    }

    if (!branchName) {
      inputsValid = false;
      formErrors["branchName"] = "Please enter your Bank branch name.";
    }

    if (!micrNumber || micrNumber.length < 9) {
      inputsValid = false;
      formErrors["micrNumber"] = "Please enter your Micr NO. (9 characters)";
    }

    if (!ifsc) {
      inputsValid = false;
      formErrors["ifscCode"] = "Please enter your IFSC code.";
    }

    if (!ifscRegEx.test(ifsc)) {
      inputsValid = false;
      formErrors["ifscCode"] = "Please enter valid IFSC code.";
    }

    if (!accountNumber) {
      inputsValid = false;
      formErrors["accountNumber"] = "Please enter your Account number.";
    }

    if (!phoneNumber) {
      inputsValid = false;
      formErrors["phoneNumber"] = "Please enter your Mobile number.";
    }

    if (!mobileRegex.test(phoneNumber)) {
      inputsValid = false;
      formErrors["phoneNumber"] = "Please enter valid Mobile number.";
    }

    setFormErrors(formErrors);
    return inputsValid;
  };

  const updateBankDetails = () => {
    if (inputValidation()) {
      const userBankDetails = {
        accountNumber: accountNumber,
        bankName: bankName,
        branchCode: branchCode,
        branchName: branchName,
        detailsExist: true,
        id: bankId,
        ifsc: ifsc,
        micrNumber: micrNumber,
        phoneNumber: phoneNumber,
      };

      dispatch(updateUserBankDetails(userBankDetails, bankId, userId));
      toggleEditMode();
    }
  };

  return (
    <Fragment>
      {Object.keys(sellerProfile).length > 0 && (
        <div className={classes.rootdiv}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <InputLabel className={classes.lable}>Bank Name*</InputLabel>
                  {!editMode && (
                    <Typography className={classes.details}>
                      {bankName}
                    </Typography>
                  )}

                  {editMode && (
                    <TextField
                      id="bankName"
                      name="bankName"
                      variant="outlined"
                      size="small"
                      value={bankName}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !ALPHA_ONLY.test(value)) {
                          return;
                        }
                        setBankName(e.target.value);
                      }}
                      InputProps={{
                        autoComplete: "off",
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.bankName ? formErrors.bankName : ""
                      }
                    />
                  )}
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <InputLabel className={classes.lable}>
                    Branch Name*
                  </InputLabel>
                  {!editMode && (
                    <Typography className={classes.details}>
                      {branchName}
                    </Typography>
                  )}

                  {editMode && (
                    <TextField
                      id="branchName"
                      name="branchName"
                      variant="outlined"
                      size="small"
                      value={branchName}
                      onChange={(e) => setBranchName(e.target.value)}
                      InputProps={{
                        autoComplete: "off",
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.branchName ? formErrors.branchName : ""
                      }
                    />
                  )}
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <InputLabel className={classes.lable}>Branch Code</InputLabel>
                  {!editMode && branchCode ? (
                    <Typography className={classes.details}>
                      {branchCode}
                    </Typography>
                  ) : (
                    ""
                  )}

                  {editMode && (
                    <TextField
                      id="branchCode"
                      name="branchCode"
                      variant="outlined"
                      size="small"
                      value={branchCode ? branchCode : ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !NUMERIC_ONLY.test(value)) {
                          return;
                        }
                        setBranchCode(e.target.value);
                      }}
                      inputProps={{
                        maxLength: 6,
                      }}
                      InputProps={{
                        autoComplete: "off",
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <InputLabel className={classes.lable}>IFSC*</InputLabel>
                  {!editMode && (
                    <Typography className={classes.details}>
                      {ifsc.toUpperCase()}
                    </Typography>
                  )}

                  {editMode && (
                    <TextField
                      id="ifscCode"
                      name="ifscCode"
                      variant="outlined"
                      size="small"
                      style={{ textTransform: "none" }}
                      value={ifsc}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !AlPHANUMERIC_ONLY.test(value)) {
                          return;
                        }
                        setIfsc(e.target.value.toUpperCase());
                      }}
                      InputProps={{
                        autoComplete: "off",
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.ifscCode ? formErrors.ifscCode : ""
                      }
                    />
                  )}
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <InputLabel className={classes.lable}>
                    Account number*
                  </InputLabel>
                  {!editMode && (
                    <Typography className={classes.details}>
                      {accountNumber}
                    </Typography>
                  )}

                  {editMode && (
                    <TextField
                      id="accountNumber"
                      name="accountNumber"
                      variant="outlined"
                      size="small"
                      value={accountNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !NUMERIC_ONLY.test(value)) {
                          return;
                        }
                        setAccountNumber(e.target.value);
                      }}
                      inputProps={{
                        minLength: 6,
                        maxLength: 18,
                      }}
                      InputProps={{
                        autoComplete: "off",
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.accountNumber ? formErrors.accountNumber : ""
                      }
                    />
                  )}
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <InputLabel className={classes.lable}>Micr NO.*</InputLabel>
                  {!editMode && (
                    <Typography className={classes.details}>
                      {micrNumber.toUpperCase()}
                    </Typography>
                  )}

                  {editMode && (
                    <TextField
                      id="micrNumber"
                      name="micrNumber"
                      variant="outlined"
                      size="small"
                      value={micrNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "" && !AlPHANUMERIC_ONLY.test(value)) {
                          return;
                        }
                        setMicrNumber(e.target.value.toUpperCase());
                      }}
                      inputProps={{
                        maxLength: 9,
                      }}
                      InputProps={{
                        autoComplete: "off",
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.micrNumber ? formErrors.micrNumber : ""
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel className={classes.lable}>Mobile No*.</InputLabel>
              {!editMode && (
                <Typography className={classes.details}>
                  {phoneNumber}
                </Typography>
              )}

              {editMode && (
                <TextField
                  id="phoneNumber"
                  name="phoneNumber"
                  variant="outlined"
                  size="small"
                  value={phoneNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "" && !NUMERIC_ONLY.test(value)) {
                      return;
                    }
                    setPhoneNumber(e.target.value);
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputProps={{
                    autoComplete: "off",
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={
                    formErrors.phoneNumber ? formErrors.phoneNumber : ""
                  }
                />
              )}
            </Grid>
          </Grid>

          {!editMode && (
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                className={classes.backbutton}
                onClick={toggleEditMode}
              >
                Edit
              </Button>
            </div>
          )}

          {editMode && (
            <div className={classes.buttons}>
              <Button className={classes.button} onClick={toggleEditMode}>
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={classes.backbutton}
                onClick={updateBankDetails}
              >
                Update
              </Button>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};
