import React, { useState } from "react";

/* import react components */
import { AppBars } from "../../components/AppBar";
// import { CategoryList } from "../../components/CategoryList";
import { ChangePassword } from "../../components/customer/settings/ChangePassword";
import { MyProfile } from "../../components/customer/settings/MyProfile";

/* import material ui */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import MenuHeader from "../../components/MenuHeader";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  settingroot: {
    flexGrow: 1,
  },
  portfoliomenus: {
    "& .MuiPaper-root .MuiButtonBase-root": {
      outline: "none",
      textTransform: "capitalize",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 600,
      letterSpacing: "0.19px",
      lineHeight: 1.38,
    },
  },
  portfolioappbar: {
    color: "inherit",
    background: "transparent",
    boxShadow: "none",
    borderBottom: "1px solid #E9E9E9",
    marginTop: "75px",
    "& .MuiTab-wrapper": {
      flexDirection: "initial",
    },
    "& .Mui-selected": {
      color: "#27B23E",
    },
  },
  indicator: {
    backgroundColor: "#27B23E",
  },
}));

export const Settings = () => {
  const classes = useStyles();

  const [value, setValue] = useState("1");

  const handleChangeActiveTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.settingroot}>
      <AppBars />
      {/* <CategoryList /> */}
      <MenuHeader />

      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.portfoliomenus}>
              <TabContext value={value}>
                <AppBar position="static" className={classes.portfolioappbar}>
                  <TabList
                    onChange={handleChangeActiveTab}
                    aria-label="simple tabs example"
                    classes={{
                      indicator: classes.indicator,
                    }}
                  >
                    <Tab label="Change Password" value="1" />

                    <Tab label="My Profile" value="2" />
                  </TabList>
                </AppBar>

                <TabPanel value="1">
                  <ChangePassword />
                </TabPanel>

                <TabPanel value="2">
                  <MyProfile />
                </TabPanel>
              </TabContext>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
