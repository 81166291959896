import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  loading: false,
  serverError: false,
  error: null,
  wishlist: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOADING_WISHLIST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_CUSTOMER_WISHLIST:
      return {
        ...state,
        loading: false,
        wishlist: action.payload.products,
      };
    case ActionTypes.CLEAR_WISHLIST_ERRORS:
      return {
        ...state,
        loading: false,
        serverError: false,
        error: null,
      };
    case ActionTypes.DELETE_WISHLIST_PRODUCT:
      return {
        ...state,
        wishlist: state.wishlist.filter((item) => item.id !== action.payload),
      };
    case ActionTypes.ADD_PRODUCT_WISHLIST:
      return {
        ...state,
        wishlist: action.payload.products,
      };
    default:
      return state;
  }
}
