export const PrivacyPolicyDoc = `<h1 style='margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:21px;font-family:"Cambria",serif;color:#365F91;font-weight:normal;'>&nbsp;</h1>
<p style='margin-top:0in;margin-right:0in;margin-bottom:9.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;background:white;vertical-align:baseline;'><strong><u><span style='font-family:"Palatino Linotype",serif;color:black;'>PRIVACY POLICY</span></u></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>Please read this Privacy Policy carefully. This contains important information about your rights and obligations. This Privacy Policy sets out the manner in which Stockxbid(&ldquo;We&rdquo;, &ldquo;Us&rdquo; and &ldquo;Our&rdquo;) collects, uses, maintains and discloses information collected from Users (each, a &ldquo;User&rdquo; also referred to as &ldquo;You&rdquo; and &ldquo;Yours&rdquo;) of the Website&nbsp;</span><span style="background:yellow;">___________________</span><span style='font-family:"Palatino Linotype",serif;'>(&ldquo;Website&rdquo;).</span><span style='font-family:"Palatino Linotype",serif;'>&nbsp;This Privacy Policy applies to the Website&nbsp;and all services offered by Us through the Website. By accessing and using the&nbsp;Website, You are consenting to the collection, transfer, storage, disclosure and other uses of your information as set out in this Privacy Policy. If you do not agree to the Privacy Policy please do not avail, use, or register with the&nbsp;Website. We shall not use your Information except in the manner provided under this Privacy Policy.&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>We may revise the Privacy Policy at any time by updating this page. Your continued use of the Website&nbsp;indicates your agreement to be bound by any such changes. You can view our updated Privacy Policy by visiting <span style="background:yellow;">here</span>:</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-family:"Palatino Linotype",serif;color:black;'>1. INFORMATION WE MAY COLLECT FROM YOU:</span></u></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-family:"Palatino Linotype",serif;color:black;'><span style="text-decoration: none;">&nbsp;</span></span></u></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-family:"Palatino Linotype",serif;color:black;'>Personal identification information&nbsp;</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.25in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Palatino Linotype",serif;color:black;'>&nbsp;</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;margin:0in;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;color:black;'>We may collect Your personal information either directly from You while You use, register, or access our&nbsp;</span><span style='font-family:"Palatino Linotype",serif;'>Website and provide it to us or automatically as you navigate through our services or from any other source of information including third party srouces including our delivery partners, as and when it applies</span><span style='font-family:"Palatino Linotype",serif;color:black;'>. &nbsp;We may collect your personal identification information including but not limited to name, email id, phone number, residential address, gender, credit/debit card information, marital status, anniversary date, PAN Card, Adhar Card, &nbsp;<span style="background:yellow;">_________</span> and any other information that You may enter while registering or otherwise using the</span><span style='font-family:"Palatino Linotype",serif;'>&nbsp;Website</span><span style='font-family:"Palatino Linotype",serif;color:black;'>. While information such as date of birth in isolation may not be enough to uniquely identify You, a combination of these information may be sufficient to do so. &nbsp;You may optionally provide us with this information through third party sign in service such as Facebook or Google. In such cases we fetch and store whatever information is made available to use through these sign-in services.&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;margin:0in;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;margin:0in;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;color:black;'>While browsing the Website You are not required to provide personal information as set out herein and this may be required only if the user choses to avail or sign up on our website. However, we do not guarantee that you would be able to enjoy our full services without registering with our Website. All the information provided by You including personal information is voluntary. You may modify, correct, delete, or eliminate the personal information at any time. Any grievances related to the information shared by You may be brought to the attention of the grievance officer.&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;margin:0in;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;margin:0in;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;color:black;'>For the use or services and purchase of products, you may be required to pay us with a credit card, wire-transfer or debit card through third party payment gateway provider and such third party payment gateway may be required to collect certain financial information including, but not restricted to your credit/debit card number or your bank account details. All such financial information will be used only for billing and payment processes. The verification of such information shall be accomplished only by You through a process of authentication in which we have not role to play.&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;margin:0in;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;color:black;'>&nbsp; &nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;margin:0in;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;color:black;'>We may keep records of telephone calls received from and made to users for the purpose of administration of services, research and development, training, business intelligence, business development, or for user administration. We may share these records when required by law or when required to provide or facilitate you with services.&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:.5in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;margin:0in;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-family:"Palatino Linotype",serif;'>Non-personal identification information</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>We may collect non-personal information about You whenever You access or interact with the</span><span style='font-family:"Palatino Linotype",serif;'>&nbsp;Website</span><span style='font-family:"Palatino Linotype",serif;'>. Non-personal identification information may include without limitation device name, version, location information, Gps information , server location, operating system and version, your mobile devices unique device ID, third party apps or website or service that referred users to the</span><span style='font-family:"Palatino Linotype",serif;'>&nbsp;Website</span><span style='font-family:"Palatino Linotype",serif;'>, language preference, timezone, browisng information including details of how long you used our services, search terms you have looked, transaction information, means of connection to the</span><span style='font-family:"Palatino Linotype",serif;'>&nbsp;Website</span><span style='font-family:"Palatino Linotype",serif;'>, IP address, technical information, and other similar information about users.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;margin:0in;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;color:black;'>Due to the communication standards on the internet when You visit our Website, the Website may automatically receive the uniform resource locator of the site from which you use or visit the Website, details of website you are using after leaving our Website, the IP address of your computer operating system, type of web browser you are using, email patterns, and the name of ISP.&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;margin:0in;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:.5in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;margin:0in;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;color:black;'>This information is used solely to analyze overall user trends and to help us improve our services. This information is not shared with third parties without user&rsquo;s permission or when required under law or to facilitate user with the Services. However, you agree and confirm that we have the right to share the aggregate findings, including personal information by you in an unidentifiable form, and not the specific data with advertisers, sponsors, investors, strategic partners and others in course of our business.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>When you visit our Website from your device, we collect, maintain, process and use your location data through GPS, WIFI or cellular triangulation. The location data collected by us are collected in a form that does not personally identify you. Our Website collects your location data to enable, provide, and improve location-based products and services offered by us, even when the app is closed or not in use.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-family:"Palatino Linotype",serif;'>Cookies</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>Whenever You access the&nbsp;</span><span style='font-family:"Palatino Linotype",serif;'>Website&nbsp;</span><span style='font-family:"Palatino Linotype",serif;'>We may place &quot;cookies&quot; on your hard drive or your device for record-keeping purposes to enhance your experience or sometimes to personalize your experience. Cookies are small text files that are placed on your computer&rsquo;s hard drive by the</span><span style='font-family:"Palatino Linotype",serif;'>&nbsp;Website</span><span style='font-family:"Palatino Linotype",serif;'>. Cookies help us to identify information relating your activities and to retain information relating to your preferences and history on</span><span style='font-family:"Palatino Linotype",serif;'>&nbsp;the Website</span><span style='font-family:"Palatino Linotype",serif;'>.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>We may use advertising cookies to deliver ads that are more relevant to you and your interests.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>You may choose to disable cookies by turning off cookie feature on the web browser or your device. However, by disabling this feature, some parts of the&nbsp;</span><span style='font-family:"Palatino Linotype",serif;'>Website</span><span style='font-family:"Palatino Linotype",serif;'>&nbsp;may not function properly. This may prevent You from taking full advantage of the</span><span style='font-family:"Palatino Linotype",serif;'>&nbsp;Website</span><span style='font-family:"Palatino Linotype",serif;'>.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:4.5pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-family:"Palatino Linotype",serif;color:black;'>HOW WE USE COLLECTED INFORMATION:</span></u></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:4.5pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-family:"Palatino Linotype",serif;color:black;'><span style="text-decoration: none;">&nbsp;</span></span></u></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:4.5pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>We may collect and use Your information for the following purposes:</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:4.5pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>&nbsp;</span></p>
<ul style="list-style-type: disc;">
    <li><span style='font-family:"Palatino Linotype",serif;'>We may use your information to control your access to Our Website&nbsp;and services, to identify you, communicate with you, customize your experience by providing information that may be useful or interesting to you and to resolve any disputes.</span></li>
    <li><span style='font-family:"Palatino Linotype",serif;color:black;'>We may use the email address to send, account information, administrative information, changes in account settings, changes to our services or any updates pertaining to the services. Apart from this if Users choose to opt-in to our mailing list, they will receive periodic emails that may relate to our activities, related service information, e.t.c<span style="background:yellow;">.&nbsp;</span></span><span style='font-family:"Palatino Linotype",serif;background:yellow;'>If You want to unsubscribe from receiving future emails from Us, please follow the &ldquo;Unsubscribe&rdquo; instructions provided at the bottom of each email</span><span style='font-family:"Palatino Linotype",serif;'>.</span></li>
    <li><span style='font-family:"Palatino Linotype",serif;color:black;'>Your Information&nbsp;</span><span style='font-family:"Palatino Linotype",serif;color:black;'>may also be used for responding to any of your inquiries, questions, and/or any other requests.&nbsp;</span></li>
    <li><span style='font-family:"Palatino Linotype",serif;'>We may use the Users information to analyse the use of the&nbsp;</span><span style='font-family:"Palatino Linotype",serif;'>Website</span><span style='font-family:"Palatino Linotype",serif;'>, and the people visiting and using the&nbsp;</span><span style='font-family:"Palatino Linotype",serif;'>Website&nbsp;</span><span style='font-family:"Palatino Linotype",serif;'>for the improving the features of the&nbsp;</span><span style='font-family:"Palatino Linotype",serif;'>Website</span><span style='font-family:"Palatino Linotype",serif;'>. Further, the information provided by You may also be used to help Us in responding to Your requests and support needs more efficiently.&nbsp;</span></li>
    <li><span style='font-family:"Palatino Linotype",serif;'>We may also use Your information for research purposes.</span></li>
    <li><span style='font-family:"Palatino Linotype",serif;'>We may also share Your information with any third parties including any government departments if the disclosure of such information is required by law or is based on any order by a court of competent jurisdiction. &nbsp;</span></li>
    <li><span style='font-family:"Palatino Linotype",serif;'>We may use your geographical and location information to analyse our target audience for advertising purposes.&nbsp;</span></li>
    <li><span style='font-family:"Palatino Linotype",serif;'>To meet any legal and regulatory requirements</span></li>
    <li><span style='font-family:"Palatino Linotype",serif;'>To provide, trouble shoot and improve our services. We use tour information to provide functionality, analyse performance, fix errors, and improve usability and effectiveness of the Services. &nbsp;&nbsp;</span></li>
    <li><span style='font-family:"Palatino Linotype",serif;'>To enforce the terms of use.&nbsp;</span></li>
    <li><span style='font-family:"Palatino Linotype",serif;'>Purchase and delivery of products and service. We use your personal information to take, handle and fulfil orders made by you.&nbsp;</span></li>
</ul>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:.25in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-family:"Palatino Linotype",serif;'>Protection of your information</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;color:black;background:white;'>We adopt appropriate data collection, storage and processing practices and security measures to protect Your personal information against unauthorized access, alteration, disclosure or destruction.</span><span style='font-family:"Palatino Linotype",serif;'>&nbsp;However, We cannot guarantee the security of any information obtained through unauthorized entry or use, hardware or software failure, and other factors that may compromise the security of user information at any time.&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:32.2pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-family:"Palatino Linotype",serif;'>&nbsp;</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>We do not sell, rent, distribute or otherwise make personal information commercially available to any third party, except as described in this policy or with your prior permission.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>However, We may disclose your information to our holding companies, subsidiaries, and affiliates which are entities under our common ownership or control or to contractors, advertisers or service providers and other third parties whom we use to support our business or to third parties to market their products or services which we feel may be of interest or beneficial to you. We may also share your information with outside vendor that we use for a variety of purposes such as sending you communications via emails, messages, or tele calls to inform you about the services and/or products that may be of interest to you, push notifications to your mobile device on our behalf, help us analyze use of services and process and collect payments, for conducting surveys etc.&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>We may share generic aggregated demographic information not linked to any personally identifiable information regarding visitors and users with our business partners, trusted affiliates and advertisers.&nbsp;</span><span style='font-family:"Palatino Linotype",serif;'>We may anonymise and/or de-identify information collected from you using third party web analytic tools.&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>At times We may be required by law or litigation to disclose your personal information. We may also disclose information about You, if We at our sole discretion determine that for national security, law enforcement, or other issues of public importance that disclosure of information is necessary.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-family:"Palatino Linotype",serif;'>Third Party Links&nbsp;</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>This&nbsp;</span><span style='font-family:"Palatino Linotype",serif;'>Website&nbsp;</span><span style='font-family:"Palatino Linotype",serif;'>may contain links to other websites or content. You agree and acknowledge that these third party sites are not under our control and We are not responsible for contents of such sites. These links have been provided to You only as a convenience. &nbsp;This Privacy Policy does not govern these links and You are required to read, understand and accept the Privacy Policy and Terms of Use of these third parties prior to using their services. &nbsp;Moreover we may allow other companies to serve advertisements to users. These companies or entitles include third party advertisements servers, agencies, technology vendors and research firms. We may target some advertisements to You that fit a general profile. We do not use personal information to target advertisements to specific users. In course of serving advertisements or optimizing the services, we may allow or authorize third parties to place or recognize a unique cookie on your browser.&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-family:"Palatino Linotype",serif;'>&nbsp;</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-family:"Palatino Linotype",serif;'>Changes to this privacy policy</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>We reserve the right to update and revise this Privacy Policy from time to time. The revised Privacy Policy will be available <span style="color:red;">here.&nbsp;</span>You are encouraged to periodically check this page to stay informed about changes to our Privacy Policy. The revised Policy will be effective from the date stated on the revised Privacy Policy. Your continued use of the&nbsp;</span><span style='font-family:"Palatino Linotype",serif;'>Website&nbsp;</span><span style='font-family:"Palatino Linotype",serif;'>following the posting of revised Policy will indicate your acceptance and acknowledgment of the changes and you will be bound by it.</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-family:"Palatino Linotype",serif;'>Contacting our Grievance officer:&nbsp;</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>&nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Palatino Linotype",serif;'>If you have any questions about this Privacy Policy, our practices or any of the Services or any other queries please contact our Grievance Officer at: <span style="background:yellow;">__________________</span>. &nbsp;</span></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-family:"Palatino Linotype",serif;'><span style="text-decoration:none;">&nbsp;</span></span></u></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-family:"Palatino Linotype",serif;'>&nbsp;</span></strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'>&nbsp;</p>`;