import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//react components
import { AddproductForm } from "./AddproductForm";
import  EditProductForm  from "./EditProductForm";
import { ViewProductDetails } from "./ViewProductDetails";
import { AddAttribute } from "./AddAttribute";
import  ProductsTable  from "./ProductsTable";
import Spinner from "../../../util/spinner/spinner";
import { SnackBar } from "../../../components/SnackBar";
import { ConfirmDialog } from "../../ConfirmDialog";

// material ui
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Paper, Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";

// redux actions
import {
  getSellerProducts,
  deleteProduct,
  addProduct,
  editProduct,
  getOptions,
  changeProductStatus,
  getCategoryList,
} from "../../../redux/actions/sellerActions";
import { userActivationMessage } from "../../../redux/actions/SnackbarActions";
import Loading from "../../../util/spinner/Loading";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#d5f9ef",
    color: "#3c3c3c",
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: 13,
    fontWeight: "bold",
    letterSpacing: 1,
  },
  body: {
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#2d2b2b",
    fontWeight: 500,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#eeeeee",
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  addButton: {
    float: "right",
    "&:hover": {
      background: "#53E7BC",
      color: "#FFFFFF",
    },
    margin: theme.spacing(1),
    border: "1px solid #53E7BC",
    fontSize: "13px",
    fontWeight: 600,
    borderRadius: "0.375rem",
    textTransform: "none",
  },
  noFound: {
    color: "#030104",
    textAlign: "center",
  },
  search: {
    margin: theme.spacing(1),
    "& .MuiOutlinedInput-inputAdornedStart": {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#7c7c7c",
      opacity: 0.54,
    },
  },
  searchIcon: {
    color: "#7c7c7c",
  },
}));

export const ProductsTab = () => {
  const classes = useStyles();
  const { loading, serverError, error, loadingActive, productlist } = useSelector(
    (state) => state.seller
  );
  const { account } = useSelector((state) => state.auth);
  const sellerId = account.id;
  const { options, colors } = useSelector((state) => state.seller);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [addAttributeOpen, setAddAttributeOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [product, setProduct] = useState({});
  const [sizeoption, setSizeOptions] = useState([]);
  const [coloroption, setColorOption] = useState([]);
  const [productDetails, setProductDetails] = useState({});
  const [ProductDetailsOpen, setProductDetailsOpen] = useState(false);

  // confirmation window
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [productId, setProductId] = useState("");

  const [activeConfirm, setActiveConfirm] = useState(false);
  const [statusObject, setStatusObject] = useState({
    id: "",
    status: "",
    title: "",
  });

  useEffect(() => {
    if (productlist) {
      setItemsState(productlist);
      setFilteredItemsState(productlist);
    }
  }, [productlist]);
  const [itemsState, setItemsState] = useState(productlist ? [] : null);
  const [filteredItemsState, setFilteredItemsState] = useState(
    productlist ? [] : null
  );

  // function to open add product form
  const handleClickOpen = () => {
    if (account.userStatus === "ACTIVE") {
      setOpen(true);
    } else {
      dispatch(
        userActivationMessage(
          "warning",
          `Account activation is in progress and current status is ${account.userStatus}`
        )
      );
    }
  };

  const handleSearch = (id) => {
    let currentList = [];
    let newList = [];
    if (id !== "") {
      currentList = itemsState;
      newList = currentList.filter((item) => {
        // console.log("items: " + JSON.stringify(item));
        const lc = item.id.toString();
        const filter = id.toString();
        let name = item?.description?.name || "";
        return lc.includes(filter) || name.toLowerCase().includes(id.toLowerCase());
      });
    } else {
      newList = itemsState;
    }
    setFilteredItemsState(newList);
    setPage(0);
  };

  // function to open add product form
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddProduct = (productData) => {
    dispatch(addProduct(productData));
  };

  // function to delete product
  const handelDeleteProduct = () => {
    dispatch(deleteProduct(productId));
  };

  // function to open edit product form
  const handleClickEditOpen = (product) => {
    setProduct(product);
    setEditOpen(true);
  };

  // function to close edit product form
  const handleEditClose = () => {
    setEditOpen(false);
  };

  // function to submit edit product
  const handleEditProduct = (editData, productId) => {
    dispatch(editProduct(editData, productId));
    handleEditClose();
  };

  // function to open Product details form
  const handleClickDetailsOpen = (data) => {
    setProductDetails(data);
    setProductDetailsOpen(true);
  };

  // funtion to close Product details form
  const handleClickDetailsClose = () => {
    setProductDetailsOpen(false);
  };

  // function to open add Attribute form
  const handleClickAddAttribute = (product) => {
    setProduct(product);
    dispatch(getOptions(product.categories[0].id));
    setAddAttributeOpen(true);
  };

  const handleaddAttributeClose = () => {
    setAddAttributeOpen(false);
  };

  const handelActiveProduct = () => {
    const statusData = {
      available: statusObject.status,
    };
    dispatch(changeProductStatus(statusObject.id, statusData));
  };

  useEffect(() => {
    dispatch(getSellerProducts(sellerId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    dispatch(getCategoryList());
  },[])
  useEffect(() => {

    if (options.length !== 0) {
      setSizeOptions(options);
      setColorOption(colors);
    }
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredItemsState?.length - page * rowsPerPage);

  return (
    <React.Fragment>
      {loading ? (
        <Spinner />
      ) : serverError ? (
        <p>{serverError}</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <TextField
            className={classes.search}
            id="input-with-icon-textfield"
            size="small"
            placeholder="Search by ID"
            variant="outlined"
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            size="small"
            color="secondary"
            className={classes.addButton}
            style={{ outline: "none", height: "34px" }}
            onClick={handleClickOpen}
          >
            Add New Product
          </Button>

          <AddproductForm
            open={open}
            handleClose={handleClose}
            handleAddProduct={handleAddProduct}
          />

          <TableContainer component={Paper}>
            {loadingActive && <Loading />}
            <Table className={classes.table} aria-label="collapsible table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Product ID</StyledTableCell>
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">Category</StyledTableCell>
                  <StyledTableCell align="center">Stock</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Actions</StyledTableCell>
                  <StyledTableCell align="center">Attribute</StyledTableCell>
                </StyledTableRow>
              </TableHead>

              {filteredItemsState ? (
                filteredItemsState.length > 0 ? (
                  <TableBody>
                    {filteredItemsState
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((product) => (
                        <ProductsTable
                          key={product.id}
                          product={product}
                          setConfirmOpen={setConfirmOpen}
                          setProductId={setProductId}
                          handleClickEditOpen={handleClickEditOpen}
                          handleClickDetailsOpen={handleClickDetailsOpen}
                          handleClickAddAttribute={handleClickAddAttribute}
                          setActiveConfirm={setActiveConfirm}
                          setStatusObject={setStatusObject}
                        />
                      ))}

                    {emptyRows > 0 && (
                      <StyledTableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <StyledTableCell colSpan={8} />
                      </StyledTableRow>
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell colSpan={8} className={classes.noFound}>
                        Products not found
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                )
              ) : <TableBody>
              <StyledTableRow>
                <StyledTableCell colSpan={8} className={classes.noFound}>
                <Spinner />
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>}
            </Table>

            {productlist.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={filteredItemsState?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </TableContainer>

          <EditProductForm
            open={editOpen}
            handleEditClose={handleEditClose}
            product={product}
            handleEditProduct={handleEditProduct}
          />

          <AddAttribute
            open={addAttributeOpen}
            handleaddAttributeClose={handleaddAttributeClose}
            product={product}
            sizeoption={sizeoption}
            coloroption={coloroption}
            onsubmit={setFilteredItemsState}
          />

          <ViewProductDetails
            open={ProductDetailsOpen}
            handleProductDetailsClose={handleClickDetailsClose}
            productDetails={productDetails}
            handleClickDetailsOpen={handleClickDetailsOpen}
          />

          <ConfirmDialog
            title="Delete Product?"
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={handelDeleteProduct}
          >
            Are you sure you want to delete this Product?{" "}
          </ConfirmDialog>

          <ConfirmDialog
            title={`Confirm ${statusObject.title}?`}
            open={activeConfirm}
            setOpen={setActiveConfirm}
            onConfirm={handelActiveProduct}
          >
            {`Are you sure you want to ${statusObject.title} this Product? `}
          </ConfirmDialog>

          <SnackBar />
        </>
      )}
    </React.Fragment>
  );
};
