import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

/* react component */
import { AppBars } from "../components/AppBar";
// import { CategoryList } from "../components/CategoryList";
import { Footer } from "../components/Footer";
import { MiniCart } from "../components/productpage/MiniCart";
// import { AuthenticationAlert } from "../components/AuthenticationAlert";
import { SeeallBids } from "../components/SeeallBids";
import { BargainTimer } from "../components/productpage/BargainTimer";
import { Reviews } from "../components/productpage/Reviews";
import { RelatedProducts } from "../components/productpage/RelatedProducts";
import { SoldOut } from "../components/productpage/SoldOut";
import { SnackBar } from "../components/SnackBar";
import Spinner from "../util/spinner/spinner";
import { ServerError } from "../components/errors/ServerError";
import { PageNotFound } from "../components/errors/PageNotFound";
import * as moment from "moment";

/* redux actions */
import {
  getProductbyId,
  getAllBidsByProduct,
  getAllAsksByProduct,
  addProductToWishlist,
  createMiniCart,
  addAttributeToMiniCart,
  deleteMiniCart,
  minimumBuyAlert,
  clearProductOnPageUnMount,
  // showBetaMsg,
} from "../redux/actions/productActions";
import { addToCart } from "../redux/actions/cartActions";
import { submitBargain } from "../redux/actions/customerActions";
import {
  checkDeliveryByPincode,
  snackBarAlert,
  resetPincode,
} from "../redux/actions/deliveryActions";
import { authAlert } from "../redux/actions/authActions";
import {
  deleteProductFromWishlist,
  duplictaeAlertWishlist,
} from "../redux/actions/customerWishlistActions";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Rating from "@material-ui/lab/Rating";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormLabel from "@material-ui/core/FormLabel";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import Tooltip from "@material-ui/core/Tooltip";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Links from "@material-ui/core/Link";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import InputLabel from "@material-ui/core/InputLabel";
import { Divider } from "@material-ui/core";
import Axios from "axios";
import { isStringNullorEmpty } from "../util/commonUtil/commonUtil";
// import { ActionTypes } from "../redux/constants/actionTypes";
import MenuHeader from "../components/MenuHeader";
import AuthenticationAlertPop from "./customer/AuthenticationAlertPop";
import Sitemap from "./Sitemap/sitemap";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiButtonBase-root": {
      outline: "none",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
      fontFamily: "'Fira Sans', sans-serif",
    },
    "& .MuiFormLabel-root": {
      display: "block",
      margin: "8px 0 0",
      fontSize: 11,
      color: "#212121",
      fontFamily: "'Fira Sans', sans-serif",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
    width: "80%",
    margin: "auto",
  },
  gridSection: {
    marginBottom: 12,
  },
  productTitle: {
    fontWeight: 600,
    fontSize:'3rem',
    color: "#383838",
    lineHeight: 1.22,
    textTransform: "capitalize",
  },
  brandTitle: {
    fontSize:'2.5rem',
    textTransform: "capitalize",
    margin: "6px 0px 0px 1px",
    color: "#878787",
    fontWeight: 600,
  },
  review: {
    fontSize: 12,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#27B23E",
    marginLeft: 10,
    "&:hover": {
      textDecoration: "none",
      color: "#27B23E",
    },
  },
  productCount: {
    color: "#5e5e5e",
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.19,
    margin: "10px auto",
    fontWeight: 500,
  },
  initialCount: {
    fontWeight: 600,
    color: "#212121",
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 2.19,
    margin: "10px 0px 8px 0px",
  },
  qtyDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  qtyBlock: {
    marginBottom: theme.spacing(3),
  },
  qtyLable: {
    fontSize: "14px",
    paddingRight: theme.spacing(1),
    fontWeight: 500,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#383838",
    lineHeight: 1.19,
  },
  qtyInput: {
    width: "20%",
  },
  priceblock: {
    width: "100%",
  },
  priceTitle: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    lineHeight: 1.2,
    color: "#212121",
    margin: "auto",
  },
  price: {
    fontSize: "32px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: "bold",
    color: "#212121",
    lineHeight: 1.22,
    margin: "10px auto",
  },
  buttonSection: {
    marginTop: 18,
  },
  buyButton: {
    fontSize: "18px",
    borderRadius: "2px",
    padding: "10px 8px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.2)",
    background: "#ff9d00",
    color: "#FFFFFF",
    fontWeight: 600,
    width: "45%",
    border: "none",
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "none",
    marginRight: theme.spacing(2),
    transition: "ease all 0.3s",
    "&:hover": {
      background: "#f59701",
      color: "#FFFFFF",
      transform: "translateY(-5px)",
    },
  },
  bargainButton: {
    fontSize: "18px",
    borderRadius: "2px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.2)",
    background: "transparent",
    color: "#ff9d00",
    fontWeight: 600,
    width: "45%",
    border: "1px solid #ff9d00",
    padding: "10px 8px",
    fontFamily: "'Fira Sans', sans-serif",
    textTransform: "none",
    marginLeft: theme.spacing(1),
    transition: "ease all 0.3s",
    "&:hover": {
      background: "#f59701",
      color: "#FFFFFF",
      transform: "translateY(-5px)",
    },
  },
  barginSection: {
    border: "1px solid #53E7BC",
    background: "transparent",
    color: "#53E7BC",
    fontSize: "13px",
    fontWeight: 600,
    borderRadius: "0.375rem",
    padding: "0.375rem",
    textTransform: "none",
    marginRight: theme.spacing(2),
    "&:hover": {
      background: "#4AD0AA",
      color: "#FFFFFF",
    },
  },
  bargain: {
    background: "#FBFBFB",
    border: "1px solid #53E7BC",
    borderRadius: "6px",
    width: "80%",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  bargainSec: {
    padding: 8,
    textAlign: "center",
  },
  bargainPrices: {
    borderBottom: "1px solid #53E7BC",
    padding: "10px 0 0 10px",
  },
  descSection: {
    color: "#5e5e5e",
    fontSize: "13px",
    fontWeight: "normal",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.39,
    marginTop: theme.spacing(3),
    border: "1px solid #f0f0f0",
    borderRadius: "2px",
  },
  descTitle: {
    alignItems: "center",
    padding: "16px 30px 16px 24px",
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.14,
    color: "#212121",
    fontFamily: "'Fira Sans', sans-serif",
  },
  descList: {
    borderTop: "1px solid #f0f0f0",
    padding: "20px 18px 0px 25px",
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#212121",
  },
  closeIcon: {
    float: "right",
    position: "relative",
    right: "-24px",
    top: "-22px",
  },
  sellerSection: {
    fontWeight: "normal",
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: "28px",
    color: "#212121",
    lineHeight: 1.23,
    letterSpacing: "normal",
    margin: "12px 0px",
  },
  sellerinfoDiv: {
    display: "flex",
    border: "1px solid #979797",
    borderRadius: "6px",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },
  sellerName: {
    alignItems: "center",
    width: "50%",
    padding: 10,
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  seller: {
    fontSize: "16px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#212121",
    lineHeight: 0.94,
    fontWeight: "normal",
  },
  sellerplace: {
    fontSize: "12px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#5e5e5e",
    lineHeight: 0.94,
    fontWeight: "normal",
    marginBottom: theme.spacing(1),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  sellerTitle: {
    color: "#4A4A4A",
    fontSize: "14px",
    fontWeight: 500,
    paddingLeft: theme.spacing(1),
  },
  soldInfo: {
    color: "#4A4A4A",
    fontSize: "14px",
    textAlign: "center",
    margin: "auto",
  },
  verdictImg: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  since: {
    fontSize: "20px",
    fontFamily: "'Fira Sans', sans-serif",
    lineHeight: 1.2,
    color: "#212121",
    marginTop: theme.spacing(1),
  },
  sizediv: {
    display: "flex",
    margin: "12px 0px auto",
    "& .active": {
      opacity: 1,
      border: "2px solid #086fcf",
      outline: "none",
      backgroundColor: "#FFF",
      height: "42px",
      width: "42px",
      fontSize: 12,
      fontFamily: "'Fira Sans', sans-serif",
      borderRadius: "50%",
      margin: "0px 2px",
      color: "#086fcf",
      fontWeight: 600,
    },
    "& .sizebox": {
      border: "2px solid #edeff2",
      outline: "none",
      backgroundColor: "#FFF",
      height: "42px",
      width: "42px",
      fontSize: 12,
      fontFamily: "'Fira Sans', sans-serif",
      borderRadius: "50%",
      margin: "0px 2px",
      color: "#16161a",
      cursor: "pointer",
    },
  },
  sizelable: {
    lineHeight: 1.2,
    color: "#383838",
    marginTop: "12px",
    marginBottom: "6px",
    fontWeight: "bold",
  },
  sizebox: {
    border: "2px solid #edeff2",
    outline: "none",
    backgroundColor: "#FFF",
    height: "42px",
    width: "42px",
    fontSize: 12,
    fontFamily: "'Fira Sans', sans-serif",
    borderRadius: "50%",
    margin: "0px 2px",
    color: "#16161a",
    cursor: "pointer",
  },
  sizeboxItem: {
    border: "2px solid #edeff2",
    outline: "none",
    backgroundColor: "#FFF",
    height: "42px",
    width: "42px",
    fontSize: 12,
    fontFamily: "'Fira Sans', sans-serif",
    borderRadius: "50%",
    margin: "0px 2px",
    color: "#16161a",
    cursor: "pointer",
    position: "relative",
  },
  colorDiv: {
    width: 32,
    height: 32,
    borderRadius: "50%",
    display: "block",
    margin: "0 0 0 6px",
  },
  colorbox: {
    width: 22,
    height: 22,
    border: "1px solid",
    cursor: "pointer",
    userSelect: "none",
    transition: "ease all 0.3s",
    margin: "3px 0 0 3px",
    borderRadius: "50%",
  },
  flicker: {
    animationName: "$flicker",
    animationDuration: "1000ms",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "linear",
    color: "#007600",
    fontSize: "16px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: "bold",
  },
  "@keyframes flicker": {
    from: {
      opacity: 50,
    },
    to: {
      opacity: 0.2,
    },
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 400,
    color: "#666666",
  },
  reviewpaper: {
    margin: "auto",
    maxWidth: 900,
  },
  reviewsheading: {
    fontSize: 24,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    color: "#5e5e5e",
    lineHeight: 1.21,
    paddingBottom: theme.spacing(1),
  },
  reviewslist: {
    border: "1px solid #d8d8d8",
    borderRadius: 4,
    padding: theme.spacing(2),
    margin: "auto",
  },
  inclusivetaxtext: {
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
    color: "#27B23E",
  },
  pincodebox: {
    width: 250,
    marginTop: 14,
    "& .MuiIconButton-label": {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 600,
      color: "#27B23E",
      textTransform: "capitalize",
    },
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  input: {
    fontSize: 14,
    fontFamily: "'Fira Sans', sans-serif",
    "&::placeholder": {
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
    },
  },
  nodelivery: {
    display: "block",
    margin: "8px 0 0",
    fontSize: 12,
    color: "#ff5722",
    fontFamily: "'Fira Sans', sans-serif",
  },
  wrapIcon: {
    fontSize: 13,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
  },
  linkIcon: {
    margin: 8,
  },
  soldout: {
    fontFamily: "'Fira Sans', sans-serif",
    color: "#000000",
    fontWeight: 750,
    fontSize: 28,
  },
  soldoutText: {
    fontFamily: "'Fira Sans', sans-serif",
    color: "#ff9d00",
    fontWeight: 400,
    fontSize: 18,
  },
  notify: {
    margin: "0 10px",
    color: "#fff",
    border: "1px solid #fb641b",
    fontSize: 15,
    fontWeight: 500,
    padding: "10px 30px",
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 20%)",
    backgroundColor: "#fb641b",
    textAlign: "center",
    height: 40,
    "&:hover": {
      backgroundColor: "#fb641b",
      color: "#fff",
    },
  },
  notifyText: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: 14,
    lineHeight: 2.21,
  },
  bigImag: {
    maxWidth: "615px",
    minWidth: "300px",
    overflow: "hidden",
    margin: "2px",
    border: "1px solid #f5f5f6",
  },
  defaultImg: {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
    zIndex: 0,
    opacity: 1,
    transition: "opacity .5s linear",
  },
  smallImg: {
    minWidth: "75px",
    overflow: "hidden",
    margin: "2px",
    "& .activeImage": {
      opacity: 1,
      border: "1px solid #f7c531",
      outline: "none",
      height: "100%",
      maxHeight: "70px",
      maxWidth: "85px",
      display: "block",
      objectFit: "contain",
      padding: "4px",
      margin: "8px",
    },
    "& .selImage": {
      border: "1px solid #f5f5f6",
      outline: "none",
      cursor: "pointer",
      height: "100%",
      maxHeight: "70px",
      maxWidth: "85px",
      display: "block",
      objectFit: "contain",
      padding: "4px",
      margin: "8px",
    },
  },
  smallimgdp: {
    height: "100%",
    maxHeight: "70px",
    maxWidth: "85px",
    display: "block",
    objectFit: "contain",
    padding: "4px",
    border: "1px solid #f5f5f6",
    margin: "8px",
  },
  disablemessage: {
    fontSize: 16,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 500,
    color: "red",
  },
  county: {
    margin: "10px 0px auto",
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: 12,
    color: "#212121",
    fontWeight: 500,
  },
  wishlist: {
    cursor: "pointer",
    position: "absolute",
    top: 15,
    right: 15,
    width: 36,
    height: 36,
    border: "1px solid #f0f0f0",
    borderRadius: "50%",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 10%)",
    background: "#fff",
  },
  swingimage: {
    animation: "$roll 3s infinite",
    WebkitAnimation: "$roll 3s infinite",
    MozAnimation: "$roll 3s infinite",
    "&:hover": {
      animation: "paused",
      WebkitAnimation: "paused",
      MozAnimation: "paused",
    },
  },
  "@keyframes roll": {
    "0%": {
      transform: "rotate(0)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

const imageBaseUrl = process.env.REACT_APP_IMAGE_URL; /* image base url */
const numbersRegex = /^[0-9]*[1-9][0-9]*$/; /* regex for mobile number */
const NUMERIC_ONLY = /^[0-9]+$/;
export const ProductDetailsPage = (props) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("cust"));
  const { authenticated, account, alert } = useSelector((state) => state.auth);
  const { product, loading, serverError } = useSelector(
    (state) => state.product
  );
  const { availability, notAvailable } = useSelector((state) => state.Delivery);
  const { cartItem } = useSelector((state) => state.shoppingCart);
  const { wishlist } = useSelector((state) => state.Wishlist);

  const dispatch = useDispatch();
  const history = useHistory();

  /* set attributes */
  const [attribute, setAttribute] = useState({});
  const [attributevalue, setAttributevalue] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [defaultImage, setDefaultImage] = useState("");
  const [defaultColor, setDefaultColor] = useState("");
  const [valueDispatch, setValueDispatch] = useState(true);
  const [photoIndex, setphotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [miniCartOpen, setMiniCartOpen] = useState(false);
  const [showSiteMap3, setShowSiteMap3] = useState(false);
  const [open, setOpen] = useState(false);
  const [allbids, setAllBids] = useState(false);
  const [bidtype, setBidType] = useState("");
  const [quantity, setQuantity] = useState("");
  const [bargainprice, setBargainPrice] = useState(0);
  const [errors, setErrors] = useState({});
  const [pincode, setPincode] = useState("");
  const [pincodeCheck, setPincodeCheck] = useState(false);
  const [cartDataJsonList, setCartDataJsonList] = useState();

  const [miniQuantity, setMiniQuantity] = useState("");
  const [sizeColorQuantity, setSizeColorQuantity] = useState();
  const [saleDateValidation, setSaleDateValidation] = useState(false);
  /* scroll to review section */
  const reviewSection = React.useRef(null);
  const textRef = React.useRef();

  const gotoReviewSection = () =>
    window.scrollTo({
      top: reviewSection.current.offsetTop,
      behavior: "smooth",
    });

  /* open bargain window */
  const handleClickOpenBargain = async () => {
    // dispatch(showBetaMsg());
    if (pincodeCheck) {
      if (!authenticated) {
        dispatch(authAlert());
      } else {
        if (pincode !== "") {
          const { data } = await Axios.get(
            `${process.env.REACT_APP_SHIPPING_URL}/api/v1/searchByPinCode/${pincode}`
          );

          if (data.pincode === pincode) {
            setOpen(true);
          } else {
            dispatch(
              snackBarAlert(
                "error",
                "Unfortunately we do not ship to your pincode."
              )
            );
          }
        } else {
          dispatch(
            snackBarAlert("error", "Please check if delivery is available.")
          );
        }
      }
    } else {
      dispatch(
        snackBarAlert("error", "Please check if delivery is available.")
      );
    }
  };

  /* close bargin window */
  const handleCloseBargain = () => {
    setOpen(false);
    setQuantity(attributevalue.minQuantity);
    setBargainPrice(0);
  };

  /* function to close authentication alert */
  const handleCloseAlert = () => {
    dispatch(authAlert());
  };

  /* function to open bids dialog */
  const handleClickOpenBids = (data) => {
    if (data === "allBids") {
      setAllBids(true);
      setBidType(data);
      dispatch(getAllBidsByProduct(product.sku));
    } else {
      setAllBids(true);
      setBidType(data);
      dispatch(getAllAsksByProduct(product.sku));
    }
  };

  /* function to close bids dialog */
  const handleCloseOpenBids = () => {
    setAllBids(false);
  };
  /* add to cart inputs validation */
  const buyingInputValidation = () => {
    let errors = {};
    let inputsValid = true;

    if (!numbersRegex.test(quantity)) {
      inputsValid = false;
      errors["quantity"] = "Please select a valid Quantity to proceed";
    }

    if (quantity > attributevalue.attributeQuantity) {
      inputsValid = false;
      errors["quantity"] = "Please select within available Quantity";
    }

    setErrors(errors);
    return inputsValid;
  };

  /* function to add product to cart */
  const handleAddToCart = async (id) => {
    // dispatch(showBetaMsg());
    if (pincodeCheck) {
      if (authenticated) {
        if (pincode !== "") {
          const { data } = await Axios.get(
            `${process.env.REACT_APP_SHIPPING_URL}/api/v1/searchByPinCode/${pincode}`
          );

          if (data.pincode === pincode) {
            if (buyingInputValidation()) {
              if (valueDispatch === true) {
                const cartData = {
                  attributes: [
                    {
                      id: attributevalue.attributeId
                        ? attributevalue.attributeId
                        : 0,
                    },
                  ],
                  bargainPrice: 0,
                  bargained: "NO",
                  product: id,
                  quantity: quantity,
                };

                setValueDispatch(false);

                dispatch(addToCart(cartData, user.id, history, false));
              }
            }
          } else {
            dispatch(
              snackBarAlert(
                "error",
                "Unfortunately we do not ship to your pincode."
              )
            );
          }
        } else {
          textRef.current.focus();

          dispatch(
            snackBarAlert("error", "Please check if delivery is available.")
          );
        }
      } else {
        dispatch(authAlert());
      }
    } else {
      textRef.current.focus();

      dispatch(
        snackBarAlert("error", "Please check if delivery is available.")
      );
    }
  };

  /* bargain inputs validation */
  const bargainInputsValidation = () => {
    let errors = {};
    let inputsValid = true;

    if (!numbersRegex.test(quantity)) {
      inputsValid = false;
      errors["quantity"] = "Please select a valid Quantity to proceed";
    }

    if (quantity === 0 || quantity === "0" || quantity === 0) {
      inputsValid = false;
      errors["quantity"] = "Please select a Quantity to proceed";
    }

    if (quantity > product.quantity) {
      inputsValid = false;
      errors["quantity"] = "Please select within available Quantity";
    }

    if (!numbersRegex.test(bargainprice)) {
      inputsValid = false;
      errors["bargainprice"] = "Enter valid Price to proceed";
    }

    if (bargainprice === 0 || bargainprice === "0" || bargainprice === "") {
      inputsValid = false;
      errors["bargainprice"] = "Please enter your bargain price ";
    }

    if ("MANU" === product.productUserDetail.userType) {
      inputsValid = false;
      errors["bargainprice"] = "Bargains not allowed for Manufacturer Products";
    }

    setErrors(errors);
    return inputsValid;
  };

  /* function to submite customer bargain */
  const handleSubmitBargain = (product, price) => {
    if (bargainInputsValidation()) {
      const bargainData = {
        ACTIVE_STATUS: true,
        ATTRIBUTE_ID: attributevalue.attributeId
          ? attributevalue.attributeId
          : null,
        ATTRIBUTE_COLOR: attributevalue.optionValueCode
          ? attributevalue.optionValueCode
          : null,
        ATTRIBUTE_COLOR_CODE: attributevalue.colorCode,
        ATTRIBUTE_PRICE: attributevalue.attributePrice,
        ATTRIBUTE_QUANTITY: attributevalue.attributeQuantity
          ? attributevalue.attributeQuantity
          : null,
        ATTRIBUTE_SIZE: attribute.optionCode ? attribute.optionCode : null,
        ATTRIBUTE_SKU: attributevalue.item_sku,
        BRAND: product.brandName,
        BARGAIN_START_DATE: product.bargainStartDateTime,
        BARGAIN_END_DATE: product.bargainEndDateTime,
        BARGAIN_PRICE: parseInt(bargainprice),
        BARGAIN_QUANTITY: quantity,
        BARGAIN_TITLE: "BARGAIN",
        CATEGORY: product.categories[0].code,
        CUST_ID: account.id,
        CUST_NAME: account.firstName,
        CUST_MOBILE: account.billing.phone,
        event_saga_store_id: "",
        FASHION_GROUP: product.fashionGroup,
        IDEAL_FOR: product.collectionCategory,
        LOWEST_PRICE: attributevalue.priceFinal,
        MANUFACTURER:
          product.manufacturer && product.manufacturer !== null
            ? product.manufacturer.description.name
            : null,
        MERCHANT_STORE_ID: "",
        MERCHANT:
          product.productUserDetail.userType === "MRCHNT"
            ? product.productUserDetail.userName
            : null,
        MANUFACTURER_ID:
          product.productUserDetail.userType === "MANU"
            ? product.productUserDetail.userId
            : null,
        MERCHANT_ID:
          product.productUserDetail.userType === "MERCH"
            ? product.productUserDetail.userId
            : null,
        OFFERING_PRICE: "",
        PARENT_SKU_ID: product.sku,
        PRODUCT_ID: product.id,
        PRODUCT_BUYABLE: product.buyable,
        PRODUCT_IMAGE_URL: defaultImage,
        PRODUCT_HSN: product.hsn,
        PRODUCT_TOTAL_QUANTITY: product.quantity,
        STATUS: "BARGAIN",
        SKU_DESC: "",
        SUB_CATEGORY: "",
        SKU_NAME: product.description.name,
        SELLER_NUMBER: product.productUserDetail.mid,
        uid: "",
        UPC: "",
        VNDR_ID:
          product.productUserDetail.userType === "VNDR"
            ? product.productUserDetail.userId
            : null,
        VENDOR:
          product.productUserDetail.userType === "VNDR"
            ? product.productUserDetail.userName
            : null,
        YWC_ID: "",
      };

      dispatch(submitBargain(bargainData));
      setQuantity(attributevalue.minQuantity);
      setBargainPrice(0);
      setOpen(false);
    }
  };

  const myRef = React.createRef();
  const imgRef = React.createRef();

  const handleDisplayColor = (item, index) => {
    setAttribute(item);

    const sizes = myRef.current.children;
    for (let i = 0; i < sizes.length; i++) {
      sizes[i].className = sizes[i].className.replace("active", "sizebox");
    }

    sizes[index].className = "active";

    const selectDefaultColor = item.attributeOptionValues.reduce((prev, curr) =>
      prev.attributeId < curr.attributeId ? prev : curr
    );

    setAttributevalue(selectDefaultColor);
    setProductImages(selectDefaultColor.images);
    setDefaultImage(selectDefaultColor.images[0]?.imageUrl);
    setDefaultColor(selectDefaultColor.colorCode);
    setQuantity(selectDefaultColor.minQuantity);
    setMiniQuantity(selectDefaultColor.minQuantity);
    setValueDispatch(true);
  };

  const handleSetAttribute = (item, index) => {
    setAttributevalue(item);
    setValueDispatch(true);
    setQuantity(item.minQuantity);
    setProductImages(item.images);
    setDefaultImage(item.images[0].imageUrl);

    setDefaultColor(item.colorCode);
    setMiniQuantity(item.minQuantity);
  };

  const handleCheckPincode = () => {
    setPincodeCheck(true);
    setValueDispatch(true);

    const inputData = {
      weight: product.packagingSpecifications.weight / 1000,
      id: product.productUserDetail.userId,
      pincode: pincode,
    };

    dispatch(checkDeliveryByPincode(inputData));
  };

  const handleChangePincode = () => {
    setPincode("");
    dispatch(resetPincode());
  };

  useEffect(() => {
    dispatch(getProductbyId(props.match.params.productId));
    setValueDispatch(false);
    setPincodeCheck(false);
    return () => {
      dispatch(clearProductOnPageUnMount());
    };
  }, [props.match.params.productId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let people = new Map();
    product.attributes &&
      product.attributes.map((size, index) => {
        var key = size.optionCode;
        const totalQuantity = size.attributeOptionValues.reduce(
          (sum, sizeQuantity) => sum + sizeQuantity.attributeQuantity,
          0
        );
        if (totalQuantity > 0) {
          people.set(key, true);
        } else {
          people.set(key, false);
        }
      });
    setValueDispatch(false);
    setSizeColorQuantity(people);
  }, [product]);

  useEffect(() => {
    const cartDataList = async (cartItemList) => {
      const { data } = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/cart/${cartItemList}`
      );

      setCartDataJsonList(data.products);
    };
    cartItem.code && cartDataList(cartItem.code);
  }, [cartItem.code]);

  /* set default attribute on page mount */
  useEffect(() => {
    if (product) {
      // const productData = product.attributes.reduce((prev, curr) =>
      //   prev.optionId < curr.optionId ? prev : curr
      // );

      const productDataList = product.attributes.filter(
        (defaultItems) => defaultItems.attributeDefault === true
      )[0];

      // const selectDefaultColor = productData.attributeOptionValues.reduce(
      //   (prev, curr) => (prev.attributeId < curr.attributeId ? prev : curr)
      // );
      let defaultProduct = product.attributes
        .map((productItem) =>
          productItem.attributeOptionValues.filter(
            (item) =>
              item.optionValueDefault && item.optionValueDefault === true
          )
        )
        .flat()[0];

      const sizeIndex = product.attributes.indexOf(productDataList);
      const sizes = myRef.current.children;
      for (let i = 0; i < sizes.length; i++) {
        sizes[i].className = sizes[i].className.replace("active", "sizebox");
      }
      sizes[sizeIndex].className = "active";
      setAttribute(productDataList);
      setAttributevalue(defaultProduct);
      setProductImages(defaultProduct.images);
      setDefaultImage(defaultProduct.images[0].imageUrl);
      setDefaultColor(defaultProduct.colorCode);
      setQuantity(defaultProduct.minQuantity);
      setMiniQuantity(defaultProduct.minQuantity);

      let saleValidation = false;
      if (!isStringNullorEmpty(product.dateAvailable)) {
        saleValidation = new Date() >= new Date(product.dateAvailable);
      }
      setSaleDateValidation(saleValidation);
    }
  }, [product]); // eslint-disable-line react-hooks/exhaustive-deps

  /* add active classe to selectd(small) image */
  const handleSetDefaultImage = (image, index) => {
    setDefaultImage(image.imageUrl);

    const selectedImage = imgRef.current.children;
    for (let i = 0; i < selectedImage.length; i++) {
      selectedImage[i].className = selectedImage[i].className.replace(
        "activeImage",
        "selImage"
      );
    }

    selectedImage[index].className = "activeImage";
  };
// Define a state variable to keep track of added product IDs
const [addedToWishlist, setAddedToWishlist] = useState(false);

  /* function to add product to wishlist */
  const handleAddToWishlist = (id) => {
    if (authenticated) {
      if (!addedToWishlist) {
        if (wishlist.length === 0) {
          dispatch(addProductToWishlist(id));
          setAddedToWishlist(true);
        } else {
          const productIndex = wishlist.find((item) => item.id === id);
          if (productIndex) {
            dispatch(duplictaeAlertWishlist());
            setAddedToWishlist(true);
          } else {
            dispatch(addProductToWishlist(id));
            setAddedToWishlist(true);
          }
        }
      } else {
        dispatch(duplictaeAlertWishlist());
      }
    } else {
      dispatch(authAlert());
    }
  };

  /* remove product from wishlist */
  const handleRemoveWishlist = (id) => {
    dispatch(deleteProductFromWishlist(id));
    setAddedToWishlist(false);
  };

  /* add product to wishlist */
  const wishlisted = () => {
    const index = wishlist.filter((item) => item.id === product.id);
   
    if (index.length > 0) {
      return (
        <div className={classes.wishlist}>
          <IconButton
            aria-label="wishlist"
            style={{ padding: "6px 10px 6px 5px" }}
            onClick={() => handleRemoveWishlist(product.id)}
          >
            <FavoriteIcon style={{ color: "#ff0000" }} />
          </IconButton>
        </div>
      );
    }

    return (
      <div className={classes.wishlist}>
        <IconButton
          aria-label="wishlist"
          style={{ padding: "6px 10px 6px 5px" }}
          onClick={() => handleAddToWishlist(product.id)}
          disabled={loading ? true : false}
        >
          <FavoriteBorderIcon style={{ color: "#ff9d00" }} />
        </IconButton>
      </div>
    );
  };

  /* function to open product minicart */
  const handleMiniCart = async () => {
    if (pincodeCheck) {
      if (!authenticated) {
        dispatch(authAlert());
      } else {
        if (!isStringNullorEmpty(pincode)) {
          const { data } = await Axios.get(
            `${process.env.REACT_APP_SHIPPING_URL}/api/v1/searchByPinCode/${pincode}`
          );

          if (data.pincode === pincode) {
            setMiniCartOpen(true);
            dispatch(createMiniCart(product.id));
          } else {
            dispatch(
              snackBarAlert("error", "Please check if delivery is available.")
            );
          }
        }
      }
    } else {
      dispatch(
        snackBarAlert("error", "Please check if delivery is available.")
      );
    }
  };

  /* function to close product minicart */
  const handleMiniCartClose = (miniCartId) => {
    if (miniCartId === 0) {
      setMiniCartOpen(false);
    } else {
      setMiniCartOpen(false);
      dispatch(deleteMiniCart(miniCartId));
    }
  };

  /* function add product to mini cart */
  const addToMiniCart = (miniCartId) => {
    if (quantity <= attributevalue.attributeQuantity ) {
      setErrors({});
      const persistableMiniCartAttribute = {
        productAttributeId: attributevalue.attributeId,
        quantity: quantity,
      };
      dispatch(
        addAttributeToMiniCart(miniCartId, persistableMiniCartAttribute)
      );
    } else {
      let errors = {};
      errors["quantity"] = "Please select within available Quantity";
      setErrors(errors);
    }
  };

  const handleQuantityAlert = (data) => {
    dispatch(minimumBuyAlert(data.msg));
  };

  const handleInput = (e) => {
    // Allow only numeric input
    const numericValue = e.target.value.replace(/[^0-9]/g, '');
    setPincode(numericValue);
  };
  const setMetaTitle = (title,brandName) => {
    var metaDescriptionTag = document.querySelector('meta[name="description"]');

    // Check if the meta tag is found
    if (metaDescriptionTag) {
      // Update the content attribute with a new description
      metaDescriptionTag.setAttribute('content', `${brandName} ${title}`)
    }
    document.title = `${brandName} ${title}`
    return title
  }


  return (
    <Fragment>
      <CssBaseline />
      {loading ? (
        <Spinner />
      ) : serverError ? (
        <ServerError />
      ) : (
        <div className={classes.root}>
          {product && attributevalue && attribute && sizeColorQuantity ? (
            <Fragment>
                  <AppBars />
                  {!showSiteMap3 ? (<>
              {/* <CategoryList /> */}
              <MenuHeader />

              <Grid container className={classes.gridSection}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={5}
                  style={{
                    backgroundColor: "#fff",
                    padding: "40px",
                  }}
                >
                 <Box style={{ position: "sticky", top: 70, maxWidth: "100%" }}>
                    {productImages && productImages.length > 0 && (
                      <Grid
                        container
                        spacing={1}
                        style={{ justifyContent: "center" }}
                      >
                        <div style={{ display: "flex" }}>
                          <div className={classes.smallImg} ref={imgRef} >
                            {productImages.map((image, index) => (
                              <img
                                alt={image.imageName}
                                src={`${imageBaseUrl}${image.imageUrl}`}
                                className={classes.smallimgdp}
                                key={index}
                                onClick={() =>
                                  handleSetDefaultImage(image, index)
                                }
                              />
                            ))}
                          </div>

                          <div className={classes.bigImag}>
                            <img
                              alt={productImages[0].imageName}
                              src={`${imageBaseUrl}${defaultImage}`}
                              className={classes.defaultImg}
                              onClick={() => setIsOpen(true)} 
                            />
                          </div>
                        </div>
                        <div>
                          {wishlist && wishlist.length > 0 ? (
                            wishlisted()
                          ) : (
                            <div className={classes.wishlist}>
                              <IconButton
                                aria-label="wishlist"
                                style={{ padding: "6px 10px 6px 5px" }}
                                onClick={() => handleAddToWishlist(product.id)}
                              >
                                <FavoriteBorderIcon
                                  style={{ color: "#ff0000" }}
                                />
                              </IconButton>
                            </div>
                          )}
                        </div>
                      </Grid>
                    )}
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={7}
                  style={{
                    backgroundColor: "#fff",
                    padding: "40px",
                  }}
                >
                  <Fragment>
                    <Typography
                      variant="h2"
                      className={classes.brandTitle}
                    >
                      {product.brandName}
                    </Typography>

                    <Typography variant="h1" className={classes.productTitle}>
                      {setMetaTitle( product.description.title, product.brandName)}
                    </Typography>

                    <div style={{ display: "flex" }}>
                      <Rating
                        name="customized-empty"
                        defaultValue={product.rating}
                        precision={1}
                        size="small"
                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                        readOnly
                      />

                      {product.ratingCount > 0 ? (
                        <Links
                          component="button"
                          className={classes.review}
                          onClick={gotoReviewSection}
                          style={{ outline: "none" }}
                        >
                          ({product.ratingCount} reviews)
                        </Links>
                      ) : (
                        <Typography className={classes.review}>
                          ({product.ratingCount} reviews)
                        </Typography>
                      )}
                    </div>

                    {product.attributes && product.attributes.length > 0 ? (
                      <Fragment>
                        <BargainTimer product={product} />

                        <Fragment>
                          <Typography
                            variant="subtitle2"
                            className={classes.sizelable}
                          >
                            Available Sizes and Colors
                          </Typography>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            <div className={classes.sizediv} ref={myRef}>
                              {product.attributes
                                .sort((a, b) => a.optionId - b.optionId)
                                .map((item, index) => (
                                  <button
                                    key={index}
                                    className={classes.sizeboxItem}
                                    onClick={() =>
                                      handleDisplayColor(item, index)
                                    }
                                    // style={{ outline: "none" }}
                                  >
                                    {item.optionCode === "Watches Size" ||
                                    item.optionCode === "Bag Size" ||
                                    item.optionCode === "Toys Size"
                                      ? "One Size"
                                      : item.optionCode}
                                    {sizeColorQuantity.get(item.optionCode) ? (
                                      ""
                                    ) : (
                                      <div
                                        style={{
                                          border: "1px solid gray",
                                          position: "absolute",
                                          textAlign: "center",
                                          marginTop: "-10px",
                                          width: "28px",
                                        }}
                                        orientation="vertical"
                                      />
                                    )}
                                  </button>
                                ))}
                            </div>

                            <div style={{ margin: "auto" }}>
                              {product.bargain === "NO" &&
                                product.buyable &&
                                !miniCartOpen && (
                                  <>
                                    <Typography
                                      variant="body2"
                                      style={{
                                        textAlign: "center",
                                        color: "#27B23E",
                                      }}
                                    >
                                      Hi, There!
                                    </Typography>
                                    <Tooltip
                                      title={
                                        <h2
                                          style={{
                                            fontSize: 15,
                                            fontFamily:
                                              "'Fira Sans', sans-serif",
                                          }}
                                        >
                                          Hi{" "}
                                          {authenticated
                                            ? account.firstName
                                            : ""}
                                          , I am Minicart. I help you to shop
                                          multiple attributes of this product.
                                        </h2>
                                      }
                                      arrow
                                      className={classes.tooltip}
                                    >
                                      <div
                                        style={{
                                          padding: 10,
                                        }}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/bag.png"
                                          }
                                          alt="stockX"
                                          height="50px"
                                          className={classes.swingimage}
                                          onClick={handleMiniCart}
                                        />
                                      </div>
                                    </Tooltip>
                                  </>
                                )}
                            </div>
                          </div>
                        </Fragment>

                        {Object.keys(attribute).length > 0 && (
                          <Fragment>
                            <Typography
                              variant="subtitle2"
                              className={classes.sizelable}
                            >
                              Colors
                            </Typography>
                            {/* {// console.log(JSON.stringify(attribute))} */}
                            <div className={classes.sizediv}>
                              {attribute.attributeOptionValues.map(
                                (item, index) => (
                                  <Tooltip
                                    classes={{ tooltip: classes.customTooltip }}
                                    title={`${item.optionValueCode}`}
                                    key={index}
                                    arrow
                                    placement="top"
                                  >
                                    <div
                                      className={classes.colorDiv}
                                      style={
                                        item.colorCode === defaultColor
                                          ? {
                                              border: `2px solid #086fcf`,
                                            }
                                          : { border: "2px solid #edeff2" }
                                      }
                                    >
                                      <button
                                        className={classes.colorbox}
                                        style={{
                                          background: `${item.colorCode}`,
                                          borderColor: `${item.colorCode}`,
                                          outline: "none",
                                        }}
                                        onClick={() =>
                                          handleSetAttribute(item, index)
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                )
                              )}
                            </div>

                            {Object.keys(attributevalue).length > 0 &&
                            attributevalue.attributeQuantity <= 0 ? (
                              <Fragment>
                                <Typography
                                  variant="h6"
                                  className={classes.soldout}
                                >
                                  Sold Out
                                </Typography>

                                <Typography
                                  variant="subtitle1"
                                  className={classes.soldoutText}
                                >
                                  This item Size is currently out of stock
                                </Typography>
                              </Fragment>
                            ) : cartDataJsonList ? (
                              cartDataJsonList &&
                              attributevalue.attributeQuantity &&
                              (cartDataJsonList.filter(
                                (vendor) =>
                                  vendor.cartItemattributes[0]
                                    .productAttributeId ===
                                  attributevalue.attributeId
                              ).length ? (
                                cartDataJsonList
                                  .filter(
                                    (vendor) =>
                                      vendor.cartItemattributes[0]
                                        .productAttributeId ===
                                      attributevalue.attributeId
                                  )
                                  .map((cartDataItem, index) =>
                                    quantity >
                                    attributevalue.attributeQuantity -
                                      cartDataItem.quantity ? (
                                      <Fragment key={index}>
                                        <Typography
                                          variant="h6"
                                          className={classes.soldout}
                                        >
                                          Sold Out
                                        </Typography>

                                        <Typography
                                          variant="subtitle1"
                                          className={classes.soldoutText}
                                        >
                                          This item Size is currently out of
                                          stock
                                        </Typography>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        <Typography
                                          variant="subtitle1"
                                          className={classes.initialCount}
                                        >
                                          <EventAvailableIcon
                                            size="small"
                                            style={{ color: "#ff9f00" }}
                                          />{" "}
                                          Items Available -{" "}
                                          {
                                             attributevalue.attributeQuantity  - cartDataItem.quantity}
                                        </Typography>

                                        <div className={classes.qtyBlock}>
                                          <Box
                                            component="div"
                                            className={classes.qtyDiv}
                                          >
                                            <ButtonGroup
                                              style={{
                                                color: "#212121",
                                              }}
                                              aria-label="outlined primary button group"
                                            >
                                              <Button
                                                type="button"
                                                style={{
                                                  color: "#fff",
                                                  fontWeight: 700,
                                                  fontSize: 18,
                                                  backgroundColor: "#ff9d00",
                                                }}
                                                onClick={
                                                  quantity >
                                                  attributevalue.minQuantity
                                                    ? () =>
                                                        setQuantity(
                                                          quantity - 1
                                                        )
                                                    : () =>
                                                        handleQuantityAlert({
                                                          msg: "Sorry, Quantity should be greater than or equal to minimum buy.",
                                                        })
                                                }
                                              >
                                                -
                                              </Button>

                                              <Button
                                                disabled={true}
                                                style={{ color: "#212121" }}
                                              >
                                                {quantity}
                                              </Button>

                                              <Button
                                                type="button"
                                                style={{
                                                  color: "#fff",
                                                  fontWeight: 700,
                                                  fontSize: 18,
                                                  backgroundColor: "#ff9d00",
                                                }}
                                                onClick={
                                                  quantity <
                                                  attributevalue.attributeQuantity -
                                                    cartDataItem.quantity
                                                    ? () =>
                                                        setQuantity(
                                                          quantity + 1
                                                        )
                                                    : () =>
                                                        handleQuantityAlert({
                                                          msg: "Maximum Quantity exceeds.",
                                                        })
                                                }
                                              >
                                                +
                                              </Button>
                                            </ButtonGroup>

                                            <Typography
                                              className={
                                                classes.inclusivetaxtext
                                              }
                                              style={{ marginLeft: 10 }}
                                            >
                                              (Minimum quantity to buy or
                                              bargain)
                                            </Typography>
                                          </Box>

                                          {errors.quantity && (
                                            <Box
                                              style={{
                                                fontSize: "12px",
                                                color: "red",
                                              }}
                                            >
                                              {errors.quantity}
                                            </Box>
                                          )}
                                        </div>

                                        {!open ? (
                                          <Fragment>
                                            <div className={classes.priceblock}>
                                              <Typography
                                                className={classes.priceTitle}
                                              >
                                                Offering Price{" "}
                                                <span
                                                  className={
                                                    classes.inclusivetaxtext
                                                  }
                                                >
                                                  {/* (inclusive of all taxes) */}
                                                </span>
                                              </Typography>
                                              <Typography
                                                className={classes.price}
                                              >
                                                <Typography
                                                  component="span"
                                                  style={{
                                                    fontSize: "30px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  &#x20B9;{" "}
                                                </Typography>
                                                {attributevalue.attributePrice
                                                  ? attributevalue.attributePrice
                                                  : product.price}{" "}
                                                /-
                                              </Typography>
                                            </div>

                                            {product.buyable &&
                                            saleDateValidation ? (
                                              <div
                                                className={
                                                  classes.buttonSection
                                                }
                                              >
                                                {miniCartOpen ? (
                                                  <MiniCart
                                                    handleMiniCartClose={
                                                      handleMiniCartClose
                                                    }
                                                    addToMiniCart={
                                                      addToMiniCart
                                                    }
                                                    miniQuantity={miniQuantity}
                                                  />
                                                ) : (
                                                  <Fragment>
                                                    {saleDateValidation && (
                                                      <Button
                                                        variant="contained"
                                                        className={
                                                          classes.buyButton
                                                        }
                                                        onClick={() =>
                                                          handleAddToCart(
                                                            product.id,
                                                            product.price
                                                          )
                                                        }
                                                      >
                                                        Buy Now
                                                      </Button>
                                                    )}

                                                    {saleDateValidation &&
                                                      product.bargain ===
                                                        "YES" &&
                                                      product.bargainStartDateTime &&
                                                      product.bargainEndDateTime &&
                                                      new Date(
                                                        product.bargainStartDateTime
                                                      ) <= new Date() &&
                                                      new Date(
                                                        product.bargainEndDateTime
                                                      ) >= new Date() && (
                                                        <Button
                                                          variant="contained"
                                                          onClick={
                                                            handleClickOpenBargain
                                                          }
                                                          className={
                                                            classes.bargainButton
                                                          }
                                                        >
                                                          Bargain
                                                        </Button>
                                                      )}
                                                  </Fragment>
                                                )}
                                              </div>
                                            ) : (
                                              <Typography
                                                className={
                                                  classes.disablemessage
                                                }
                                                style={{ fontSize: "13px" }}
                                              >
                                                Seller has disabled this product
                                                for BUY and BARGAIN.
                                                <Typography
                                                  className={
                                                    classes.disablemessage
                                                  }
                                                  style={{ fontSize: "13px" }}
                                                >
                                                  This product will be available
                                                  from :{" "}
                                                  {moment(
                                                    product.dateAvailable
                                                  ).format("Do MMM YYYY")}
                                                </Typography>
                                              </Typography>
                                            )}
                                          </Fragment>
                                        ) : (
                                          <div className={classes.bargain}>
                                            <Box className={classes.closeIcon}>
                                              <IconButton
                                                edge="start"
                                                color="inherit"
                                                onClick={handleCloseBargain}
                                                aria-label="close"
                                              >
                                                <HighlightOffRoundedIcon />
                                              </IconButton>
                                            </Box>

                                            <div
                                              className={classes.bargainPrices}
                                            >
                                              <Typography
                                                className={classes.priceTitle}
                                              >
                                                Offering Price{" "}
                                                <span
                                                  className={
                                                    classes.inclusivetaxtext
                                                  }
                                                >
                                                  (exclusive of all taxes)
                                                </span>
                                              </Typography>

                                              <Typography
                                                className={classes.price}
                                              >
                                                {attributevalue.attributePrice}{" "}
                                                /-
                                              </Typography>
                                            </div>

                                            <Grid
                                              container
                                              className={classes.bargainSec}
                                            >
                                              <Grid item xs={12}>
                                                <InputLabel
                                                  style={{ margin: "4px" }}
                                                >
                                                  Bargain price (per product)
                                                </InputLabel>

                                                <TextField
                                                  id="outlined-number"
                                                  type="tel"
                                                  size="small"
                                                  value={bargainprice}
                                                  variant="outlined"
                                                  onChange={(event) => {
                                                    const value =
                                                      event.target.value;
                                                    if (
                                                      value !== "" &&
                                                      !NUMERIC_ONLY.test(value)
                                                    ) {
                                                      return;
                                                    }
                                                    setBargainPrice(
                                                      event.target.value
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    classes: {
                                                      root: classes.labelRoot,
                                                    },
                                                  }}
                                                  InputProps={{
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Button
                                                          color="default"
                                                          component="span"
                                                          style={{
                                                            backgroundColor:
                                                              "#FF9D00",
                                                            left: 13,
                                                            color: "#fff",
                                                          }}
                                                          onClick={() =>
                                                            handleSubmitBargain(
                                                              product,
                                                              attributevalue.productWithAttrPrice
                                                                ? attributevalue.productWithAttrPrice
                                                                : product.price
                                                            )
                                                          }
                                                        >
                                                          Submit
                                                        </Button>
                                                      </InputAdornment>
                                                    ),
                                                    classes: {
                                                      root: classes.labelRoot,
                                                    },
                                                  }}
                                                  helperText={
                                                    errors.bargainprice
                                                      ? errors.bargainprice
                                                      : ""
                                                  }
                                                />
                                              </Grid>

                                              <Grid item xs={12}>
                                                <Grid
                                                  container
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    lg={3}
                                                    md={4}
                                                    sm={4}
                                                    xs={12}
                                                  >
                                                    <InputLabel>
                                                      Min Qty.
                                                    </InputLabel>
                                                    <TextField
                                                      disabled
                                                      id="outlined-number"
                                                      type="number"
                                                      size="small"
                                                      value={quantity}
                                                      variant="outlined"
                                                      style={{
                                                        minWidth: 75,
                                                        margin: 10,
                                                      }}
                                                    />
                                                  </Grid>

                                                  <Grid
                                                    item
                                                    lg={3}
                                                    md={4}
                                                    sm={4}
                                                    xs={12}
                                                  >
                                                    <InputLabel>
                                                      Bargain Price
                                                    </InputLabel>
                                                    <TextField
                                                      disabled
                                                      id="outlined-number"
                                                      type="number"
                                                      size="small"
                                                      value={bargainprice}
                                                      variant="outlined"
                                                      style={{
                                                        minWidth: 75,
                                                        margin: 10,
                                                      }}
                                                    />
                                                  </Grid>

                                                  <Grid
                                                    item
                                                    lg={3}
                                                    md={4}
                                                    sm={4}
                                                    xs={12}
                                                  >
                                                    <InputLabel>
                                                      Total
                                                    </InputLabel>
                                                    <TextField
                                                      disabled
                                                      id="outlined-number"
                                                      type="number"
                                                      size="small"
                                                      value={
                                                        bargainprice * quantity
                                                      }
                                                      variant="outlined"
                                                      style={{
                                                        minWidth: 75,
                                                        margin: 10,
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        )}

                                        <TextField
                                          id="pinCode"
                                          name="pinCode"
                                          type="text"
                                          inputRef={textRef}
                                          autoComplete="off"
                                          disabled={
                                            availability
                                              ? true
                                              : notAvailable
                                              ? true
                                              : false
                                          }
                                          variant="outlined"
                                          size="small"
                                          placeholder="Enter pincode"
                                          value={pincode}
                                          onChange={handleInput}
                                          className={classes.pincodebox}
                                          inputProps={{ maxLength: 6 }}
                                          InputProps={
                                            availability
                                              ? {
                                                  classes: {
                                                    input: classes.input,
                                                  },
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                          handleChangePincode
                                                        }
                                                        edge="end"
                                                      >
                                                        change
                                                      </IconButton>
                                                    </InputAdornment>
                                                  ),
                                                }
                                              : notAvailable
                                              ? {
                                                  classes: {
                                                    input: classes.input,
                                                  },
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                          handleChangePincode
                                                        }
                                                        edge="end"
                                                      >
                                                        change
                                                      </IconButton>
                                                    </InputAdornment>
                                                  ),
                                                }
                                              : {
                                                  classes: {
                                                    input: classes.input,
                                                  },
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                          handleCheckPincode
                                                        }
                                                        edge="end"
                                                      >
                                                        check
                                                      </IconButton>
                                                    </InputAdornment>
                                                  ),
                                                }
                                          }
                                        />

                                        {availability ? (
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.wrapIcon}
                                          >
                                            <LocalShippingIcon
                                              className={classes.linkIcon}
                                            />{" "}
                                            {availability.message}
                                          </Typography>
                                        ) : notAvailable ? (
                                          <Typography
                                            className={classes.nodelivery}
                                          >
                                            {notAvailable}
                                          </Typography>
                                        ) : (
                                          <FormLabel>
                                            Please enter <b>PIN CODE</b> to
                                            check Delivery Availability
                                          </FormLabel>
                                        )}

                                        {product.bargain === "YES" && (
                                          <Box
                                            component="div"
                                            style={{ marginTop: 16 }}
                                          >
                                            <Button
                                              variant="contained"
                                              className={classes.barginSection}
                                              onClick={() =>
                                                handleClickOpenBids("allBids")
                                              }
                                            >
                                              All Price
                                            </Button>

                                            <Button
                                              variant="contained"
                                              className={classes.barginSection}
                                              onClick={() =>
                                                handleClickOpenBids("allAsks")
                                              }
                                            >
                                              All Asks
                                            </Button>
                                          </Box>
                                        )}

                                        <Typography className={classes.county}>
                                          Country of Origin:{" "}
                                          {product.countryOfOrigin}
                                        </Typography>

                                        <Typography
                                          className={classes.descSection}
                                          component="div"
                                        >
                                          <Typography
                                            component="div"
                                            className={classes.descTitle}
                                          >
                                            Product Description
                                          </Typography>

                                          <Typography
                                            component="div"
                                            className={classes.descList}
                                          >
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                 attributevalue.attiributeDescription,
                                              }}
                                            />
                                          </Typography>
                                        </Typography>
                                      </Fragment>
                                    )
                                  )
                              ) : (
                                <Fragment>
                                  <Typography
                                    variant="subtitle1"
                                    className={classes.initialCount}
                                  >
                                    <EventAvailableIcon
                                      size="small"
                                      style={{ color: "#ff9f00" }}
                                    />{" "}
                                    Items Available -{" "}
                                    { attributevalue.attributeQuantity}
                                  </Typography>

                                  <div className={classes.qtyBlock}>
                                    <Box
                                      component="div"
                                      className={classes.qtyDiv}
                                    >
                                      <ButtonGroup
                                        style={{
                                          color: "#212121",
                                        }}
                                        aria-label="outlined primary button group"
                                      >
                                        <Button
                                          type="button"
                                          style={{
                                            color: "#fff",
                                            fontWeight: 700,
                                            fontSize: 18,
                                            backgroundColor: "#ff9d00",
                                          }}
                                          onClick={
                                            quantity >
                                            attributevalue.minQuantity
                                              ? () => setQuantity(quantity - 1)
                                              : () =>
                                                  handleQuantityAlert({
                                                    msg: "Sorry, Quantity should be greater than or equal to minimum buy.",
                                                  })
                                          }
                                        >
                                          -
                                        </Button>

                                        <Button
                                          disabled={true}
                                          style={{ color: "#212121" }}
                                        >
                                          {quantity}
                                        </Button>

                                        <Button
                                          type="button"
                                          style={{
                                            color: "#fff",
                                            fontWeight: 700,
                                            fontSize: 18,
                                            backgroundColor: "#ff9d00",
                                          }}
                                          onClick={
                                            quantity <
                                            attributevalue.attributeQuantity
                                              ? () => setQuantity(quantity + 1)
                                              : () =>
                                                  handleQuantityAlert({
                                                    msg: "Maximum Quantity exceeds.",
                                                  })
                                          }
                                        >
                                          +
                                        </Button>
                                      </ButtonGroup>

                                      <Typography
                                        className={classes.inclusivetaxtext}
                                        style={{ marginLeft: 10 }}
                                      >
                                        (Minimum quantity to buy or bargain)
                                      </Typography>
                                    </Box>

                                    {errors.quantity && (
                                      <Box
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                        }}
                                      >
                                        {errors.quantity}
                                      </Box>
                                    )}
                                  </div>

                                  {!open ? (
                                    <Fragment>
                                      <div className={classes.priceblock}>
                                        <Typography
                                          className={classes.priceTitle}
                                        >
                                          Offering Price{" "}
                                          <span
                                            className={classes.inclusivetaxtext}
                                          >
                                            {/* (inclusive of all taxes) */}
                                          </span>
                                        </Typography>
                                        <Typography className={classes.price}>
                                          <Typography
                                            component="span"
                                            style={{
                                              fontSize: "30px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            &#x20B9;{" "}
                                          </Typography>
                                          {attributevalue.attributePrice
                                            ? attributevalue.attributePrice
                                            : product.price}{" "}
                                          /-
                                        </Typography>
                                      </div>

                                      {product.buyable && saleDateValidation ? (
                                        <div className={classes.buttonSection}>
                                          {miniCartOpen ? (
                                            <MiniCart
                                              handleMiniCartClose={
                                                handleMiniCartClose
                                              }
                                              addToMiniCart={addToMiniCart}
                                              miniQuantity={miniQuantity}
                                            />
                                          ) : (
                                            <Fragment>
                                              {saleDateValidation && (
                                                <Button
                                                  variant="contained"
                                                  className={classes.buyButton}
                                                  onClick={() =>
                                                    handleAddToCart(
                                                      product.id,
                                                      product.price
                                                    )
                                                  }
                                                >
                                                  Buy Now
                                                </Button>
                                              )}

                                              {saleDateValidation &&
                                                product.bargain === "YES" &&
                                                product.bargainStartDateTime &&
                                                product.bargainEndDateTime &&
                                                new Date(
                                                  product.bargainStartDateTime
                                                ) <= new Date() &&
                                                new Date(
                                                  product.bargainEndDateTime
                                                ) >= new Date() && (
                                                  <Button
                                                    variant="contained"
                                                    onClick={
                                                      handleClickOpenBargain
                                                    }
                                                    className={
                                                      classes.bargainButton
                                                    }
                                                  >
                                                    Bargain
                                                  </Button>
                                                )}
                                            </Fragment>
                                          )}
                                        </div>
                                      ) : (
                                        <Typography
                                          className={classes.disablemessage}
                                          style={{ fontSize: "13px" }}
                                        >
                                          Seller has disabled this product for
                                          BUY and BARGAIN.
                                          <Typography
                                            className={classes.disablemessage}
                                            style={{ fontSize: "13px" }}
                                          >
                                            This product will be available from
                                            :{" "}
                                            {moment(
                                              product.dateAvailable
                                            ).format("Do MMM YYYY")}
                                          </Typography>
                                        </Typography>
                                      )}
                                    </Fragment>
                                  ) : (
                                    <div className={classes.bargain}>
                                      <Box className={classes.closeIcon}>
                                        <IconButton
                                          edge="start"
                                          color="inherit"
                                          onClick={handleCloseBargain}
                                          aria-label="close"
                                        >
                                          <HighlightOffRoundedIcon />
                                        </IconButton>
                                      </Box>

                                      <div className={classes.bargainPrices}>
                                        <Typography
                                          className={classes.priceTitle}
                                        >
                                          Offering Price{" "}
                                          <span
                                            className={classes.inclusivetaxtext}
                                          >
                                            (exclusive of all taxes)
                                          </span>
                                        </Typography>

                                        <Typography className={classes.price}>
                                          {attributevalue.attributePrice} /-
                                        </Typography>
                                      </div>

                                      <Grid
                                        container
                                        className={classes.bargainSec}
                                      >
                                        <Grid item xs={12}>
                                          <InputLabel style={{ margin: "4px" }}>
                                            Bargain price (per product)
                                          </InputLabel>

                                          <TextField
                                            id="outlined-number"
                                            type="tel"
                                            size="small"
                                            value={bargainprice}
                                            variant="outlined"
                                            onChange={(event) => {
                                              const value = event.target.value;
                                              if (
                                                value !== "" &&
                                                !NUMERIC_ONLY.test(value)
                                              ) {
                                                return;
                                              }
                                              setBargainPrice(
                                                event.target.value
                                              );
                                            }}
                                            InputLabelProps={{
                                              classes: {
                                                root: classes.labelRoot,
                                              },
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Button
                                                    color="default"
                                                    component="span"
                                                    style={{
                                                      backgroundColor:
                                                        "#FF9D00",
                                                      left: 13,
                                                      color: "#fff",
                                                    }}
                                                    onClick={() =>
                                                      handleSubmitBargain(
                                                        product,
                                                        attributevalue.productWithAttrPrice
                                                          ? attributevalue.productWithAttrPrice
                                                          : product.price
                                                      )
                                                    }
                                                  >
                                                    Submit
                                                  </Button>
                                                </InputAdornment>
                                              ),
                                              classes: {
                                                root: classes.labelRoot,
                                              },
                                            }}
                                            helperText={
                                              errors.bargainprice
                                                ? errors.bargainprice
                                                : ""
                                            }
                                          />
                                        </Grid>

                                        <Grid item xs={12}>
                                          <Grid
                                            container
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Grid
                                              item
                                              lg={3}
                                              md={4}
                                              sm={4}
                                              xs={12}
                                            >
                                              <InputLabel>Min Qty.</InputLabel>
                                              <TextField
                                                disabled
                                                id="outlined-number"
                                                type="number"
                                                size="small"
                                                value={quantity}
                                                variant="outlined"
                                                style={{
                                                  minWidth: 75,
                                                  margin: 10,
                                                }}
                                              />
                                            </Grid>

                                            <Grid
                                              item
                                              lg={3}
                                              md={4}
                                              sm={4}
                                              xs={12}
                                            >
                                              <InputLabel>
                                                Bargain Price
                                              </InputLabel>
                                              <TextField
                                                disabled
                                                id="outlined-number"
                                                type="number"
                                                size="small"
                                                value={bargainprice}
                                                variant="outlined"
                                                style={{
                                                  minWidth: 75,
                                                  margin: 10,
                                                }}
                                              />
                                            </Grid>

                                            <Grid
                                              item
                                              lg={3}
                                              md={4}
                                              sm={4}
                                              xs={12}
                                            >
                                              <InputLabel>Total</InputLabel>
                                              <TextField
                                                disabled
                                                id="outlined-number"
                                                type="number"
                                                size="small"
                                                value={bargainprice * quantity}
                                                variant="outlined"
                                                style={{
                                                  minWidth: 75,
                                                  margin: 10,
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  )}

                                  <TextField
                                    id="pinCode"
                                    name="pinCode"
                                    type="text"
                                    inputRef={textRef}
                                    autoComplete="off"
                                    disabled={
                                      availability
                                        ? true
                                        : notAvailable
                                        ? true
                                        : false
                                    }
                                    variant="outlined"
                                    size="small"
                                    placeholder="Enter pincode"
                                    value={pincode}
                                    onChange={handleInput}
                                    className={classes.pincodebox}
                                    inputProps={{ maxLength: 6 }}
                                    InputProps={
                                      availability
                                        ? {
                                            classes: { input: classes.input },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleChangePincode}
                                                  edge="end"
                                                >
                                                  change
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }
                                        : notAvailable
                                        ? {
                                            classes: { input: classes.input },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleChangePincode}
                                                  edge="end"
                                                >
                                                  change
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }
                                        : {
                                            classes: { input: classes.input },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleCheckPincode}
                                                  edge="end"
                                                >
                                                  check
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }
                                    }
                                  />

                                  {availability ? (
                                    <Typography
                                      variant="subtitle1"
                                      className={classes.wrapIcon}
                                    >
                                      <LocalShippingIcon
                                        className={classes.linkIcon}
                                      />{" "}
                                      {availability.message}
                                    </Typography>
                                  ) : notAvailable ? (
                                    <Typography className={classes.nodelivery}>
                                      {notAvailable}
                                    </Typography>
                                  ) : (
                                    <FormLabel>
                                      Please enter <b>PIN CODE</b> to check
                                      Delivery Availability
                                    </FormLabel>
                                  )}

                                  {product.bargain === "YES" && (
                                    <Box
                                      component="div"
                                      style={{ marginTop: 16 }}
                                    >
                                      <Button
                                        variant="contained"
                                        className={classes.barginSection}
                                        onClick={() =>
                                          handleClickOpenBids("allBids")
                                        }
                                      >
                                        All Price
                                      </Button>

                                      <Button
                                        variant="contained"
                                        className={classes.barginSection}
                                        onClick={() =>
                                          handleClickOpenBids("allAsks")
                                        }
                                      >
                                        All Asks
                                      </Button>
                                    </Box>
                                  )}

                                  <Typography className={classes.county}>
                                    Country of Origin: {product.countryOfOrigin}
                                  </Typography>

                                  <Typography
                                    className={classes.descSection}
                                    component="div"
                                  >
                                    <Typography
                                      component="div"
                                      className={classes.descTitle}
                                    >
                                      Product Description
                                    </Typography>

                                    <Typography
                                      component="div"
                                      className={classes.descList}
                                    >
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            attributevalue.attiributeDescription,
                                        }}
                                      />
                                    </Typography>
                                  </Typography>
                                </Fragment>
                              ))
                            ) : attributevalue.attributeQuantity &&
                              attributevalue.attributeQuantity.length ? (
                              quantity >
                              attributevalue.attributeQuantity - 0 ? (
                                <Fragment>
                                  <Typography
                                    variant="h6"
                                    className={classes.soldout}
                                  >
                                    Sold Out
                                  </Typography>

                                  <Typography
                                    variant="subtitle1"
                                    className={classes.soldoutText}
                                  >
                                    This item Size is currently out of stock
                                  </Typography>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <Typography
                                    variant="subtitle1"
                                    className={classes.initialCount}
                                  >
                                    <EventAvailableIcon
                                      size="small"
                                      style={{ color: "#ff9f00" }}
                                    />{" "}
                                    Items Available -{" "}
                                    { attributevalue.attributeQuantity}
                                  </Typography>

                                  <div className={classes.qtyBlock}>
                                    <Box
                                      component="div"
                                      className={classes.qtyDiv}
                                    >
                                      <ButtonGroup
                                        style={{
                                          color: "#212121",
                                        }}
                                        aria-label="outlined primary button group"
                                      >
                                        <Button
                                          type="button"
                                          style={{
                                            color: "#fff",
                                            fontWeight: 700,
                                            fontSize: 18,
                                            backgroundColor: "#ff9d00",
                                          }}
                                          onClick={
                                            quantity >
                                            attributevalue.minQuantity
                                              ? () => setQuantity(quantity - 1)
                                              : () =>
                                                  handleQuantityAlert({
                                                    msg: "Sorry, Quantity should be greater than or equal to minimum buy.",
                                                  })
                                          }
                                        >
                                          -
                                        </Button>

                                        <Button
                                          disabled={true}
                                          style={{ color: "#212121" }}
                                        >
                                      {quantity}
                                        </Button>
                                        <Button
                                          type="button"
                                          style={{
                                            color: "#fff",
                                            fontWeight: 700,
                                            fontSize: 18,
                                            backgroundColor: "#ff9d00",
                                          }}
                                          onClick={
                                            quantity <
                                            attributevalue.attributeQuantity - 0
                                              ? () => setQuantity(quantity + 1)
                                              : () =>
                                                  handleQuantityAlert({
                                                    msg: "Maximum Quantity exceeds.",
                                                  })
                                          }
                                        >
                                          +
                                        </Button>
                                      </ButtonGroup>

                                      <Typography
                                        className={classes.inclusivetaxtext}
                                        style={{ marginLeft: 10 }}
                                      >
                                        (Minimum quantity to buy or bargain)
                                      </Typography>
                                    </Box>

                                    {errors.quantity && (
                                      <Box
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                        }}
                                      >
                                        {errors.quantity}
                                      </Box>
                                    )}
                                  </div>

                                  {!open ? (
                                    <Fragment>
                                      <div className={classes.priceblock}>
                                        <Typography
                                          className={classes.priceTitle}
                                        >
                                          Offering Price{" "}
                                          <span
                                            className={classes.inclusivetaxtext}
                                          >
                                            {/* (inclusive of all taxes) */}
                                          </span>
                                        </Typography>
                                        <Typography className={classes.price}>
                                          <Typography
                                            component="span"
                                            style={{
                                              fontSize: "30px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            &#x20B9;{" "}
                                          </Typography>
                                          {attributevalue.attributePrice
                                            ? attributevalue.attributePrice
                                            : product.price}{" "}
                                          /-
                                        </Typography>
                                      </div>

                                      {product.buyable && saleDateValidation ? (
                                        <div className={classes.buttonSection}>
                                          {miniCartOpen ? (
                                            <MiniCart
                                              handleMiniCartClose={
                                                handleMiniCartClose
                                              }
                                              addToMiniCart={addToMiniCart}
                                              miniQuantity={miniQuantity}
                                            />
                                          ) : (
                                            <Fragment>
                                              {saleDateValidation && (
                                                <Button
                                                  variant="contained"
                                                  className={classes.buyButton}
                                                  onClick={() =>
                                                    handleAddToCart(
                                                      product.id,
                                                      product.price
                                                    )
                                                  }
                                                >
                                                  Buy Now
                                                </Button>
                                              )}

                                              {saleDateValidation &&
                                                product.bargain === "YES" &&
                                                product.bargainStartDateTime &&
                                                product.bargainEndDateTime &&
                                                new Date(
                                                  product.bargainStartDateTime
                                                ) <= new Date() &&
                                                new Date(
                                                  product.bargainEndDateTime
                                                ) >= new Date() && (
                                                  <Button
                                                    variant="contained"
                                                    onClick={
                                                      handleClickOpenBargain
                                                    }
                                                    className={
                                                      classes.bargainButton
                                                    }
                                                  >
                                                    Bargain
                                                  </Button>
                                                )}
                                            </Fragment>
                                          )}
                                        </div>
                                      ) : (
                                        <Typography
                                          className={classes.disablemessage}
                                          style={{ fontSize: "13px" }}
                                        >
                                          Seller has disabled this product for
                                          BUY and BARGAIN.
                                          <Typography
                                            className={classes.disablemessage}
                                            style={{ fontSize: "13px" }}
                                          >
                                            This product will be available from
                                            :{" "}
                                            {moment(
                                              product.dateAvailable
                                            ).format("Do MMM YYYY")}
                                          </Typography>
                                        </Typography>
                                      )}
                                    </Fragment>
                                  ) : (
                                    <div className={classes.bargain}>
                                      <Box className={classes.closeIcon}>
                                        <IconButton
                                          edge="start"
                                          color="inherit"
                                          onClick={handleCloseBargain}
                                          aria-label="close"
                                        >
                                          <HighlightOffRoundedIcon />
                                        </IconButton>
                                      </Box>

                                      <div className={classes.bargainPrices}>
                                        <Typography
                                          className={classes.priceTitle}
                                        >
                                          Offering Price{" "}
                                          <span
                                            className={classes.inclusivetaxtext}
                                          >
                                            (exclusive of all taxes)
                                          </span>
                                        </Typography>

                                        <Typography className={classes.price}>
                                          {attributevalue.attributePrice} /-
                                        </Typography>
                                      </div>

                                      <Grid
                                        container
                                        className={classes.bargainSec}
                                      >
                                        <Grid item xs={12}>
                                          <InputLabel style={{ margin: "4px" }}>
                                            Bargain price (per product)
                                          </InputLabel>

                                          <TextField
                                            id="outlined-number"
                                            type="tel"
                                            size="small"
                                            value={bargainprice}
                                            variant="outlined"
                                            onChange={(event) => {
                                              const value = event.target.value;
                                              if (
                                                value !== "" &&
                                                !NUMERIC_ONLY.test(value)
                                              ) {
                                                return;
                                              }
                                              setBargainPrice(
                                                event.target.value
                                              );
                                            }}
                                            InputLabelProps={{
                                              classes: {
                                                root: classes.labelRoot,
                                              },
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Button
                                                    color="default"
                                                    component="span"
                                                    style={{
                                                      backgroundColor:
                                                        "#FF9D00",
                                                      left: 13,
                                                      color: "#fff",
                                                    }}
                                                    onClick={() =>
                                                      handleSubmitBargain(
                                                        product,
                                                        attributevalue.productWithAttrPrice
                                                          ? attributevalue.productWithAttrPrice
                                                          : product.price
                                                      )
                                                    }
                                                  >
                                                    Submit
                                                  </Button>
                                                </InputAdornment>
                                              ),
                                              classes: {
                                                root: classes.labelRoot,
                                              },
                                            }}
                                            helperText={
                                              errors.bargainprice
                                                ? errors.bargainprice
                                                : ""
                                            }
                                          />
                                        </Grid>

                                        <Grid item xs={12}>
                                          <Grid
                                            container
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Grid
                                              item
                                              lg={3}
                                              md={4}
                                              sm={4}
                                              xs={12}
                                            >
                                              <InputLabel>Min Qty.</InputLabel>
                                              <TextField
                                                disabled
                                                id="outlined-number"
                                                type="number"
                                                size="small"
                                                value={quantity}
                                                variant="outlined"
                                                style={{
                                                  minWidth: 75,
                                                  margin: 10,
                                                }}
                                              />
                                            </Grid>

                                            <Grid
                                              item
                                              lg={3}
                                              md={4}
                                              sm={4}
                                              xs={12}
                                            >
                                              <InputLabel>
                                                Bargain Price
                                              </InputLabel>
                                              <TextField
                                                disabled
                                                id="outlined-number"
                                                type="number"
                                                size="small"
                                                value={bargainprice}
                                                variant="outlined"
                                                style={{
                                                  minWidth: 75,
                                                  margin: 10,
                                                }}
                                              />
                                            </Grid>

                                            <Grid
                                              item
                                              lg={3}
                                              md={4}
                                              sm={4}
                                              xs={12}
                                            >
                                              <InputLabel>Total</InputLabel>
                                              <TextField
                                                disabled
                                                id="outlined-number"
                                                type="number"
                                                size="small"
                                                value={bargainprice * quantity}
                                                variant="outlined"
                                                style={{
                                                  minWidth: 75,
                                                  margin: 10,
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  )}

                                  <TextField
                                    id="pinCode"
                                    name="pinCode"
                                    type="text"
                                    inputRef={textRef}
                                    autoComplete="off"
                                    disabled={
                                      availability
                                        ? true
                                        : notAvailable
                                        ? true
                                        : false
                                    }
                                    variant="outlined"
                                    size="small"
                                    placeholder="Enter pincode"
                                    value={pincode}
                                    onChange={handleInput}
                                    className={classes.pincodebox}
                                    inputProps={{ maxLength: 6 }}
                                    InputProps={
                                      availability
                                        ? {
                                            classes: {
                                              input: classes.input,
                                            },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleChangePincode}
                                                  edge="end"
                                                >
                                                  change
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }
                                        : notAvailable
                                        ? {
                                            classes: {
                                              input: classes.input,
                                            },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleChangePincode}
                                                  edge="end"
                                                >
                                                  change
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }
                                        : {
                                            classes: {
                                              input: classes.input,
                                            },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleCheckPincode}
                                                  edge="end"
                                                >
                                                  check
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }
                                    }
                                  />

                                  {availability ? (
                                    <Typography
                                      variant="subtitle1"
                                      className={classes.wrapIcon}
                                    >
                                      <LocalShippingIcon
                                        className={classes.linkIcon}
                                      />{" "}
                                      {availability.message}
                                    </Typography>
                                  ) : notAvailable ? (
                                    <Typography className={classes.nodelivery}>
                                      {notAvailable}
                                    </Typography>
                                  ) : (
                                    <FormLabel>
                                      Please enter <b>PIN CODE</b> to check
                                      Delivery Availability
                                    </FormLabel>
                                  )}

                                  {product.bargain === "YES" && (
                                    <Box
                                      component="div"
                                      style={{ marginTop: 16 }}
                                    >
                                      <Button
                                        variant="contained"
                                        className={classes.barginSection}
                                        onClick={() =>
                                          handleClickOpenBids("allBids")
                                        }
                                      >
                                        All Price
                                      </Button>

                                      <Button
                                        variant="contained"
                                        className={classes.barginSection}
                                        onClick={() =>
                                          handleClickOpenBids("allAsks")
                                        }
                                      >
                                        All Asks
                                      </Button>
                                    </Box>
                                  )}

                                  <Typography className={classes.county}>
                                    Country of Origin: {product.countryOfOrigin}
                                  </Typography>

                                  <Typography
                                    className={classes.descSection}
                                    component="div"
                                  >
                                    <Typography
                                      component="div"
                                      className={classes.descTitle}
                                    >
                                      Product Description
                                    </Typography>

                                    <Typography
                                      component="div"
                                      className={classes.descList}
                                    >
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            attributevalue.attiributeDescription,
                                        }}
                                      />
                                    </Typography>
                                  </Typography>
                                </Fragment>
                              )
                            ) : (
                              <Fragment>
                                <Typography
                                  variant="subtitle1"
                                  className={classes.initialCount}
                                >
                                  <EventAvailableIcon
                                    size="small"
                                    style={{ color: "#ff9f00" }}
                                  />{" "}
                                  Items Available -{" "}
                                  { attributevalue.attributeQuantity }
                                </Typography>

                                <div className={classes.qtyBlock}>
                                  <Box
                                    component="div"
                                    className={classes.qtyDiv}
                                  >
                                    <ButtonGroup
                                      style={{
                                        color: "#212121",
                                      }}
                                      aria-label="outlined primary button group"
                                    >
                                      <Button
                                        type="button"
                                        style={{
                                          color: "#fff",
                                          fontWeight: 700,
                                          fontSize: 18,
                                          backgroundColor: "#ff9d00",
                                        }}
                                        onClick={
                                          quantity > attributevalue.minQuantity
                                            ? () => setQuantity(quantity - 1)
                                            : () =>
                                                handleQuantityAlert({
                                                  msg: "Sorry, Quantity should be greater than or equal to minimum buy.",
                                                })
                                        }
                                      >
                                        -
                                      </Button>

                                      <Button
                                        disabled={true}
                                        style={{ color: "#212121" }}
                                      >
                                        {quantity}
                                      </Button>

                                      <Button
                                        type="button"
                                        style={{
                                          color: "#fff",
                                          fontWeight: 700,
                                          fontSize: 18,
                                          backgroundColor: "#ff9d00",
                                        }}
                                        onClick={
                                          quantity <
                                          attributevalue.attributeQuantity
                                            ? () => setQuantity(quantity + 1)
                                            : () =>
                                                handleQuantityAlert({
                                                  msg: "Maximum Quantity exceeds.",
                                                })
                                        }
                                      >
                                        +
                                      </Button>
                                    </ButtonGroup>

                                    <Typography
                                      className={classes.inclusivetaxtext}
                                      style={{ marginLeft: 10 }}
                                    >
                                      (Minimum quantity to buy or bargain)
                                    </Typography>
                                  </Box>

                                  {errors.quantity && (
                                    <Box
                                      style={{
                                        fontSize: "12px",
                                        color: "red",
                                      }}
                                    >
                                      {errors.quantity}
                                    </Box>
                                  )}
                                </div>

                                {!open ? (
                                  <Fragment>
                                    <div className={classes.priceblock}>
                                      <Typography
                                        className={classes.priceTitle}
                                      >
                                        Offering Price{" "}
                                        <span
                                          className={classes.inclusivetaxtext}
                                        >
                                          {/* (inclusive of all taxes) */}
                                        </span>
                                      </Typography>
                                      <Typography className={classes.price}>
                                        <Typography
                                          component="span"
                                          style={{
                                            fontSize: "30px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          &#x20B9;{" "}
                                        </Typography>
                                        {attributevalue.attributePrice
                                          ? attributevalue.attributePrice
                                          : product.price}{" "}
                                        /-
                                      </Typography>
                                    </div>

                                    {product.buyable && saleDateValidation ? (
                                      <div className={classes.buttonSection}>
                                        {miniCartOpen ? (
                                          <MiniCart
                                            handleMiniCartClose={
                                              handleMiniCartClose
                                            }
                                            addToMiniCart={addToMiniCart}
                                            miniQuantity={miniQuantity}
                                          />
                                        ) : (
                                          <Fragment>
                                            {saleDateValidation && (
                                              <Button
                                                variant="contained"
                                                className={classes.buyButton}
                                                onClick={() =>
                                                  handleAddToCart(
                                                    product.id,
                                                    product.price
                                                  )
                                                }
                                              >
                                                Buy Now
                                              </Button>
                                            )}

                                            {saleDateValidation &&
                                              product.bargain === "YES" &&
                                              product.bargainStartDateTime &&
                                              product.bargainEndDateTime &&
                                              new Date(
                                                product.bargainStartDateTime
                                              ) <= new Date() &&
                                              new Date(
                                                product.bargainEndDateTime
                                              ) >= new Date() && (
                                                <Button
                                                  variant="contained"
                                                  onClick={
                                                    handleClickOpenBargain
                                                  }
                                                  className={
                                                    classes.bargainButton
                                                  }
                                                >
                                                  Bargain
                                                </Button>
                                              )}
                                          </Fragment>
                                        )}
                                      </div>
                                    ) : (
                                      <Typography
                                        className={classes.disablemessage}
                                        style={{ fontSize: "13px" }}
                                      >
                                        Seller has disabled this product for BUY
                                        and BARGAIN.
                                        <Typography
                                          className={classes.disablemessage}
                                          style={{ fontSize: "13px" }}
                                        >
                                          This product will be available from :{" "}
                                          {moment(product.dateAvailable).format(
                                            "Do MMM YYYY"
                                          )}
                                        </Typography>
                                      </Typography>
                                    )}
                                  </Fragment>
                                ) : (
                                  <div className={classes.bargain}>
                                    <Box className={classes.closeIcon}>
                                      <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseBargain}
                                        aria-label="close"
                                      >
                                        <HighlightOffRoundedIcon />
                                      </IconButton>
                                    </Box>

                                    <div className={classes.bargainPrices}>
                                      <Typography
                                        className={classes.priceTitle}
                                      >
                                        Offering Price{" "}
                                        <span
                                          className={classes.inclusivetaxtext}
                                        >
                                          (exclusive of all taxes)
                                        </span>
                                      </Typography>

                                      <Typography className={classes.price}>
                                        {attributevalue.attributePrice} /-
                                      </Typography>
                                    </div>

                                    <Grid
                                      container
                                      className={classes.bargainSec}
                                    >
                                      <Grid item xs={12}>
                                        <InputLabel style={{ margin: "4px" }}>
                                          Bargain price (per product)
                                        </InputLabel>

                                        <TextField
                                          id="outlined-number"
                                          type="tel"
                                          size="small"
                                          value={bargainprice}
                                          variant="outlined"
                                          onChange={(event) => {
                                            const value = event.target.value;
                                            if (
                                              value !== "" &&
                                              !NUMERIC_ONLY.test(value)
                                            ) {
                                              return;
                                            }
                                            setBargainPrice(event.target.value);
                                          }}
                                          InputLabelProps={{
                                            classes: {
                                              root: classes.labelRoot,
                                            },
                                          }}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Button
                                                  color="default"
                                                  component="span"
                                                  style={{
                                                    backgroundColor: "#FF9D00",
                                                    left: 13,
                                                    color: "#fff",
                                                  }}
                                                  onClick={() =>
                                                    handleSubmitBargain(
                                                      product,
                                                      attributevalue.productWithAttrPrice
                                                        ? attributevalue.productWithAttrPrice
                                                        : product.price
                                                    )
                                                  }
                                                >
                                                  Submit
                                                </Button>
                                              </InputAdornment>
                                            ),
                                            classes: {
                                              root: classes.labelRoot,
                                            },
                                          }}
                                          helperText={
                                            errors.bargainprice
                                              ? errors.bargainprice
                                              : ""
                                          }
                                        />
                                      </Grid>

                                      <Grid item xs={12}>
                                        <Grid
                                          container
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Grid
                                            item
                                            lg={3}
                                            md={4}
                                            sm={4}
                                            xs={12}
                                          >
                                            <InputLabel>Min Qty.</InputLabel>
                                            <TextField
                                              disabled
                                              id="outlined-number"
                                              type="number"
                                              size="small"
                                              value={quantity}
                                              variant="outlined"
                                              style={{
                                                minWidth: 75,
                                                margin: 10,
                                              }}
                                            />
                                          </Grid>

                                          <Grid
                                            item
                                            lg={3}
                                            md={4}
                                            sm={4}
                                            xs={12}
                                          >
                                            <InputLabel>
                                              Bargain Price
                                            </InputLabel>
                                            <TextField
                                              disabled
                                              id="outlined-number"
                                              type="number"
                                              size="small"
                                              value={bargainprice}
                                              variant="outlined"
                                              style={{
                                                minWidth: 75,
                                                margin: 10,
                                              }}
                                            />
                                          </Grid>

                                          <Grid
                                            item
                                            lg={3}
                                            md={4}
                                            sm={4}
                                            xs={12}
                                          >
                                            <InputLabel>Total</InputLabel>
                                            <TextField
                                              disabled
                                              id="outlined-number"
                                              type="number"
                                              size="small"
                                              value={bargainprice * quantity}
                                              variant="outlined"
                                              style={{
                                                minWidth: 75,
                                                margin: 10,
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                )}

                                <TextField
                                  id="pinCode"
                                  name="pinCode"
                                  type="text"
                                  inputRef={textRef}
                                  autoComplete="off"
                                  disabled={
                                    availability
                                      ? true
                                      : notAvailable
                                      ? true
                                      : false
                                  }
                                  variant="outlined"
                                  size="small"
                                  placeholder="Enter pincode"
                                  value={pincode}
                                  onChange={handleInput}
                                  className={classes.pincodebox}
                                  inputProps={{ maxLength: 6 }}
                                  InputProps={
                                    availability
                                      ? {
                                          classes: { input: classes.input },
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleChangePincode}
                                                edge="end"
                                              >
                                                change
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }
                                      : notAvailable
                                      ? {
                                          classes: { input: classes.input },
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleChangePincode}
                                                edge="end"
                                              >
                                                change
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }
                                      : {
                                          classes: { input: classes.input },
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleCheckPincode}
                                                edge="end"
                                              >
                                                check
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }
                                  }
                                />

                                {availability ? (
                                  <Typography
                                    variant="subtitle1"
                                    className={classes.wrapIcon}
                                  >
                                    <LocalShippingIcon
                                      className={classes.linkIcon}
                                    />{" "}
                                    {availability.message}
                                  </Typography>
                                ) : notAvailable ? (
                                  <Typography className={classes.nodelivery}>
                                    {notAvailable}
                                  </Typography>
                                ) : (
                                  <FormLabel>
                                    Please enter <b>PIN CODE</b> to check
                                    Delivery Availability
                                  </FormLabel>
                                )}

                                {product.bargain === "YES" && (
                                  <Box
                                    component="div"
                                    style={{ marginTop: 16 }}
                                  >
                                    <Button
                                      variant="contained"
                                      className={classes.barginSection}
                                      onClick={() =>
                                        handleClickOpenBids("allBids")
                                      }
                                    >
                                      All Price
                                    </Button>

                                    <Button
                                      variant="contained"
                                      className={classes.barginSection}
                                      onClick={() =>
                                        handleClickOpenBids("allAsks")
                                      }
                                    >
                                      All Asks
                                    </Button>
                                  </Box>
                                )}

                                <Typography className={classes.county}>
                                  Country of Origin: {product.countryOfOrigin}
                                </Typography>

                                <Typography
                                  className={classes.descSection}
                                  component="div"
                                >
                                  <Typography
                                    component="div"
                                    className={classes.descTitle}
                                  >
                                    Product Description
                                  </Typography>

                                  <Typography
                                    component="div"
                                    className={classes.descList}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          attributevalue.attiributeDescription,
                                      }}
                                    />
                                  </Typography>
                                </Typography>
                              </Fragment>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <SoldOut />
                    )}

                    <Grid container>
                      <Grid item xs={12}>
                        <Typography className={classes.sellerSection}>
                          Seller Information
                        </Typography>
                      </Grid>

                      <Grid
                        container
                        alignItems="center"
                        style={{
                          width: "100%",
                          border: "1px solid #f0f0f0",
                          borderRadius: 3,
                        }}
                      >
                        <Box component="div" className={classes.sellerName}>
                          <Box>
                            <Avatar
                              alt="Remy Sharp"
                              src={
                                process.env.PUBLIC_URL + "/images/seller.svg"
                              }
                              className={classes.large}
                            />
                          </Box>
                          <Box className={classes.sellerTitle}>
                            <Typography className={classes.seller}>
                              {product.productUserDetail.companyName}
                            </Typography>

                            <div>
                              <Rating
                                name="read-only"
                                value={product.productUserDetail.userRatings}
                                readOnly
                                size="small"
                              />
                            </div>

                            <div>
                              <Typography
                                variant="caption"
                                style={{ color: "#27B23E" }}
                              >
                                (
                                {
                                  product.productUserDetail
                                    .totalUserRatingsCount
                                }{" "}
                                reviews)
                              </Typography>
                            </div>
                          </Box>
                        </Box>

                        <Divider orientation="vertical" flexItem />

                        <Box className={classes.soldInfo}>
                          <Typography className={classes.seller}>
                            Items Sold
                          </Typography>
                          <Typography className={classes.since}>
                            {product.productUserDetail.totalItemsSold}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    {/* <Typography variant="h4" className={classes.sellerSection}>
                      Verdict
                    </Typography> */}

                    {/* <img
                      src={demoImage}
                      alt="demo"
                      className={classes.verdictImg}
                    />

                    <Typography
                      variant="h4"
                      className={classes.descSection}
                      style={{ border: "none" }}
                    >
                      <Box component="span">
                        Looking at the price change since the sale started,this
                        product will definitely will one day become cult
                        classic. do not let this offer go now that there are
                      </Box>
                      <Box component="span" className={classes.flicker}>
                        {" "}
                        only {product.quantity} pieces left in stock. Hurry!!!
                      </Box>
                    </Typography> */}

                    <div ref={reviewSection}>
                      {product.ratingCount > 0 && (
                        <Reviews productId={product.id} />
                      )}
                    </div>
                  </Fragment>
                </Grid>

                
                {/* <Grid item xs={12}>
                  <RelatedProducts productId={product.id}  title='Recommended Products'/>
                </Grid> */}
                <Grid item xs={12}>
                  <RelatedProducts productId={product.id} title='Related Products' />
                </Grid>
                {isOpen && (
                  <Lightbox
                    mainSrc={`${imageBaseUrl}${productImages[photoIndex].imageUrl}`}
                    nextSrc={`${imageBaseUrl}${
                      productImages[(photoIndex + 1) % productImages.length]
                        .imageUrl
                    }`}
                    prevSrc={`${imageBaseUrl}${
                      productImages[
                        (photoIndex + productImages.length - 1) %
                          productImages.length
                      ].imageUrl
                    }`}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                      setphotoIndex(
                        (photoIndex + productImages.length - 1) %
                          productImages.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setphotoIndex((photoIndex + 1) % productImages.length)
                    }
                    reactModalStyle={{
                      overlay: {
                        top: "80px",
                      },
                    }}
                  />
                )}
              </Grid>

              {/* render authentication warning modal*/}
              {alert && (
                <AuthenticationAlertPop
                  open={alert}
                  handleCloseAlert={handleCloseAlert}
                />
              )}
              {/* {alert && (
                <AuthenticationAlert
                  alert={alert}
                  handleCloseAlert={handleCloseAlert}
                />
              )} */}

              {/* render all bids and all asks modal*/}
              <SeeallBids
                allbids={allbids}
                handleCloseOpenBids={handleCloseOpenBids}
                type={bidtype}
                    />
                    </>) : (<Sitemap />)}
            </Fragment>
          ) : (
            <PageNotFound />
          )}

              <Footer showSiteMap={showSiteMap3} setShowSiteMap={setShowSiteMap3} />
        </div>
      )}

      <SnackBar />
    </Fragment>
  );
};
