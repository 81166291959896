import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export const AuthRoute = ({ component: Component, ...rest }) => {
  const {
    authenticated,
    account: { userType },
  } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true && userType === "CUST" ? (
          <Redirect to="/" />
        ) : authenticated === true && userType === "VNDR" ? (
          <Redirect to="/seller-dashboard" />
        ) : authenticated === true && userType === "MERCH" ? (
          <Redirect to="/seller-dashboard" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export const CustomerRoute = ({ component: Component, ...rest }) => {
  const {
    authenticated,
    account: { userType },
  } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false ? (
          <Redirect to="/" />
        ) : authenticated === true && userType === "VNDR" ? (
          <Redirect to="/seller-dashboard" />
        ) : authenticated === true && userType === "MERCH" ? (
          <Redirect to="/seller-dashboard" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export const SellerRoute = ({ component: Component, ...rest }) => {
  const {
    authenticated,
    account: { userType },
  } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false ? (
          <Redirect to="/" />
        ) : authenticated === true && userType === "CUST" ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
