export const ActionTypes = {
  //UI reducers
  SET_ERRORS: "SET_ERRORS",
  LOADING_UI: "LOADING_UI",
  CLEAR_ERRORS: "CLEAR_ERRORS",
  SERVER_ERROR: "SERVER_ERROR",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SET_ERROR: "SET_ERROR",
  SET_ERRORS_SIGNUP_SELLER: "SET_ERRORS_SIGNUP_SELLER",
  SET_ERROR_ITEM: "SET_ERROR_ITEM",
  CHECK_SELLER_ACCOUNT_STATUS: "CHECK_SELLER_ACCOUNT_STATUS",
  CLEAR_FORM_MESSAGES: "CLEAR_FORM_MESSAGES",

  //User reducers   
  LOADING_USER: "LOADING_USER",
  SET_USER: "SET_USER",
  SET_AUTHENTICATED: "SET_AUTHENTICATED",
  SET_UNAUTHENTICATED: "SET_UNAUTHENTICATED",
  UPDATE_USER: "UPDATE_USER",
  ADD_SELLER_BANK_DETAILS: "ADD_SELLER_BANK_DETAILS",
  AUTH_ALERT: "AUTH_ALERT",
  CHECK_DUPLICATE_MOBILENUMBER: "CHECK_DUPLICATE_MOBILENUMBER",
  CLEAR_DUPLICATE_MOBILENUMBER: "CLEAR_DUPLICATE_MOBILENUMBER",
  CHECK_CUST_DUPLICATE_EMAIL: "CHECK_CUST_DUPLICATE_EMAIL",
  CLEAR_CUST_DUPLICATE_EMAIL: "CLEAR_CUST_DUPLICATE_EMAIL",

  //Home page reducers
  LOADING_HOME_PAGE_UI: "LOADING_HOME_PAGE_UI",
  PRODUCT_GROUPS: "PRODUCT_GROUPS",
  SET_HOME_PAGE_ERROR: "SET_HOME_PAGE_ERROR",
  SET_HOME_PAGE_SERVER_ERROR: "SET_HOME_PAGE_SERVER_ERROR",
  PRODUCT_LISTING_WITH_FILTERS: "PRODUCT_LISTING_WITH_FILTERS",
  PRODUCT_LIST_SORTING: "PRODUCT_LIST_SORTING",
  GET_BANNERS: "GET_BANNERS",
  GET_CATEGORY_LIST: "GET_CATEGORY_LIST",
  GET_PRODUCT_FILTERS: "GET_PRODUCT_FILTERS",
  PRODUCT_PRICE_TYPE_CLICKED: "PRODUCT_PRICE_TYPE_CLICKED",
  PRODUCT_PAGE_NUMBER_CLICKED: "PRODUCT_PAGE_NUMBER_CLICKED",
  PRODUCT_SORT_BY_CLICKED: "PRODUCT_SORT_BY_CLICKED",

  //Product details page reducer
  PRODUCT_DETAILS_REQUEST: "PRODUCT_DETAILS_REQUEST",
  PRODUCT_DETAILS_SUCCESS: "PRODUCT_DETAILS_SUCCESS",
  PRODUCT_DETAILS_ORDER_SUCCESS: "PRODUCT_DETAILS_ORDER_SUCCESS",
  GET_PRODUCT_REVIEWS: "GET_PRODUCT_REVIEWS",
  GET_RELATED_PRODUCTS: "GET_RELATED_PRODUCTS",
  PRODUCT_DETAILS_ERROR: "PRODUCT_DETAILS_ERROR",
  PRODUCT_DETAILS_SERVER_ERROR: "PRODUCT_DETAILS_SERVER_ERROR",
  PRODUCT_DETAILS_CLEAR_ERROR: "PRODUCT_DETAILS_CLEAR_ERROR",
  UPDATE_BARGAIN_TYPE: "UPDATE_BARGAIN_TYPE",

  //Customer wishlist
  LOADING_WISHLIST: "LOADING_WISHLIST",
  ADD_PRODUCT_WISHLIST: "ADD_PRODUCT_WISHLIST",
  GET_CUSTOMER_WISHLIST: "GET_CUSTOMER_WISHLIST",
  CLEAR_WISHLIST_ERRORS: "CLEAR_WISHLIST_ERRORS",
  DELETE_WISHLIST_PRODUCT: "DELETE_WISHLIST_PRODUCT",

  //View all bids and all asks reducers
  PRODUCT_BIDS_REQUEST: "PRODUCT_BIDS_REQUEST",
  PRODUCT_ALL_BIDS: "PRODUCT_ALL_BIDS",
  PRODUCT_ALL_ASKS: "PRODUCT_ALL_ASKS",
  PRODUCT_BIDS_ERROR: "PRODUCT_BIDS_ERROR",
  PRODUCT_BIDS_SERVER_ERROR: "PRODUCT_BIDS_SERVER_ERROR",
  PRODUCT_BIDS_CLEAR_ERRORS: "PRODUCT_BIDS_CLEAR_ERRORS",

  //Delivery pincode reducers
  DELIVERY_AVAILABILITY_BY_PINCODE: "DELIVERY_AVAILABILITY_BY_PINCODE",
  DELIVERY_NOT_AVAILABLE: "DELIVERY_NOT_AVAILABLE",
  RESET_PINCODE: "RESET_PINCODE",
  DELIVERY_ERROR: "DELIVERY_ERROR",
  CLEAR_DELIVERY_ERRORS: "CLEAR_DELIVERY_ERRORS",

  //Cart reducers
  CART_PAGE_LOADING: "CART_PAGE_LOADING",
  CART_PAGE_LOADED: "CART_PAGE_LOADED",
  ADD_PRODUCT_TO_CART: "ADD_PRODUCT_TO_CART",
  GET_CART_ITEM: "GET_CART_ITEM",
  CART_PAGE_ERROR: "CART_PAGE_ERROR",
  CART_PAGE_SERVER_ERROR: "CART_PAGE_SERVER_ERROR",
  CART_CLEAR_ERRORS: "CART_CLEAR_ERRORS",
  INCREASE_QUANTITY: "INCREASE_QUANTITY",
  DECREASE_QUANTITY: "DECREASE_QUANTITY",
  DELETE_CART_ITEM: "DELETE_CART_ITEM",

  //Customer notifications
  LOADING_REQUEST: "LOADING_REQUEST",
  LOADED_REQUEST: "LOADED_REQUEST",
  SET_CUSTOMER_NOTIFICATION: "SET_CUSTOMER_NOTIFICATION",
  SET_CUSTOMER_ERROR: "SET_CUSTOMER_ERROR",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
  GET_CUSTOMER_ORDERS: "GET_CUSTOMER_ORDERS",
  CLEAR_CUSTOMER_ERRORS: "CLEAR_CUSTOMER_ERRORS",
    GET_CUSTOMER_REVIEWS: "GET_CUSTOMER_REVIEWS",
  DELETE_CUSTOMER_REVIEW: "DELETE_CUSTOMER_REVIEW",
  EDIT_CUSTOMER_REVIEW: "EDIT_CUSTOMER_REVIEW",
  CUSTOMER_SEARCH: "CUSTOMER_SEARCH",
  SAVE_CUSTOMER_SEARCH_QURIES: "SAVE_CUSTOMER_SEARCH_QURIES",
  SET_ORDERS_ERROR: "SET_ORDERS_ERROR",
  CANCEL_CUSTOMER_ORDER: "CANCEL_CUSTOMER_ORDER",

  //Minicart
  CREATE_MINI_CART: "CREATE_MINI_CART",

  //Seller reducers
  LOADING_SELLER_DATA: "LOADING_SELLER_DATA",
  SELLER_SET_ERROR: "SELLER_SET_ERROR",
  SELLER_SERVER_ERROR: "SELLER_SERVER_ERROR",
  CATEGORY_LIST: "CATEGORY_LIST",
  CATEGORY_PRODUCT_COUNT: "CATEGORY_PRODUCT_COUNT",
  SELLER_PRODUCT_LIST: "SELLER_PRODUCT_LIST",
  CLEAR_ERRORS_SELLER: "CLEAR_ERRORS_SELLER",
  GET_SELLER_PROFILE: "GET_SELLER_PROFILE",
  UPDATE_SELLER_BANK_DETAILS: "UPDATE_SELLER_BANK_DETAILS",
  SELLER_CANCEL_REFRESH: "SELLER_CANCEL_REFRESH",
  //Seller selling reducers
  SELLINGS_DETAILS_BARGAINS_REQUEST: "SELLINGS_DETAILS_REQUEST",
  SELLINGS_DETAILS_BARGAIN_SUCCESS: "SELLINGS_DETAILS_SUCCESS",
  SELLINGS_DETAILS_BARGAINS_ERROR: "SELLINGS_DETAILS_ERROR",
  SELLINGS_CLEAR_ERRORS: "SELLINGS_CLEAR_ERRORS",
  SELLINGS_BARGAINS_RESPONSE_REQUEST: "SELLINGS_BARGAINS_RESPONSE_REQUEST",
  SET_BARGAINS: "SET_BARGAINS",
  SELLINGS_DETAILS_ORDERS_SUCCESS: "SELLINGS_DETAILS_ORDERS_SUCCESS",
  SELLINGS_DETAILS_ORDERS_REQUEST: "SELLINGS_DETAILS_ORDERS_REQUEST",
  SELLINGS_DETAILS_ORDERS_ERROR: "SELLINGS_DETAILS_ORDERS_ERROR",
  SELLINGS_BARGAIN_RESPONSE_ERROR: "SELLINGS_BARGAIN_RESPONSE_ERROR",

  //Seller product upload reducer
  CHANGE_PRODUCT_LOADING: "CHANGE_PRODUCT_LOADING",
  CHANGE_PRODUCT_STATUS: "CHANGE_PRODUCT_STATUS",
  ADD_PRODUCT: "ADD_PRODUCT",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  EDIT_PRODUCT: "EDIT_PRODUCT",
  SET_ATTRIBUTE_OPTIONS: "SET_ATTRIBUTE_OPTIONS",

  //Other reduccers
  VALID_OTP: "VALID_OTP",
  INVALID_OTP: "INVALID_OTP",
  CLEAR_OTP_MESSAGE: "CLEAR_OTP_MESSAGE",
  CITY_BY_STATE: "CITY_BY_STATE",
  GET_ALL_STATE: "GET_ALL_STATE",
  PINCODES_BY_CITY: "PINCODES_BY_CITY",
  USER_TERMS_CONDITIONS: "USER_TERMS_CONDITIONS",
  BUSSINESS_CATEGORIES: "BUSSINESS_CATEGORIES",
  BUSSINESS_SUB_CATEGORIES: "BUSSINESS_SUB_CATEGORIES",
  SELECTED_PRODUCT_CATEGORIES: "SELECTED_PRODUCT_CATEGORIES",
  REMOVE_SELECTED_CATEGORY: "REMOVE_SELECTED_CATEGORY",
  CLEAR_SELECTED_CATERORIES: "CLEAR_SELECTED_CATERORIES",
  SHOW_USER_SIGNUP_ALERT: "SHOW_USER_SIGNUP_ALERT",
  CLOSE_USER_SIGNUP_ALERT: "CLOSE_USER_SIGNUP_ALERT",
  VALID_GST: "VALID_GST",
  INVALID_GST: "INVALID_GST",
  CLEAR_GST_MESSAGE: "CLEAR_GST_MESSAGE",
  UPDATE_CLICKED_POLICY: "UPDATE_CLICKED_POLICY",

  //Inventory Management
  INVENTORY_LOADING: "INVENTORY_LOADING",
  BULK_UPLOADED_LIST: "BULK_UPLOADED_LIST",
  BULK_UPLOAD_VALIDATION: "BULK_UPLOAD_VALIDATION",
  UN_USED_IMAGES_BY_SELLER: "UN_USED_IMAGES_BY_SELLER",
  DELETE_UNUSED_IMAGE_BY_NAME: "DELETE_UNUSED_IMAGE_BY_NAME",
  INVENTORY_ERROR: "INVENTORY_ERROR",
  INVENTORY_SERVER_ERROR: "INVENTORY_SERVER_ERROR",
  CLEAR_INVENTORY_ERROR: "CLEAR_INVENTORY_ERROR",

  //Snackbar reducers
  SET_SNACKBAR: "SET_SNACKBAR",
  SNACKBAR_CLEAR: "SNACKBAR_CLEAR",

  //Payment reducers
  AFTER_PAYMENT_LOADING: "AFTER_PAYMENT_LOADING",
  CLEAR_PAYMENT_LOAD: "CLEAR_PAYMENT_LOAD",
  PAYMENT_ERROR_MSG: "PAYMENT_ERROR_MSG",

  // policy modal action types
  SHOW_POLICY_MODAL: "SHOW_POLICY_MODAL",
  CLOSE_POLICY_MODAL: "CLOSE_POLICY_MODAL",
};
