import React, { useState } from "react";
import { useSelector } from "react-redux";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { Bargain } from "./sellingTabs/Bargain";
import { Orders } from "./sellingTabs/Orders";
// import { History } from "./sellingTabs/History";

const useStyles = makeStyles((theme) => ({
  tabSection: {
    borderBottom: "1px solid #E9E9E9",
  },
  appBar: {
    color: "inherit",
    background: "transparent",
    boxShadow: "none",
    borderBottom: "1px solid #E9E9E9",
    "& .Mui-selected": {
      color: "#27B23E",
    },
    "& .MuiTab-wrapper": {
      fontSize: 13,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 600,
      letterSpacing: "0.19px",
      lineHeight: 1.38,
      textTransform: "capitalize",
    },
  },
  errorBlock: {
    height: "120px",
    margin: theme.spacing(2),
    padding: "42px",
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: "'Fira Sans', sans-serif",
    textAlign: "center",
    border: "solid 1px #919ea7",
  },
  indicator: {
    backgroundColor: "#27B23E",
  },
}));

export const Sellings = () => {
  const classes = useStyles();
  const { orders, bargains, error } = useSelector((state) => state.sales);

  const [activeTab, setActiveTab] = useState("1");

  const selectTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className={classes.tabSection}>
      <TabContext value={activeTab}>
        <AppBar position="static" className={classes.appBar}>
          <TabList
            onChange={selectTab}
            aria-label="simple tabs example"
            className={classes.tabList}
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab
              label={`Bargains (${bargains?.length || 0})`}
              value="1"
              style={{ outline: "none" }}
            />
            <Tab
              label={`Orders (${orders?.length || 0})`}
              value="2"
              style={{ outline: "none" }}
            />
            {/* <Tab
              label={`History (${0})`}
              value="3"
              style={{ outline: "none" }}
            /> */}
          </TabList>
        </AppBar>

        {error ? (
          <div>
            <Grid container spacing={3}>
              <Grid item xs>
                <Paper className={classes.errorBlock}>
                  <Typography>{error}</Typography>
                </Paper>
              </Grid>
            </Grid>
          </div>
        ) : (
          <React.Fragment>
            <TabPanel value="1">
              <Bargain />
            </TabPanel>

            <TabPanel value="2">
              <Orders />
            </TabPanel>

            {/* <TabPanel value="3">
              <History />
            </TabPanel> */}
          </React.Fragment>
        )}
      </TabContext>
    </div>
  );
};
