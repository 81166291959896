import React from "react";

/* import material ui components */
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#f0e0d4",
  },
  pagetitle: {
    fontSize: 28,
    fontWeight: 600,
    color: "#F24046",
    fontFamily: "'Fira Sans', sans-serif",
    letterSpacing: "normal",
    fontStretch: "normal",
    margin: "90px 0 10px 0",
    textAlign: "center",
  },

  text: {
    fontSize: 16,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 300,
    margin: "auto",
    lineHeight: "30px",
    color: "#5c6166",
  },
  heading: {
    textAlign: "start",
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    margin: "15px 0 15px 0",
    color: "#F24046",
  },
  subheading: {
    fontSize: 16,
    fontFamily: "'Fira Sans', sans-serif",
    margin: "15px 0 15px 0",
    fontWeight: 600,
  },
}));

export const PrivacyPolicyFooterNewContent = () => {
  const classes = useStyles();

  const todayDateDIplay = () => {
    let objectDate = new Date();
    let day = objectDate.getDate();
    let month = objectDate.getMonth();
    let year = objectDate.getFullYear();

    return `${day}-${month+ 1}-${year}`;
  };

  return (
    <div className={classes.root}>
      <Typography component="h6" className={classes.subheading}>
       Date: {todayDateDIplay()}
      </Typography>

      <Container fixed style={{ marginBottom: 20 }}>
        <Grid item xs={12}>
          <Typography component="h6" className={classes.pagetitle}>
            PRIVACY POLICY
          </Typography>

          {/* <div>
              {parse(getDocument(TERMS_OF_USE_DOC))}
          </div> */}

          <Typography component="p" className={classes.text}>
            Please read this Privacy Policy carefully. This contains important
            information about your rights and obligations. This Privacy Policy
            sets out the manner in which Stockxbid(“We”, “Us” and “Our”)
            collects, uses, maintains and discloses information collected from
            Users (each, a “User” also referred to as “You” and “Yours”) of the
            Website <a href="https://outxtock.com" target="_blank">
              www.outxtock.com
            </a> (“Website”).
            This Privacy Policy applies to the Website and all services offered
            by Us through the Website. By accessing and using the Website, You
            are consenting to the collection, transfer, storage, disclosure and
            other uses of your information as set out in this Privacy Policy. If
            you do not agree to the Privacy Policy please do not avail, use, or
            register with the Website. We shall not use your Information except
            in the manner provided under this Privacy Policy.
          </Typography>

          <Typography component="p" className={classes.text}>
            We may revise the Privacy Policy at any time by updating this page.
            Your continued use of the Website indicates your agreement to be
            bound by any such changes. You can view our updated Privacy Policy
            by visiting <Link to={"/pages/privacy-policy-1"} target="_blank">here</Link>.
          </Typography>

          <Typography component="h6" className={classes.subheading}>
            INFORMATION WE MAY COLLECT FROM YOU:
          </Typography>
          <Typography component="h6" className={classes.heading}>
            Personal identification information
          </Typography>

          <Typography component="p" className={classes.text}>
            We may collect Your personal information either directly from You
            while You use, register, or access our Website and provide it to us
            or automatically as you navigate through our services or from any
            other source of information including third party sources including
            our delivery partners, as and when it applies. We may collect your
            personal identification information including but not limited to
            name, email id, phone number, residential address, gender,
            credit/debit card information, marital status, anniversary date, PAN
            Card, Adhar Card and any other information that You may enter while
            registering or otherwise using the Website. While information such
            as date of birth in isolation may not be enough to uniquely identify
            You, a combination of these information may be sufficient to do so.
            You may optionally provide us with this information through third
            party sign in service such as Facebook or Google. In such cases we
            fetch and store whatever information is made available to use
            through these sign-in services.
          </Typography>

          <Typography component="p" className={classes.text}>
            While browsing the Website You are not required to provide personal
            information as set out herein and this may be required only if the
            user choses to avail or sign up on our website. However, we do not
            guarantee that you would be able to enjoy our full services without
            registering with our Website. All the information provided by You
            including personal information is voluntary. You may modify,
            correct, delete, or eliminate the personal information at any time.
            Any grievances related to the information shared by You may be
            brought to the attention of the grievance officer.
          </Typography>

          <Typography component="p" className={classes.text}>
            For the use or services and purchase of products, you may be
            required to pay us with a credit card, wire-transfer or debit card
            through third party payment gateway provider and such third party
            payment gateway may be required to collect certain financial
            information including, but not restricted to your credit/debit card
            number or your bank account details. All such financial information
            will be used only for billing and payment processes. The
            verification of such information shall be accomplished only by You
            through a process of authentication in which we have not role to
            play.
          </Typography>

          <Typography component="p" className={classes.text}>
            We may keep records of telephone calls received from and made to
            users for the purpose of administration of services, research and
            development, training, business intelligence, business development,
            or for user administration. We may share these records when required
            by law or when required to provide or facilitate you with services.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            Non-personal identification information
          </Typography>

          <Typography component="p" className={classes.text}>
            We may collect non-personal information about You whenever You
            access or interact with the Website. Non-personal identification
            information may include without limitation device name, version,
            location information, Gps information , server location, operating
            system and version, your mobile devices unique device ID, third
            party apps or website or service that referred users to the Website,
            language preference, timezone, browisng information including
            details of how long you used our services, search terms you have
            looked, transaction information, means of connection to the Website,
            IP address, technical information, and other similar information
            about users.
          </Typography>

          <Typography component="p" className={classes.text}>
            Due to the communication standards on the internet when You visit
            our Website, the Website may automatically receive the uniform
            resource locator of the site from which you use or visit the
            Website, details of website you are using after leaving our Website,
            the IP address of your computer operating system, type of web
            browser you are using, email patterns, and the name of ISP.
          </Typography>

          <Typography component="p" className={classes.text}>
            This information is used solely to analyze overall user trends and
            to help us improve our services. This information is not shared with
            third parties without user’s permission or when required under law
            or to facilitate user with the Services. However, you agree and
            confirm that we have the right to share the aggregate findings,
            including personal information by you in an unidentifiable form, and
            not the specific data with advertisers, sponsors, investors,
            strategic partners and others in course of our business.
          </Typography>

          <Typography component="p" className={classes.text}>
            When you visit our Website from your device, we collect, maintain,
            process and use your location data through GPS, WIFI or cellular
            triangulation. The location data collected by us are collected in a
            form that does not personally identify you. Our Website collects
            your location data to enable, provide, and improve location-based
            products and services offered by us, even when the app is closed or
            not in use.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            Cookies
          </Typography>

          <Typography component="p" className={classes.text}>
            Whenever You access the Website We may place "cookies" on your hard
            drive or your device for record-keeping purposes to enhance your
            experience or sometimes to personalize your experience. Cookies are
            small text files that are placed on your computer’s hard drive by
            the Website. Cookies help us to identify information relating your
            activities and to retain information relating to your preferences
            and history on the Website.
          </Typography>

          <Typography component="p" className={classes.text}>
            We may use advertising cookies to deliver ads that are more relevant
            to you and your interests.
          </Typography>

          <Typography component="p" className={classes.text}>
            You may choose to disable cookies by turning off cookie feature on
            the web browser or your device. However, by disabling this feature,
            some parts of the Website may not function properly. This may
            prevent You from taking full advantage of the Website.
          </Typography>

          <Typography component="h6" className={classes.subheading}>
            HOW WE USE COLLECTED INFORMATION:
          </Typography>

          <Typography component="p" className={classes.text}>
            We may collect and use Your information for the following purposes:
          </Typography>

          <Typography component="p" className={classes.text}>
            a) We may use your information to control your access to Our Website
            and services, to identify you, communicate with you, customize your
            experience by providing information that may be useful or
            interesting to you and to resolve any disputes.
          </Typography>
          <Typography component="p" className={classes.text}>
            b) We may use the email address to send, account information,
            administrative information, changes in account settings, changes to
            our services or any updates pertaining to the services. Apart from
            this if Users choose to opt-in to our mailing list, they will
            receive periodic emails that may relate to our activities, related
            service information, e.t.c. If You want to unsubscribe from
            receiving future emails from Us, please follow the “Unsubscribe”
            instructions provided at the bottom of each email.
          </Typography>
          <Typography component="p" className={classes.text}>
            c) Your Information may also be used for responding to any of your
            inquiries, questions, and/or any other requests.
          </Typography>
          <Typography component="p" className={classes.text}>
            d) We may use the Users information to analyse the use of the
            Website, and the people visiting and using the Website for the
            improving the features of the Website. Further, the information
            provided by You may also be used to help Us in responding to Your
            requests and support needs more efficiently.
          </Typography>

          <Typography component="p" className={classes.text}>
            e) We may also use Your information for research purposes.
          </Typography>

          <Typography component="p" className={classes.text}>
            f) We may also share Your information with any third parties
            including any government departments if the disclosure of such
            information is required by law or is based on any order by a court
            of competent jurisdiction.
          </Typography>
          <Typography component="p" className={classes.text}>
            g) We may use your geographical and location information to analyse
            our target audience for advertising purposes.
          </Typography>
          <Typography component="p" className={classes.text}>
            h) To meet any legal and regulatory requirements
          </Typography>
          <Typography component="p" className={classes.text}>
            i) To provide, trouble shoot and improve our services. We use tour
            information to provide functionality, analyse performance, fix
            errors, and improve usability and effectiveness of the Services.
          </Typography>
          <Typography component="p" className={classes.text}>
            j) To enforce the terms of use.
          </Typography>
          <Typography component="p" className={classes.text}>
            k) Purchase and delivery of products and service. We use your
            personal information to take, handle and fulfil orders made by you.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            Protection of your information
          </Typography>
          <Typography component="p" className={classes.text}>
            We adopt appropriate data collection, storage and processing
            practices and security measures to protect Your personal information
            against unauthorized access, alteration, disclosure or destruction.
            However, We cannot guarantee the security of any information
            obtained through unauthorized entry or use, hardware or software
            failure, and other factors that may compromise the security of user
            information at any time.
          </Typography>

          <Typography component="p" className={classes.text}>
            We do not sell, rent, distribute or otherwise make personal
            information commercially available to any third party, except as
            described in this policy or with your prior permission.
          </Typography>

          <Typography component="p" className={classes.text}>
            However, We may disclose your information to our holding companies,
            subsidiaries, and affiliates which are entities under our common
            ownership or control or to contractors, advertisers or service
            providers and other third parties whom we use to support our
            business or to third parties to market their products or services
            which we feel may be of interest or beneficial to you. We may also
            share your information with outside vendor that we use for a variety
            of purposes such as sending you communications via emails, messages,
            or tele calls to inform you about the services and/or products that
            may be of interest to you, push notifications to your mobile device
            on our behalf, help us analyze use of services and process and
            collect payments, for conducting surveys etc.
          </Typography>

          <Typography component="p" className={classes.text}>
            We may share generic aggregated demographic information not linked
            to any personally identifiable information regarding visitors and
            users with our business partners, trusted affiliates and
            advertisers. We may anonymise and/or de-identify information
            collected from you using third party web analytic tools.
          </Typography>
          <Typography component="p" className={classes.text}>
            At times We may be required by law or litigation to disclose your
            personal information. We may also disclose information about You, if
            We at our sole discretion determine that for national security, law
            enforcement, or other issues of public importance that disclosure of
            information is necessary.
          </Typography>
          <Typography component="h6" className={classes.heading}>
            Third Party Links
          </Typography>
          <Typography component="p" className={classes.text}>
            This Website may contain links to other websites or content. You
            agree and acknowledge that these third party sites are not under our
            control and We are not responsible for contents of such sites. These
            links have been provided to You only as a convenience. This Privacy
            Policy does not govern these links and You are required to read,
            understand and accept the Privacy Policy and Terms of Use of these
            third parties prior to using their services. Moreover we may allow
            other companies to serve advertisements to users. These companies or
            entitles include third party advertisements servers, agencies,
            technology vendors and research firms. We may target some
            advertisements to You that fit a general profile. We do not use
            personal information to target advertisements to specific users. In
            course of serving advertisements or optimizing the services, we may
            allow or authorize third parties to place or recognize a unique
            cookie on your browser.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            Changes to this privacy policy
          </Typography>
          <Typography component="p" className={classes.text}>
            We reserve the right to update and revise this Privacy Policy from
            time to time. The revised Privacy Policy will be available{" "}
            <Link to={"/pages/privacy-policy-1"} target="_blank">here</Link>. You are encouraged to
            periodically check this page to stay informed about changes to our
            Privacy Policy. The revised Policy will be effective from the date
            stated on the revised Privacy Policy. Your continued use of the
            Website following the posting of revised Policy will indicate your
            acceptance and acknowledgment of the changes and you will be bound
            by it.
          </Typography>

          <Typography component="h6" className={classes.heading}>
            Contacting our Grievance officer:
          </Typography>
          <Typography component="p" className={classes.text}>
            If you have any questions about this Privacy Policy, our practices
            or any of the Services or any other queries please contact our
            Grievance Officer at:{" "}
            <b>
              Rohan Dhiman Grievance Officer, Address: 29/1, First Floor, 6th
              Cross Rd, Sangeetha Apts, Swimming Pool Extension, Malleshwaram
              11th Cross, Bengaluru, Karnataka 560003, Ph: +918708885991. Email:
              Rohan.dhiman@stockxbid.com Time: Mon - Sat (10:00 AM - 07:00 PM)
            </b>
            .
          </Typography>
        </Grid>
      </Container>
    </div>
  );
};
