import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  loading: false,
  serverError: false,
  error: null,
  allBids: [],
  allAsks: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.PRODUCT_BIDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PRODUCT_ALL_BIDS:
      return {
        ...state,
        loading: false,
        allBids: action.payload,
      };
    case ActionTypes.PRODUCT_ALL_ASKS:
      return {
        ...state,
        loading: false,
        allAsks: action.payload,
      };
    case ActionTypes.PRODUCT_BIDS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ActionTypes.PRODUCT_BIDS_SERVER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
        serverError: action.payload,
      };
    case ActionTypes.PRODUCT_BIDS_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
        serverError: false,
      };
    default:
      return state;
  }
}
