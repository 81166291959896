import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  loading: false,
  error: null,
  serverError: false,
  uploadedProducts: [],
  fileValidation: null,
  unused: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.INVENTORY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.UN_USED_IMAGES_BY_SELLER:
      return {
        ...state,
        unused: action.payload,
        loading: false,
      };
    case ActionTypes.DELETE_UNUSED_IMAGE_BY_NAME:
      return {
        ...state,
        unused: state.unused.filter(
          (item) => item.imageName !== action.payload
        ),
      };
    case ActionTypes.BULK_UPLOADED_LIST:
      return {
        ...state,
        loading: false,
        uploadedProducts: action.payload,
      };
    case ActionTypes.BULK_UPLOAD_VALIDATION:
      return {
        ...state,
        loading: false,
        fileValidation: action.payload,
      };
    case ActionTypes.INVENTORY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.INVENTORY_SERVER_ERROR:
      return {
        ...state,
        loading: false,
        serverError: action.payload,
      };
    case ActionTypes.CLEAR_INVENTORY_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
        serverError: false,
        fileValidation: null,
      };
    default:
      return state;
  }
}
