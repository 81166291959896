import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

/* redux actions */
import {
  addAddress,
  alertSnackBar,
} from "../../../redux/actions/checkoutActions";

import { SnackBar } from "../../../components/SnackBar";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Collapse from "@material-ui/core/Collapse";
import Axios from "axios";
// import { InputLabel } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { isEmptyObject } from "jquery";
import {
  getCities,
  getPinCodes,
  getStates,
} from "../../../redux/actions/otherActions";

/* material ui css */
const useStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid #d6d6d6",
    "& .MuiCardHeader-root": {
      backgroundColor: "#53E7BC",
    },
    "& .MuiCardHeader-title": {
      fontSize: "14px",
      fontFamily: "'Fira Sans', sans-serif",
    },
    "& .MuiCardActions-root": {
      justifyContent: "center",
      borderTop: "1px solid #d6d6d6",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      fontSize: "12px",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      color: "#495057",
      textAlign: "initial",
      background: "#F7F7F7",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "13px",
      fontFamily: "'Fira Sans', sans-serif",
    },
  },
  toggleLabel: {
    color: "#5d5d5d",
    margin: "auto",
  },
  addresstitle: {
    fontSize: "16px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#5d5d5d",
  },
  labelRoot: {
    fontSize: "12px",
    fontFamily: "'Fira Sans', sans-serif",
    color: "#5d5d5d",
    letterSpacing: 0.17,
  },
  button: {
    backgroundColor: "#53e7bc",
    fontSize: 12,
    "&:hover": {
      backgroundColor: "#53e7bc",
      fontSize: 12,
    },
  },
}));


const ALPHA_ONLY = /^[a-zA-Z\s]+$/;
const NUMERIC_ONLY =/^[0-9]+$/;  
const AlPHANUMERIC_ONLY = /^[ A-Za-z0-9_@./#&+-]+$/; 
const SPECIAL_ONLY = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
const NUMERIC_SPECIAL_ONLY = /^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;

export const AddAddressForm = (props) => {
  const classes = useStyles();
  const {setAddAddressUpdated, handleCancel, account } = props;
  const dispatch = useDispatch();
  const { pincodes, cities, states } = useSelector((state) => state.Others);
  const [checked, setChecked] = useState(false);

  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [pincode, setPincode] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [stateSeleted, setStateSeleted] = useState();
  const [citySeleted, setCitySeleted] = useState();
  const [pinSeleted, setPinSeleted] = useState();
  const [billingname, setBillingName] = useState("");
  const [billinglastname, setBillingLastName] = useState("");
  const [billingmobile, setBillingMobile] = useState("");
  const [billingpincode, setBillingPincode] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingstate, setBillingState] = useState("");
  const [billingcity, setBillingCity] = useState("");
  const [billingPostalCode, setBillingPostalCode] = useState("");
  const [billingStateProvince, setBillingStateProvince] = useState("");
  const [stateBillingSeleted, setStateBillingSeleted] = useState();
  const [cityBillingSeleted, setCityBillingSeleted] = useState();
  const [pinBillingSeleted, setPinBillingSeleted] = useState();
  const [formErrors, setFormErrors] = useState({});

  



  useEffect(() => {
    setBillingName(account.billing.firstName);
    setBillingLastName(account.billing.lastName);
    setBillingMobile(account.billing.phone);
    setBillingPincode(
      account.billing.postalCode ? account.billing.postalCode : ""
    );
    setBillingAddress(account.billing.address ? account.billing.address : "");
    setBillingCity(account.billing.city ? account.billing.city : "");
    setBillingState(
      account.billing.stateProvince ? account.billing.stateProvince : ""
    );
    setBillingStateProvince(
      account.billing.stateProvince ? account.billing.stateProvince : ""
    );
    setBillingPostalCode(
      account.billing.postalCode ? account.billing.postalCode : ""
    );
    setStateBillingSeleted(account.billing ? account.billing : "");
    setCityBillingSeleted(account.billing ? account.billing : "");
    setPinBillingSeleted(account.billing ? account.billing : "");


    setName(account.delivery ? account.delivery.firstName : "");
    setLastName(account.delivery ? account.delivery.lastName : "");
    setMobile(account.delivery ? account.delivery.phone : "");
    setPincode(account.delivery ? account.delivery.postalCode : "");
    setAddress(account.delivery ? account.delivery.address : "");
    setState(account.delivery ? account.delivery.stateProvince : "");
    setCity(account.delivery ? account.delivery.city : "");
    setStateProvince(account.delivery ? account.delivery.stateProvince : "");
    setCitySeleted(account.delivery ? account.delivery : "");
    setStateSeleted(account.delivery ? account.delivery : "");
    setPinSeleted(account.delivery ? account.delivery : "");
    setPostalCode(account.delivery ? account.delivery.postalCode : "");
  }, [account]);

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  /// delivery address api state, dis, pincode
  const handleClickState = (state) => {
    if (!isEmptyObject(state)) {
      setStateSeleted(state);
      setCitySeleted()
      setPinSeleted()
      setCity()
      setPincode()
      setStateProvince(state.hubState);
      dispatch(getCities(state.hubState));
    }
  };

  const handleClickCity = (city) => {
    if (!isEmptyObject(city)) {
      setCitySeleted(city);
      setCity(city.hubCity);
      setPinSeleted()
      setPincode()
      dispatch(getPinCodes(city.hubCity));
    }
  };

  const handleClickPinCode = (pinCodes) => {
    if (!isEmptyObject(pinCodes)) {
      setPinSeleted(pinCodes)
      setPostalCode(pinCodes.pincode);
    }
  };

  /// billing address api state, dis, pincode
  const handleClickBillingState = (state) => {
    if (!isEmptyObject(state)) {
      setStateBillingSeleted(state);
      setCityBillingSeleted()
      setPinBillingSeleted()
      setBillingCity()
      setBillingPostalCode()
      setBillingStateProvince(state.hubState);
      dispatch(getCities(state.hubState));
    }
  };

  const handleClickBillingCity = (city) => {
    if (!isEmptyObject(city)) {
      setCityBillingSeleted(city);
      setBillingCity(city.hubCity);
      setPinBillingSeleted()
      setBillingPostalCode()
      dispatch(getPinCodes(city.hubCity));
    }
  };

  const handleClickBillingPinCode = (pinCode) => {
    if (!isEmptyObject(pinCode)) {
      setStateBillingSeleted(pinCode);
      setPinBillingSeleted(pinCode);
      setBillingPostalCode(pinCode.pincode);
    }
  };


  /* add address form validation */
  const addressFormValidation = () => {
    let formErrors = {};
    let inputsValid = true;

    if (!checked) {
      if (!name) {
        inputsValid = false;
        formErrors["name"] = "This field is required";
      }

      if (!lastname) {
        inputsValid = false;
        formErrors["lastname"] = "This field is required";
      }

      if (!mobile) {
        inputsValid = false;
        formErrors["mobile"] = "This field is required";
      }

      if (mobile.length !== 10) {
        inputsValid = false;
  
        formErrors["mobile"] = "mobile number should be 10 digit";
      }

      if (!pinSeleted) {
        inputsValid = false;
        formErrors["pincode"] = "This field is required";
      }

      if (!address) {
        inputsValid = false;
        formErrors["address"] = "This field is required";
      }

     

      if (!stateSeleted) {
        inputsValid = false;
        formErrors["state"] = "This field is required";
      }

      if (!city) {
        inputsValid = false;
        formErrors["city"] = "This field is required";
      }
    }

    if (!billingname) {
      inputsValid = false;
      formErrors["billingname"] = "This field is required";
    }

    if (!billinglastname) {
      inputsValid = false;
      formErrors["billinglastname"] = "This field is required";
    }

    // if (!billingmobile) {
    //   inputsValid = false;
    //   formErrors["billingmobile"] = "This field is required";
    // }

    if (!billingPostalCode) {
      inputsValid = false;
      formErrors["billingpincode"] = "This field is required";
    }

    if (!billingAddress) {
      inputsValid = false;
      formErrors["billingAddress"] = "This field is required";
    }

  

    if (!billingStateProvince) {
      inputsValid = false;
      formErrors["billingstate"] = "This field is required";
    }

    if (!billingcity) {
      inputsValid = false;
      formErrors["billingcity"] = "This field is required";
    }

    setFormErrors(formErrors);
    // console.log(formErrors)
    // console.log(inputsValid)
    return inputsValid;
  };

  /* function to clear address form */
  const clearAddressForm = () => {
    setChecked(false);
    setName("");
    setLastName("");
    setMobile("");
    setPincode("");
    setAddress("");
    setState("");
    setCity("");
    setBillingName("");
    setBillingLastName("");
    // setBillingMobile("");
    setBillingPincode("");
    setBillingAddress("");
    setBillingState("");
    setBillingCity("");
    setFormErrors({});
    setStateProvince("")
  };

  /* handle submite customer address */
  const handleAddAddress = async (event) => {
    event.preventDefault();
    setAddAddressUpdated(true)
    if (addressFormValidation()) {
      let delivery;

      if (!checked) {
        delivery = {
          address: address,
          billingAddress: false,
          bilstateOther: "No",
          city: city,
          company: "",
          country: "IN",
          countryCode: "IN",
          firstName: name,
          lastName: lastname,
          postalCode: postalCode,
          phone: mobile,
          stateProvince: stateProvince,
          zone: "IN",
        };
      } else {
        delivery = {
          address: billingAddress,
          billingAddress: true,
          bilstateOther: "No",
          city: billingcity,
          company: "",
          country: "IN",
          countryCode: "IN",
          firstName: billingname,
          lastName: billinglastname,
          postalCode: billingPostalCode,
          phone: billingmobile,
          stateProvince: billingStateProvince,
          zone: "IN",
        };
      }

      const addressData = {
        userName: billingname,
        billing: {
          address: billingAddress,
          billingAddress: true,
          bilstateOther: "No",
          city: billingcity,
          company: "",
          country: "IN",
          countryCode: "IN",
          firstName: billingname,
          lastName: billinglastname,
          postalCode: billingPostalCode,
          phone: billingmobile,
          stateProvince: billingStateProvince,
          zone: "IN",
        },
        delivery: delivery,
      };


      
      const { data } = await Axios.get(
        `${process.env.REACT_APP_SHIPPING_URL}/api/v1/searchByPinCode/${
          addressData.delivery
            ? addressData.delivery.postalCode
            : addressData.billing.postalCode
        }`
      );

      if (
        data.pincode === addressData.delivery.postalCode ||
        data.pincode === addressData.billing.postalCode
      ) {

        dispatch(addAddress(addressData));
        clearAddressForm();
        handleCancel();
      } else {
        dispatch(
          alertSnackBar(
            "error",
            "Unfortunately we do not ship to your pincode."
          )
        );
      }
    }
  };

  useEffect(() => {
    dispatch(getStates());
  }, []);

  return (
    <React.Fragment>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox size="small" checked={checked} onChange={toggleChecked} />
          }
          label="Delivery address same as billing address"
          className={classes.toggleLabel}
        />
      </FormGroup>

      <form onSubmit={handleAddAddress} autoComplete="off">
       {stateBillingSeleted && <CardContent>
          <Grid container spacing={3}>
            <React.Fragment>
              <Grid item xs={12}>
                <Typography className={classes.addresstitle}>
                  Billing Address
                </Typography>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="First Name *"
                  id="billing_firstname"
                  variant="outlined"
                  size="small"
                  value={billingname}
                  onChange={(e) => {
                    const value = e.target.value;
                      if(value !== '' && !ALPHA_ONLY.test(value)){
                        return;
                      }
                    setBillingName(e.target.value)}}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={
                    formErrors.billingname ? formErrors.billingname : ""
                  }
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Last Name *"
                  id="billing_lastname"
                  variant="outlined"
                  size="small"
                  value={billinglastname}
                  onChange={(e) => {
                    const value = e.target.value;
                      if(value !== '' && !ALPHA_ONLY.test(value)){
                        return;
                      }
                    setBillingLastName(e.target.value)}}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={
                    formErrors.billinglastname ? formErrors.billinglastname : ""
                  }
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  disabled
                  label="Mobile Number *"
                  id="billing_mobile"
                  variant="outlined"
                  size="small"
                  value={billingmobile}
                  onChange={(e) => setBillingMobile(e.target.value)}
                  inputProps={{ maxLength: 10}}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={
                    formErrors.billingmobile ? formErrors.billingmobile : ""
                  }
                />
              </Grid>

              {/* <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Pin Code"
                  id="billing_pincode"
                  variant="outlined"
                  size="small"
                  value={billingpincode}
                  onChange={(e) => setBillingPincode(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={
                    formErrors.billingpincode ? formErrors.billingpincode : ""
                  }
                />
              </Grid> */}

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Address *"
                  id="billing_address"
                  variant="outlined"
                  size="small"
                  value={billingAddress}
                  onChange={(e) => setBillingAddress(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={
                    formErrors.billingAddress ? formErrors.billingAddress : ""
                  }
                />
              </Grid>

              {/* <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="City/District"
                  id="billing_city"
                  variant="outlined"
                  size="small"
                  value={billingcity}
                  onChange={(e) => setBillingCity(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={
                    formErrors.billingcity ? formErrors.billingcity : ""
                  }
                />
              </Grid> */}

              {/* <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="State"
                  id="billing_state"
                  variant="outlined"
                  size="small"
                  value={billingstate}
                  onChange={(e) => setBillingState(e.target.value)}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  helperText={
                    formErrors.billingstate ? formErrors.billingstate : ""
                  }
                />
              </Grid> */}

              <Grid item md={6} xs={12}>
                <Autocomplete
                  id="asynchronous-demo"
                  classes={{
                    option: classes.option,
                    noOptions: classes.noOptions,
                  }}
                  ListboxProps={{ style: { textTransform: "uppercase" } }}
                  options={states}
                  value={stateBillingSeleted}
                  getOptionSelected={(option, value) =>
                    option.hubState === stateBillingSeleted.stateProvince
                  }
                  getOptionLabel={(option) =>
                    option.hubState !== "HubState"
                      ? option.hubState
                        ? option.hubState
                        : stateBillingSeleted.stateProvince
                      : ""
                  }
                  onChange={(event, newValue) => {
                    handleClickBillingState(newValue);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="State *"
                      variant="outlined"
                      size="small"
                      // style={{ width: 150, height: 29 }}
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: "new-password",
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.billingStateProvince
                          ? formErrors.billingStateProvince
                          : ""
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Autocomplete
                  id="asynchronous-demo"
                  classes={{
                    option: classes.option,
                    noOptions: classes.noOptions,
                  }}
                  ListboxProps={{ style: { textTransform: "uppercase" } }}
                  options={cities || []}
                  value={cityBillingSeleted || []}
                  getOptionSelected={(option, value) =>
                    option.hubCity === value.city
                  }
                  getOptionLabel={(option) =>
                    option.hubCity ? option.hubCity : stateBillingSeleted.city
                  }
                  onChange={(event, newValue) => {
                    handleClickBillingCity(newValue);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="City/District *"
                      variant="outlined"
                      size="small"
                      // style={{ width: 150, height: 29 }}
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: "new-password",
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.billingcity ? formErrors.billingcity : ""
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Autocomplete
                  id="asynchronous-demo"
                  classes={{
                    option: classes.option,
                    noOptions: classes.noOptions,
                  }}
                  // ListboxProps={{ style: { maxHeight: 150 } }}
                  options={pincodes || []}
                  value={pinBillingSeleted || []}
                  getOptionSelected={(option, value) =>
                    option.pincode === value.postalCode
                  }
                  getOptionLabel={(option) =>
                    option.pincode
                      ? option.pincode
                      : stateBillingSeleted.postalCode
                  }
                  onChange={(event, newValue) => {
                    handleClickBillingPinCode(newValue);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Pin Code *"
                      variant="outlined"
                      size="small"
                      // style={{ width: 150, height: 29 }}
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: "new-password",
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                      helperText={
                        formErrors.billingpincode
                          ? formErrors.billingpincode
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
            </React.Fragment>
          </Grid>

          <Collapse in={!checked} timeout="auto" unmountOnExit>
            <Grid container spacing={3}>
              <React.Fragment>
                <Grid item xs={12} style={{ marginTop: 18 }}>
                  <Typography className={classes.addresstitle}>
                    Delivery Address
                  </Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="First Name *"
                    id="firstname"
                    variant="outlined"
                    size="small"
                    value={name}
                    onChange={(e) => {
                      const value = e.target.value;
                      if(value !== '' && !ALPHA_ONLY.test(value)){
                        return;
                      }
                      
                      setName(e.target.value)}}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.name ? formErrors.name : ""}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Last Name *"
                    id="lastname"
                    variant="outlined"
                    size="small"
                    value={lastname}
                    onChange={(e) => {
                      const value = e.target.value;
                      if(value !== '' && !ALPHA_ONLY.test(value)){
                        return;
                      }
                      setLastName(e.target.value)}}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.lastname ? formErrors.lastname : ""}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Mobile Number *"
                    id="mobile"
                    variant="outlined"
                    size="small"
                    value={mobile}
                    onChange={(e) => {
                      const value = e.target.value;
                      if( value !== '' && !NUMERIC_ONLY.test(value)){
                        return;
                      }
                      setMobile(e.target.value)}}
                      inputProps={{ maxLength: 10}}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.mobile ? formErrors.mobile : ""}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Address *"
                    id="address"
                    variant="outlined"
                    size="small"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.address ? formErrors.address : ""}
                  />
                </Grid>
                {/* <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Pin Code"
                    id="pincode"
                    variant="outlined"
                    size="small"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.pincode ? formErrors.pincode : ""}
                  />
                </Grid> */}
                {/* <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="City/District"
                    id="city"
                    variant="outlined"
                    size="small"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.city ? formErrors.city : ""}
                  />
                </Grid> */}

                {/* <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="State"
                    id="state"
                    variant="outlined"
                    size="small"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    helperText={formErrors.state ? formErrors.state : ""}
                  />
                </Grid> */}

                <Grid item md={6} xs={12}>
                  <Autocomplete
                    id="asynchronous-demo"
                    classes={{
                      option: classes.option,
                      noOptions: classes.noOptions,
                    }}
                    ListboxProps={{ style: { textTransform: "uppercase" } }}
                    options={states}
                    value={stateSeleted}
                    getOptionSelected={(option, value) =>
                      option.hubState === value.stateProvince
                    }
                    getOptionLabel={(option) =>
                      option.hubState !== "HubState"
                      ? option.hubState
                        ? option.hubState
                        : stateSeleted.stateProvince
                      : ""
                    }
                    onChange={(event, newValue) => {
                      handleClickState(newValue);
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="States *"
                        variant="outlined"
                        size="small"
                        // style={{ width: 150, height: 29 }}
                        InputProps={{
                          ...params.InputProps,
                          autoComplete: "new-password",
                          classes: {
                            root: classes.labelRoot,
                          },
                        }}
                        helperText={
                          formErrors.stateProvince
                            ? formErrors.stateProvince
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Autocomplete
                    id="asynchronous-demo"
                    classes={{
                      option: classes.option,
                      noOptions: classes.noOptions,
                    }}
                    ListboxProps={{ style: { textTransform: "uppercase" } }}
                    options={cities || []}
                    value={citySeleted || []}
                    getOptionSelected={(option, value) =>
                      option.hubCity === value.city
                    }
                    getOptionLabel={(option) =>
                      option.hubCity ? option.hubCity : stateSeleted.city
                    }
                    onChange={(event, newValue) => {
                      handleClickCity(newValue);
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="City/District *"
                        variant="outlined"
                        size="small"
                        // style={{ width: 150, height: 29 }}
                        InputProps={{
                          ...params.InputProps,
                          autoComplete: "new-password",
                          classes: {
                            root: classes.labelRoot,
                          },
                        }}
                        helperText={formErrors.city ? formErrors.city : ""}
                      />
                    )}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Autocomplete
                    id="asynchronous-demo"
                    classes={{
                      option: classes.option,
                      noOptions: classes.noOptions,
                    }}
                    // ListboxProps={{ style: { maxHeight: 150 } }}
                    options={pincodes || []}
                    value={pinSeleted || []}
                    getOptionSelected={(option, value) =>
                      option.pincode === value.postalCode
                    }
                    getOptionLabel={(option) =>
                      option.pincode ? option.pincode : stateSeleted.postalCode
                    }
                    onChange={(event, newValue) => {
                      handleClickPinCode(newValue);
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Pin Code *"
                        variant="outlined"
                        size="small"
                        // style={{ width: 150, height: 29 }}
                        InputProps={{
                          ...params.InputProps,
                          autoComplete: "new-password",
                          classes: {
                            root: classes.labelRoot,
                          },
                        }}
                        helperText={
                          formErrors.pincode ? formErrors.pincode : ""
                        }
                      />
                    )}
                  />
                </Grid>
              </React.Fragment>
            </Grid>
          </Collapse>
        </CardContent>}

        <CardActions>
          <Button
            autoFocus
            type="submit"
            variant="contained"
            className={classes.button}
          >
            Save
          </Button>
          <Button autoFocus onClick={handleCancel} style={{ fontSize: 12 }}>
            Cancel
          </Button>
        </CardActions>
      </form>

      <SnackBar />
    </React.Fragment>
  );
};
