import React, { useEffect } from 'react';
import './style/MenuHeader.css';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

/* redux actions */
import { getCategoryList } from "../redux/actions/homepageActions";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";

// import Toolbar from "@material-ui/core/Toolbar";
// import Button from "@material-ui/core/Button";
import { PlayArrow } from '@material-ui/icons';
import { capitalize } from '@material-ui/core';

/* material ui css */
const useStyles = makeStyles((theme) => ({
  menuHeader: {
    marginTop: '80px',
    width: '100%',
    height: '45px',
    background: 'rgb(82, 80, 80)',
    borderBottom: '1px solid #cecece',
    cursor: "pointer",
    transition: 'all 0.5s ease-out',
    fontFamily: "'Fira Sans', sans-serif",
    "@media(max-width: 380px)":{
      overflowX: "scroll",
    }
    
  },
  menuHeaderUl: {
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    position: 'relative'
  },
  menuHeaderUlLiSpan: {
    display: 'block',
    lineHeight: '45px',
    cursor: 'pointer',
    padding: '0 10px',
    fontSize: '16px',
    fontWeight: 600,
    color: '#fff',
    "&:hover": {
      color:"#cecece", 
    },
    // "@media(max-width: 380px)":{
    //   fontSize: '13px',
    // }
  },

  menuHeaderUlLiUl: {
    position: 'absolute',
    background: '#fff',
    left: 0,
    right: 0,
    display: 'block',
    border: '1px solid #cecece',
    zIndex: 1,
  },

  menuHeaderUlLiUlFalse:{
    display: 'none',
  },

  ahref: {
    fontSize: '10px'
  }
}));


const MenuHeader = (props) => {

  const classes = useStyles();
  const { categories } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const history = useHistory();
  // const [isShown, setIsShown] = useState(false);

  const { categoryClicked , fromProductOrSearchPage} = props;


  const onCategoryClick = (catCode) => {

    if(fromProductOrSearchPage) {
      categoryClicked(catCode)
    } else {
      history.push(`/${catCode}/products`);
    }
    
  };

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(getCategoryList());
    }
  }, []); 



  const renderCategories = (categories) => {

    let myCategories = [];
    for (let category of categories) {

      myCategories.push(
        <li  key={category.id}>
          {
            category.parent ? <div onClick={() => onCategoryClick(category.code)} style={{  cursor: "pointer"}} >
              {category.code}{ category.children.length ?  <PlayArrow className={classes.ahref} /> : ''}
            </div> :
            <span className={classes.menuHeaderUlLiSpan} onClick={() => onCategoryClick(category.code)} >{category.code  ? capitalize(category.code) : ''}</span>
          }
          {category.children.length > 0 ? (<ul>{renderCategories(category.children)}</ul>) : null}
        </li>
      );
    }
    return myCategories;
  }
  return (
    <div className={classes.menuHeader}>
      <ul className={classes.menuHeaderUl}>
        {categories.length > 0 ? renderCategories(categories.filter((cate) => cate.visible === true)) : null}
      </ul>
    </div>
  )

}

export default React.memo(MenuHeader)