import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

//reducers
import userReducer from "./reducers/userReducer";
import uiReducer from "./reducers/uiReducer";
import sellerReducer from "./reducers/sellerReducer";
import salesReducer from "./reducers/salesReducer";
import homepageReducer from "./reducers/homepageReducer";
import productReducer from "./reducers/productReducer";
import allBidsReducer from "./reducers/allbidsReducer";
import cartReducer from "./reducers/cartReducer";
import customerNotificationReducer from "./reducers/customerNotificationReducer";
import snackbar from "./reducers/snackbarReducer";
import delivery from "./reducers/deliveryReducer";
import otheractions from "./reducers/otherReducer";
import inventoryReducer from "./reducers/inventoryReducer";
import customerWishlistReducer from "./reducers/customerWishlistReducer";
import checkoutReducer from "./reducers/checkoutReducer";
import PolicyModalReducer from "./reducers/policyModalReducer";

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  auth: userReducer,
  UI: uiReducer,
  seller: sellerReducer,
  sales: salesReducer,
  home: homepageReducer,
  product: productReducer,
  allbids: allBidsReducer,
  shoppingCart: cartReducer,
  notification: customerNotificationReducer,
  checkout: checkoutReducer,
  snackbar: snackbar,
  Delivery: delivery,
  Others: otheractions,
  Inventory: inventoryReducer,
  Wishlist: customerWishlistReducer,
  policyModalReducer : PolicyModalReducer
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));
const store = createStore(reducers, initialState, enhancer);

export default store;
