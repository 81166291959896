import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// react component
import { DemoItem } from "../../components/DemoItem";

// redux actions
import { getRelatedProductsByProduct } from "../../redux/actions/productActions";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  groupproducts: {
    padding: 24,
    borderRadius: "0 0 2px 2px",
    boxShadow: "0 1px 8px 0 rgb(0 0 0 / 6%)",
    margin: "10px 0 auto",
  },
  relatedLable: {
    fontSize: 18,
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    color: "#000000",
  },
  slide: {
    "& .slick-arrow": {
      backgroundColor: "hsla(0,0%,100%,.98)",
      boxShadow: "1px 2px 10px -1px rgba(0,0,0,.3)",
      height: 75,
    },
    "& .slick-prev:before": {
      color: "#ff9d00",
    },
    "& .slick-next:before": {
      color: "#ff9d00",
    },
  },
}));

export const RelatedProducts = (props) => {
  const classes = useStyles();
  const { productId, title } = props;
  const { related } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRelatedProductsByProduct(productId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <Fragment>
      {related && related.length > 0 && (
        <Paper square className={classes.groupproducts}>
          <Typography className={classes.relatedLable}>
            {title}
          </Typography>
          <Slider {...settings} className={classes.slide}>
            {related.map((item) => (
              <Grid item key={item.id}>
                <DemoItem product={item} />
              </Grid>
            ))}
          </Slider>
        </Paper>
      )}
    </Fragment>
  );
};
