import React, { useState, Fragment } from "react";

/* react components */
import { UploadProductImage } from "./UploadProductImage";
import { ImageViewer } from "./ImageViewer";
import { UnusedImage } from "./UnusedImage";

/* material ui */
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

/* Material ui css */
const useStyles = makeStyles((theme) => ({
  listingSection: {
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    textAlign: "center",
  },
  label: {
    fontFamily: "'Fira Sans', sans-serif",
    fontSize: 14,
    fontWeight: 600,
  },

  appBar: {
    color: "inherit",
    background: "transparent",
    boxShadow: "none",
    borderBottom: "1px solid #E9E9E9",
    "& .Mui-selected": {
      color: "#27B23E",
    },
    "& .MuiTab-wrapper": {
      fontSize: 13,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 600,
      letterSpacing: "0.19px",
      lineHeight: 1.38,
      textTransform: "capitalize",
    },
  },
  indicator: {
    backgroundColor: "#27B23E",
  },
}));

export const ImageCloud = () => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState("1");

  const selectTab = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <Fragment>
      <TabContext value={activeTab}>
        <AppBar position="static" className={classes.appBar}>
          <TabList
            variant="scrollable"
            onChange={selectTab}
            aria-label="simple tabs example"
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab label="Upload service" value="1" style={{ outline: "none" }} />
            <Tab label="Image Viewer" value="2" style={{ outline: "none" }} />
            <Tab label="Unused Images" value="3" style={{ outline: "none" }} />
          </TabList>
        </AppBar>

        <TabPanel value="1">
          <UploadProductImage />
        </TabPanel>

        <TabPanel value="2">
          <ImageViewer />
        </TabPanel>

        <TabPanel value="3">
          <UnusedImage />
        </TabPanel>
      </TabContext>
    </Fragment>
  );
};
