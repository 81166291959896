import { ActionTypes } from "../constants/actionTypes";

const initialState = {
  snackbarOpen: false,
  snackbarType: "",
  snackbarMessage: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_SNACKBAR:
      return {
        ...state,
        snackbarOpen: true,
        snackbarType: action.payload.alert,
        snackbarMessage: action.payload.message,
      };
    case ActionTypes.SNACKBAR_CLEAR:
      return {
        ...state,
        snackbarOpen: false,
        snackbarType: "",
        snackbarMessage: "",
      };
    default:
      return state;
  }
}
